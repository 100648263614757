import { OpenFormButton } from '../OpenFormComponents/OpenFormButton.js';
import { OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import classNames from 'classnames';

export const OpenFormViewBreadcrumbs = ({
  breadcrumbs,
  disabled,
  index,
}: {
  breadcrumbs?: string[];
  disabled: boolean;
  index: number;
}) => (
  <OpenFormGridRow className="of-openform__view__breadcrumbs">
    {breadcrumbs?.map((guid, i) => (
      <OpenFormButton
        className={classNames({ incomplete: i >= index })}
        disabled={disabled}
        color="primary"
        size="l"
        text={i + 1}
        key={guid}
      />
    ))}
  </OpenFormGridRow>
);
