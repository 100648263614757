import { CREATED_CONTACT } from '../../common/react-hook-form/fields/Contact';
import { CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE, ReceiverTypes } from '../../common/utils/billingAccountUtils';
import { ContactRole } from '../../generated/api/contactRole';
import { ContactType } from '../../generated/api/contactType';
import { createBillingAccount, postContact } from '../../common/fetch';
import type { Contact } from '../../generated/api/contact';
import type { ReadyFormValues } from './CatalogConfigurationForm';

const enrichContact = (contact: Contact) => {
  const contactPerson = contact.person!;
  return {
    ...contact,
    person: {
      ...contactPerson,
      roles: [ContactRole.BILLING_CONTACT],
    },
  };
};

export const createContactsAndBillingAccount = async (data: ReadyFormValues): Promise<string> => {
  if (data.catalog?.billingAccountId === CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE) {
    const isNewContact = data.billingAccount?.billingContactId === CREATED_CONTACT;
    const billingContactId = isNewContact
      ? (await postContact(enrichContact(data.contact!))).contactId
      : data.billingAccount?.billingContactId;

    const isNewReceiver = data.billingAccount?.billReceiverId === CREATED_CONTACT;
    const billReceiverId = isNewReceiver
      ? (await postContact(enrichContact(data.receiver!))).contactId
      : data.billReceiverSelection
        ? data.billReceiverSelection === ReceiverTypes.SAME_AS_CONTACT
          ? billingContactId
          : data.billingAccount?.billReceiverId
        : undefined;
    const billReceiverType = billReceiverId ? ContactType.PERSON : undefined;

    const catalogBa = data.billingAccount!;
    return (
      await createBillingAccount({
        ...catalogBa,
        billingContactId,
        billReceiverId,
        billReceiverType,
      })
    ).billingAccountId;
  }
  return data.catalog.billingAccountId!;
};
