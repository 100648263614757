import * as CL from '@design-system/component-library';
import { type ChangeEvent, type FocusEvent, useCallback } from 'react';
import { type Context } from '../OpenFormContext.js';
import { Input } from '../../Input/Input.js';
import { OpenFormCheckbox } from '../OpenFormComponents/OpenFormCheckbox.js';
import { OpenFormGridCol, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { type SetAnswer, type SetChoice, type SetContext } from '../OpenFormHooks/useDispatcher.js';
import { onBlurPrice, onChangePrice } from '../OpenFormUtils.js';
import classNames from 'classnames';
import type { OpenFormChoice } from '../../../generated/api/openFormChoice.js';
import type { OpenFormListColumn } from '../../../generated/api/openFormListColumn.js';

// If there are both `Name` and `Description` columns, we'll render them in a special way.
const NAME = 'name';
const DESCRIPTION = 'description__c';

export const OpenFormQuestionListOfObjects = ({
  choices,
  context,
  disabled,
  required,
  multiselect,
  headers,
  label,
  set,
  setAnswer,
  setChoice,
  setContext,
}: {
  choices: OpenFormChoice[];
  context: Context | undefined;
  disabled: boolean;
  required: boolean;
  multiselect: boolean;
  headers: OpenFormListColumn[];
  label: string;
  set: Set<string>;
  setAnswer: SetAnswer;
  setChoice: SetChoice;
  setContext: SetContext;
}) => {
  const hasNameAndDescription = headers.some(h => h.name === NAME) && headers.some(h => h.name === DESCRIPTION);
  const isNameFieldWithDescription = useCallback(
    (header: OpenFormListColumn) => hasNameAndDescription && header.name === NAME,
    [hasNameAndDescription]
  );

  const rowCell = useCallback(
    ({ guid, values }: OpenFormChoice) => {
      const descriptionIndex = headers.findIndex(h => h.name === DESCRIPTION);
      const visibleHeaders = hasNameAndDescription ? headers.filter(h => h.name !== DESCRIPTION) : headers;
      const visibleValues = hasNameAndDescription ? values.filter((_, i) => i !== descriptionIndex) : values;

      return visibleValues.map((value, i) => {
        if (visibleHeaders[i]?.isReadOnly ?? true) {
          return (
            <div className="ds-margin-vertical--2" key={guid + i}>
              <div>{value}</div>
              {isNameFieldWithDescription(visibleHeaders[i]) ? (
                <div className="ds-font-size--small ds-margin-top--2">{values[descriptionIndex]}</div>
              ) : null}
            </div>
          );
        }

        const { name, prefix, suffix } = visibleHeaders[i];

        const onBlur = (e: FocusEvent<HTMLInputElement>) =>
          setContext('row', {
            ...context?.row,
            [guid]: { ...context?.row?.[guid], [name]: onBlurPrice(e.target.value) },
          });

        const onChange = (e: ChangeEvent<HTMLInputElement>) =>
          setContext('row', {
            ...context?.row,
            [guid]: { ...context?.row?.[guid], [name]: onChangePrice(e.target.value) },
          });

        return (
          <div key={guid + name} className="ds-display--flex ds-align-items--center ds-justify-content--flex-end">
            {!prefix ? null : prefix}
            <Input
              disabled={disabled}
              required={required}
              value={context?.row?.[guid]?.[name] ?? value}
              size={8}
              type="text"
              autoComplete="off"
              inputMode="decimal"
              maxLength={8}
              onBlur={onBlur}
              onChange={onChange}
              onClick={e => set.has(guid) && e.stopPropagation()}
            />
            {!suffix ? null : suffix}
          </div>
        );
      });
    },
    [context?.row, disabled, hasNameAndDescription, headers, required, set, setContext, isNameFieldWithDescription]
  );

  const rowClick = useCallback(
    ({ guid, values }: OpenFormChoice) => {
      switch (true) {
        case disabled: {
          return;
        }
        case set.has(guid): {
          set.delete(guid);
          return setChoice(Array.from(set));
        }
        case !!headers?.length: {
          !multiselect && set.clear();
          set.add(guid);
          return setAnswer(Array.from(set), 'row', {
            ...context?.row,
            [guid]: values.reduce(
              (acc, value, i) => (headers[i]?.name && { [headers[i].name]: value, ...acc }) || acc,
              { ...context?.row?.[guid] }
            ),
          });
        }
      }
    },
    [context?.row, disabled, headers, multiselect, set, setAnswer, setChoice]
  );

  return (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={9} className={classNames({ ['label--mandatory']: required })}>
        <div className="ds-input--labelarea ds-margin-bottom--3">
          <label className="ds-input--labelarea-label">{label}</label>
        </div>
        <CL.Table
          showHeaders={true}
          valignText="middle"
          className="of-openform__view__list-of-objects-table"
          tableType="bordered"
          hover={true}
          columns={[
            { align: 'center', key: 'checkbox', label: null },
            ...headers
              .filter(header => !hasNameAndDescription || header.name !== DESCRIPTION)
              .map((header, index) => ({
                align: (!index ? 'left' : 'right') as 'left' | 'center' | 'right',
                key: String(index),
                label: (header.label ?? null) as string | null,
              })),
          ]}
          rows={choices.map(choice => ({
            active: set.has(choice.guid),
            checkbox: <OpenFormCheckbox value={choice.guid} checked={set.has(choice.guid)} disabled={disabled} />,
            ...Object.fromEntries(Object.entries(rowCell(choice))),
          }))}
          rowClicks={choices.map(choice => () => rowClick(choice))}
        />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
