import type { BillingAccountHeader } from '../../generated/api/billingAccountHeader.js';
import type { ContactHeader } from '../../generated/api/contactHeader.js';
import type { MatchedAddress } from '../../generated/api/matchedAddress.js';

export type Address = MatchedAddress & { postalCode: string };
export type BillingAccount = BillingAccountHeader & { billingAccountId: string };
export type Contact = ContactHeader;
export type Row<
  ChoiceGUID extends string = string,
  HeaderName extends string = string,
  ChoiceValue extends string = string,
> = Record<ChoiceGUID, Record<HeaderName, ChoiceValue>>;
export type SubscriptionTypes = string[];

export type Context = {
  address?: Address;
  billingAccount?: BillingAccount;
  contact?: Contact;
  row?: Row;
  subscriptionTypes?: SubscriptionTypes;
};

export class OpenFormContext extends Map<string, Context> {
  constructor(context?: OpenFormContext | Record<string, Context>) {
    super(!context ? undefined : context instanceof OpenFormContext ? context : Object.entries(context));
  }

  get addresses() {
    return Array.from(this).reduce(
      (acc, [guid, { address }]) => (address && acc.push([guid, address]) && acc) || acc,
      [] as [string, Address][]
    );
  }

  get billingAccounts() {
    return Array.from(this).reduce(
      (acc, [guid, { billingAccount }]) => (billingAccount && acc.push([guid, billingAccount]) && acc) || acc,
      [] as [string, BillingAccount][]
    );
  }

  get contacts() {
    return Array.from(this).reduce(
      (acc, [guid, { contact }]) => (contact && acc.push([guid, contact]) && acc) || acc,
      [] as [string, Contact][]
    );
  }

  get rows() {
    return Array.from(this.values()).reduce((acc: Row, { row }) => ({ ...acc, ...row }), {});
  }

  get subscriptionTypes() {
    return Array.from(this).reduce(
      (acc, [guid, { subscriptionTypes }]) => (subscriptionTypes && acc.push([guid, subscriptionTypes]) && acc) || acc,
      [] as [string, SubscriptionTypes][]
    );
  }

  assign<K extends keyof Context>(id: string, key: K, patch?: Context[K]) {
    return this.set(id, { ...this.get(id), [key]: patch });
  }
}
