export enum AuxiliaryEsimOrderPhaseEnum {
  LOGIN = 'LOGIN',
  MANAGE = 'MANAGE',
  VALIDATE = 'VALIDATE',
  TYPE = 'TYPE',
  DETAILS = 'DETAILS',
  PROGRESS = 'PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum AuxiliaryEsimOrderFailureEnum {
  CREDIT_CHECK_ISSUE = 'CREDIT_CHECK_ISSUE',
  TOO_MANY_MULTISIMS = 'TOO_MANY_MULTISIMS',
  CONFLICT_MULTISIM = 'CONFLICT_MULTISIM',
  CONFLICT_RING = 'CONFLICT_RING',
  CONFLICT_LANKANUMERO = 'CONFLICT_LANKANUMERÖ́', // TODO: leo 5.7.2023 - this might be buggy, note the last `O` on the right hand side, it's not an `O`, there's some extra stuff there that very likely doesn't belong there. Some MM discussion: https://mattermost.elisa.fi/elisa/pl/nxmeeghqfj81bckhsd9fp8pwir; no immediate intention of fixing this.
  ACCOUNT_LEVEL_BARRING = 'ACCOUNT_LEVEL_BARRING',
  REQUIRED_SERVICE_MISSING = 'REQUIRED_SERVICE_MISSING',
  SUB_NOT_ACTIVE = 'SUB_NOT_ACTIVE',
  SALESFORCE_SUB = 'SALESFORCE_SUB',
  NO_DATA = 'NO_DATA',
  CONNECTION_ERROR = 'CONNECTION_ERROR',
  SUCCESS_NO_PAIRING = 'SUCCESS_NO_PAIRING',
  ESIM_OPEN_ORDER = 'ESIM_OPEN_ORDER',
  CONFLICT_MOBIILITURVA = 'CONFLICT_MOBIILITURVA',
}
