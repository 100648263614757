import { CommercialProductSubType } from '../../generated/api/commercialProductSubType.js';
import { SUBSCRIPTION_CHANGE_SERVICE_FEE } from './content/SubscriptionCardContent.js';
import { SubscriptionAddonCode } from '../../common/enums.js';
import { SubscriptionType } from '../../generated/api/subscriptionType.js';
import { changeFeeMsg, formatNumber, t } from '../../common/i18n';
import { deepEqual, isEmptyOrNullOrUndefined, isObjectNotEmpty } from '../../common/utils/objectUtils.js';
import { getAddedAddOn } from '../SubscriptionDetails/addOnDependencyUtils.js';
import { getCommercialProductPrices } from '../../common/utils/commercialProductUtils.js';
import { m2mContent } from './content/CommercialProductContent.js';
import { shouldIncludeAddon } from '../../common/utils/ringUtils.js';
import type { AddOn } from '../../generated/api/addOn.js';
import type { AddOnAssociation } from '../../generated/api/addOnAssociation.js';
import type { AddOnRulesResponse } from '../../generated/api/addOnRulesResponse';
import type { AddOnSelectionType, OrderConfiguration } from '../OrderSubscriptionConfiguration/FormWrapper.js';
import type { AddOnVisibility } from '../../generated/api/addOnVisibility.js';
import type { AddedAddon } from '../../common/types/addon.js';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { CommercialProduct } from '../../generated/api/commercialProduct.js';
import type { Subscription } from '../../generated/api/subscription.js';

export interface ChangeOfferAddons {
  addOnsToAdd: AddedAddon[];
  addOnsToRemove: AddedAddon[];
  addOnsToUpdate: AddedAddon[];
}

type MinMaxRule = {
  defaultQuantity: number;
  max: number;
  min: number;
  used: number;
};

type MinMaxRulesMap = Record<string, MinMaxRule>;

const addonNotInArray = (addOnsArray: ReadonlyArray<AddedAddon>, addOn: Readonly<AddedAddon>) =>
  addOnsArray.every(item => item.addOnCode !== addOn.addOnCode);

const getMinMaxRulesMap = (newProductAssociations: ReadonlyArray<AssociationRecord>) =>
  newProductAssociations.reduce<MinMaxRulesMap>((rulesMap: MinMaxRulesMap, assoc: AssociationRecord) => {
    // Use the group ID as the key for the rules map
    const groupId = assoc.cspmb__group__c;

    // Ensure that the group ID is not already present in the map to avoid overwriting
    if (!rulesMap[groupId]) {
      rulesMap[groupId] = {
        defaultQuantity: assoc.cspmb__default_quantity__c || 0,
        max: assoc.cspmb__max__c || 0,
        min: assoc.cspmb__min__c || 0,
        used: 0,
      };
    }

    return rulesMap;
  }, {});

const processNewProductsAddOns = (
  newProductAssociations: Readonly<AssociationRecord[]>,
  dependencies: Readonly<DependencyRecord[]>,
  selectedProduct: CommercialProduct
): [AddedAddon[], AddedAddon[]] => {
  const addOnsToAdd: AddedAddon[] = [];
  const addOnsToRemove: AddedAddon[] = [];

  newProductAssociations.forEach((assoc: Readonly<AssociationRecord>) => {
    const addOn = getAddedAddOn(assoc);
    const isExcluded = dependencies.some(
      (dep: Readonly<DependencyRecord>) =>
        dep.price_item__c === selectedProduct.commercialProductId &&
        assoc.cspmb__add_on_price_item__r.id === dep.add_on_target__r?.id &&
        dep.type__c === 'Exclusion'
    );

    const targetArray = isExcluded ? addOnsToRemove : addOnsToAdd;
    if (addonNotInArray(targetArray, addOn)) {
      targetArray.push(addOn);
    }
  });

  return [addOnsToAdd, addOnsToRemove];
};

const shouldIncludeToPotentialRemoves = (addon: AddOn, isRingSub: boolean): boolean => {
  const isAddonHidden = addon.display === false;
  const isExcludedForRingSub = isRingSub && addon.addOnCode === SubscriptionAddonCode.PUHELUN_EDELLEENYHDISTAMINEN;
  return isAddonHidden && !isExcludedForRingSub;
};

const getCurrentProductsFixedAddOns = (
  currentProductAssociations: ReadonlyArray<AssociationRecord>,
  currentProductAddOns: ReadonlyArray<AddOn>,
  addOnsToRemove: AddedAddon[],
  isRingSub: boolean
): AddedAddon[] => {
  const currentCommercialProductAddons: ReadonlyArray<AddOn> = currentProductAddOns.filter(addon =>
    shouldIncludeToPotentialRemoves(addon, isRingSub)
  );
  return currentProductAssociations
    .filter(assoc =>
      currentCommercialProductAddons.some(addon => addon.addOnCode === assoc.cspmb__add_on_price_item__r.guid__c)
    )
    .map(assoc => getAddedAddOn(assoc))
    .filter(addOn => addonNotInArray(addOnsToRemove, addOn));
};

const processCurrentSubscriptionsAddOns = (
  currentSubscriptionAssociationsFilteredByAddons: ReadonlyArray<AssociationRecord>,
  associationCodesToUpdate: ReadonlyArray<string>,
  targetMinMaxRules: Record<string, MinMaxRule>
): [toAdd: AddedAddon[], toRemove: AddedAddon[]] => {
  const toAdd: AddedAddon[] = [];
  const toRemove: AddedAddon[] = [];
  currentSubscriptionAssociationsFilteredByAddons.forEach(assoc => {
    if (associationCodesToUpdate.includes(assoc.cspmb__add_on_price_item__r.guid__c)) {
      return;
    }
    const addOn = getAddedAddOn(assoc);
    const targetMinMaxRule = targetMinMaxRules[assoc.cspmb__group__c];

    // Remove because addOn is not associated
    if (!targetMinMaxRule) {
      if (addonNotInArray(toRemove, addOn)) {
        toRemove.push(addOn);
      }
      return;
    }

    const { defaultQuantity, max, min, used: currentlyUsed } = targetMinMaxRule;

    if (currentlyUsed < max && (defaultQuantity > 0 || min > 0)) {
      targetMinMaxRule.used = currentlyUsed + 1;
      // Add because default or because minimum of one
      if (addonNotInArray(toAdd, addOn)) {
        toAdd.push(addOn);
      }
    } else if (currentlyUsed < max) {
      // Add because not maxed
      if (addonNotInArray(toAdd, addOn)) {
        toAdd.push(addOn);
      }
      targetMinMaxRule.used = currentlyUsed + 1;
    } else {
      // If previous conditions are not met, addon should be removed
      if (addonNotInArray(toRemove, addOn)) {
        toRemove.push(addOn);
      }
    }
  });

  return [toAdd, toRemove];
};

const getAddOnsToUpdate = (
  currentSubscriptionAssociationsFilteredByAddons: ReadonlyArray<AssociationRecord>,
  associationCodesToUpdate: ReadonlyArray<string>
) =>
  currentSubscriptionAssociationsFilteredByAddons.reduce((addOnsToUpdate: AddedAddon[], assoc: AssociationRecord) => {
    if (associationCodesToUpdate.includes(assoc.cspmb__add_on_price_item__r.guid__c)) {
      const addOn = getAddedAddOn(assoc);
      if (addonNotInArray(addOnsToUpdate, addOn)) {
        addOnsToUpdate.push(addOn);
      }
    }
    return addOnsToUpdate;
  }, []);

export const handleChangeOffer = (
  subscription: Subscription,
  selectedProduct: CommercialProduct,
  addOnRules: AddOnRulesResponse,
  addOnRulesPbx: AddOnRulesResponse,
  isRingSub: boolean
): ChangeOfferAddons => {
  const currentProductAddOns: ReadonlyArray<AddOn> = subscription?.details?.selectedAddOns || [];

  const associations: ReadonlyArray<AssociationRecord> =
    subscription?.subscriptionType === SubscriptionType.MOBILE_PBX
      ? (addOnRulesPbx.associations?.[0] as AssociationRecord[]) || []
      : (addOnRules.associations?.[0] as AssociationRecord[]) || [];

  const dependencies: ReadonlyArray<DependencyRecord> =
    subscription?.subscriptionType === SubscriptionType.MOBILE_PBX
      ? (addOnRulesPbx.dependencies?.[0] as DependencyRecord[]) || []
      : (addOnRules.dependencies?.[0] as DependencyRecord[]) || [];

  // List all associations for new offer's commercial product
  const newProductAssociations: ReadonlyArray<AssociationRecord> = associations.filter(
    (assocRecord: AssociationRecord) => assocRecord.cspmb__price_item__c === selectedProduct.commercialProductId
  );
  const newProductAddOns: ReadonlyArray<AddOnAssociation> = selectedProduct.addOnAssociations || [];
  // Filter all associations configured for new offer
  const newProductAssociationsFilteredByAddons: ReadonlyArray<AssociationRecord> = newProductAssociations.filter(
    (assoc: Readonly<AssociationRecord>) =>
      newProductAddOns.some(addon => addon.addOnCode === assoc.cspmb__add_on_price_item__r.guid__c)
  );

  // Generate addOn rules table
  const targetMinMaxRules: MinMaxRulesMap = getMinMaxRulesMap(newProductAssociations);

  // Populate addOn rules table with new offer's addOns
  newProductAssociationsFilteredByAddons.forEach((assoc: Readonly<AssociationRecord>) => {
    const targetGroup = assoc.cspmb__group__c;
    const currentlyUsed = targetMinMaxRules[targetGroup].used;
    const max = targetMinMaxRules[targetGroup].max;
    if (currentlyUsed < max) {
      targetMinMaxRules[targetGroup].used = currentlyUsed + 1;
    }
  });

  // List all associations of current subscription's commercial product
  const currentProductAssociations: ReadonlyArray<AssociationRecord> = associations.filter(
    (assocRecord: AssociationRecord) => assocRecord.cspmb__price_item__c === subscription.commercialProductId
  );

  // Filter all addOns that are part of selectable addOns
  const currentSelectedAddons: ReadonlyArray<AddOn> = currentProductAddOns.filter(addon => addon.display);

  // Filter all associations selected for current commercial product
  const currentSubscriptionAssociationsFilteredByAddons: ReadonlyArray<AssociationRecord> =
    currentProductAssociations.filter((assoc: Readonly<AssociationRecord>) =>
      currentSelectedAddons.some(addon => addon.addOnCode === assoc.cspmb__add_on_price_item__r.guid__c)
    );

  const currentAssociationCodes: ReadonlyArray<string> = currentSubscriptionAssociationsFilteredByAddons.map(
    (assocNew: Readonly<AssociationRecord>) => assocNew.cspmb__add_on_price_item__r.guid__c
  );
  const newAssociationCodes: ReadonlyArray<string> = newProductAssociationsFilteredByAddons.map(
    (assocNew: Readonly<AssociationRecord>) => assocNew.cspmb__add_on_price_item__r.guid__c
  );
  const associationCodesToUpdate: ReadonlyArray<string> = currentAssociationCodes.filter((assocCode: string) =>
    newAssociationCodes.includes(assocCode)
  );

  const [addOnsToAdd, addOnsToRemove] = processNewProductsAddOns(
    newProductAssociationsFilteredByAddons,
    dependencies,
    selectedProduct
  );

  // Add current product's fixed addOns to remove list
  addOnsToRemove.push(
    ...getCurrentProductsFixedAddOns(currentProductAssociations, currentProductAddOns, addOnsToRemove, isRingSub)
  );

  // Process current subscription's addons
  const [toAdd, toRemove] = processCurrentSubscriptionsAddOns(
    currentSubscriptionAssociationsFilteredByAddons,
    associationCodesToUpdate,
    targetMinMaxRules
  );
  addOnsToAdd.push(...toAdd);
  addOnsToRemove.push(...toRemove);

  // Handle updated addons
  const addOnsToUpdate: AddedAddon[] = getAddOnsToUpdate(
    currentSubscriptionAssociationsFilteredByAddons,
    associationCodesToUpdate
  );

  // Filter out addOns that are marked for removal or update
  const addOnsToAddFiltered = addOnsToAdd.filter(
    ({ addOnCode: toAddId }) =>
      addOnsToUpdate.every(({ addOnCode: toUpdateId }) => toUpdateId !== toAddId) &&
      addOnsToRemove.every(({ addOnCode: toRemoveId }) => toRemoveId !== toAddId)
  );

  return {
    addOnsToAdd: addOnsToAddFiltered,
    addOnsToRemove,
    addOnsToUpdate,
  };
};

const isLaitenettiAddOn = (addOnCode: string) => m2mContent().some(product => product.addOnCode === addOnCode);

const isAvausmaksu = (addOn: AddedAddon) => addOn.displayName.toLowerCase().includes('avausmaksu');

// Sometimes some form values are returned as duplicates, root cause not identified
export const removeDuplicateAddedAddOns = (addOns: AddedAddon[] = []): AddedAddon[] =>
  Object.values(
    addOns.reduce((acc: Record<string, AddedAddon>, addOn) => {
      acc[addOn.addOnCode] = addOn;
      return acc;
    }, {})
  );

export const filterAddOns = (commercialProductName: string, addOns: AddedAddon[] = []): AddedAddon[] => {
  // Packaged addon is addon, which is always included in a commercial product and has a price, and the same price is also set as
  // commercial product price. Naturally we shouldn't show both to customer, since he/she won't have to pay both.
  // However, our current dataset lacks explicit indicators for packaged addon, requiring us to rely on matching names to identify them.
  // E.g. commercial product "Elisa Yritysliittymä 5G Premium (1000M)" and addon "Elisa Yritysliittymä 5G Premium (1000M) data"
  return addOns.filter(
    addOn =>
      (!addOn.displayName.startsWith(commercialProductName) && !isAvausmaksu(addOn)) ||
      isLaitenettiAddOn(addOn.addOnCode)
  );
};

export const getAddOnFormData = (jsonData: OrderConfiguration): AddOnSelectionType | undefined => {
  if (isObjectNotEmpty(jsonData)) {
    return Object.values(jsonData?.configuration)[0]?.addOns;
  }
  return undefined;
};

export interface GetAddOnPayloadProps {
  codesToAdd: string[];
  codesToUpdate: string[];
  codesToRemove: string[];
}

// Reflect form changes to the payload which will be sent to backend.
export const getAddOnPayload = (
  addOnsToAdd: AddedAddon[],
  addOnsToUpdate: AddedAddon[],
  addOnsToRemove: AddedAddon[],
  isRingSub: boolean,
  formSelections?: AddOnSelectionType
): GetAddOnPayloadProps => {
  const extractCodes = (addOns: AddedAddon[]) => addOns.map(addOn => addOn.addOnCode);

  const initialCodesToAdd = extractCodes(addOnsToAdd);
  const initialCodesToUpdate = extractCodes(addOnsToUpdate);
  const initialCodesToRemove = extractCodes(addOnsToRemove);

  // Calculate the final codes to add, taking into account the removed addons
  const removedCodesSet = new Set(formSelections?.removedAddOns);

  // Filter out codes that are marked as removed or not applicable for Ring subscriptions
  const filterCodes = (codes: string[]) => codes.filter(code => !removedCodesSet.has(code));

  const codesToAdd = filterCodes(initialCodesToAdd);
  const codesToUpdate = filterCodes(initialCodesToUpdate);

  // Calculate the final codes to remove, including both the initial ones and the ones from form selections
  const codesToRemove = [...new Set([...initialCodesToRemove, ...removedCodesSet])];

  // Add new addons from form selections that are not already in the codesToAdd
  formSelections?.addedAddOns?.forEach(addOn => {
    if (!codesToAdd.includes(addOn.addOnCode) && shouldIncludeAddon(addOn.addOnCode, isRingSub)) {
      codesToAdd.push(addOn.addOnCode);
    }
  });

  return {
    codesToAdd,
    codesToUpdate,
    codesToRemove,
  };
};

export const getNonEditableAddOns = (addOnVisibilities: AddOnVisibility[], addOns: AddedAddon[]): AddedAddon[] => {
  const visibilityGuids = new Set(addOnVisibilities.map(({ addOnGuidCode }) => addOnGuidCode));
  return addOns.filter(({ addOnCode }) => !visibilityGuids.has(addOnCode));
};

export const shouldUpdateState = (newFormAddOns?: AddOnSelectionType, formAddOns?: AddOnSelectionType): boolean => {
  // Disregard the empty form values. React Hook Form initially returns empty values, which should be overlooked because
  // we are assigning the initial values for the summary externally, separate from the form logic.
  if (isEmptyOrNullOrUndefined(newFormAddOns)) {
    return false;
  }
  return !deepEqual(newFormAddOns, formAddOns);
};

// There is no Salesforce based addon object for change fee, so create a custom one for summary.
// Not optimal, butter better than to modify summary component to handle one special case.
export const getAddedAddOnForOpeningFee = (): AddedAddon => {
  const name = t.ZRKX(changeFeeMsg);
  return {
    addOnCode: name,
    addOnAssociationCode: name,
    displayName: name,
    oneTimePrice: SUBSCRIPTION_CHANGE_SERVICE_FEE,
  };
};

export const getLaitenettiMonthlyPrice = (commercialProduct: CommercialProduct): number =>
  commercialProduct.associatedAddOns?.find(addOn => isLaitenettiAddOn(addOn.addOnCode))?.price?.effectivePrice
    .monthlyRecurringCharge || 0;

// Returns addons that are transferred from the existing subscription to the new one and are defined as visible in NOE
// in visibility API response (visibility object in Salesforce). There might be also some addons that are included in the
// product by default (selectedAddOns), like Mobiiliturva in Voice Premium.
// These should be displayed as preselected in the change offer order form.
export const getPreselectedAddOns = (
  addOnsToAdd: AddedAddon[],
  addOnsToUpdate: AddedAddon[],
  selectedAddOns: AddedAddon[],
  addOnVisibilities: AddOnVisibility[]
): AddedAddon[] => {
  const uniqueAddOnsMap = new Map<string, AddedAddon>();

  const addUniqueAddOn = (addOn: AddedAddon) => {
    const { addOnCode } = addOn;
    // Laitenetti is a special case, because commercial product doesn't include the price. Price is defined in addon, so
    // we have to include the addon even if it's not defined in the Salesfoce visibility object, so that the price can
    // be shown to the customer in the order summary.
    const isLaitenetti = isLaitenettiAddOn(addOnCode);

    if (!uniqueAddOnsMap.has(addOnCode)) {
      // Check the price from visibility object, because at least in UAT there was a case where association price was missing
      const visibilityPrice = addOnVisibilities.find(vis => vis.addOnGuidCode === addOnCode)?.addOn?.price;

      uniqueAddOnsMap.set(addOnCode, {
        ...addOn,
        monthlyPrice:
          isLaitenetti || visibilityPrice === undefined
            ? addOn.monthlyPrice
            : visibilityPrice?.effectivePrice?.monthlyRecurringCharge,
        oneTimePrice:
          isLaitenetti || visibilityPrice === undefined
            ? addOn.oneTimePrice
            : visibilityPrice?.effectivePrice?.oneTimeCharge,
      });
    }
  };

  [...addOnsToAdd, ...addOnsToUpdate, ...selectedAddOns].forEach(addUniqueAddOn);

  return Array.from(uniqueAddOnsMap.values());
};

export const getChangeOfferAddOns = (addOnsToAdd: AddedAddon[], addOnsToUpdate: AddedAddon[]): AddedAddon[] =>
  Array.from(new Set([...addOnsToAdd, ...addOnsToUpdate]));

enum IconType {
  MOBILE_BROADBAND = 'mbb',
  MOBILE_VOICE = 'sim',
}

export const getIconType = (subType?: CommercialProductSubType): IconType =>
  subType === CommercialProductSubType.MOBILE_BROADBAND ? IconType.MOBILE_BROADBAND : IconType.MOBILE_VOICE;

export const formatPrice = (price = 0) => formatNumber(price / 100);

export const getMonthlyPrice = (commercialProduct: CommercialProduct): number => {
  // Laitenetti is a special case, because commercial product doesn't include the price. Price is defined in addon.
  if (commercialProduct.commercialProductName.startsWith('Laitenetti')) {
    return getLaitenettiMonthlyPrice(commercialProduct);
  }
  const { monthlyRecurringCharge } = getCommercialProductPrices(commercialProduct);
  return monthlyRecurringCharge || 0;
};
