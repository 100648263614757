import * as CL from '@design-system/component-library';
import { type Choices } from '../OpenFormAnswers.js';
import { OpenFormGridCol, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { type SetChoice } from '../OpenFormHooks/useDispatcher.js';
import { getValue } from '../OpenFormUtils.js';
import classNames from 'classnames';
import type { OpenFormChoice } from '../../../generated/api/openFormChoice.js';

export const OpenFormQuestionSelectGroup = ({
  choices,
  disabled,
  required,
  multiselect,
  label,
  name,
  selected,
  setChoice,
}: {
  choices: OpenFormChoice[];
  disabled: boolean;
  required: boolean;
  multiselect: boolean;
  label: string;
  name: string;
  selected: Choices | undefined;
  setChoice: SetChoice;
}) => (
  <OpenFormGridRow>
    <OpenFormGridCol colWidth={6} className={classNames({ ['label--mandatory']: required })}>
      <CL.SelectGroup
        key={name + getValue(selected)}
        label={label}
        multiselect={multiselect}
        onChange={v => setChoice(multiselect && Array.isArray(v) && v.includes('[]') ? undefined : v)}
        onClick={e => !multiselect && !disabled && e.target.defaultValue === getValue(selected) && setChoice(undefined)}
        products={choices.map(choice => ({ disabled, id: choice.guid, name: getValue(choice.values) }))}
        type="column"
        value={multiselect ? selected : getValue(selected)}
      />
    </OpenFormGridCol>
  </OpenFormGridRow>
);
