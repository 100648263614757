import { type Context, OpenFormContext } from './OpenFormContext.js';
import { type FieldValues, type FormState } from 'react-hook-form';
import { OFPageType } from '../../generated/api/oFPageType.js';
import { OFQuestionType } from '../../generated/api/oFQuestionType.js';
import { OpenFormRules } from './OpenFormRules.js';
import { getQuestions, not } from './OpenFormUtils.js';
import type { OpenFormQuestion } from '../../generated/api/openFormQuestion.js';
import type { OpenFormSection } from '../../generated/api/openFormSection.js';

export type Choices = string[];

export class OpenFormAnswers extends Map<string, Choices> {
  private static readonly IS_ACTIVE_PAGE_TYPE = [OFPageType.AVAILABILITY_CHECK, OFPageType.ORDER_ENRICHMENT] as const;
  private static readonly IS_ACTIVE_QUESTION_TYPE = [OFQuestionType.FREE_TEXT] as const;

  readonly context: OpenFormContext;

  constructor(
    answers?: OpenFormAnswers | Record<string, Choices>,
    context?: OpenFormContext | Record<string, Context>
  ) {
    super(!answers ? undefined : answers instanceof OpenFormAnswers ? answers : Object.entries(answers));
    this.context = new OpenFormContext(context);
  }

  readonly getChoices = (sections: OpenFormSection[], ...types: OFPageType[]) =>
    getQuestions(sections, types).flatMap(q => (q.type !== OFQuestionType.FREE_TEXT && this.get(q.guid)) || []);

  readonly getFreeText = (section: OpenFormSection) => {
    const visible = OpenFormRules.isVisible(this);
    return section.questions.filter(question => question.type === OFQuestionType.FREE_TEXT && visible(question));
  };

  readonly getMandatory = (section: OpenFormSection) => {
    const visible = OpenFormRules.isVisible(this);
    return section.questions.filter(question => question.isMandatory && visible(question));
  };

  readonly getRecords = () => Object.fromEntries(Array.from(this).map(([guid, choices]) => [guid, String(choices)]));

  readonly hasAnswer = (question: OpenFormQuestion) => {
    const choices = this.get(question.guid);
    if (!choices?.length) {
      return false;
    }
    switch (question.type) {
      case 'BA_SELECTION':
      case 'DATE_OF_DELIVERY':
      case 'FREE_TEXT':
      case 'INSTALLATION_ADDRESS':
      case 'INSTALLATION_CONTACT':
      case 'TECHNICAL_CONTACT':
      case 'DELIVERY_CONTACT':
      case 'FAULT_INCIDENT_CONTACT':
      case 'ORDERING_CONTACT':
      case 'TIME_OF_DELIVERY': {
        return choices.every(choice => choice.length);
      }
      case 'LIST_OF_OBJECTS_SINGLE_SELECT':
      case 'LIST_OF_OBJECTS_MULTI_SELECT':
      case 'MULTI_SELECT':
      case 'SINGLE_SELECT': {
        return choices.every(choice => question.choices.some(({ guid }) => guid === choice));
      }
    }
  };

  readonly includes = (value: string) => {
    for (const choices of this.values()) {
      if (choices.includes(value)) {
        return true;
      }
    }
    return false;
  };

  readonly isActive = (section: OpenFormSection) => {
    const visible = OpenFormRules.isVisible(this);
    return (
      OpenFormAnswers.IS_ACTIVE_PAGE_TYPE.includes(section.pageType) ||
      section.questions.some(
        question =>
          visible(question) &&
          (OpenFormAnswers.IS_ACTIVE_QUESTION_TYPE.includes(question.type) || question.choices.some(visible))
      )
    );
  };

  readonly isCompleted = (section: OpenFormSection) => this.getMandatory(section).every(this.hasAnswer);

  readonly nextInvalid = (section: OpenFormSection, formState: FormState<FieldValues>) =>
    this.getFreeText(section).find(q => formState.errors[q.guid]);

  readonly nextMissing = (section: OpenFormSection) => this.getMandatory(section).find(not(this.hasAnswer));
}
