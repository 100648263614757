import { type OpenFormAnswers } from '../OpenFormAnswers.js';
import { type OpenFormDispatcher } from '../OpenFormHooks/useDispatcher.js';
import { OpenFormQuestionBillingAccount } from './OpenFormQuestionBillingAccount.js';
import { OpenFormQuestionContact } from './OpenFormQuestionContact.js';
import { OpenFormQuestionDate } from './OpenFormQuestionDate.js';
import { OpenFormQuestionInput } from './OpenFormQuestionInput.js';
import { OpenFormQuestionInstallationAddress } from './OpenFormQuestionInstallationAddress.js';
import { OpenFormQuestionListOfObjects } from './OpenFormQuestionListOfObjects.js';
import { OpenFormQuestionSelectGroup } from './OpenFormQuestionSelectGroup.js';
import { OpenFormRules } from '../OpenFormRules.js';
import type { OpenFormQuestion } from '../../../generated/api/openFormQuestion.js';

export const OpenFormQuestionType = ({
  answers,
  question,
  disabled,
  dispatcher,
}: {
  answers: OpenFormAnswers;
  question: OpenFormQuestion;
  disabled: boolean;
  dispatcher: OpenFormDispatcher;
}) => {
  switch (question.type) {
    case 'LIST_OF_OBJECTS_MULTI_SELECT': {
      return (
        <OpenFormQuestionListOfObjects
          choices={question.choices.filter(OpenFormRules.isVisible(answers))}
          context={answers.context.get(question.guid)}
          disabled={disabled}
          required={question.isMandatory}
          multiselect={true}
          headers={question.listColumns}
          label={question.label}
          set={new Set(answers.get(question.guid) ?? [])}
          setAnswer={dispatcher.setAnswer(question)}
          setChoice={dispatcher.setChoice(question)}
          setContext={dispatcher.setContext(question)}
        />
      );
    }
    case 'LIST_OF_OBJECTS_SINGLE_SELECT': {
      return (
        <OpenFormQuestionListOfObjects
          choices={question.choices.filter(OpenFormRules.isVisible(answers))}
          context={answers.context.get(question.guid)}
          disabled={disabled}
          required={question.isMandatory}
          multiselect={false}
          headers={question.listColumns}
          label={question.label}
          set={new Set(answers.get(question.guid) ?? [])}
          setAnswer={dispatcher.setAnswer(question)}
          setChoice={dispatcher.setChoice(question)}
          setContext={dispatcher.setContext(question)}
        />
      );
    }
    case 'MULTI_SELECT': {
      return (
        <OpenFormQuestionSelectGroup
          choices={question.choices.filter(OpenFormRules.isVisible(answers))}
          disabled={disabled}
          required={question.isMandatory}
          multiselect={true}
          label={question.label}
          name={question.guid}
          selected={answers.get(question.guid)}
          setChoice={dispatcher.setChoice(question)}
        />
      );
    }
    case 'SINGLE_SELECT': {
      return (
        <OpenFormQuestionSelectGroup
          choices={question.choices.filter(OpenFormRules.isVisible(answers))}
          disabled={disabled}
          required={question.isMandatory}
          multiselect={false}
          label={question.label}
          name={question.guid}
          selected={answers.get(question.guid)}
          setChoice={dispatcher.setChoice(question)}
        />
      );
    }
    case 'BA_SELECTION': {
      return (
        <OpenFormQuestionBillingAccount
          disabled={disabled}
          required={question.isMandatory}
          label={question.label}
          selected={answers.get(question.guid)}
          setAnswer={dispatcher.setAnswer(question)}
        />
      );
    }
    case 'DATE_OF_DELIVERY': {
      return (
        <OpenFormQuestionDate
          disabled={disabled}
          required={question.isMandatory}
          label={question.label}
          name={question.guid}
          offset={question.offset}
          selected={answers.get(question.guid)}
        />
      );
    }
    case 'INSTALLATION_ADDRESS': {
      return (
        <OpenFormQuestionInstallationAddress
          context={answers.context.get(question.guid)}
          required={question.isMandatory}
          selected={answers.get(question.guid)}
          setAnswer={dispatcher.setAnswer(question)}
        />
      );
    }
    case 'INSTALLATION_CONTACT':
    case 'DELIVERY_CONTACT':
    case 'FAULT_INCIDENT_CONTACT':
    case 'ORDERING_CONTACT':
    case 'TECHNICAL_CONTACT': {
      return (
        <OpenFormQuestionContact
          disabled={disabled}
          required={question.isMandatory}
          label={question.label}
          selected={answers.get(question.guid)}
          setAnswer={dispatcher.setAnswer(question)}
        />
      );
    }
    case 'TIME_OF_DELIVERY': {
      return (
        <OpenFormQuestionInput
          disabled={disabled}
          required={question.isMandatory}
          label={question.label}
          name={question.guid}
          type="time"
        />
      );
    }
    case 'FREE_TEXT': {
      switch (question.dataType) {
        case 'DATE_FIELD': {
          return (
            <OpenFormQuestionDate
              disabled={disabled}
              required={question.isMandatory}
              label={question.label}
              name={question.guid}
              offset={question.offset}
              selected={answers.get(question.guid)}
            />
          );
        }
        case 'EMAIL': {
          return (
            <OpenFormQuestionInput
              disabled={disabled}
              required={question.isMandatory}
              label={question.label}
              name={question.guid}
              type="email"
            />
          );
        }
        case 'NUMBER_INTEGER': {
          return (
            <OpenFormQuestionInput
              disabled={disabled}
              required={question.isMandatory}
              label={question.label}
              name={question.guid}
              type="int"
            />
          );
        }
        case 'PHONE': {
          return (
            <OpenFormQuestionInput
              disabled={disabled}
              required={question.isMandatory}
              label={question.label}
              name={question.guid}
              type="tel"
            />
          );
        }
        case 'TIME_FIELD': {
          return (
            <OpenFormQuestionInput
              disabled={disabled}
              required={question.isMandatory}
              label={question.label}
              name={question.guid}
              type="time"
            />
          );
        }
        case 'TEXT': {
          return (
            <OpenFormQuestionInput
              disabled={disabled}
              required={question.isMandatory}
              label={question.label}
              name={question.guid}
              type="text"
            />
          );
        }
      }
    }
  }
};
