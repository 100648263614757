import { formatSumToString } from '../../common/utils/priceUtils.js';
import { isFiveGPlusOffer, isFiveGVoiceOffer } from '../../common/utils/subscriptionUtils.js';
import { isString } from '../../common/utils/objectUtils.js';
import type { SubscriptionCardContentProps } from './SubscriptionCard.js';

const isEmptyObject = (obj: object): boolean => Object.keys(obj).length === 0;

const isEmptyArray = (arr: unknown[]): boolean => arr.length === 0;

export const allPropsEmpty = (props: SubscriptionCardContentProps): boolean =>
  Object.keys(props).every(key => {
    const value = props[key as keyof SubscriptionCardContentProps];

    if (value === null || value === undefined) {
      return true;
    } else if (Array.isArray(value)) {
      return isEmptyArray(value);
    } else if (typeof value === 'object') {
      return isEmptyObject(value);
    } else if (typeof value === 'string') {
      return value === '';
    }
    return false;
  });

// Determine whether to show a separator based on content presence.
// Do not show a separator if:
// 1. There are additional services associated with the subscription card content (SME voice)
// 2. All properties of the subscription card content are empty (no need to render SubscriptionCardContent subcomponent).
export const shouldShowSeparator = (props: SubscriptionCardContentProps): boolean =>
  !props.services && !allPropsEmpty(props);

export const isEmptyHeader = (name?: unknown, selectedOffer?: string) => {
  if (isFiveGPlusOffer(selectedOffer) || isString(name)) {
    return false;
  }
  return !name || isFiveGVoiceOffer(selectedOffer);
};

export const getMonthlySaving = (monthlyPrice?: number, originalMonthlyPrice?: number) =>
  monthlyPrice != null ? (originalMonthlyPrice ?? monthlyPrice) - monthlyPrice : 0;

const getOneTimeSaving = (oneTimePrice?: number, originalOneTimePrice?: number) =>
  (originalOneTimePrice ?? (oneTimePrice || 0)) - (oneTimePrice || 0);

export const hasOffer = (
  monthlyPrice?: number,
  oneTimePrice?: number,
  originalMonthlyPrice?: number,
  originalOneTimePrice?: number
) =>
  getMonthlySaving(monthlyPrice, originalMonthlyPrice) > 0 || getOneTimeSaving(oneTimePrice, originalOneTimePrice) > 0;

export const getPriceString = (monthlyPrice?: number, priceString?: string): string => {
  if (priceString) {
    return priceString;
  }
  const monthlyPriceStr = formatSumToString(monthlyPrice, true);
  return monthlyPrice != null ? monthlyPriceStr : monthlyPriceStr.replace(/0/g, '_');
};

export const isNotEmpty = (inputArray?: object[] | string[]) => Boolean(inputArray && inputArray.length > 0);
