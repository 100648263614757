/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Subscription usage detail item type
 */
export type CallCategory = 'CALL' | 'SMS' | 'DATA' | 'OTHERS';

export const CallCategory = {
    CALL: 'CALL' as CallCategory,
    SMS: 'SMS' as CallCategory,
    DATA: 'DATA' as CallCategory,
    OTHERS: 'OTHERS' as CallCategory
};

