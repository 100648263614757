import { SystemError } from '../SystemError/SystemError.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getMobileIdContracts } from '../../selfservice/actions/mobileIdContractActions.js';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { resetErrors } from '../../selfservice/actions/index.js';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type { State } from '../../selfservice/common/store.js';
import type { SubscriptionsState } from '../../common/types/states.js';

interface SubscriptionProps {
  children: JSX.Element;
  subscriptions?: SubscriptionsState;
}

export const SubscriptionLayout = ({ children, subscriptions }: SubscriptionProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useStore<State>();
  const config = store.getState().config;
  const resources = useSelector((state: State) => state.resources, deepEqual);

  useEffect(() => {
    if (isFeatureEnabled(config?.featureFlags?.mobileId) && !subscriptions?.mobileIdContracts) {
      dispatch(getMobileIdContracts());
    }
  }, [config.featureFlags.mobileId, dispatch, subscriptions?.mobileIdContracts]);

  if (subscriptions?.errors) {
    return (
      <SystemError
        errors={subscriptions.errors}
        onButtonClick={() => {
          dispatch(resetErrors());
          navigate(paths.SELF_SERVICE_HOME);
        }}
      />
    );
  }
  if (resources?.errors) {
    return <SystemError errors={resources.errors} homePath={paths.SELF_SERVICE_HOME} />;
  }

  return <div className="of-subscriptions-scene">{children}</div>;
};
