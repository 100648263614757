import { EMPTY, timer } from 'rxjs';
import { ServiceFeeRequest } from '../../generated/api/models.js';
import { SubscriptionCategory } from '../../common/enums.js';
import { TypeKeys, loadSubscriptionsFailed, loadSubscriptionsFulfilled } from '../actions/index.js';
import { actionToActionStateFromMultiple, getAccountMasterIdHeaderFromUrlParams } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap, debounce } from 'rxjs/operators';
import {
  getContractsPrivateMethod,
  getSubscriptionsPrivateMethod,
  postServiceFeePrivateMethod,
} from '../../generated/api/uiApiMethods.js';
import { getFilterValues } from '../../components/SubscriptionLists/subscriptionListUtils.js';
import { getMobileIdContractsFailed, getMobileIdContractsFulfilled } from '../actions/mobileIdContractActions.js';
import { getServiceFeeFailed, getServiceFeeFulfilled } from '../actions/serviceFeeActions.js';
import { getSubscriptionTypes } from '../../public/common/util/category.js';
import { loadSubscriptionsUseSearchService } from '../common/elasticsearchUtils.js';
import { replacePipeWithCommaInQueryParams } from '../../common/utils/filterUtils.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { AjaxResponse } from 'rxjs/ajax';
import type { CategoryKey } from '../../common/utils/categoryUtils.js';
import type { ContractsResponse, SubscriptionsResponse } from '../../generated/api/models.js';
import type { GetMobileIdContractsAction } from '../actions/mobileIdContractActions.js';
import type { GetServiceFeeAction } from '../actions/serviceFeeActions.js';
import type { ItemsQuery, State } from '../common/store.js';
import type { LoadSubscriptionsAction, SelfServiceActionTypes } from '../actions/index.js';
import type { SubscriptionFilterValue } from '../../common/enums.js';

const getSubscriptionsQueryParams = (category: CategoryKey): { [s: string]: string } => {
  const subscriptionTypes = getSubscriptionTypes(category);
  return {
    details: 'true',
    subscriptionType: subscriptionTypes.join(','),
  };
};

export const getSubscriptionsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.LOAD_SUBSCRIPTIONS)), (action: LoadSubscriptionsAction) =>
    actionToActionStateFromMultiple(action, state$, 'subscriptions', action.category)
  ).pipe(
    debounce((actionAndState: ActionAndState) => {
      const loadSubscriptionsAction = actionAndState.action as LoadSubscriptionsAction;
      return loadSubscriptionsUseSearchService(loadSubscriptionsAction) ? timer(500) : EMPTY;
    }),
    concatMap((actionAndState: ActionAndState) => {
      const loadSubscriptionsAction = actionAndState.action as LoadSubscriptionsAction;
      const useSearchService = loadSubscriptionsUseSearchService(loadSubscriptionsAction);

      const stateQuery: ItemsQuery = actionAndState?.state?.query || { offset: 0 };

      // ES supports sorting but salesforce /subscriptions doesn't support sort or order and crashes if they are present
      const query: ItemsQuery = useSearchService ? stateQuery : { ...stateQuery, sort: undefined, order: undefined };
      const successAction = (res: SubscriptionsResponse) => {
        if (loadSubscriptionsAction.displayId && (!res.subscriptions || res.subscriptions.length === 0)) {
          return loadSubscriptionsFailed(
            `subscription ${loadSubscriptionsAction.displayId} could not be found`,
            404,
            undefined,
            { category: loadSubscriptionsAction.category }
          );
        }
        return loadSubscriptionsFulfilled(
          // If displayId is defined in query, total is unreliable atm. -1 means unknown total.
          { ...res, total: loadSubscriptionsAction.displayId ? -1 : res.total },
          query,
          loadSubscriptionsAction.category,
          loadSubscriptionsAction.contactId,
          useSearchService,
          useSearchService && query.offset > 0
        );
      };

      let additionalQueryParams = {};
      if (loadSubscriptionsAction.displayId) {
        additionalQueryParams = { subscriptionDisplayId: loadSubscriptionsAction.displayId };
      }
      if (loadSubscriptionsAction.reporting) {
        additionalQueryParams = {
          ...additionalQueryParams,
          ...{ reporting: loadSubscriptionsAction.reporting },
        };
      }
      if (loadSubscriptionsAction.expiringOrExpired) {
        additionalQueryParams = {
          ...additionalQueryParams,
          ...{ expiringOrExpired: loadSubscriptionsAction.expiringOrExpired },
        };
      }
      if (loadSubscriptionsAction.eppOnly) {
        additionalQueryParams = {
          ...additionalQueryParams,
          ...{ eppOnly: loadSubscriptionsAction.eppOnly },
        };
      }
      if (loadSubscriptionsAction.contactId) {
        additionalQueryParams = {
          ...additionalQueryParams,
          contactId: loadSubscriptionsAction.contactId,
        };
      }
      if (loadSubscriptionsAction.subscriptionToken) {
        additionalQueryParams = {
          ...additionalQueryParams,
          subscriptionToken: loadSubscriptionsAction.subscriptionToken,
        };
      }
      additionalQueryParams = {
        ...additionalQueryParams,
        ...getSubscriptionsQueryParams(loadSubscriptionsAction.category),
      };
      const failureParams = { category: loadSubscriptionsAction.category };

      // If more than 200 subscriptions need to be fetched, then it needs to be split into batches of multiple API calls
      // which is be executed in parallel & then the results combined. This is needed as SFDC subscription API currently
      // cannot handle more than that and fails with heap size limit exceeded error. Setting totalSubscriptions as limit
      // indicates to backend about the number of rows to expect in result. Backend calculates its splitting logic based
      // on this. This is a temporary workaround till some better solution is implemented.
      const totalSubscriptions = state$.value.selfservice?.subscriptions
        ? // TODO Category types don't match from different sources. Should be investigated how this can be fixed
          // @ts-ignore
          state$.value.selfservice?.subscriptions[loadSubscriptionsAction.category]?.total
        : undefined;
      if (!query.limit && !loadSubscriptionsAction.displayId && totalSubscriptions && totalSubscriptions > 500) {
        query.limit = totalSubscriptions;
      }

      if (useSearchService) {
        const { subscriptionSubType, subscriptionType } = getFilterValues(query?.filterText as SubscriptionFilterValue);
        const multiFilter = loadSubscriptionsAction.multiFilter || {};
        const useSearchServiceMethod = getSubscriptionsPrivateMethod({
          ...query,
          ...additionalQueryParams,
          useSearchService: true,
          ...(subscriptionSubType ? { subscriptionSubType } : {}),
          ...(subscriptionType ? { subscriptionType } : {}),
          ...replacePipeWithCommaInQueryParams(multiFilter),
        });
        return callUiApi({
          epicDependencies,
          failureAction: loadSubscriptionsFailed,
          failureParams,
          method: useSearchServiceMethod,
          state$,
          successAction,
        });
      }
      // TODO: ItemsQuery should not have "sort" and "order": they eventually didn't end up anywhere except invoices
      // so they should be a special case there and not special cased out everywhere else.
      const method = getSubscriptionsPrivateMethod({
        offset: query.offset,
        limit: query.limit,
        ...additionalQueryParams,
      });
      return callUiApi({
        epicDependencies,
        failureAction: loadSubscriptionsFailed,
        failureParams,
        method,
        state$,
        successAction,
      });
    })
  );

export const getMobileIdContractsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.GET_MOBILEID_CONTRACTS)), (action: GetMobileIdContractsAction) =>
    actionToActionStateFromMultiple(action, state$, 'subscriptions', SubscriptionCategory.CONTRACT)
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const mobileIdContractsAction = actionAndState.action as GetMobileIdContractsAction;
      const successAction = (res: ContractsResponse) => {
        return getMobileIdContractsFulfilled(res);
      };

      return callUiApi({
        epicDependencies,
        failureAction: getMobileIdContractsFailed,
        method: getContractsPrivateMethod({ contractDisplayId: mobileIdContractsAction?.contractNumber }),
        state$,
        successAction,
      });
    })
  );

export const getServiceFeeEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  action$.pipe(
    ofType(TypeKeys.GET_SERVICE_FEE),
    concatMap((serviceFeeAction: GetServiceFeeAction) => {
      const successAction = (ajaxResponse: AjaxResponse) => {
        return getServiceFeeFulfilled(serviceFeeAction.resourceId, ajaxResponse.response.price);
      };

      const serviceFeePayload: ServiceFeeRequest = {
        serviceType: ServiceFeeRequest.ServiceTypeEnum.SIM,
        resourceId: serviceFeeAction.resourceId,
        changeMobileSimParameters: serviceFeeAction.changeMobileSimParameters,
      };

      return callUiApi({
        epicDependencies,
        failureAction: getServiceFeeFailed,
        method: postServiceFeePrivateMethod(),
        payload: serviceFeePayload,
        state$,
        successAction,
        headers: getAccountMasterIdHeaderFromUrlParams(),
      });
    })
  );

export const subscriptionEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  getSubscriptionsEpic,
  getMobileIdContractsEpic,
  getServiceFeeEpic
);
