import { GridNarrow } from '../Grid/Grid.js';
import { createImageUrl } from '../../common/utils/domUtils.js';
import classNames from 'classnames';
import type { FC, ReactNode } from 'react';
import type { GridProps } from '../Grid/Grid.js';

import './DetailsWrapper.scss';

export interface DetailsWrapperProps {
  children: ReactNode;
  classes?: string[];
  grid?: FC<GridProps>;
  detailsTop?: JSX.Element;
  heading: string;
  headingBottom?: string | JSX.Element;
  headingTop?: string;
  heroIcon?: string;
  heroImage?: string;
  heroPicto?: string;
  heroWhiteBackground?: boolean;
  id: string;
  imagesBaseUrl?: string;
  styledHeading?: JSX.Element;
  styledHeadingTop?: JSX.Element;
}

const DetailsMainHeading = ({ hasIcon, heading }: { hasIcon: boolean; heading: string }) => {
  if (hasIcon) {
    return <h2 className="of-details-wrapper__main-heading">{heading}</h2>;
  }

  return <h1 className="of-details-wrapper__main-heading">{heading}</h1>;
};

export const DetailsWrapper = ({
  classes,
  detailsTop,
  children,
  heading,
  headingBottom,
  headingTop,
  heroIcon,
  heroImage,
  heroPicto,
  heroWhiteBackground,
  id,
  imagesBaseUrl,
  styledHeading,
  styledHeadingTop,
}: DetailsWrapperProps) => {
  const hasHeroImage = Boolean(heroImage && imagesBaseUrl);
  const heroBackgroundClass = heroWhiteBackground ? '' : 'of-details-wrapper__hero--grey';
  const heroTextClass = [heroIcon ? 'of-details-wrapper__hero-right--full-width' : ''];
  return (
    <div id={id} className={classNames('of-details-wrapper', classes)}>
      <div className={classNames('of-details-wrapper__hero', heroBackgroundClass)}>
        {detailsTop && <div className="of-details-top">{detailsTop}</div>}
        <GridNarrow>
          <div className="of-details-wrapper__hero-grid-item">
            {heroPicto && !hasHeroImage && (
              <span className={`of-details-wrapper__hero-picto ea-picto ea-picto--${heroPicto}`} />
            )}
            {heroIcon && (
              <span className={`of-details-wrapper__hero-icon ea-icon ea-icon--large ea-icon--${heroIcon}`} />
            )}
            {heroImage && imagesBaseUrl && (
              <div className="of-details-wrapper__hero-image">
                <img
                  alt=""
                  {...createImageUrl({
                    format: 'png',
                    height: 150,
                    imagesBaseUrl: imagesBaseUrl,
                    originalUrl: heroImage,
                    scalingFactors: [0.5, 1, 1.5],
                    width: 150,
                  })}
                />
              </div>
            )}
            <div className={classNames('of-details-wrapper__hero-right', heroTextClass)}>
              {headingTop && (
                <h3 className="of-details-wrapper__hero-right-top of-details-wrapper__subheading ds-h3--book">
                  {headingTop}
                </h3>
              )}
              {styledHeadingTop}
              {styledHeading ?? <DetailsMainHeading hasIcon={Boolean(heroPicto ?? heroIcon)} heading={heading} />}
              {headingBottom && (
                <span className="of-details-wrapper__hero-right-bottom of-details-wrapper__subheading">
                  {headingBottom}
                </span>
              )}
            </div>
          </div>
        </GridNarrow>
      </div>

      <GridNarrow>
        <div className="of-details-wrapper__content">{children}</div>
      </GridNarrow>
    </div>
  );
};
