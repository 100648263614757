import { DEFAULT_ITEMS_PER_PAGE, ListPagination, ListSearch, Table, TableTopActions } from '../Table/index.js';
import {
  type MultiAccountRowClickFunction,
  useMultiAccountRowClick,
} from '../../common/hooks/useMultiAccountRowClick.js';
import { SubscriptionHiddenFields } from '../SubscriptionLists/SubscriptionHiddenFields.js';
import { SubscriptionLink } from '../SubscriptionLists/SubscriptionLink.js';
import {
  billingAccountMsg,
  companyMsg,
  idNumberMsg,
  orderMsg,
  serviceMsg,
  statusMsg,
  t,
} from '../../common/i18n/index.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { getSubscriptionStatusText } from '../SubscriptionDetails/subscriptionDetailsCommon.js';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useLoaderData } from 'react-router-dom';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import { useStore } from 'react-redux';
import type { DefaultListSearchParams } from '../Table/index.js';
import type { RealAuthenticatedUserState } from '../../common/types/states.js';
import type { State } from '../../selfservice/common/store.js';
import type { SubscriptionSearchResponse, SubscriptionsResponse } from '../../generated/api/models.js';

const getServiceRows = (
  searchResults: SubscriptionSearchResponse[] = [],
  search?: string,
  authenticatedUser?: RealAuthenticatedUserState,
  onRowClick?: MultiAccountRowClickFunction
) => {
  return searchResults.map(({ result: subscriptionHeader, matchedFields }) => ({
    subscriptionName: (
      <SubscriptionLink
        subscription={subscriptionHeader}
        path={paths.PS_SERVICE}
        search={search}
        onRowClick={onRowClick}
      >
        <SubscriptionHiddenFields
          subscriptionHeader={subscriptionHeader}
          search={search}
          matchedFields={matchedFields}
        />
      </SubscriptionLink>
    ),
    subscriptionUserFriendlyId: matchedFields?.includes('subscriptionUserFriendlyId')
      ? highlightKeyword(subscriptionHeader.subscriptionUserFriendlyId || '', search)
      : subscriptionHeader.subscriptionUserFriendlyId || '',
    billingAccountDisplayId: matchedFields?.includes('billingAccountDisplayId')
      ? highlightKeyword(subscriptionHeader.billingAccountDisplayId || '', search)
      : subscriptionHeader.billingAccountDisplayId || '',
    subscriptionStatus: getSubscriptionStatusText(subscriptionHeader.subscriptionStatus),
    company: getCompanyName(authenticatedUser, subscriptionHeader.accountMasterId),
  }));
};

export const SubscriptionsService = () => {
  const searchParams = useSearchParams<DefaultListSearchParams>();
  const { search } = searchParams;
  const { searchResults, total } = useLoaderData() as SubscriptionsResponse;
  const { authenticatedUser } = useAuth();
  const onRowClick = useMultiAccountRowClick();
  const config = useStore<State>().getState().config;

  const serviceColumns = [
    { key: 'subscriptionName', label: t.CDME(serviceMsg), width: '30%' },
    { key: 'subscriptionUserFriendlyId', label: t.YTH3(idNumberMsg), sortable: true, width: '25%' },
    { key: 'billingAccountDisplayId', label: t.IFT9(billingAccountMsg), sortable: true, width: '10%' },
    { key: 'subscriptionStatus', label: t.ASQT(statusMsg), sortable: true, width: '10%' },
    { key: 'company', label: t.KJTS(companyMsg), sortable: false, width: '25%' },
  ];

  return (
    <div className="of-subscription-table">
      <TableTopActions
        links={[
          {
            label: t.AQOL(orderMsg),
            to: `${config.classicSiteUrl}/tilaukset`,
          },
        ]}
      />
      <ListSearch onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)} />
      <Table
        columns={serviceColumns}
        noItemsText={t.XZA8('No services')}
        rows={getServiceRows(searchResults, search, authenticatedUser, onRowClick)}
      />
      <ListPagination totalItems={total || 0} />
    </div>
  );
};
