import { ActionPhase } from '../common/storeUtils.js';
import {
  AuxiliaryEsimOrderFailureEnum,
  AuxiliaryEsimOrderPhaseEnum,
} from '../../components/AuxiliaryEsimOrderScene/auxiliaryEsimOrderEnums.js';
import { CreditCheckDecision, OrderStatus, ValidateAuxiliaryEsimOrderResponse } from '../../generated/api/models.js';
import { TypeKeys } from '../actions/index.js';
import { reduceCrudAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory, AuxiliaryEsimOrderState } from '../common/store.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

const errorReasons = {
  [ValidateAuxiliaryEsimOrderResponse.ReasonCodeEnum.TOO_MANY_MULTISIMS]:
    AuxiliaryEsimOrderFailureEnum.TOO_MANY_MULTISIMS,
  [ValidateAuxiliaryEsimOrderResponse.ReasonCodeEnum.ACCOUNT_LEVEL_BARRING]:
    AuxiliaryEsimOrderFailureEnum.ACCOUNT_LEVEL_BARRING,
  [ValidateAuxiliaryEsimOrderResponse.ReasonCodeEnum.REQUIRED_SERVICE_MISSING]:
    AuxiliaryEsimOrderFailureEnum.REQUIRED_SERVICE_MISSING,
  [ValidateAuxiliaryEsimOrderResponse.ReasonCodeEnum.SUB_NOT_ACTIVE]: AuxiliaryEsimOrderFailureEnum.SUB_NOT_ACTIVE,
  [ValidateAuxiliaryEsimOrderResponse.ReasonCodeEnum.SALESFORCE_SUB]: AuxiliaryEsimOrderFailureEnum.SALESFORCE_SUB,
  [ValidateAuxiliaryEsimOrderResponse.ReasonCodeEnum.NO_DATA]: AuxiliaryEsimOrderFailureEnum.NO_DATA,
  [ValidateAuxiliaryEsimOrderResponse.ReasonCodeEnum.CONNECTION_ERROR]: AuxiliaryEsimOrderFailureEnum.CONNECTION_ERROR,
  [ValidateAuxiliaryEsimOrderResponse.ReasonCodeEnum.SUCCESS_NO_PAIRING]:
    AuxiliaryEsimOrderFailureEnum.SUCCESS_NO_PAIRING,
};
const conflictReasons = {
  [ValidateAuxiliaryEsimOrderResponse.ConflictingServiceNameEnum.RING]: AuxiliaryEsimOrderFailureEnum.CONFLICT_RING,
  [ValidateAuxiliaryEsimOrderResponse.ConflictingServiceNameEnum.LANKANUMERO]:
    AuxiliaryEsimOrderFailureEnum.CONFLICT_LANKANUMERO,
  [ValidateAuxiliaryEsimOrderResponse.ConflictingServiceNameEnum.MULTISIM]:
    AuxiliaryEsimOrderFailureEnum.CONFLICT_MULTISIM,
  [ValidateAuxiliaryEsimOrderResponse.ConflictingServiceNameEnum.MOBIILITURVA]:
    AuxiliaryEsimOrderFailureEnum.CONFLICT_MOBIILITURVA,
};

const getFailureForResponse = (response: ValidateAuxiliaryEsimOrderResponse) => {
  const code = response.reasonCode;
  const serviceName = response.conflictingServiceName;
  if (code && code in errorReasons) {
    return errorReasons[code];
  } else if (
    code === ValidateAuxiliaryEsimOrderResponse.ReasonCodeEnum.CONFLICTING_SERVICE_EXISTS &&
    serviceName &&
    serviceName in conflictReasons
  ) {
    return conflictReasons[serviceName];
  } else {
    return AuxiliaryEsimOrderFailureEnum.CREDIT_CHECK_ISSUE;
  }
};

export function auxiliaryEsimOrderReducer(
  state: (AuxiliaryEsimOrderState & ActionsHistory) | undefined,
  action: SelfServiceActionTypes
): (AuxiliaryEsimOrderState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.AUXILIARY_ESIM_ORDER_SET_PHASE:
      return {
        ...state,
        phase: action.phase,
        subscriptionToken: state?.subscriptionToken ?? action.subscriptionToken,
      };

    case TypeKeys.AUXILIARY_ESIM_ORDER_GET_SUBSCRIPTION_FULFILLED:
      return {
        ...state,
        subscription: action.subscription,
      };

    case TypeKeys.AUXILIARY_ESIM_ORDER_SEND:
      return {
        ...state,
        ...reduceCrudAction(action, state),
      };
    case TypeKeys.AUXILIARY_ESIM_ORDER_SEND_FAILED:
      return {
        ...state,
        phase: AuxiliaryEsimOrderPhaseEnum.FAILED,
        actions: updateActionStatePhase(
          TypeKeys.AUXILIARY_ESIM_ORDER_SEND,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    case TypeKeys.AUXILIARY_ESIM_ORDER_SEND_FULFILLED:
      return {
        ...state,
        onlineRequestId: action.response.orderRequestId,
        actions: updateActionStatePhase(
          TypeKeys.AUXILIARY_ESIM_ORDER_SEND,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
      };

    case TypeKeys.AUXILIARY_ESIM_ORDER_VALIDATE_FAILED:
    case TypeKeys.PROCESS_ELISAID_LOGIN_FAILED:
    case TypeKeys.LOAD_SUBSCRIPTIONS_FAILED:
      // TODO: errorReason for generic error?
      return {
        ...state,
        phase: AuxiliaryEsimOrderPhaseEnum.FAILED,
      };

    case TypeKeys.AUXILIARY_ESIM_ORDER_GET_SUBSCRIPTION_FAILED:
      return {
        ...state,
        errorReason: AuxiliaryEsimOrderFailureEnum.SUCCESS_NO_PAIRING,
        phase: AuxiliaryEsimOrderPhaseEnum.FAILED,
      };

    case TypeKeys.AUXILIARY_ESIM_ORDER_VALIDATE_FULFILLED:
      return {
        ...state,
        errorReason: action.response.canOrder ? undefined : getFailureForResponse(action.response),
        phase: action.response.canOrder ? AuxiliaryEsimOrderPhaseEnum.TYPE : AuxiliaryEsimOrderPhaseEnum.FAILED,
      };

    case TypeKeys.POLL_FOR_MYSELF_CHANGES_FULFILLED:
      if (
        state?.onlineRequestId &&
        action.creditCheckResult &&
        action.creditCheckResult.decision ===
          (CreditCheckDecision.COMPANY_NEGATIVE_CREDIT_DECISION ||
            CreditCheckDecision.COMPANY_CREDIT_LIMIT_EXCEEDED ||
            CreditCheckDecision.INTERNAL_ERROR) &&
        action.creditCheckResult.onlineRequestId === state.onlineRequestId
      ) {
        return {
          ...state,
          errorReason: AuxiliaryEsimOrderFailureEnum.CREDIT_CHECK_ISSUE,
          phase: AuxiliaryEsimOrderPhaseEnum.FAILED,
        };
      } else if (
        state?.onlineRequestId &&
        action.auxiliaryEsimOpenOrderStatus &&
        action.auxiliaryEsimOpenOrderStatus.onlineRequestId === state.onlineRequestId &&
        action.auxiliaryEsimOpenOrderStatus.openOrderExists
      ) {
        return {
          ...state,
          errorReason: AuxiliaryEsimOrderFailureEnum.ESIM_OPEN_ORDER,
          phase: AuxiliaryEsimOrderPhaseEnum.FAILED,
        };
      } else if (
        state?.onlineRequestId &&
        action.orderStatusResult &&
        action.orderStatusResult.status === OrderStatus.FAIL &&
        action.orderStatusResult.onlineRequestId === state.onlineRequestId
      ) {
        // TODO: errorReason for generic error?
        return {
          ...state,
          phase: AuxiliaryEsimOrderPhaseEnum.FAILED,
        };
      } else if (
        state?.onlineRequestId &&
        action.auxiliaryEsimServiceOrderResult &&
        !action.auxiliaryEsimServiceOrderResult.activationStatus &&
        action.auxiliaryEsimServiceOrderResult.onlineRequestId === state.onlineRequestId
      ) {
        return {
          ...state,
          // TODO: do we need something indicating error in order vs. in connections for the order!?
          errorReason: AuxiliaryEsimOrderFailureEnum.CONNECTION_ERROR,
          phase: AuxiliaryEsimOrderPhaseEnum.FAILED,
        };
      } else if (
        state?.onlineRequestId &&
        action.auxiliaryEsimServiceOrderResult &&
        action.auxiliaryEsimServiceOrderResult.activationStatus &&
        action.auxiliaryEsimServiceOrderResult.onlineRequestId === state.onlineRequestId
      ) {
        return {
          ...state,
          phase: AuxiliaryEsimOrderPhaseEnum.SUCCESS,
          orderResult: action.auxiliaryEsimServiceOrderResult,
        };
      } else {
        return state;
      }

    default:
      return state;
  }
}
