import * as CL from '@design-system/component-library';
import { type BillingAccount } from '../OpenFormContext.js';
import { type Choices } from '../OpenFormAnswers.js';
import { OpenFormGridCol, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { type SetAnswer } from '../OpenFormHooks/useDispatcher.js';
import { getValue } from '../OpenFormUtils.js';
import { selectMsg, t } from '../../../common/i18n/index.js';
import { useFetchBillingAccounts } from '../OpenFormHooks/useFetchBillingAccounts.js';
import { useMemo } from 'react';
import classNames from 'classnames';

const toComboboxItem = (ba: BillingAccount) => ({
  html: <span>{`${ba.billingAccountDisplayId} ${ba.payerName} ${ba.customerReference1 || ''}`}</span>,
  id: ba.billingAccountId,
  label: `${ba.billingAccountDisplayId} ${ba.payerName}`,
  value: ba.billingAccountId,
});

export const OpenFormQuestionBillingAccount = ({
  disabled,
  required,
  label,
  selected,
  setAnswer,
}: {
  disabled: boolean;
  required: boolean;
  label: string;
  selected: Choices | undefined;
  setAnswer: SetAnswer;
}) => {
  const accounts = useFetchBillingAccounts();

  return (
    <OpenFormGridRow>
      <OpenFormGridCol
        colWidth={6}
        className={classNames('ds-justify-content--center', { ['label--mandatory']: required })}
      >
        <CL.Combobox
          label={label}
          i18n_combobox_placeholderText={t.QRYV(selectMsg)}
          disabled={disabled}
          items={useMemo(() => accounts?.map(toComboboxItem) ?? [], [accounts])}
          selectedValue={getValue(selected)}
          onValueSelect={el =>
            setAnswer(
              el?.dataset.value,
              'billingAccount',
              accounts?.find(ba => ba.billingAccountId === el?.dataset.value)
            )
          }
        />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
