import { OpenFormOutlet } from './OpenFormOutlet.js';
import { OpenFormProvider } from './OpenFormProvider.js';
import { setFooterVisibility } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const OpenFormLayout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFooterVisibility(false));
    return () => {
      dispatch(setFooterVisibility(true));
    };
  }, [dispatch]);

  return (
    <OpenFormProvider>
      <OpenFormOutlet {...useAuth()} />
    </OpenFormProvider>
  );
};
