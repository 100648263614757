import { SubscriptionCategory, SubscriptionFilterValue } from '../../common/enums.js';
import { SubscriptionStatusType, SubscriptionType } from '../../generated/api/models.js';
import {
  accessoriesMsg,
  allSubscriptionsMsg,
  computersMsg,
  fixedBroadbandMsg,
  networkingHardwareMsg,
  t,
  tabletsMsg,
} from '../../common/i18n/index.js';
import type { CategoryKey } from '../../common/utils/categoryUtils.js';
import type { ItemFilter } from '../../common/types/subscription.js';
import type { Subscription } from '../../generated/api/models.js';

export const SUBSCRIPTIONS_PER_PAGE = 10;

export const isSubscriptionShown = (
  subscriptionStatus: SubscriptionStatusType,
  category?: CategoryKey,
  type?: SubscriptionType
): boolean =>
  category === SubscriptionCategory.DEVICE || type === SubscriptionType.DEVICE
    ? [SubscriptionStatusType.ACTIVE, SubscriptionStatusType.SUSPENDED].includes(subscriptionStatus)
    : [
        SubscriptionStatusType.CREATED,
        SubscriptionStatusType.IN_ACTIVATION,
        SubscriptionStatusType.ACTIVE,
        SubscriptionStatusType.SUSPENDED,
      ].includes(subscriptionStatus);

interface SubscriptionOption {
  displayText: string;
  subscriptionType?: string[];
  subscriptionSubType?: string;
  filterValue?: SubscriptionFilterValue;
}

export const getFilterOptions = (): { [s: string]: SubscriptionOption[] } => ({
  voice: [
    {
      displayText: t.TAXY(allSubscriptionsMsg),
      filterValue: SubscriptionFilterValue.ALL,
    },
    {
      displayText: t.OZCC('Mobile subscriptions'),
      subscriptionType: [SubscriptionType.MOBILE],
      filterValue: SubscriptionFilterValue.MOBILE_SUBSCRIPTIONS,
    },
    {
      displayText: t.N04E('Fixed line subscriptions'),
      subscriptionType: [SubscriptionType.LANDLINE],
      filterValue: SubscriptionFilterValue.FIXED_LINE_SUBSCRIPTIONS,
    },
  ],
  broadband: [
    {
      displayText: t.TAXY(allSubscriptionsMsg),
      filterValue: SubscriptionFilterValue.ALL,
    },
    {
      displayText: t.YW61(fixedBroadbandMsg),
      subscriptionType: [
        SubscriptionType.HOUSING_COMPANY_BROADBAND,
        SubscriptionType.BROADBAND,
        SubscriptionType.CORPORATE_NETWORK,
      ],
      filterValue: SubscriptionFilterValue.FIXED_BROADBAND,
    },
    {
      displayText: t.G0EZ('Mobile broadbands'),
      subscriptionType: [SubscriptionType.MOBILE_BROADBAND],
      filterValue: SubscriptionFilterValue.MOBILE_BROADBANDS,
    },
  ],
  device: [
    {
      displayText: t.QG50('All devices'),
      filterValue: SubscriptionFilterValue.ALL,
    },
    {
      displayText: t.U17V('Mobile phones'),
      subscriptionSubType: SubscriptionFilterValue.PHONE,
      filterValue: SubscriptionFilterValue.PHONE,
    },
    {
      displayText: t.XS42(tabletsMsg),
      subscriptionSubType: SubscriptionFilterValue.TABLET,
      filterValue: SubscriptionFilterValue.TABLET,
    },
    {
      displayText: t.MCW3(computersMsg),
      subscriptionSubType: SubscriptionFilterValue.COMPUTERS,
      filterValue: SubscriptionFilterValue.COMPUTERS,
    },
    {
      displayText: t.J954(accessoriesMsg),
      subscriptionSubType: SubscriptionFilterValue.ACCESSORIES,
      filterValue: SubscriptionFilterValue.ACCESSORIES,
    },
    {
      displayText: t.DMM5(networkingHardwareMsg),
      subscriptionSubType: SubscriptionFilterValue.NETWORK_EQUIPMENT,
      filterValue: SubscriptionFilterValue.NETWORK_EQUIPMENT,
    },
    {
      displayText: t.A4V5('Other devices'),
      subscriptionSubType: SubscriptionFilterValue.OTHERS,
      filterValue: SubscriptionFilterValue.OTHERS,
    },
  ],
});

interface FilterValueProperties {
  subscriptionSubType?: string;
  subscriptionType?: string[];
}
export const getFilterValues = (filterValue?: SubscriptionFilterValue): FilterValueProperties => {
  const filterOption = Object.values(getFilterOptions())
    .flat()
    .find(option => option.filterValue === filterValue);

  return { subscriptionSubType: filterOption?.subscriptionSubType, subscriptionType: filterOption?.subscriptionType };
};

export const getPaginatedItems = <T extends object>(itemsPerPage?: number, offset?: number, subItems?: T[]) => {
  if (itemsPerPage && offset && subItems) {
    return subItems.slice(offset, offset + itemsPerPage);
  }
  return subItems || [];
};

export const getFilterForSelection = (
  category: string,
  selectedSubType?: string
): ItemFilter<Subscription> | undefined => {
  if (!selectedSubType) {
    return undefined;
  }
  const filterConfig = getFilterOptions()[category].find(option => option.filterValue === selectedSubType);
  return filterConfig
    ? {
        displayText: filterConfig.displayText,
        filterValue: filterConfig.filterValue,
        filter: () => true,
      }
    : undefined;
};
