import { TypeKeys } from '../actions/index.js';
import { actionToActionState, getAccountMasterIdHeaderFromUrlParams } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import {
  getCampaignsFailed,
  getCampaignsFulfilled,
  loadOnlineModelFailed,
  loadOnlineModelFulfilled,
  loadOnlineModelWithEffectivePrices,
  loadOnlineModelWithId,
  loadOnlineModelsFailed,
  loadOnlineModelsFulfilled,
} from '../actions/onlineModelsActions.js';
import {
  getCampaignsPublicMethod,
  getOnlineModelPublicMethod,
  getOnlineModelWithEffectivePricesPrivateMethod,
  getOnlineModelsPublicMethod,
} from '../../generated/api/uiApiMethods.js';
import { of } from 'rxjs';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { CampaignsResponse, OnlineModelsResponse } from '../../generated/api/models.js';
import type {
  GetCampaignsAction,
  LoadOnlineModelAction,
  LoadOnlineModelByModelTypeAction,
  LoadOnlineModelWithEffectivePricesAction,
  LoadOnlineModelsAction,
} from '../actions/onlineModelsActions.js';
import type { SelfServiceActionTypes } from '../actions/index.js';
import type { State } from '../common/store.js';

const loadOnlineModelEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.LOAD_ONLINE_MODEL)), (action: LoadOnlineModelAction) =>
    actionToActionState(action, state$, 'onlineModels')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const action = actionAndState.action as LoadOnlineModelAction;

      return callUiApi({
        epicDependencies,
        failureAction: loadOnlineModelFailed,
        method: getOnlineModelPublicMethod(action.displayId!),
        state$,
        successAction: loadOnlineModelFulfilled,
      });
    })
  );

const loadOnlineModelByModelTypeEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.LOAD_ONLINE_MODEL_BY_MODEL_TYPE)),
    (action: LoadOnlineModelByModelTypeAction) => actionToActionState(action, state$, 'onlineModels')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const action = actionAndState.action as LoadOnlineModelByModelTypeAction;
      // TODO: remove the part of if condition after `&&` as this should be used for *Self Service* ModelTypes also.
      //  Currently used only for ring as the campaigns for clp and cld customers should also be calculated
      //  using this api, UI implementation for campaigns needs to be done.
      if (
        state$.value.user?.authenticated?.customerLevelPriceOrDiscount &&
        action.displayId! === '9e7bf5da-c7c2-484c-c95b-5fc60d0f5011'
      ) {
        return of(loadOnlineModelWithEffectivePrices(action.displayId!));
      }
      return of(loadOnlineModelWithId(action.displayId!));
    })
  );

const loadOnlineModelWithEffectivePricesEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.LOAD_ONLINE_MODEL_WITH_EFFECTIVE_PRICES)),
    (action: LoadOnlineModelWithEffectivePricesAction) => actionToActionState(action, state$, 'onlineModels')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const action = actionAndState.action as LoadOnlineModelWithEffectivePricesAction;

      return callUiApi({
        epicDependencies,
        failureAction: loadOnlineModelFailed,
        method: getOnlineModelWithEffectivePricesPrivateMethod(action.displayId!),
        state$,
        successAction: loadOnlineModelFulfilled,
      });
    })
  );

const loadOnlineModelsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  // TODO: handle LOAD_ONLINE_MODELS crud Action per category
  action$.ofType(TypeKeys.LOAD_ONLINE_MODELS).pipe(
    concatMap((action: LoadOnlineModelsAction) => {
      const method = getOnlineModelsPublicMethod({
        category: action.category,
        headersOnly: action.headersOnly,
      });
      return callUiApi({
        epicDependencies,
        failureAction: loadOnlineModelsFailed,
        method,
        state$,
        successAction: response =>
          loadOnlineModelsFulfilled(action.category, action.headersOnly, response as OnlineModelsResponse),
      });
    })
  );

const getCampaignsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  action$.ofType(TypeKeys.GET_CAMPAIGNS).pipe(
    concatMap(({ params }: GetCampaignsAction) => {
      return callUiApi({
        epicDependencies,
        state$,
        method: getCampaignsPublicMethod(params),
        successAction: response => getCampaignsFulfilled(params, response as CampaignsResponse),
        failureAction: getCampaignsFailed,
        headers: getAccountMasterIdHeaderFromUrlParams(),
      });
    })
  );

export const onlineModelsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  loadOnlineModelEpic,
  loadOnlineModelByModelTypeEpic,
  loadOnlineModelsEpic,
  loadOnlineModelWithEffectivePricesEpic,
  getCampaignsEpic
);
