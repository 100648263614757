import { AiChatMessage } from '../../generated/api/aiChatMessage.js';
import { Ellipsis } from '../Ellipsis/Ellipsis.js';
import { Form, Link, useLoaderData, useNavigation, useNavigationType } from 'react-router-dom';
import { Grid } from '../Grid/Grid.js';
import { PublicPagesSearchInputGroup } from '../PublicPagesSearchForm/PublicPagesSearchForm.js';
import { TypingEffect } from './TypingEffect.js';
import { isInBrowser } from '../../common/utils/ssrUtils.js';
import { paths } from '../../common/constants/pathVariables';
import { pushChatMessageEventToDataLayer, pushChatStartEventToDataLayer } from '../../common/analytics.js';
import { removeAiChatSessionId } from '../../selfservice/common/localStorageUtils';
import { t } from '../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useScrollToBottom } from '../../common/hooks/useScrollToBottom.js';
import type { AiChatResponse } from '../../generated/api/aiChatResponse.js';

import './AiChat.scss';

interface AiChatMessagesProps {
  count: number;
  message: AiChatMessage;
  setIsTypingFinished: () => void;
}

const createUserMessage = (content = '') => ({ content, role: AiChatMessage.RoleEnum.user, createdAt: Date.now() });

const Paragraphs = ({ messages }: { messages: string[] }) => {
  return (
    <>
      {messages.map((paragraph, i) => {
        return <p key={i}>{paragraph}</p>;
      })}
    </>
  );
};

const AiChatMessages = ({ count, message, setIsTypingFinished }: AiChatMessagesProps) => {
  const containerRef = useScrollToBottom(count);
  const response = useLoaderData() as AiChatResponse;
  const messages = response.messages;
  const { state } = useNavigation();
  const isLoading = state === 'submitting' || state === 'loading';
  const navigationType = useNavigationType();

  useEffect(() => {
    if (isInBrowser() && navigationType === 'REPLACE') {
      if (response.messages.length === 2) {
        pushChatStartEventToDataLayer(response);
      }
      pushChatMessageEventToDataLayer(response);
    }
  }, [response, navigationType]);

  return (
    <div className="messages" ref={containerRef}>
      {[...messages, ...(isLoading ? [message] : [])]
        .filter(msg => msg.content)
        .map((msg, index) => {
          const paragraphs = msg.content.split('\n').filter(Boolean);
          return (
            <div className={`message ${msg.role}-message`} key={msg.createdAt}>
              {!isLoading &&
              message.content &&
              index === messages.length - 1 &&
              msg.role === AiChatMessage.RoleEnum.assistant ? (
                <TypingEffect messages={paragraphs} setIsTypingFinished={setIsTypingFinished} />
              ) : (
                <Paragraphs messages={paragraphs} />
              )}
            </div>
          );
        })}
      {isLoading && (
        <div className="message loading-message">
          <p>
            <Ellipsis />
          </p>
        </div>
      )}
    </div>
  );
};

export const AiChat = () => {
  const [message, setMessage] = useState(createUserMessage());
  const [query, setQuery] = useState('');
  const [count, setCount] = useState(0);
  const [isTypingFinished, setIsTypingFinished] = useState(true);

  return (
    <Grid>
      <div className="ds-text-align--center ds-margin-vertical--4">
        <h1>{t.W77L('Instructions and tips for companies')}</h1>
        <h3>{t.PFQL('Search for instructions')}</h3>
      </div>
      <div className="ds-display--flex ds-justify-content--center">
        <div className="ai-chat">
          <Form
            method="post"
            onSubmit={() => {
              setQuery('');
              setCount(c => c + 1);
              setMessage(createUserMessage(query));
              setIsTypingFinished(false);
            }}
          >
            <PublicPagesSearchInputGroup
              handleClear={() => setQuery('')}
              onChange={e => setQuery(e.target.value)}
              isSubmitDisabled={!isTypingFinished}
              value={query}
            />
            <div className="ds-margin-vertical--2">
              <Link
                to={paths.INSTRUCTIONS}
                onClick={() => {
                  removeAiChatSessionId();
                }}
              >
                {t.RW1L('Start a new topic')}
              </Link>
            </div>
          </Form>
          <AiChatMessages count={count} message={message} setIsTypingFinished={() => setIsTypingFinished(true)} />
        </div>
      </div>
    </Grid>
  );
};
