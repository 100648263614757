import {
  FiveGMobileBroadbandOfferCode,
  FiveGVoiceOfferCode,
  MobileM2MAddonCode,
  SubscriptionAddonCode,
  SubscriptionCardType,
} from '../../../common/enums.js';
import { MobiiliturvaPopoverContent } from './MobiiliturvaPopoverContent.js';
import { MobileVoicemailPopoverContent } from './MobileVoicemailPopoverContent.js';
import { PuhePopoverContent } from './PuhePopoverContent.js';
import { ReissaajaPopoverContent } from './ReissaajaPopoverContent.js';
import { ReissunettiOnePopoverContent } from './ReissunettiOnePopoverContent.js';
import { ReissunettiTwoPopoverContent } from './ReissunettiTwoPopoverContent.js';
import {
  downloadSpeed,
  euDataMsg,
  finlandNordicsAndBalticsMsg,
  formatNumber,
  gBMoMsg,
  kBitsPerSecondMsg,
  mBitsPerSecondMsg,
  mBpsMsg,
  numberGSubscriptionsMsg,
  pcsMsg,
  recommendationUsersDevicesMsg,
  selectMsg,
  t,
  textMessagesMsg,
  unlimitedMsg,
  uploadSpeedMsg,
  voiceMsg,
} from '../../../common/i18n/index.js';
import type {
  MobileBroadbandSubCardProps,
  SubCardProps,
  SubscriptionCardProps,
} from '../../SubscriptionCard/SubscriptionCard.js';

export const SUBSCRIPTION_CHANGE_SERVICE_FEE = 399;

const getMobileBroadbandSubscriptionCards = (
  inPublicStore?: boolean
): Omit<MobileBroadbandSubCardProps, 'name' | 'offers'>[] => [
  {
    bullets: [
      t.SDTT('Superfast business use'),
      t.SDTU('Video conferences and 8K movies'),
      t.SDTV('All internet users and devices'),
    ],
    description: t.SDTW('For super-fast business use'),
    cardName: '1000m',
    mainHeader: '1000M',
    salesProduct: {
      offerCode: '59b72848-c05c-5ea0-30bf-b704e0610f61',
      onlineModelCode: 'd52855be-83db-6f80-8c50-32f2f788d1f3',
    },
    selectedOffer: FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_1000_M,
    specifications: [
      {
        name: euDataMsg,
        value: { [FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_1000_M]: `70 ${t.U54O(gBMoMsg)}` },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_1000_M]: t.IJNS(mBitsPerSecondMsg, '100') },
      },
    ],
    services: [
      {
        name: 'Elisa Mobiiliturva Yrityksille',
        guid: SubscriptionAddonCode.MOBIILITURVA,
        isIncluded: true,
        icon: 'mobiiliturva',
        popoverContent: <MobiiliturvaPopoverContent inPublicStore={inPublicStore} />,
      },
      {
        name: (
          <>
            <span>Reissunetti 2 {t.C4YQ('GB')} * | </span>
            <span className="small">{t.T56E('Charged separately')}</span>
          </>
        ),
        guid: SubscriptionAddonCode.REISSUNETTI2,
        isIncluded: true,
        icon: 'roaming',
        popoverContent: <ReissunettiTwoPopoverContent />,
      },
    ],
    monthlyPrice: 4499,
    oneTimePrice: 899,
  },
  {
    bullets: [
      t.SDTP('Fast business use'),
      t.SDTQ('Video conferences and 4K movies'),
      t.SDTL(recommendationUsersDevicesMsg, '4+', '15+'),
    ],
    description: t.SDTS('For fast business use'),
    cardName: '600m',
    mainHeader: '600M',
    salesProduct: {
      offerCode: '62418d5e-2279-7565-2a8d-47dd0a3fbfb0',
      onlineModelCode: 'd52855be-83db-6f80-8c50-32f2f788d1f3',
    },
    selectedOffer: FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_600_M,
    specifications: [
      {
        name: euDataMsg,
        value: { [FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_600_M]: `58 ${t.U54O(gBMoMsg)}` },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_600_M]: t.IJNS(mBitsPerSecondMsg, '75') },
      },
    ],
    services: [
      {
        name: 'Elisa Mobiiliturva Yrityksille',
        guid: SubscriptionAddonCode.MOBIILITURVA,
        isIncluded: true,
        icon: 'mobiiliturva',
        popoverContent: <MobiiliturvaPopoverContent inPublicStore={inPublicStore} />,
      },
      {
        name: (
          <>
            <span>Reissunetti 1-1,5 {t.C4YQ('GB')} * | </span>
            <span className="small">{t.T56E('Charged separately')}</span>
          </>
        ),
        guid: SubscriptionAddonCode.REISSUNETTI1,
        isIncluded: true,
        icon: 'roaming',
        popoverContent: <ReissunettiOnePopoverContent />,
      },
    ],
    monthlyPrice: 3999,
    oneTimePrice: 899,
  },
  {
    bullets: [
      t.SDTN('Active work'),
      t.SDTO('Video meetings and watching shows'),
      t.SDTL(recommendationUsersDevicesMsg, '3-4', '10-15'),
    ],
    description: t.SDTR('For active internet use'),
    cardName: '300m',
    mainHeader: '300M',
    salesProduct: {
      offerCode: '59d62ea1-7064-53e9-0833-912c25025146',
      onlineModelCode: '034e3a4b-e4e5-ea02-8094-5e139f6a6daf',
    },
    selectedOffer: FiveGMobileBroadbandOfferCode.MBB_300_M,
    specifications: [
      {
        name: euDataMsg,
        value: { [FiveGMobileBroadbandOfferCode.MBB_300_M]: `${formatNumber(45.5)} ${t.U54O(gBMoMsg)}` },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [FiveGMobileBroadbandOfferCode.MBB_300_M]: t.IJNS(mBitsPerSecondMsg, '50') },
      },
    ],
    services: [
      {
        name: 'Elisa Mobiiliturva Yrityksille',
        guid: SubscriptionAddonCode.MOBIILITURVA,
        isIncluded: false,
        icon: 'mobiiliturva',
        popoverContent: <MobiiliturvaPopoverContent inPublicStore={inPublicStore} />,
      },
    ],
    recommended: true,
    monthlyPrice: 3195,
    oneTimePrice: 899,
  },
  {
    cardName: '100m',
    mainHeader: '100M',
    salesProduct: {
      offerCode: '7db7442e-57e2-80bc-ef51-88d504cbbedd',
      onlineModelCode: 'f40594f9-9c25-1a34-1ddb-a1e1f7bf60eb',
    },
    selectedOffer: FiveGMobileBroadbandOfferCode.MBB_100_M,
    specifications: [
      {
        name: euDataMsg,
        value: { [FiveGMobileBroadbandOfferCode.MBB_100_M]: `35 ${t.U54O(gBMoMsg)}` },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [FiveGMobileBroadbandOfferCode.MBB_100_M]: t.IJNS(mBitsPerSecondMsg, '50') },
      },
    ],
    bullets: [
      t.SDTJ('Smooth everyday life and work'),
      t.SDTK('Meetings and movies'),
      t.SDTL(recommendationUsersDevicesMsg, '1-2', '1-4'),
    ],
    services: [
      {
        name: 'Elisa Mobiiliturva Yrityksille',
        guid: SubscriptionAddonCode.MOBIILITURVA,
        isIncluded: false,
        icon: 'mobiiliturva',
        popoverContent: <MobiiliturvaPopoverContent inPublicStore={inPublicStore} />,
      },
    ],
    description: t.SDTM('Smooth everyday use'),
    monthlyPrice: 2695,
    oneTimePrice: 899,
  },
];

export const getMobileBroadbandSubscriptionCardContents = (
  inPublicStore?: boolean,
  isChangeOffer?: boolean
): MobileBroadbandSubCardProps[] => {
  const contents = getMobileBroadbandSubscriptionCards().map(cardContent => ({
    ...cardContent,
    buttonText: t.QRYV(selectMsg),
    // Public side doesn't do updates on subs
    serviceFee: isChangeOffer ? SUBSCRIPTION_CHANGE_SERVICE_FEE : undefined,
    cardType: SubscriptionCardType.MOBILE_BROADBAND,
  }));
  // Public side has the products in reverse order compared to NOE.
  if (inPublicStore) {
    contents.reverse();
  }
  return contents;
};

export const MOBILE_M2M_OFFER_CODE = '9be7de49-3191-b932-127e-811c67ae245f';
const MOBILE_M2M_OFFER_CODE_5G = 'b1ff488a-9672-22c0-f9db-bcd7ec64a4ca';

const mobileM2MSubscriptionCards4G = (): Omit<SubCardProps, 'name' | 'offers'>[] => [
  {
    cardName: 'S',
    mainHeader: 'Laitenetti S',
    groupHeader: t.J1JB(numberGSubscriptionsMsg, '4'),
    description: t.A7CC('Sending photos, SMS/voice-controlled devices, property alarms, air source heat pumps'),
    showContentSeparators: false,
    specifications: [
      {
        name: t.R4O4(downloadSpeed),
        value: { [MOBILE_M2M_OFFER_CODE]: t.B69Q(kBitsPerSecondMsg, '128') },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [MOBILE_M2M_OFFER_CODE]: t.B69Q(kBitsPerSecondMsg, '128') },
      },
      {
        name: euDataMsg,
        value: { [MOBILE_M2M_OFFER_CODE]: `5,5 ${t.U54O(gBMoMsg)}` },
      },
    ],
    services: [
      {
        name: 'Internet',
        guid: MobileM2MAddonCode.S,
        isIncluded: true,
        hidden: true,
      },
    ],
    selectedOffer: MOBILE_M2M_OFFER_CODE,
    selectedAddon: MobileM2MAddonCode.S,
  },
  {
    cardName: 'M',
    mainHeader: 'Laitenetti M',
    groupHeader: t.J1JB(numberGSubscriptionsMsg, '4'),
    showContentSeparators: false,
    description: t.A7CD(
      'For surveillance and trail cameras (photos and videos), for alarm devices, for GPS trackers, for condition monitoring sensors'
    ),
    specifications: [
      {
        name: t.R4O4(downloadSpeed),
        value: { [MOBILE_M2M_OFFER_CODE]: t.IJNS(mBitsPerSecondMsg, '2') },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [MOBILE_M2M_OFFER_CODE]: t.IJNS(kBitsPerSecondMsg, '256') },
      },
      {
        name: euDataMsg,
        value: { [MOBILE_M2M_OFFER_CODE]: `7,8 ${t.U54O(gBMoMsg)}` },
      },
    ],
    services: [
      {
        name: 'Internet',
        guid: MobileM2MAddonCode.M,
        isIncluded: true,
        hidden: true,
      },
    ],
    selectedOffer: MOBILE_M2M_OFFER_CODE,
    selectedAddon: MobileM2MAddonCode.M,
  },
  {
    cardName: 'L',
    mainHeader: 'Laitenetti L',
    groupHeader: t.J1JB(numberGSubscriptionsMsg, '4'),
    showContentSeparators: false,
    description: t.A7CE(
      'For surveillance and trail cameras (live video), for mobile routers, for network devices, for alarm devices'
    ),
    specifications: [
      {
        name: t.R4O4(downloadSpeed),
        value: { [MOBILE_M2M_OFFER_CODE]: t.IJNS(mBitsPerSecondMsg, '5') },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [MOBILE_M2M_OFFER_CODE]: t.IJNS(kBitsPerSecondMsg, '500') },
      },
      {
        name: euDataMsg,
        value: { [MOBILE_M2M_OFFER_CODE]: `11 ${t.U54O(gBMoMsg)}` },
      },
    ],
    services: [
      {
        name: 'Internet',
        guid: MobileM2MAddonCode.L,
        isIncluded: true,
        hidden: true,
      },
    ],
    selectedOffer: MOBILE_M2M_OFFER_CODE,
    selectedAddon: MobileM2MAddonCode.L,
  },
];

const mobileM2MSubscriptionCards5G = (): Omit<SubCardProps, 'name' | 'offers'>[] => [
  {
    cardName: '5G',
    mainHeader: 'Laitenetti 5G',
    groupHeader: t.J1JB(numberGSubscriptionsMsg, '5'),
    description: t.JQD9(
      'For uploading high quality live video. Allows you to benefit from new technological innovations and devices, as well as IOT applications and software.'
    ),
    showContentSeparators: false,
    specifications: [
      {
        name: t.R4O4(downloadSpeed),
        value: { [MOBILE_M2M_OFFER_CODE_5G]: t.IJNS(mBitsPerSecondMsg, '100') },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [MOBILE_M2M_OFFER_CODE_5G]: t.IJNS(mBitsPerSecondMsg, '50') },
      },
      {
        name: euDataMsg,
        value: { [MOBILE_M2M_OFFER_CODE_5G]: `28 ${t.U54O(gBMoMsg)}` },
      },
    ],
    services: [
      {
        name: 'Internet',
        guid: MobileM2MAddonCode.fiveG,
        isIncluded: true,
        hidden: true,
      },
    ],
    selectedOffer: MOBILE_M2M_OFFER_CODE_5G,
    selectedAddon: MobileM2MAddonCode.fiveG,
  },
];

const voiceSubscriptionCards = (inPublicStore?: boolean): Omit<SubscriptionCardProps, 'name'>[] => [
  {
    description: t.CVSQ('Efficient remote work, sending and receiving large files, streaming.'),
    cardName: 'premium',
    mainHeader: 'Premium 1000 M',
    monthlyPrice: 4895,
    oneTimePrice: 899,
    recommended: false,
    selectedOffer: FiveGVoiceOfferCode.Voice5gPlus1000Mbps,
    offers: [
      {
        guid: FiveGVoiceOfferCode.Voice5gPlus1000Mbps,
        icon: 'speed-meter-XL',
        salesProduct: {
          oldNumberOfferCode: 'e7da2162-1308-7491-e612-c4a4a34495db',
          onlineModelCode: '49c6bdfb-8e6d-3794-2228-009b1a08b987',
          newNumberOfferCode: 'ae2c6088-4d6b-5f18-30b4-f92c5148795c',
        },
        text: t.IJNS(mBpsMsg, '1000'),
      },
    ],
    specifications: [
      {
        name: t.M4JX(finlandNordicsAndBalticsMsg),
        value: { [FiveGVoiceOfferCode.Voice5gPlus1000Mbps]: t.MVTD(unlimitedMsg) },
      },
      {
        name: euDataMsg,
        value: { [FiveGVoiceOfferCode.Voice5gPlus1000Mbps]: `70 ${t.U54O(gBMoMsg)}` },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [FiveGVoiceOfferCode.Voice5gPlus1000Mbps]: t.IJNS(mBpsMsg, '100') },
      },
      {
        name: t.PV8D(voiceMsg),
        value: { [FiveGVoiceOfferCode.Voice5gPlus1000Mbps]: '5000 min' },
      },
      {
        name: t.MXR5(textMessagesMsg),
        value: { [FiveGVoiceOfferCode.Voice5gPlus1000Mbps]: `500 ${t.B3MG(pcsMsg)}` },
      },
    ],
    services: [
      {
        name: 'Elisa Matkapuhelinvastaaja',
        guid: SubscriptionAddonCode.MOBILE_VOICE,
        isIncluded: true,
        icon: 'voicemail',
        popoverContent: <MobileVoicemailPopoverContent inPublicStore={inPublicStore} />,
      },
      {
        name: 'Elisa Mobiiliturva Yrityksille',
        guid: SubscriptionAddonCode.MOBIILITURVA,
        isIncluded: true,
        icon: 'mobiiliturva',
        popoverContent: <MobiiliturvaPopoverContent inPublicStore={inPublicStore} />,
      },
      {
        name: (
          <>
            <span>Reissunetti 2 {t.C4YQ('GB')} * | </span>
            <span className="small">{t.T56E('Charged separately')}</span>
          </>
        ),
        guid: SubscriptionAddonCode.REISSUNETTI2,
        isIncluded: true,
        icon: 'roaming',
        popoverContent: <ReissunettiTwoPopoverContent />,
      },
    ],
  },
  {
    description: t.SE2C('Active surfing, video meetings, listening to podcasts'),
    cardName: 'perus600',
    mainHeader: 'Perus 600 M',
    monthlyPrice: 4095,
    oneTimePrice: 899,
    recommended: false,
    selectedOffer: FiveGVoiceOfferCode.Voice5gPlus600Mbps,
    offers: [
      {
        guid: FiveGVoiceOfferCode.Voice5gPlus600Mbps,
        icon: 'speed-meter-L',
        salesProduct: {
          oldNumberOfferCode: 'eb6ef5b3-4128-b02b-611c-961513b04e46',
          onlineModelCode: '413e6376-a3de-7c24-6037-d1462365d92d',
          newNumberOfferCode: '8d121d67-4d4f-11db-abf9-68bf252dfd07',
        },
        text: t.IJNS(mBpsMsg, '600'),
      },
    ],
    specifications: [
      {
        name: t.M4JX(finlandNordicsAndBalticsMsg),
        value: {
          [FiveGVoiceOfferCode.Voice5gPlus600Mbps]: t.MVTD(unlimitedMsg),
        },
      },
      {
        name: euDataMsg,
        value: {
          [FiveGVoiceOfferCode.Voice5gPlus600Mbps]: `58 ${t.U54O(gBMoMsg)}`,
        },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: {
          [FiveGVoiceOfferCode.Voice5gPlus600Mbps]: t.IJNS(mBpsMsg, '75'),
        },
      },
      {
        name: t.PV8D(voiceMsg),
        value: {
          [FiveGVoiceOfferCode.Voice5gPlus600Mbps]: '5000 min',
        },
      },
      {
        name: t.MXR5(textMessagesMsg),
        value: {
          [FiveGVoiceOfferCode.Voice5gPlus600Mbps]: `200 ${t.B3MG(pcsMsg)}`,
        },
      },
    ],
    services: [
      {
        name: 'Elisa Matkapuhelinvastaaja',
        guid: SubscriptionAddonCode.MOBILE_VOICE,
        isIncluded: true,
        icon: 'voicemail',
        popoverContent: <MobileVoicemailPopoverContent inPublicStore={inPublicStore} />,
      },
      {
        name: (
          <>
            <span>Reissunetti 1-1,5 {t.C4YQ('GB')} * | </span>
            <span className="small">{t.T56E('Charged separately')}</span>
          </>
        ),
        guid: SubscriptionAddonCode.REISSUNETTI1,
        isIncluded: true,
        icon: 'roaming',
        popoverContent: <ReissunettiOnePopoverContent />,
      },
      {
        name: 'Elisa Mobiiliturva Yrityksille',
        guid: SubscriptionAddonCode.MOBIILITURVA,
        isIncluded: false,
        icon: 'mobiiliturva',
        popoverContent: <MobiiliturvaPopoverContent inPublicStore={inPublicStore} />,
      },
    ],
  },
  {
    description: t.SE2C('Active surfing, video meetings, listening to podcasts'),
    cardName: 'perus300',
    mainHeader: 'Perus 300 M',
    monthlyPrice: 4095,
    oneTimePrice: 899,
    recommended: true,
    selectedOffer: FiveGVoiceOfferCode.Voice300Mbps,
    offers: [
      {
        guid: FiveGVoiceOfferCode.Voice300Mbps,
        icon: 'speed-meter-M',
        salesProduct: {
          oldNumberOfferCode: 'd230c4a9-c217-c89e-0b6c-a955efb427ee',
          onlineModelCode: 'a434546c-173d-a2a0-f71c-3a87d2751e84',
          newNumberOfferCode: '3569f6c1-315b-af8a-3ed3-a1597f442b8c',
        },
        text: t.IJNS(mBpsMsg, '300'),
      },
    ],
    specifications: [
      {
        name: t.M4JX(finlandNordicsAndBalticsMsg),
        value: {
          [FiveGVoiceOfferCode.Voice300Mbps]: t.MVTD(unlimitedMsg),
        },
      },
      {
        name: euDataMsg,
        value: {
          [FiveGVoiceOfferCode.Voice300Mbps]: `55,5 ${t.U54O(gBMoMsg)}`,
        },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: {
          [FiveGVoiceOfferCode.Voice300Mbps]: t.IJNS(mBpsMsg, '50'),
        },
      },
      {
        name: t.PV8D(voiceMsg),
        value: {
          [FiveGVoiceOfferCode.Voice300Mbps]: '5000 min',
        },
      },
      {
        name: t.MXR5(textMessagesMsg),
        value: {
          [FiveGVoiceOfferCode.Voice300Mbps]: `200 ${t.B3MG(pcsMsg)}`,
        },
      },
    ],
    services: [
      {
        name: 'Elisa Matkapuhelinvastaaja',
        guid: SubscriptionAddonCode.MOBILE_VOICE,
        isIncluded: true,
        icon: 'voicemail',
        popoverContent: <MobileVoicemailPopoverContent inPublicStore={inPublicStore} />,
      },
      {
        name: (
          <>
            <span>Reissunetti 1-1,5 {t.C4YQ('GB')} * | </span>
            <span className="small">{t.T56E('Charged separately')}</span>
          </>
        ),
        guid: SubscriptionAddonCode.REISSUNETTI1,
        isIncluded: true,
        icon: 'roaming',
        popoverContent: <ReissunettiOnePopoverContent />,
      },
      {
        name: 'Elisa Mobiiliturva Yrityksille',
        guid: SubscriptionAddonCode.MOBIILITURVA,
        isIncluded: false,
        icon: 'mobiiliturva',
        popoverContent: <MobiiliturvaPopoverContent inPublicStore={inPublicStore} />,
      },
    ],
  },
  {
    description: t.KVG8('Light internet use, reading emails, sending messages on social media'),
    cardName: 'mini50',
    mainHeader: 'Mini 50 M',
    monthlyPrice: 2895,
    oneTimePrice: 899,
    pricePreamble: t.OD1L('From'),
    selectedOffer: FiveGVoiceOfferCode.Voice50Mbps,
    offers: [
      {
        guid: FiveGVoiceOfferCode.Voice50Mbps,
        icon: 'speed-meter-S',
        salesProduct: {
          oldNumberOfferCode: '93085047-604c-cc8a-228a-863b23ee3e5e',
          onlineModelCode: '34d8ba65-2c65-3440-996c-909be24d2520',
          newNumberOfferCode: 'f0ea75cf-4bf6-d2f4-d444-575c663d6d22',
        },
        text: t.IJNS(mBpsMsg, '50'),
      },
    ],
    specifications: [
      {
        name: t.M4JX(finlandNordicsAndBalticsMsg),
        value: {
          [FiveGVoiceOfferCode.Voice50Mbps]: t.MVTD(unlimitedMsg),
        },
      },
      {
        name: euDataMsg,
        value: {
          [FiveGVoiceOfferCode.Voice50Mbps]: `38 ${t.U54O(gBMoMsg)}`,
        },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: {
          [FiveGVoiceOfferCode.Voice50Mbps]: t.IJNS(mBpsMsg, '25'),
        },
      },
      {
        name: `${t.PV8D(voiceMsg)}**`,
        value: {
          [FiveGVoiceOfferCode.Voice50Mbps]: '100 min',
        },
        popoverContent: <PuhePopoverContent />,
      },
      {
        name: t.MXR5(textMessagesMsg),
        value: {
          [FiveGVoiceOfferCode.Voice50Mbps]: `100 ${t.B3MG(pcsMsg)}`,
        },
      },
    ],
    services: [
      {
        name: (
          <>
            <span>Reissaaja * | </span>
            <span className="small">{t.T56E('Charged separately')}</span>
          </>
        ),
        guid: SubscriptionAddonCode.REISSAAJA,
        isIncluded: true,
        icon: 'roaming',
        popoverContent: <ReissaajaPopoverContent />,
      },
      {
        name: 'Elisa Matkapuhelinvastaaja',
        guid: SubscriptionAddonCode.MOBILE_VOICE,
        isIncluded: false,
        icon: 'voicemail',
        popoverContent: <MobileVoicemailPopoverContent inPublicStore={inPublicStore} />,
      },
      {
        name: 'Elisa Mobiiliturva Yrityksille',
        guid: SubscriptionAddonCode.MOBIILITURVA,
        isIncluded: false,
        icon: 'mobiiliturva',
        popoverContent: <MobiiliturvaPopoverContent inPublicStore={inPublicStore} />,
      },
    ],
  },
  {
    description: t.KVG8('Light internet use, reading emails, sending messages on social media'),
    cardName: 'mini10',
    mainHeader: 'Mini 10 M',
    monthlyPrice: 2895,
    oneTimePrice: 899,
    pricePreamble: t.OD1L('From'),
    selectedOffer: FiveGVoiceOfferCode.Voice10Mbps,
    offers: [
      {
        guid: FiveGVoiceOfferCode.Voice10Mbps,
        icon: 'speed-meter-XS',
        salesProduct: {
          oldNumberOfferCode: '8831a2e6-d8dc-791c-c915-2002ae27d2b3',
          onlineModelCode: 'd9bd1acc-ecc4-c601-3c61-d81165e49c51',
          newNumberOfferCode: 'dc631fd4-375a-63bf-5cb5-38b52ce9e119',
        },
        text: t.IJNS(mBpsMsg, '10'),
      },
    ],
    specifications: [
      {
        name: t.M4JX(finlandNordicsAndBalticsMsg),
        value: {
          [FiveGVoiceOfferCode.Voice10Mbps]: t.MVTD(unlimitedMsg),
        },
      },
      {
        name: euDataMsg,
        value: {
          [FiveGVoiceOfferCode.Voice10Mbps]: `35 ${t.U54O(gBMoMsg)}`,
        },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: {
          [FiveGVoiceOfferCode.Voice10Mbps]: t.IJNS(mBpsMsg, '5'),
        },
      },
      {
        name: `${t.PV8D(voiceMsg)}**`,
        value: {
          [FiveGVoiceOfferCode.Voice10Mbps]: '100 min',
        },
        popoverContent: <PuhePopoverContent />,
      },
      {
        name: t.MXR5(textMessagesMsg),
        value: {
          [FiveGVoiceOfferCode.Voice10Mbps]: `100 ${t.B3MG(pcsMsg)}`,
        },
      },
    ],
    services: [
      {
        name: (
          <>
            <span>Reissaaja * | </span>
            <span className="small">{t.T56E('Charged separately')}</span>
          </>
        ),
        guid: SubscriptionAddonCode.REISSAAJA,
        isIncluded: true,
        icon: 'roaming',
        popoverContent: <ReissaajaPopoverContent />,
      },
      {
        name: 'Elisa Matkapuhelinvastaaja',
        guid: SubscriptionAddonCode.MOBILE_VOICE,
        isIncluded: false,
        icon: 'voicemail',
        popoverContent: <MobileVoicemailPopoverContent inPublicStore={inPublicStore} />,
      },
      {
        name: 'Elisa Mobiiliturva Yrityksille',
        guid: SubscriptionAddonCode.MOBIILITURVA,
        isIncluded: false,
        icon: 'mobiiliturva',
        popoverContent: <MobiiliturvaPopoverContent inPublicStore={inPublicStore} />,
      },
    ],
  },
];

export const getVoiceSubscriptionCardContents = (
  inPublicStore?: boolean,
  isChangeOffer?: boolean
): SubscriptionCardProps[] =>
  voiceSubscriptionCards(inPublicStore).map(cardContent => ({
    ...cardContent,
    serviceFee: isChangeOffer ? SUBSCRIPTION_CHANGE_SERVICE_FEE : undefined,
    buttonText: t.QRYV(selectMsg),
    cardType: SubscriptionCardType.VOICE,
    description: cardContent.description,
  }));

export const getMobileM2MCardContents4G = (isChangeOffer?: boolean): SubCardProps[] =>
  mobileM2MSubscriptionCards4G().map(cardContent => ({
    ...cardContent,
    name: '',
    serviceFee: isChangeOffer ? SUBSCRIPTION_CHANGE_SERVICE_FEE : undefined,
    buttonText: t.QRYV(selectMsg),
    cardType: SubscriptionCardType.MOBILE_M2M,
  }));

export const getMobileM2MCardContents5G = (isChangeOffer?: boolean): SubCardProps[] =>
  mobileM2MSubscriptionCards5G().map(cardContent => ({
    ...cardContent,
    name: '',
    serviceFee: isChangeOffer ? SUBSCRIPTION_CHANGE_SERVICE_FEE : undefined,
    buttonText: t.QRYV(selectMsg),
    cardType: SubscriptionCardType.MOBILE_M2M,
  }));
