import * as CL from '@design-system/component-library';
import { BillingAccountDeliveryMethod } from '../../generated/api/billingAccountDeliveryMethod.js';
import { CREATE_NEW_CONTACT_OPTION_VALUE } from '../../common/formik/Fields/index.js';
import { Contact } from '../../common/react-hook-form/fields/Contact.js';
import { ContactType } from '../../generated/api/contactType.js';
import { DeliveryMethod } from '../../common/react-hook-form/fields/DeliveryMethod.js';
import { ElectronicAddress } from '../../common/react-hook-form/fields/ElectronicAddress.js';
import { ElectronicOperator } from '../../common/react-hook-form/fields/ElectronicOperator.js';
import { Email, Name, PhoneNumber } from '../../common/react-hook-form/fields/index.js';
import {
  FORM_GRID_FULL_WIDTH,
  FormGridFieldset,
} from '../../common/react-hook-form/FormGridFieldset/FormGridFieldset.js';
import { Language } from '../../common/react-hook-form/fields/Language.js';
import { ListInput } from '../../common/react-hook-form/components/ListInput.js';
import { ReceiverTypes } from '../../common/utils/billingAccountUtils.js';
import { SelectRadio } from '../../common/react-hook-form/components/SelectRadio.js';
import { emailMsg, firstNameMsg, invoiceLanguageMsg, lastNameMsg, recipientMsg, t } from '../../common/i18n/index.js';
import { useFormContext } from 'react-hook-form';
import type { BillChannel } from '../../generated/api/billChannel.js';
import type { Contact as ContactEntityType } from '../../generated/api/contact.js';

export const deliveryMethodDefaultValues = () => {
  return {
    billElectronicAddress: '',
    billElectronicOperator: '',
    billLanguage: 'FI',
    deliveryMethod: BillingAccountDeliveryMethod.ELECTRONIC,
    billReceiverType: ContactType.PERSON,
    billReceiverName: '',
    additionalBillReceiverEmails: [],
  };
};

interface DeliveryMethodFieldSetProps {
  billChannels: BillChannel[];
  contacts: ContactEntityType[];
  isNewDuplicateContactHandling?: boolean;
}

export const DeliveryMethodFieldset = (props: DeliveryMethodFieldSetProps) => {
  const methods = useFormContext();
  const values = methods.watch();

  return (
    <FormGridFieldset>
      <DeliveryMethod name="billingAccount.deliveryMethod" billChannels={props.billChannels} />
      <Language name="billingAccount.billLanguage" label={t.A7DR(invoiceLanguageMsg)} />

      {values.billingAccount.deliveryMethod === BillingAccountDeliveryMethod.ELECTRONIC && (
        <>
          <ElectronicOperator name="billingAccount.billElectronicOperator" billChannels={props.billChannels} />
          <ElectronicAddress name="billingAccount.billElectronicAddress" />
        </>
      )}
      {values.billingAccount.deliveryMethod === BillingAccountDeliveryMethod.EMAIL && (
        <>
          <div>
            <SelectRadio
              name="billReceiverSelection"
              label={t.SY1D(recipientMsg)}
              items={[
                {
                  label: t.ZCX7('Same as contact'),
                  value: ReceiverTypes.SAME_AS_CONTACT,
                },
                {
                  label: t.C6WY('Other'),
                  value: ReceiverTypes.OTHER,
                },
              ]}
            />
          </div>
          <div />
          {values.billReceiverSelection === ReceiverTypes.OTHER && (
            <>
              <Contact name="billingAccount.billReceiverId" contacts={props.contacts} reference="receiver" />
              <div />
              {/* TODO Migrate to use new NewContact.tsx to get better validation for duplicate-contacts
                  TODO this involves removing these fields and putting NewContact next to the form
               */}
              {!props.isNewDuplicateContactHandling &&
                values.billingAccount.billReceiverId === CREATE_NEW_CONTACT_OPTION_VALUE && (
                  <>
                    <Name name="newBillReceiverContact.firstName" label={t.AIK7(firstNameMsg)} placeholder="" />
                    <Name name="newBillReceiverContact.lastName" label={t.Y41S(lastNameMsg)} placeholder="" />
                    <Email name="newBillReceiverContact.email" placeholder="" />
                    <PhoneNumber name="newBillReceiverContact.phoneNumber" placeholder="" />
                  </>
                )}
              <div />
              <div />
            </>
          )}
          <div>
            <h4>{t.UZ3K('Other receiver')}</h4>
          </div>
          <div />
          <ListInput label={t.XVHQ(emailMsg)} name="billingAccount.additionalBillReceiverEmails" />
          {(values.billingAccount.additionalBillReceiverEmails || []).length < 5 && (
            <div className={FORM_GRID_FULL_WIDTH}>
              <CL.Button
                className="ds-margin-bottom--4"
                onClick={() => {
                  methods.setValue('billingAccount.additionalBillReceiverEmails', [
                    ...(values.billingAccount.additionalBillReceiverEmails || []),
                    '',
                  ]);
                }}
                color="light"
              >
                {t.N41Q('+ Add new')}
              </CL.Button>
            </div>
          )}
        </>
      )}
    </FormGridFieldset>
  );
};
