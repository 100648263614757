import { type OpenFormAnswers } from '../OpenFormAnswers.js';
import { OpenFormPageAvailability } from './OpenFormPageAvailability.js';
import { OpenFormPageInformative } from './OpenFormPageInformative.js';
import { OpenFormPageQuestion } from './OpenFormPageQuestion.js';
import { useDispatcher } from '../OpenFormHooks/useDispatcher.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';

export const OpenFormPageType = ({
  answers,
  section,
  disabled,
  next,
}: {
  answers: OpenFormAnswers;
  section: OpenFormSection;
  disabled: boolean;
  next: () => void;
}) => {
  const dispatcher = useDispatcher(section);

  switch (section?.pageType) {
    case 'AVAILABILITY_CHECK': {
      return <OpenFormPageAvailability section={section} next={next} setContext={dispatcher.setContext(section)} />;
    }
    case 'INFORMATIVE': {
      return <OpenFormPageInformative section={section} />;
    }
    case 'ORDER_ENRICHMENT':
    case 'QUESTION_ADDITIONAL_SERVICES':
    case 'QUESTION_MAIN_PRODUCTS':
    case 'QUESTION_SUBSCRIPTION_TYPE': {
      return <OpenFormPageQuestion answers={answers} section={section} disabled={disabled} dispatcher={dispatcher} />;
    }
  }
};
