import * as CL from '@design-system/component-library';
import * as React from 'react';
import type { FieldInputProps, FormikHelpers, FormikState, FormikValues } from 'formik';

export interface RadioGroupComponentProps {
  className?: string;
  field: FieldInputProps<string>;
  form: FormikState<FormikValues> & FormikHelpers<FormikValues>;
  id?: string;
  radioButtons: {
    children?: JSX.Element;
    disabled?: boolean;
    label?: string;
    onChange?: (
      e: React.ChangeEvent<HTMLInputElement>,
      form: FormikState<FormikValues> & FormikHelpers<FormikValues>
    ) => void;
    value: string;
  }[];
  withError?: boolean;
}

const getError = (
  field: RadioGroupComponentProps['field'],
  form: RadioGroupComponentProps['form'],
  withError: RadioGroupComponentProps['withError']
): string | undefined => {
  const { name } = field;
  const { errors, touched } = form;
  if (withError && touched[name] && errors[name]) {
    return errors[name]?.toString();
  }
  return undefined;
};

/**
 * Provided Radio button requires a list of radioButtons
 * Underlying RadioComponent always requires single entity
 * Supports inline error for presenting message after last radio button
 */
export const RadioGroupComponent = (props: RadioGroupComponentProps) => {
  const { className, field, form, id, radioButtons, withError } = props;

  return (
    <CL.RadioGroup
      i18n_radiogroup_errorMessage={getError(field, form, withError)}
      key={field.value}
      value={field.value}
    >
      {radioButtons.map((radioButton, i) => (
        <CL.RadioV2
          key={i}
          name={field.name}
          className={className}
          id={id ? `${id}${i}` : `${field.name}${i}`}
          value={radioButton.value}
          label={radioButton.label}
          disabled={radioButton.disabled}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            field.onChange(e);
            radioButton.onChange?.(e, form);
          }}
        >
          {radioButton.children}
        </CL.RadioV2>
      ))}
    </CL.RadioGroup>
  );
};
