import { OFPageType } from '../../../generated/api/oFPageType.js';
import { OpenForm } from '../OpenForm.js';
import { OpenFormGrid } from '../OpenFormComponents/OpenFormGrid.js';
import { OpenFormViewBreadcrumbs } from './OpenFormViewBreadcrumbs.js';
import { OpenFormViewSection } from './OpenFormViewSection.js';
import { OpenFormViewSummary } from './OpenFormViewSummary.js';
import { useCallback, useMemo } from 'react';
import { useFetchForm } from '../OpenFormHooks/useFetchForm.js';
import { useFetchSummary } from '../OpenFormHooks/useFetchSummary.js';
import { useFormNavigation } from '../OpenFormHooks/useFormNavigation.js';
import { useFormProvider } from '../OpenFormHooks/useFormProvider.js';
import { useOpenFormAsync, useOpenFormState } from '../OpenFormProvider.js';
import { useParams } from 'react-router-dom';
import { useReload } from '../OpenFormHooks/useReload.js';
import type { FormId, Index } from '../../../common/constants/pathInterfaces.js';

export const OpenFormViewLayout = ({ preview = false }) => {
  const { answers, disabled, formId } = useOpenFormState();
  const async = useOpenFormAsync();
  const params = useParams<FormId & Index>();
  const form = useFetchForm(params);
  const breadcrumbs = useMemo(() => form?.sections.map(({ guid }) => guid).concat('summary'), [form?.sections]);
  const [completed, summary, index, path, next, prev] = useFormNavigation(params, preview, answers, form?.sections);
  const [FormProvider, methods] = useFormProvider(index);
  const [ready, reload] = useReload();

  const response = useFetchSummary(
    summary,
    index,
    answers,
    form?.sections,
    OFPageType.QUESTION_MAIN_PRODUCTS,
    OFPageType.QUESTION_ADDITIONAL_SERVICES
  );

  const clear = useCallback(() => {
    reload();
    async.cache.clear();
    async.actions.form({ answers: undefined, context: undefined, formId });
    path(1);
  }, [formId, async, path, reload]);

  return (
    <OpenForm preview={preview} clear={answers.size ? clear : undefined}>
      {!ready(form) ? null : (
        <FormProvider>
          <OpenFormGrid className="of-openform__view">
            <OpenFormViewBreadcrumbs breadcrumbs={breadcrumbs} disabled={disabled} index={index} />
            {completed ? null : summary ? (
              <OpenFormViewSummary
                methods={methods}
                answers={answers}
                sections={form.sections}
                response={response}
                disabled={disabled}
                preview={preview}
                clear={clear}
                prev={prev}
              />
            ) : (
              <OpenFormViewSection
                methods={methods}
                answers={answers}
                sections={form.sections}
                disabled={disabled}
                index={index}
                next={next}
                prev={prev}
              />
            )}
          </OpenFormGrid>
        </FormProvider>
      )}
    </OpenForm>
  );
};
