import * as CL from '@design-system/component-library';
import { generatePath } from 'react-router-dom';
import {
  invoiceAdditionalInfoMsg,
  invoiceReferenceNumberDisclaimerMsg,
  invoiceStatusUpdateMsg,
  t,
} from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { ReactNode } from 'react';

interface InvoiceDisclaimerProps {
  children?: ReactNode;
  className?: string;
}

export const InvoiceDisclaimer = ({ children, className }: InvoiceDisclaimerProps) => {
  return (
    <CL.Disclaimer className={className}>
      <div>{t.MQQC(invoiceStatusUpdateMsg)}</div>
      <div className="ds-margin-top--2">{t.ABRO(invoiceReferenceNumberDisclaimerMsg)}</div>
      <div className="ds-margin-top--2">
        <CL.Link
          className="ds-font-size--medium"
          linkTarget="_blank"
          linkHref={generatePath(paths.INSTRUCTIONS_INVOICES)}
          rel="noreferrer"
        >
          {t.G85J(invoiceAdditionalInfoMsg)} <CL.Icon icon="external-link" size="s" type="regular" color="blue-600" />
        </CL.Link>
      </div>
      {children}
    </CL.Disclaimer>
  );
};
