import { type OpenFormLoading } from '../OpenFormComponents/OpenFormLoadingSpinner.js';
import { useCallback, useEffect } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';

export const useLoadingIndicator = (flag: boolean, { id, text }: OpenFormLoading): (() => void) => {
  const async = useOpenFormAsync();
  const cancel = useCallback(() => async.actions.loading({ id, text: undefined }), [id, async]);

  useEffect(() => {
    flag && async.actions.loading({ id, text });
    return () => cancel();
  }, [flag, id, text, async, cancel]);

  return cancel;
};
