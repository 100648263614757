import {
  billingAccountsMsg,
  billingMsg,
  businessSecurityMsg,
  companyDetailsMsg,
  contractsMsg,
  customerServiceMsg,
  deviceListsMsg,
  devicesMsg,
  fixedBroadbandMsg,
  instructionsMsg,
  internetSubscriptionsMsg,
  invitesMsg,
  invoiceLettersMsg,
  invoicesMsg,
  licenseManagementMsg,
  microsoft365Msg,
  mobileBroadbandMsg,
  mobileM2MMsg,
  myAccountMsg,
  myServicesMsg,
  orderHistoryMsg,
  ordersMsg,
  phoneSubscriptionMsg,
  redeemRequestsMsg,
  reportsMsg,
  servicesMsg,
  settingsMsg,
  shopMsg,
  supportCasesMsg,
  supportMsg,
  t,
  usersMsg,
  voiceSubscriptionsMsg,
} from '../../../common/i18n/index.js';
import { navigationItemListWithCurrentProperty } from '../../Header/dynamic/headerFunctions.js';
import { paths } from '../../../common/constants/pathVariables.js';
import type { NestedSubMenu } from '@design-system/component-library';

const omaElisaNavigationItemList = (
  mobileId: boolean,
  licenseManagementEnabled: boolean,
  dnsManagementEnabled: boolean,
  classicSiteUrl: string
): NestedSubMenu[] => {
  return [
    {
      title: t.AUI8(billingMsg),
      subMenu: [
        {
          title: t.Y7C0(invoicesMsg),
          url: paths.INVOICES,
        },
        {
          title: t.ZVMK(billingAccountsMsg),
          url: paths.BILLING_ACCOUNTS,
        },
        {
          title: t.OMMT(invoiceLettersMsg),
          url: paths.INVOICE_DOCUMENTS,
        },
      ],
    },
    {
      title: t.GCO3(orderHistoryMsg),
      subMenu: [
        {
          title: t.I9C0(ordersMsg),
          url: paths.CUSTOMER_ORDER_HOME,
        },
        {
          title: t.Z1M1(redeemRequestsMsg),
          url: paths.CUSTOMER_ORDER_REDEEM_REQUESTS,
        },
      ],
    },
    {
      title: t.OHZ1(myServicesMsg),
      subMenu: [
        {
          title: t.JJTM(devicesMsg),
          url: paths.PS_DEVICES,
        },
        {
          title: t.AR0B(voiceSubscriptionsMsg),
          url: paths.PS_MOBILE_SUBSCRIPTIONS,
        },
        {
          title: t.SEYV(internetSubscriptionsMsg),
          url: paths.PS_BROADBAND_SUBSCRIPTIONS,
        },
        {
          title: t.MBG9(servicesMsg),
          url: paths.PS_SERVICES,
        },
        ...(mobileId
          ? [
              {
                title: t.SW0J(contractsMsg),
                url: paths.PS_CONTRACTS,
              },
            ]
          : []),
        ...(licenseManagementEnabled
          ? [
              {
                title: t.N689(licenseManagementMsg),
                url: paths.PS_LICENSES,
              },
            ]
          : []),
        ...(dnsManagementEnabled
          ? [
              {
                title: 'Elisa DNS',
                url: paths.PS_ELISA_DNS,
              },
            ]
          : []),
      ],
    },
    {
      title: t.A1Q5('Company details and settings'),
      subMenu: [
        {
          title: t.L7QB(companyDetailsMsg),
          url: paths.COMPANY_INFO_HOME,
        },
        {
          title: t.QI2P(settingsMsg),
          url: paths.COMPANY_INFO_SETTINGS,
        },
        {
          title: t.BE4A(usersMsg),
          url: paths.COMPANY_INFO_CONTACTS,
        },
        {
          title: t.HFD9(invitesMsg),
          url: paths.COMPANY_INFO_INVITES,
        },
        {
          title: t.COBB(deviceListsMsg),
          url: paths.COMPANY_INFO_CATALOGS,
        },
        {
          title: t.Y36X(reportsMsg),
          url: paths.COMPANY_INFO_REPORTS,
        },
        {
          title: t.SEBD(myAccountMsg),
          url: `${classicSiteUrl}/omat-tiedot`,
          target: '_blank',
        },
      ],
    },
    {
      title: t.VUU0(supportMsg),
      subMenu: [
        {
          title: t.RD4R(instructionsMsg),
          url: paths.INSTRUCTIONS,
        },
        {
          title: t.UZOX(customerServiceMsg),
          url: paths.CONTACT_INFO,
        },
        {
          title: t.H4F0(supportCasesMsg),
          url: paths.SUPPORT_CASES,
        },
      ],
    },
    {
      title: t.BZYZ(shopMsg),
      subMenu: [
        {
          title: t.JJTM(devicesMsg),
          url: paths.WEB_SHOP,
        },
        {
          title: t.R6AR(phoneSubscriptionMsg),
          url: paths.PS_MOBILE_SUBSCRIPTION_NEW,
        },
        {
          title: t.QXQ7(fixedBroadbandMsg),
          url: paths.ANONYMOUS_FIXED_BROADBAND_ORDER,
        },
        {
          title: t.EQKJ(mobileBroadbandMsg),
          url: paths.PS_BROADBAND_SUBSCRIPTION_NEW,
        },
        {
          title: t.A7CA(mobileM2MMsg),
          url: paths.PS_BROADBAND_SUBSCRIPTION_NEW_M2M,
        },
        {
          title: t.MV3J(microsoft365Msg),
          url: paths.MICROSOFT_365,
        },
        {
          title: t.AV0P(businessSecurityMsg),
          url: paths.BUSINESS_SECURITY,
        },
      ],
    },
  ];
};

export const omaElisaNavigationLinksWithCurrentProperty = (
  mobileId: boolean,
  currentPath: string,
  licenseManagementEnabled: boolean,
  dnsManagementEnabled: boolean,
  classicSiteUrl: string
): NestedSubMenu[] => {
  return navigationItemListWithCurrentProperty(
    omaElisaNavigationItemList(mobileId, licenseManagementEnabled, dnsManagementEnabled, classicSiteUrl),
    currentPath
  );
};
