import * as CL from '@design-system/component-library';
import { ACTIVE_CLASS } from './config.js';
import { createOptionId } from './utils.js';
import { useAutoComplete } from './useAutoComplete.js';
import classNames from 'classnames';
import type { ChangeEvent, KeyboardEvent, MouseEvent, ReactNode } from 'react';
import type { ConfigurationObject, ExternalInputRef } from './config.js';

import './AutoComplete.scss';

export interface AsyncConfig<T> {
  loadingMsg: string;
  noResultsMsg: string;
  fetchFn: (query: string) => Promise<T[]>;
}

export interface AutoCompleteProps<T> {
  className?: string;
  disabled?: boolean;
  options: T[];
  getUniqueId: (option: T) => string | number;
  getDisplayValue: (option: T) => string;
  getDisplayHtml?: (option: T) => JSX.Element;
  // Input change handler that will trigger when user selects option (typing a letter doesn't trigger this)
  onInputChange: (event: ChangeEvent | KeyboardEvent | MouseEvent, option: Partial<T>) => void;
  // Input blur handler to trigger when user leaves the input (used for form library touched state)
  onInputBlur?: (event: ChangeEvent | FocusEvent | KeyboardEvent | MouseEvent) => void;
  defaultOption?: T;
  config?: ConfigurationObject;
  error?: string;
  id: string;
  label?: string | ReactNode;
  placeholder?: string;
  noOptionsMsg: string;
  readOnlyContent?: (props: T) => JSX.Element;
  async?: AsyncConfig<T>;
  inputRef?: ExternalInputRef;
}

export type BasicAutoCompleteItem = {
  html: JSX.Element;
  label: string;
  value: string;
  id?: string;
};

export const AutoComplete = <T,>(props: AutoCompleteProps<T>) => {
  const { className, disabled, getUniqueId, getDisplayHtml, getDisplayValue, label, placeholder } = props;
  const {
    id,
    config: { isClearable },
    error,
    isOpen,
    isFilter,
    isFocused,
    isLoading,
    inputValueAndId,
    optionValue,
    options,
    filteredOptions,
    optionsRef,
    optionRef,
    getControlProps,
    getInputProps,
    getOptionProps,
    getNoOptionProps,
    getClearProps,
    getChevronProps,
  } = useAutoComplete<T>(props);
  if (options.length === 0 && !props.async) {
    throw new Error('Please provide list of options, make the component searchable or do both.');
  }

  if (props.readOnlyContent) {
    const ReadOnlyContent = props.readOnlyContent;
    return optionValue ? <ReadOnlyContent {...optionValue} /> : null;
  }

  return (
    <div
      className={classNames([
        'autocomplete-root',
        disabled && 'autocomplete-disabled',
        error && ' autocomplete-error',
        isFocused && 'autocomplete-active',
        className,
      ])}
    >
      <div className="ds-input--labelarea">
        {label &&
          (typeof label === 'string' ? (
            <label className="ds-input--labelarea-label" htmlFor={`autocomplete-input-${id}`}>
              {label}
            </label>
          ) : (
            label
          ))}
      </div>
      <div className="ds-input--inputarea autocomplete-control" {...getControlProps()}>
        <input
          {...getInputProps()}
          aria-autocomplete="list"
          aria-controls={`autocomplete-listbox-${id}`}
          aria-expanded={isOpen}
          aria-invalid="false"
          autoComplete="off"
          autoCapitalize="none"
          disabled={disabled}
          spellCheck="false"
          role="combobox"
          className="ds-input--inputarea-input"
          placeholder={placeholder}
          name="autocomplete-input"
          type="text"
          id={`autocomplete-input-${id}`}
          readOnly={false}
          tabIndex={0}
          value={inputValueAndId.value}
        />

        {options.length === 0 && isLoading && (
          <CL.LoadingSpinner
            className={'loading ' + (isClearable && optionValue ? 'left' : 'right')}
            i18n_loadingspinner_ariaText={props.async?.loadingMsg}
            size="xs"
          />
        )}

        {isClearable && optionValue && (
          <div className="clear" role="none" tabIndex={-1} data-testid="clear" {...getClearProps()}>
            <CL.Icon icon="close" size="s" type="regular" />
          </div>
        )}

        <div
          className="chevron"
          role="none"
          tabIndex={-1}
          data-testid={isOpen ? 'chevron-up' : 'chevron-down'}
          {...getChevronProps()}
        >
          {isOpen && <CL.Icon icon="arrow-up" type="light" size="m" />}
          {!isOpen && <CL.Icon icon="arrow-down" type="light" size="m" />}
        </div>

        <div role="presentation" className="autocomplete-presentation">
          {!disabled && isOpen && (
            <ul ref={optionsRef} id={`autocomplete-listbox-${id}`} tabIndex={-1} role="listbox" data-show="true">
              {isFilter && Boolean(options.length) && filteredOptions.length === 0 && (
                <li {...getNoOptionProps()} tabIndex={-1}>
                  {props.noOptionsMsg}
                </li>
              )}

              {props.async && isFocused && options.length === 0 && (
                <li {...getNoOptionProps()} tabIndex={-1}>
                  {isLoading ? props.async?.loadingMsg : props.async?.noResultsMsg}
                </li>
              )}

              {filteredOptions.map((option, index) => {
                const isActive = inputValueAndId.id === getUniqueId(option);
                return (
                  <li
                    {...getOptionProps(option)}
                    aria-disabled={false}
                    aria-selected={isActive}
                    data-option-index={index}
                    key={getUniqueId(option)}
                    ref={isActive ? optionRef : undefined}
                    className={isActive ? ACTIVE_CLASS : ''}
                    id={createOptionId(id, index)}
                    role="option"
                    tabIndex={-1}
                  >
                    {(getDisplayHtml || getDisplayValue)(option)}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
      {!isOpen && error && <p className="ds-inputerror">{error}</p>}
    </div>
  );
};
