import * as CL from '@design-system/component-library';
import { Ribbon } from '../Ribbon/Ribbon.js';
import { Sticker } from '../Sticker/Sticker.js';
import { TurbonappiOrderForm } from '../Turbonappi/TurbonappiOrderForm.js';
import { activationFeeMsg, alvZeroMsg, noFixedTermMsg, saveMsg, sumPerMonthMsg, t } from '../../common/i18n/index.js';
import { formatSumToString } from '../../common/utils/priceUtils.js';
import {
  getMonthlySaving,
  getPriceString,
  hasOffer,
  isEmptyHeader,
  isNotEmpty,
  shouldShowSeparator,
} from './subscriptionCardUtils.js';
import { getTurbonappiSelectItemEvent } from '../Turbonappi/turbonappiAnalyticsUtils.js';
import { isFiveGPlusOffer, isFiveGVoiceOffer } from '../../common/utils/subscriptionUtils.js';
import { pushToDataLayer } from '../../common/analytics.js';
import { useState } from 'react';
import classNames from 'classnames';
import type { MouseEvent } from 'react';
import type { SubscriptionCardType } from '../../common/enums.js';
import type { TurbonappiOffer } from '../../generated/api/turbonappiOffer.js';

import './SubscriptionCard.scss';

type SubscriptionCardHeadingProps = {
  description?: string | JSX.Element;
  subDescription?: string | JSX.Element;
  // 'Family name', e.g. 'Yritysliittymä' or 'Laitenetti'
  // or something like the 5G pill.
  name?: string | JSX.Element;
  // Speed moniker, e.g. '100M' or 'L'
  mainHeader: string;
  voucherCode?: string;
  groupHeader?: string;
  selectedOffer?: string;
};

type SubscriptionCardServices = {
  name: string | JSX.Element;
  guid: string;
  icon?: string;
  isIncluded: boolean;
  popoverText?: string;
  popoverContent?: string | JSX.Element;
  hidden?: boolean;
};

export type SubscriptionCardContentProps = {
  bullets?: string[];
  features?: {
    icon: string;
    text: string;
    title: string;
  }[];
  inPublicStore?: boolean;
  text?: string[];
  selectedOffer?: string;
  // This is used with Laitenetti. With Laitenetti we only have one offer and cards are addons for that offer.
  selectedAddon?: string;
  onChangeOffer?: (offerGuid: string, groupName?: string) => void;
  contentHeader?: string;
  offers?: SubscriptionCardOffer[];
  specifications?: SubscriptionCardOfferSpecification[];
  services?: SubscriptionCardServices[];
  showContentSeparators?: boolean;
};

export type SubscriptionCardOffer = {
  guid: string;
  icon?: string;
  // This is for the 'public' store, i.e. https://yrityksille.elisa.fi/yritysliittymat and such
  salesProduct: {
    oldNumberOfferCode?: string;
    onlineModelCode: string;
    newNumberOfferCode: string;
  };
  text: string;
};

export type SubscriptionCardOfferSpecification = {
  name: string;
  value?: { [guid: string]: string };
  icon?: string;
  popoverText?: string;
  popoverContent?: string | JSX.Element;
  disabled?: boolean;
};

export type MbbSalesProductProps = {
  salesProduct: {
    offerCode: string;
    onlineModelCode: string;
  };
};

export type TurbonappiProps = {
  turbonappiPrice: string;
  priceUnit: string;
  turbonappiPriceAdditionalInfo: string;
  turbonappiOffer: TurbonappiOffer;
  turbonappiFullName: string;
};

type SubscriptionCardPriceProps = Partial<TurbonappiProps> & {
  fixedTermMonths?: number;
  monthlyPrice?: number;
  oneTimePrice?: number;
  serviceFee?: number;
  originalMonthlyPrice?: number;
  originalOneTimePrice?: number;
  pricePreamble?: string;
  showSticker?: boolean;
  selectButton?: JSX.Element;
  turbonappiOrderForm?: JSX.Element;
};

type SubscriptionCardFooterProps = {
  // ID of element that the button controls (if any)
  buttonControls?: string;
  buttonDisabled?: boolean;
  buttonText?: string;
  separator?: boolean;
  // We might want to disable the button until we've loaded the product data
  isLoading?: boolean;
  onButtonClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  showSticker?: boolean;
  selectedOffer?: string;
} & SubscriptionCardPriceProps;

export type SubscriptionCardProps = {
  color?: 'orange' | 'turquoise';
  recommended?: boolean;
  // We pass along ribbonWidth so that if we have a bunch of cards,
  // we can match the ribbon width between them.
  ribbonWidth?: number;
  selected?: boolean;
  showSticker?: boolean;
  cardType?: SubscriptionCardType;
  cardName?: string;
} & SubscriptionCardHeadingProps &
  SubscriptionCardContentProps &
  Omit<SubscriptionCardFooterProps, 'separator'>;

export type SubCardProps = Exclude<SubscriptionCardProps, 'color' | 'ribbonWidth'>;
export type MobileBroadbandSubCardProps = SubCardProps & MbbSalesProductProps;
type HeadingNameOrBadgeProps = Pick<SubscriptionCardProps, 'selectedOffer' | 'name'>;

const HeaderName = ({ selectedOffer, name }: HeadingNameOrBadgeProps) => {
  if (isFiveGPlusOffer(selectedOffer)) {
    return <span className="of-subscription-card__header-badge">5G+</span>;
  } else if (isFiveGVoiceOffer(selectedOffer)) {
    return null;
  }
  return <span>{name}</span>;
};

const SubscriptionCardHeading = ({
  description,
  name,
  mainHeader,
  voucherCode,
  subDescription,
  selectedOffer,
}: SubscriptionCardHeadingProps) => (
  <div className="of-subscription-card--header">
    <div className={`of-subscription-card--${voucherCode ? 'voucher' : 'no-voucher'}`}>{voucherCode}</div>
    <div className={subDescription ? 'ds-margin--0' : ''}>
      <div className={`ds-h4 ${isEmptyHeader(name, selectedOffer) ? 'ds-padding-top--4' : ''}`}>
        <HeaderName name={name} selectedOffer={selectedOffer} />
      </div>
      <div className={`of-subscription-card--header__main ds-padding-top--3 ${subDescription ? 'ds-margin--0' : ''}`}>
        <h2>{mainHeader}</h2>
      </div>
    </div>
    {description && (
      <>
        <div className="of-subscription-card--header__description">{description}</div>
        {subDescription && <div className="ds-h5">{subDescription}</div>}
      </>
    )}
  </div>
);

interface CardServicesProps {
  inPublicStore: boolean;
  services?: SubscriptionCardServices[];
}

const CardServices = ({ inPublicStore, services }: CardServicesProps) => {
  const getServicesRows = (filteredServices: SubscriptionCardServices[]) => (
    <div className="of-subscription-card--service--items">
      {filteredServices.map((service, i) => (
        <div key={`service-included-item-${i}`} className="of-subscription-card--service--items--item">
          <div className={`icon icon--${service.icon}`} />
          <div className="ds-text-align--left">{service.name}</div>
          <div className="popover">
            <CL.Popover
              triggerElement={<CL.Icon icon="information" size="s" type="light" color="neutral-700" />}
              placement="top"
              i18n_popover_contentText={service.popoverText}
            >
              {service.popoverContent}
            </CL.Popover>
          </div>
        </div>
      ))}
    </div>
  );

  return services?.some(s => !s.hidden) ? (
    <div className="ds-margin-top--3 of-subscription-card--service">
      <div className="of-subscription-card--service--column">
        {services.some(s => s.isIncluded) && (
          <>
            <div className="ds-h5 ds-text-align--left ds-margin-top--1 ds-margin-bottom--1">
              {t.QQ80('Subscription includes')}
            </div>
            {getServicesRows(services.filter(s => s.isIncluded && !s.hidden))}
          </>
        )}
        {services.some(s => !s.isIncluded) && (
          <>
            <div className="ds-h5 ds-text-align--left ds-margin-top--3 ds-margin-bottom--1">
              {inPublicStore ? t.RMXE('Available in OmaElisa') : t.RMXD('Buy additionally')}
            </div>
            {getServicesRows(services.filter(s => !s.isIncluded && !s.hidden))}
          </>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

const OfferSpecifications = ({
  specifications,
  selectedOffer,
  hasContentHeader,
}: {
  specifications?: SubscriptionCardOfferSpecification[];
  selectedOffer: string;
  hasContentHeader: boolean;
}) => {
  const iconColor = (disabled?: boolean) => (disabled ? 'neutral-300' : 'neutral-700');

  return (
    // Reduce top margin if a content header is present to avoid excessive spacing, since the header includes its own margin
    <div className={hasContentHeader ? 'ds-margin-top--2' : 'ds-margin-top--4'}>
      {specifications?.map((specification, i) => (
        <div
          key={`offer-inclusion-row-${i}`}
          className={`of-subscription-card--specification${
            specification.disabled ? '--disabled' : ''
          } ds-display--flex ds-justify-content--space-between ds-margin-top--1`}
          aria-disabled="true"
        >
          <div className="ds-text--xs of-subscription-card--specification--start">
            {specification.popoverText || specification.popoverContent ? (
              <div className="ds-display--flex">
                <div className="ds-margin-right--2">{specification.name}</div>
                <CL.Popover
                  triggerElement={
                    <CL.Icon icon="information" size="s" type="light" color={iconColor(specification.disabled)} />
                  }
                  placement="right"
                  i18n_popover_contentText={specification.popoverText}
                >
                  {specification.popoverContent}
                </CL.Popover>
              </div>
            ) : (
              specification.name
            )}
          </div>
          <div className="of-subscription-card--specification--word-separator"></div>
          <div className="ds-text--xs of-subscription-card--specification--end">
            {specification.icon ? (
              <CL.Icon icon={specification.icon} size="s" type="light" color={iconColor(specification.disabled)} />
            ) : (
              specification.value?.[selectedOffer]
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const Features = ({
  features,
  text = [],
  showContentSeparators = true,
}: Pick<SubscriptionCardContentProps, 'features' | 'text' | 'showContentSeparators'>) => (
  <>
    {showContentSeparators && <hr aria-hidden="true" />}
    <div className="of-subscription-card__specs">
      <ul className="of-subscription-card__icongrid ds-text--xs">
        {features &&
          features.map(feature => (
            <li key={feature.title}>
              <CL.Icon icon={feature.icon} size="s" type="light" />
              <span className="ds-sr-only">{feature.title}</span> <span>{feature.text}</span>
            </li>
          ))}
      </ul>
      {text.map((line, idx) => (
        <p className="ds-text--xs" key={idx}>
          {line}
        </p>
      ))}
    </div>
  </>
);

const Bullets = ({ bullets = [] }: Pick<SubscriptionCardContentProps, 'bullets'>) => (
  <>
    <hr aria-hidden="true" />
    <ul className="of-subscription-card__bullets ds-list ds-list--icon">
      {bullets.map(bulletText => (
        <li className="ds-text--s" key={bulletText}>
          <CL.Icon icon="check" size="s" type="filled" />
          {bulletText}
        </li>
      ))}
    </ul>
  </>
);

const FiveGPlusInfo = () => (
  <div className="ds-padding-top--4 of-subscription-card--five-g-info">
    <div className="ds-h5">{t.U781('Benefits of the 5G+ network')}</div>
    <div className="of-subscription-card--five-g-info__features">
      <div>{t.EPSL('More consistent connections')}</div>
      <div>{t.RE1O('Up to 20% better battery life of the device')}</div>
    </div>
  </div>
);

const SubscriptionCardContent = ({
  bullets,
  features,
  text = [],
  contentHeader,
  inPublicStore,
  offers,
  specifications,
  selectedOffer,
  services,
  showContentSeparators = true,
}: SubscriptionCardContentProps) => (
  <div className={`${isNotEmpty(offers) ? 'of-subscription-card--content' : ''}`}>
    {(contentHeader || isNotEmpty(offers) || isNotEmpty(specifications)) && showContentSeparators && (
      <div className="ds-padding-bottom--1">
        <hr aria-hidden="true" />
      </div>
    )}
    {contentHeader && <div className="ds-h5 ds-margin-top--3">{contentHeader}</div>}
    <div>
      {isNotEmpty(specifications) && (
        <OfferSpecifications
          specifications={specifications}
          selectedOffer={selectedOffer || ''}
          hasContentHeader={Boolean(contentHeader)}
        />
      )}
      {isNotEmpty(services) && (
        <div className="ds-margin-top--3">
          <CardServices inPublicStore={Boolean(inPublicStore)} services={services} />
        </div>
      )}
      {isFiveGPlusOffer(selectedOffer) && <FiveGPlusInfo />}
    </div>
    {(isNotEmpty(features) || (text && text.length > 0)) && (
      <Features features={features} showContentSeparators={showContentSeparators} text={text} />
    )}
    {bullets && bullets.length > 0 && <Bullets bullets={bullets} />}
  </div>
);

const SubscriptionCardPriceAdditionalInfo = ({
  monthlySaving,
  fixedTermMonths,
  oneTimePrice,
  originalOneTimePrice,
  originalMonthlyPrice,
  serviceFee,
  showSticker,
}: SubscriptionCardPriceProps & { monthlySaving: number }) => {
  return (
    <div className="ds-price-additional">
      {showSticker && monthlySaving > 0 && (
        <div>
          {t.SYWS('Normal price')} {t.YO7F(sumPerMonthMsg, formatSumToString(originalMonthlyPrice))}
        </div>
      )}
      <div>
        <span>
          {fixedTermMonths ? t.IWHR('{} month fixed term contract', `${fixedTermMonths}`) : t.XJMB(noFixedTermMsg)}
          {', '}
        </span>
        {serviceFee && t.IKVP('Change subscription type fee {}', formatSumToString(serviceFee))}
        {!serviceFee && oneTimePrice != null && `${t.HWDR(activationFeeMsg)} ${formatSumToString(oneTimePrice)}`}
        {!serviceFee && originalOneTimePrice != null && oneTimePrice != null && originalOneTimePrice > oneTimePrice && (
          <>
            {' '}
            <del>{formatSumToString(originalOneTimePrice)}</del>
          </>
        )}
        <span>, {t.S8TX(alvZeroMsg)}</span>
      </div>
    </div>
  );
};

const SubscriptionCardPriceAndUnit = ({
  monthlyPrice,
  pricePreamble,
  turbonappiPrice,
  priceUnit,
  monthlySaving,
  showSticker = false,
}: SubscriptionCardPriceProps & { monthlySaving: number }) => (
  <div className="ds-price-content">
    {showSticker && monthlySaving > 0 && (
      <Sticker saving={t.YO7F(sumPerMonthMsg, formatSumToString(monthlySaving))} text={t.HWDT(saveMsg)} />
    )}
    <div className="ds-price-content-number">
      {pricePreamble && <span className="ds-text--s of-subscription-card__price-preamble">{pricePreamble}</span>}
      {getPriceString(monthlyPrice, turbonappiPrice)}
      <span className="of-subscription-card__price-unit">
        {priceUnit ? ` ${priceUnit}` : ` ${t.YO7F('{}/month', '€')}`}
      </span>
    </div>
  </div>
);

// We have to recreate the CL.Price component here, as we can't insert the preamble and sticker elements in the real one.
const SubscriptionCardPrice = ({
  fixedTermMonths,
  monthlyPrice,
  oneTimePrice,
  originalMonthlyPrice,
  originalOneTimePrice,
  pricePreamble,
  turbonappiPrice,
  priceUnit,
  turbonappiPriceAdditionalInfo,
  showSticker,
  serviceFee,
  selectButton,
  turbonappiOrderForm,
}: SubscriptionCardPriceProps) => {
  const monthlySaving = getMonthlySaving(monthlyPrice, originalMonthlyPrice);
  return (
    <div className={`ds-price of-subscription-card__price ${turbonappiPriceAdditionalInfo ? 'turbonappi-form' : ''}`}>
      <div className="of-subscription-card__price__text-and-button">
        <SubscriptionCardPriceAndUnit
          monthlyPrice={monthlyPrice}
          pricePreamble={pricePreamble}
          turbonappiPrice={turbonappiPrice}
          priceUnit={priceUnit}
          monthlySaving={monthlySaving}
          showSticker={showSticker}
        />
        {selectButton}
      </div>
      <div className="of-subscription-card__price__original-price">
        <del>{monthlySaving > 0 && `${formatSumToString(originalMonthlyPrice, true)} ${t.YO7F('{}/month', '€')}`}</del>
      </div>
      {turbonappiPriceAdditionalInfo ? (
        <div className="ds-price-additional">
          <div>{turbonappiPriceAdditionalInfo}</div>
          <div className="of-subscription-card__price__turbonappi-form">{turbonappiOrderForm}</div>
        </div>
      ) : (
        <SubscriptionCardPriceAdditionalInfo
          monthlySaving={monthlySaving}
          originalMonthlyPrice={originalMonthlyPrice}
          oneTimePrice={oneTimePrice}
          originalOneTimePrice={originalOneTimePrice}
          fixedTermMonths={fixedTermMonths}
          serviceFee={serviceFee}
          turbonappiPriceAdditionalInfo={turbonappiPriceAdditionalInfo}
          showSticker={showSticker}
        />
      )}
    </div>
  );
};

const SubscriptionCardFooter = ({
  buttonControls,
  buttonDisabled,
  buttonText,
  fixedTermMonths,
  isLoading,
  monthlyPrice,
  onButtonClick,
  oneTimePrice,
  originalMonthlyPrice,
  originalOneTimePrice,
  pricePreamble,
  turbonappiPrice,
  priceUnit,
  turbonappiPriceAdditionalInfo,
  turbonappiOffer,
  showSticker,
  serviceFee,
}: SubscriptionCardFooterProps) => {
  const [showTurboNappiContent, setShowTurboNappiContent] = useState(false);
  if (turbonappiPrice) {
    onButtonClick = () => {
      setShowTurboNappiContent(!showTurboNappiContent);
      pushToDataLayer(getTurbonappiSelectItemEvent(turbonappiOffer));
    };
  }
  const turbonappiOrderForm = showTurboNappiContent ? <TurbonappiOrderForm turbonappiOffer={turbonappiOffer} /> : <></>;
  const selectButton = showTurboNappiContent ? (
    <></>
  ) : (
    <div className="of-subscription-card__button">
      <CL.Button
        aria-controls={buttonControls}
        color={turbonappiPrice ? 'light' : 'linkblue'}
        disabled={buttonDisabled}
        loading={isLoading}
        onClick={onButtonClick}
        size="l"
      >
        {buttonText}
      </CL.Button>
    </div>
  );

  return (
    <>
      <hr aria-hidden="true" />
      <SubscriptionCardPrice
        fixedTermMonths={fixedTermMonths}
        monthlyPrice={monthlyPrice}
        oneTimePrice={oneTimePrice}
        originalMonthlyPrice={originalMonthlyPrice}
        originalOneTimePrice={originalOneTimePrice}
        pricePreamble={pricePreamble}
        turbonappiPrice={turbonappiPrice}
        priceUnit={priceUnit}
        turbonappiPriceAdditionalInfo={turbonappiPriceAdditionalInfo}
        showSticker={showSticker}
        serviceFee={serviceFee}
        selectButton={selectButton}
        turbonappiOrderForm={turbonappiOrderForm}
      />
    </>
  );
};

const getRibbonText = (
  monthlyPrice?: number,
  oneTimePrice?: number,
  originalMonthlyPrice?: number,
  originalOneTimePrice?: number,
  recommended?: boolean
): string[] | undefined => {
  if (recommended) {
    return [t.TJPP('Recommended')];
  }
  if (hasOffer(monthlyPrice, oneTimePrice, originalMonthlyPrice, originalOneTimePrice)) {
    return [t.TJPQ('Offer')];
  }
  return undefined;
};

export const SubscriptionCard = ({
  bullets,
  buttonControls,
  buttonText,
  description,
  features,
  fixedTermMonths,
  isLoading,
  monthlyPrice,
  name,
  onButtonClick,
  oneTimePrice,
  originalMonthlyPrice,
  originalOneTimePrice,
  pricePreamble,
  recommended,
  selected,
  mainHeader,
  text,
  turbonappiPrice,
  priceUnit,
  turbonappiPriceAdditionalInfo,
  turbonappiOffer,
  showSticker,
  contentHeader,
  inPublicStore,
  offers,
  selectedOffer,
  onChangeOffer,
  specifications,
  services,
  serviceFee,
  voucherCode,
  cardName,
  showContentSeparators,
  subDescription,
}: SubscriptionCardProps) => {
  const color = recommended ? 'light-blue' : undefined;
  const ribbonText = getRibbonText(monthlyPrice, oneTimePrice, originalMonthlyPrice, originalOneTimePrice, recommended);
  return (
    <div className="of-subscription-card--card-wrapper">
      <CL.Card
        badge={ribbonText ? <Ribbon color={color} text={ribbonText} /> : ''}
        className={classNames([
          'of-subscription-card',
          (recommended || selected) && `of-subscription-card--highlight-${selected ? 'blue' : color}`,
        ])}
        content={
          <SubscriptionCardContent
            bullets={bullets}
            features={features}
            inPublicStore={inPublicStore}
            text={text}
            contentHeader={contentHeader}
            offers={offers}
            selectedOffer={selectedOffer}
            onChangeOffer={(guid: string) => {
              onChangeOffer?.(guid, cardName);
            }}
            specifications={specifications}
            services={services}
            showContentSeparators={showContentSeparators}
          />
        }
        footer={
          <SubscriptionCardFooter
            buttonControls={buttonControls}
            buttonDisabled={selected}
            buttonText={buttonText}
            fixedTermMonths={fixedTermMonths}
            isLoading={isLoading}
            monthlyPrice={monthlyPrice}
            onButtonClick={onButtonClick}
            oneTimePrice={oneTimePrice}
            originalMonthlyPrice={originalMonthlyPrice}
            originalOneTimePrice={originalOneTimePrice}
            pricePreamble={pricePreamble}
            separator={shouldShowSeparator({
              bullets,
              features,
              inPublicStore,
              text,
              contentHeader,
              offers,
              specifications,
              services,
            })}
            turbonappiPrice={turbonappiPrice}
            priceUnit={priceUnit}
            turbonappiPriceAdditionalInfo={turbonappiPriceAdditionalInfo}
            turbonappiOffer={turbonappiOffer}
            showSticker={showSticker}
            serviceFee={serviceFee}
            selectedOffer={selectedOffer}
          />
        }
        heading={
          <SubscriptionCardHeading
            description={description}
            mainHeader={mainHeader}
            voucherCode={voucherCode}
            subDescription={subDescription}
            selectedOffer={selectedOffer}
            name={name}
          />
        }
        type="outlined"
      />
      {selected && (
        <div className="selection-footer-ribbon">
          <span>
            <CL.Icon icon="check" type="filled" size="s" color="white" />
            <span className="spacer" />
            {t.QRYW('Selected')}
          </span>
        </div>
      )}
    </div>
  );
};
