import { fetchOpenForm } from '../../../common/fetch.js';
import { loadingFormMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { FormId } from '../../../common/constants/pathInterfaces.js';
import type { OpenFormResponse } from '../../../generated/api/openFormResponse.js';

export const useFetchForm = ({ formId }: Readonly<Partial<FormId>>) => {
  const async = useOpenFormAsync();
  const [data, setData] = useState<OpenFormResponse | undefined>(undefined);

  useEffect(() => {
    if (!formId) {
      return;
    }
    const { ctrl, evict } = new async(({ signal }) => fetchOpenForm(formId, { signal }))
      .resolved(setData)
      .rejected(async.actions.error)
      .settled(() => async.actions.form({ ...async.storage.getItem(formId), formId }))
      .cache(formId)
      .execute(t.DR5T(loadingFormMsg));

    return () => {
      ctrl.abort();
      setData(undefined);
      evict();
      async.actions.form({ answers: undefined, context: undefined, formId: undefined });
    };
  }, [async, formId]);

  return data;
};
