import { GridNarrow } from '../../../components/Grid/Grid.js';
import { LoginAndRegistration } from '../../../components/LoginAndRegistration/LoginAndRegistration.js';
import { ShoppingBasket } from '../../../components/ShoppingBasket/ShoppingBasket.js';
import { ShoppingBasketCompanySelector } from '../../../components/ShoppingBasket/ShoppingBasketCompanySelector.js';
import { Spinner } from '../siteUtils.js';
import { changeQuantityInBasket } from '../../../components/ShoppingBasket/shoppingBasketUtils.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { getUserAccounts } from '../../../components/Header/dynamic/headerFunctions.js';
import { isCompanyInfoLoading } from '../../../common/utils/stateUtils.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useAuth } from '../AuthProvider.js';
import { useEffect } from 'react';
import { useFirstRender } from '../../../common/hooks/useFirstRender.js';
import { useLocation, useNavigate, useRevalidator, useRouteLoaderData } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useShoppingBasket } from '../../../common/hooks/useShoppingBasket.js';
import type { OnlineModel } from '../../../generated/api/onlineModel.js';
import type { ShoppingBasketType } from 'common/types/shoppingBasket.js';
import type { State } from '../../../selfservice/common/store.js';

export const ShoppingBasketPath = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const firstRender = useFirstRender();

  const loginInProgress = location.state?.loginInProgress === true;
  const { isAuthenticated, authenticatedUser } = useAuth();
  const loaderData = useRouteLoaderData('newShoppingCart') as OnlineModel[];
  const { companyInfo } = useSelector((state: State) => {
    return {
      companyInfo: state.selfservice?.companyInfo,
    };
  }, deepEqual);

  const { shoppingBasket, setShoppingBasket } = useShoppingBasket();
  const shoppingCartJson: ShoppingBasketType = !!shoppingBasket && JSON.parse(shoppingBasket);

  // Revalidate the loader (load onlinemodels) only if cart item amount changes and not on first render
  useEffect(() => {
    if (revalidator.state === 'idle' && !firstRender) {
      revalidator.revalidate();
    }
  }, [shoppingCartJson.items?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeQuantity = (cartItemId: string, quantity: number) => {
    setShoppingBasket(changeQuantityInBasket(shoppingCartJson, cartItemId, quantity));
  };

  const userAccounts = getUserAccounts(authenticatedUser);
  const isLoading = authenticatedUser?.isFetching || isCompanyInfoLoading(companyInfo);

  useEffect(() => {
    if (!isLoading && loginInProgress && userAccounts.length === 1) {
      // If there is no need for user to select company first: reset state
      navigate(paths.NEW_SHOPPING_BASKET, { replace: true });
    }
  }, [loginInProgress, isLoading, navigate, userAccounts.length]);

  if (isLoading) {
    return <Spinner />;
  }

  const loggedIn = isAuthenticated;

  return !loggedIn && loginInProgress ? (
    <GridNarrow>
      <LoginAndRegistration redirectTargetAfterRegisterUrl={paths.NEW_SHOPPING_BASKET} />
    </GridNarrow>
  ) : (
    <div>
      {loggedIn && userAccounts.length > 1 && (
        <ShoppingBasketCompanySelector userAccounts={userAccounts} showInDialog={loginInProgress} />
      )}
      <ShoppingBasket
        basketItems={shoppingCartJson}
        discountedPrices={companyInfo?.discountedPrices || []}
        models={loaderData}
        loggedIn={loggedIn}
        changeQuantity={changeQuantity}
      />
    </div>
  );
};
