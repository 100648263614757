import { BreadCrumbsWithTitle } from '../BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { EppSupportOrServiceRequest } from './EppSupportOrServiceRequest.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { devicesMsg, omaElisaForEmployeeMsg, t } from '../../common/i18n/index.js';
import { generatePath, useNavigate } from 'react-router-dom';
import { paths } from '../../common/constants/pathVariables.js';
import { useSelector } from 'react-redux';
import type { State } from '../../selfservice/common/store.js';
import type { Subscription } from '../../generated/api/subscription.js';

interface EppMaintenanceEmployeeProps {
  subscription: Subscription;
  subscriptionId: string;
}

export const EppMaintenanceEmployee = ({ subscription, subscriptionId }: EppMaintenanceEmployeeProps) => {
  const navigate = useNavigate();
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  const breadCrumbs = (
    <BreadCrumbsWithTitle
      breadCrumbPaths={[
        { name: t.H6JV(omaElisaForEmployeeMsg), path: paths.EMPLOYEE_HOME },
        { name: t.JJTM(devicesMsg), path: paths.EMPLOYEE_DEVICES },
        {
          name: subscription?.subscriptionName || subscriptionId,
          path: generatePath(paths.EMPLOYEE_DEVICE, { subscriptionId }),
        },
        { name: t.C7PN('Support request') },
      ]}
    />
  );

  return (
    <DetailsWrapper
      classes={['of-epp-maintenance']}
      detailsTop={breadCrumbs}
      heading={subscription.subscriptionName}
      headingTop={t.N2CC('Damage or support request')}
      heroPicto="generic-service-filled"
      id="subscription-details"
    >
      <EppSupportOrServiceRequest
        isEmployee={true}
        onCancel={() => {
          navigate(generatePath(paths.EMPLOYEE_DEVICE, { subscriptionId }));
        }}
        subscription={subscription}
        companyInfo={companyInfo}
      />
    </DetailsWrapper>
  );
};
