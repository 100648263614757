import { AuthorizationStatus, useEmployeeAuthenticationStatus } from '../../public/site/employeeSessionResolver.js';
import { EmployeeDialog } from './EmployeeDialog.js';
import { NewEmployeeHeader as EmployeeHeader } from '../EmployeeHeaderNew/EmployeeHeader.js';
import { EmployeeLoginPath, EmployeeLoginPathV2 } from '../../public/site/path/Employee/EmployeeLoginPath.js';
import { Loading } from '../Loading/index.js';
import { NotificationContainer } from '../NotificationContainer/NotificationContainer.js';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SnapInChat } from '../SnapInChat/SnapInChat.js';
import { SystemError, hasSystemError } from '../SystemError/SystemError.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getElisaIdSession, resetErrors } from '../../selfservice/actions/index.js';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { loadingMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useElisaIdClient } from '../../common/hooks/useElisaIdClient.js';
import { useOnBoardingId } from '../../common/hooks/useOnBoardingId.js';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { State } from '../../selfservice/common/store.js';

import './Employee.scss';

export interface ProcessElisaIdLoginResponseProps {
  authenticationMethod: string;
  token: string;
  isVerified: boolean;
}

export const EMPLOYEE_SUBSCRIPTION_PAIR_PATH = 'lisää';

export const isUserDetailsMissing = (user?: AuthenticatedUserState | null): boolean =>
  !!user &&
  (user.mobile === undefined ||
    user.lastName === undefined ||
    user.email === undefined ||
    !user.firstName.length ||
    user.emailVerified === false);

// TODO: remove the component EmployeeView after the removal of elisaIdV2 feature flag
const EmployeeView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { anonymousUser, authenticatedUser } = useAuth();
  const billingAccounts = useSelector((state: State) => state.selfservice?.billingAccounts, deepEqual);
  const authenticatedUserErrors = billingAccounts?.errors || authenticatedUser?.errors;
  const authenticatedErrors = authenticatedUser && authenticatedUserErrors;
  const anonymousErrors = anonymousUser?.errors;
  const elisaIdClient = useElisaIdClient();
  const employeeAuthenticationStatus = useEmployeeAuthenticationStatus(elisaIdClient);

  useEffect(() => {
    if (!anonymousUser?.ssoSessionValid) {
      dispatch(getElisaIdSession(elisaIdClient));
    }
  }, [dispatch, elisaIdClient, anonymousUser?.ssoSessionValid]);

  useEffect(() => {
    const decodedPathname = decodeURI(pathname);
    if (
      anonymousUser?.ssoSessionValid &&
      decodedPathname !== paths.EMPLOYEE_OWN_INFO &&
      !decodedPathname.startsWith(paths.EMPLOYEE_EMAIL_VERIFICATION) &&
      isUserDetailsMissing(authenticatedUser)
    ) {
      navigate(paths.EMPLOYEE_OWN_INFO, { replace: true });
    }
  }, [anonymousUser, authenticatedUser, navigate, pathname]);

  if (!employeeAuthenticationStatus) {
    return (
      <div id="elisaid-login-form-loading">
        <div className="ea-fgrid ea-fgrid--center">
          <div className="ea-fgrid__item ea-fgrid__item--center ea-fgrid__item--block ea-fgrid__item--column">
            <span className="ea-loading" aria-label={t.KW12(loadingMsg)} />
          </div>
        </div>
      </div>
    );
  } else if (employeeAuthenticationStatus === AuthorizationStatus.AUTHORIZED && hasSystemError(authenticatedErrors)) {
    return (
      <SystemError
        errors={authenticatedErrors}
        onButtonClick={() => {
          navigate(paths.EMPLOYEE_HOME);
          dispatch(resetErrors());
        }}
      />
    );
  } else if (employeeAuthenticationStatus === AuthorizationStatus.UNAUTHORIZED && hasSystemError(anonymousErrors)) {
    return (
      <SystemError
        errors={anonymousErrors}
        onButtonClick={() => {
          dispatch(resetErrors());
          if (pathname !== paths.EMPLOYEE_HOME) {
            navigate(paths.EMPLOYEE_HOME);
          }
        }}
      />
    );
  } else if (employeeAuthenticationStatus === AuthorizationStatus.AUTHORIZED) {
    return <Outlet />;
  } else {
    return <EmployeeLoginPath />;
  }
};

// TODO: rename the component EmployeeViewV2 to EmployeeView after the removal of elisaIdV2 feature flag
const EmployeeViewV2 = ({ employeeAuthenticationStatus }: { employeeAuthenticationStatus: string | undefined }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { anonymousUser, authenticatedUser } = useAuth();
  const billingAccounts = useSelector((state: State) => state.selfservice?.billingAccounts, deepEqual);
  const authenticatedUserErrors = billingAccounts?.errors || authenticatedUser?.errors;
  const authenticatedErrors = authenticatedUser && authenticatedUserErrors;
  const anonymousErrors = anonymousUser?.errors;

  useEffect(() => {
    const decodedPathname = decodeURI(pathname);
    if (
      anonymousUser?.ssoSessionValid &&
      decodedPathname !== paths.EMPLOYEE_OWN_INFO &&
      !decodedPathname.startsWith(paths.EMPLOYEE_EMAIL_VERIFICATION) &&
      isUserDetailsMissing(authenticatedUser)
    ) {
      navigate(paths.EMPLOYEE_OWN_INFO, { replace: true });
    }
  }, [anonymousUser, authenticatedUser, navigate, pathname]);

  if (!employeeAuthenticationStatus) {
    return (
      <div id="elisaid-login-form-loading">
        <div className="ea-fgrid ea-fgrid--center">
          <div className="ea-fgrid__item ea-fgrid__item--center ea-fgrid__item--block ea-fgrid__item--column">
            <span className="ea-loading" aria-label={t.KW12(loadingMsg)} />
          </div>
        </div>
      </div>
    );
  } else if (employeeAuthenticationStatus === AuthorizationStatus.AUTHORIZED && hasSystemError(authenticatedErrors)) {
    return (
      <SystemError
        errors={authenticatedErrors}
        onButtonClick={() => {
          navigate(paths.EMPLOYEE_HOME);
          dispatch(resetErrors());
        }}
      />
    );
  } else if (employeeAuthenticationStatus === AuthorizationStatus.UNAUTHORIZED && hasSystemError(anonymousErrors)) {
    return (
      <SystemError
        errors={anonymousErrors}
        onButtonClick={() => {
          dispatch(resetErrors());
          if (pathname !== paths.EMPLOYEE_HOME) {
            navigate(paths.EMPLOYEE_HOME);
          }
        }}
      />
    );
  } else if (employeeAuthenticationStatus === AuthorizationStatus.AUTHORIZED) {
    return <Outlet />;
  } else {
    return <EmployeeLoginPathV2 />;
  }
};

// TODO: remove the component EmployeeV1 after the removal of elisaIdV2 feature flag
const EmployeeV1 = () => {
  useOnBoardingId();
  return (
    <div id="employee" className="of-site--employee">
      <EmployeeHeader />
      <div className="of-employee">
        <NotificationContainer />
        <EmployeeDialog />
        <EmployeeView />
        <SnapInChat />
      </div>
    </div>
  );
};

// TODO: rename the component EmployeeV2 to Employee after the removal of elisaIdV2 feature flag
const EmployeeV2 = () => {
  useOnBoardingId();
  const { ssoSessionValid, elisaIdV2LoginProcessing, preElisaIdV2AuthLoading } = useAuth();

  const employeeAuthenticationStatus = () => {
    if (elisaIdV2LoginProcessing) {
      return undefined;
    } else {
      return ssoSessionValid ? AuthorizationStatus.AUTHORIZED : AuthorizationStatus.UNAUTHORIZED;
    }
  };

  if (preElisaIdV2AuthLoading) {
    return <Loading big={true} topPadding={4} />;
  }
  return (
    <div id="employee" className="of-site--employee">
      <EmployeeHeader />
      <div className="of-employee">
        <NotificationContainer />
        <EmployeeDialog />
        <EmployeeViewV2 employeeAuthenticationStatus={employeeAuthenticationStatus()} />
        <SnapInChat />
      </div>
    </div>
  );
};

// TODO: remove the component Employee after the removal of elisaIdV2 feature flag and call the EmployeeV2 directly
export const Employee = () => {
  const elisaIdV2 = useSelector((state: State) => state.config.featureFlags.elisaIdV2);

  if (isFeatureEnabled(elisaIdV2)) {
    return <EmployeeV2 />;
  } else {
    return <EmployeeV1 />;
  }
};
