import { AttachVakio } from '../../../../../components/AttachVakio/AttachVakio.js';
import { SubscriptionCategory } from '../../../../../common/enums.js';
import { deepEqual } from '../../../../../common/utils/objectUtils';
import { generatePath, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { getSubscriptionStatus } from '../../../../../common/utils/subscriptionUtils';
import { paths } from '../../../../../common/constants/pathVariables';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import type { MobileSubPostChangeRequestLoaderResponse } from '../../../../../common/loaders';
import type { State } from '../../../../../selfservice/common/store';

export const MobileSubAttachVakioPath = () => {
  const { companyInfo, pendingSubscriptionActions, subscription } =
    useLoaderData() as MobileSubPostChangeRequestLoaderResponse;
  const numberRanges = useSelector((state: State) => state.resources?.numberRanges, deepEqual);
  const subscriptionStatus = getSubscriptionStatus(subscription, pendingSubscriptionActions);
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    if (subscriptionStatus.pendingActions) {
      navigate(
        generatePath(paths.PS_MOBILE_SUBSCRIPTION, { subscriptionId: subscription.subscriptionDisplayId }) + search,
        {
          replace: true,
        }
      );
    }
  }, [navigate, search, subscription.subscriptionDisplayId, subscriptionStatus.pendingActions]);

  return (
    <AttachVakio
      category={SubscriptionCategory.VOICE}
      companyInfo={companyInfo}
      numberRanges={numberRanges}
      subscription={subscription}
    />
  );
};
