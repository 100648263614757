import * as CL from '@design-system/component-library';
import { Modal } from '../Modal/Modal.js';
import { gbMsg, t } from '../../common/i18n/index.js';
import { useState } from 'react';

import './SubscriptionSelectionNotification.scss';

export const SubscriptionSelectionNotification = () => {
  const [showModal, setShowModal] = useState(false);

  const roamingPopupContent = (
    <>
      <div>
        <div>
          <strong>Elisa Reissunetti 2 {t.C4YQ(gbMsg)}</strong>
        </div>
        <div>
          {t.KV0F(
            'Elisa Reissunetti 2 GB is an additional service for corporate customers’ mobile subscriptions. The service is activated automatically in the target countries and offers a fast data transfer connection using a mobile device or laptop computer at an affordable price in countries outside the EU/EEA. The service has no monthly charge. Instead, pricing is activated based on usage at €26.90 per 14 days per a 2 GB package.'
          )}
        </div>
        <div className="ds-margin-top--2">
          {t.WQRC(
            'The service includes 2 GB of data in the target countries, and it is available for 14 days or until the limit of 2 GB is reached.'
          )}
        </div>
        <div>
          <a href="https://yrityksille.elisa.fi/reissunetti" target="_blank" rel="noreferrer">
            {t.PRKE('Read more about the Reissunetti service')}
          </a>
        </div>
      </div>
      <div className="ds-margin-top--3">
        <div>
          <strong>{t.ZIH0('Elisa Reissunetti 1-1.5 GB')}</strong>
        </div>
        <div>
          {t.Q9BI(
            'Elisa Reissunetti 1–1.5 GB is an additional service for corporate customers’ mobile subscriptions. The service is activated automatically in the target countries and offers a fast data transfer connection using a mobile device or laptop computer at an affordable price in countries outside the EU/EEA. The service has no monthly charge. Instead, pricing is activated based on usage at €21.90 per 14 days per a 1–1.5 GB package.'
          )}
        </div>
        <div className="ds-margin-top--2">
          {t.XF86(
            'The service includes 1 GB or 1.5 GB of data depending on the target country, and it is available for 14 days or until the limit of 1 GB/1.5 GB is reached.'
          )}
        </div>
        <div>
          <a href="https://yrityksille.elisa.fi/reissunetti" target="_blank" rel="noreferrer">
            {t.PRKE('Read more about the Reissunetti service')}
          </a>
        </div>
      </div>
      <div className="ds-margin-top--3">
        <div>
          <strong>Elisa Reissaaja 500 {t.PZOF('MB')}</strong>
        </div>
        <div>
          {t.QA66(
            `Elisa Reissaaja is an additional service for corporate customers' mobile subscriptions. The service is activated automatically in the target countries and offers a fast data transfer connection using a mobile device or laptop computer at an affordable price in countries outside the EU/EEA. The service has no monthly charge. Instead, pricing is activated based on usage at €10.90 per 1 day per a 500 GB package.`
          )}
        </div>
        <div className="ds-margin-top--2">
          {t.BDHS(
            'The service includes 500 MB of data, and it is available for 24 hours or until the limit of 500 MB is reached. The Elisa Reissaaja service is activated when the data transfer in the network of the target country begins. Notifications concerning the use of the service will be sent to you when 80, 95 and 100 per cent of the package has been used. A new package is activated automatically.'
          )}
        </div>
        <div>
          <a href="https://yrityksille.elisa.fi/verkkovierailu-ulkomailla" target="_blank" rel="noreferrer">
            {t.XV60('Read more about the Reissaaja service')}
          </a>
        </div>
      </div>
    </>
  );

  return (
    <div className="of-subscription-selection-notification">
      {showModal && (
        <Modal
          closeable
          onClose={() => setShowModal(false)}
          size="l"
          className="of-subscription-selection-notification--modal"
        >
          {roamingPopupContent}
        </Modal>
      )}
      <div className="of-subscription-selection-notification--cards ds-margin-top--2">
        <CL.Button color="link" onClick={() => setShowModal(true)} className="text-button">
          {`* ${t.GNSO('Read more about Reissunetti and Reissaaja roaming services')}`}
        </CL.Button>
      </div>
      <div className="of-order-subscription-selection--cards ds-margin-top--2 ds-text--s">
        ** {t.U7MJ('With optimised voice, you will only pay for what you use. Limits in voice billing: ')}
        {/* Zero-width spaces so this can be broken into multiple lines */}
        <span>100/&#8203;500/&#8203;1000/&#8203;3500</span>
        {t.U7MK(' minutes.')}
      </div>
    </div>
  );
};
