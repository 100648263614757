import { EMAIL_REGEX } from '../../../common/utils/emailUtils.js';
import { HOURS_AND_MINUTES_REGEX } from '../../../common/utils/validationUtils.js';
import { InputComponent, type InputComponentProps } from '../../../common/react-hook-form/components/InputComponent.js';
import { OpenFormGridCol, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { PHONE_NUMBER_REGEX } from '../../../common/utils/phoneNumberUtils.js';
import {
  invalidIntegerMsg,
  invalidPhoneNumberMsg,
  invalidTimeMsg,
  invalidValueMsg,
  pleaseCheckEmailMsg,
  t,
} from '../../../common/i18n/index.js';
import classNames from 'classnames';

const isEmail = (required: boolean) => (value: string) =>
  required || value ? (EMAIL_REGEX.test(value) ? undefined : t.ROMH(pleaseCheckEmailMsg)) : undefined;

const isInt = (required: boolean) => (value: string) =>
  required || value ? (/^(0|-?[1-9]\d*)$/.test(value) ? undefined : t.EL7N(invalidIntegerMsg)) : undefined;

const isTel = (required: boolean) => (value: string) =>
  required || value ? (PHONE_NUMBER_REGEX.test(value) ? undefined : t.YLCX(invalidPhoneNumberMsg)) : undefined;

const isTime = (required: boolean) => (value: string) =>
  required || value ? (HOURS_AND_MINUTES_REGEX.test(value) ? undefined : t.SZBW(invalidTimeMsg)) : undefined;

const isText = (required: boolean) => (value: string) =>
  required || value ? (value.length ? undefined : t.DUPA(invalidValueMsg)) : undefined;

export const OpenFormQuestionInput = ({
  required,
  type,
  error = field => field.error?.message,
  ...props
}: Record<'type', 'email' | 'int' | 'tel' | 'time' | 'text'> & Omit<InputComponentProps, 'type' | 'validate'>) => {
  let input: JSX.Element;
  switch (type) {
    case 'email': {
      input = <InputComponent {...props} error={error} required={required} type="text" validate={isEmail(required)} />;
      break;
    }
    case 'int': {
      input = <InputComponent {...props} error={error} required={required} type="number" validate={isInt(required)} />;
      break;
    }
    case 'tel': {
      input = <InputComponent {...props} error={error} required={required} type="text" validate={isTel(required)} />;
      break;
    }
    case 'time': {
      input = <InputComponent {...props} error={error} required={required} type="time" validate={isTime(required)} />;
      break;
    }
    case 'text': {
      input = <InputComponent {...props} error={error} required={required} type="text" validate={isText(required)} />;
      break;
    }
  }
  return !input ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={6} className={classNames({ ['label--mandatory']: required })}>
        <div className="of-openform__view__input">{input}</div>
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
