import { fetchOpenFormList } from '../../../common/fetch.js';
import { getIndex } from '../OpenFormUtils.js';
import { loadingFormListMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { ListId } from '../../../common/constants/pathInterfaces.js';
import type { OpenFormListResponse } from '../../../generated/api/openFormListResponse.js';

export const useFetchFormList = ({ listId }: Readonly<Partial<ListId>>) => {
  const async = useOpenFormAsync();
  const [data, setData] = useState<OpenFormListResponse | undefined>(undefined);

  useEffect(() => {
    if (!listId) {
      return;
    }
    const { ctrl, evict } = new async(({ signal }) => fetchOpenFormList(listId, { signal }))
      .resolved(setData)
      .rejected(async.actions.error)
      .settled(list =>
        async.actions.list({
          indexes: list?.forms.map(({ id }) => [id, getIndex(async.storage.getItem(id)?.index)]),
          listId,
        })
      )
      .cache(listId)
      .execute(t.ZN5B(loadingFormListMsg));

    return () => {
      ctrl.abort();
      setData(undefined);
      evict();
    };
  }, [async, listId]);

  return data;
};
