import { ModelType } from '../../common/enums.js';
import { SubscriptionCards } from '../SubscriptionCards/SubscriptionCards.js';
import { SubscriptionSelectionNotification } from './SubscriptionSelectionNotification.js';
import { getListName } from '../SubscriptionCards/subscriptionCardsAnalyticsUtils.js';
import { isFiveGPlusOffer } from '../../common/utils/subscriptionUtils.js';
import { t } from '../../common/i18n/index.js';
import type { ModelType as OnlineModelType } from '../../common/enums.js';
import type {
  SubCardWithAnalyticsV4Props,
  SubCardWithImpressionProps,
} from '../SubscriptionCards/subscriptionCardsAnalyticsUtils.js';
import type { SubscriptionCardProps } from '../SubscriptionCard/SubscriptionCard.js';

interface VoiceSubscriptionCardsProps {
  subscriptionCards: (SubscriptionCardProps | SubCardWithAnalyticsV4Props | SubCardWithImpressionProps)[];
  onlineModelType: OnlineModelType;
  handleSubscriptionCardSelection: (subscriptionCard: SubCardWithImpressionProps) => void;
}

export const SubscriptionCardsWithFiveGPlus = ({
  subscriptionCards,
  onlineModelType,
  handleSubscriptionCardSelection,
}: VoiceSubscriptionCardsProps) => {
  const fiveGPlusCards = subscriptionCards.filter(sub => isFiveGPlusOffer(sub.selectedOffer));
  const otherCards = subscriptionCards.filter(sub => !isFiveGPlusOffer(sub.selectedOffer));
  return (
    <>
      <div className="ds-display--flex ds-justify-content--center">
        <SubscriptionCards
          buttonText={fiveGPlusCards[0]?.buttonText} // Button text is same in all cards
          listName={getListName(onlineModelType)}
          onButtonClick={subscriptionCard =>
            'offer' in subscriptionCard ? handleSubscriptionCardSelection(subscriptionCard) : undefined
          }
          subscriptions={fiveGPlusCards}
        />
      </div>
      {onlineModelType === ModelType.VoiceSME && (
        <div className="of-subscription-selection-voice__notification">
          <SubscriptionSelectionNotification />
        </div>
      )}
      <h3 className="ds-padding-top--3 of-order-subscription-selection__choose-subscription-header">
        {t.P6NV('Select a 5G subscription')}
      </h3>
      <div className="ds-display--flex ds-justify-content--center">
        <SubscriptionCards
          buttonText={otherCards[0]?.buttonText} // Button text is same in all cards
          listName={getListName(onlineModelType)}
          onButtonClick={subscriptionCard =>
            'offer' in subscriptionCard ? handleSubscriptionCardSelection(subscriptionCard) : undefined
          }
          subscriptions={otherCards}
        />
      </div>
    </>
  );
};
