import { OpenFormAsync } from './OpenFormAsync.js';
import { type OpenFormDispatch, OpenFormReducer, type OpenFormState } from './OpenFormReducer.js';
import { OpenFormStorage } from './OpenFormStorage.js';
import { type ReactNode, createContext, useContext, useEffect, useMemo } from 'react';
import { type SetURLSearchParams } from 'react-router-dom';
import { useRefReducer } from './OpenFormHooks/useRefReducer.js';
import { useURLParams } from './OpenFormHooks/useURLParams.js';

const OpenFormContextAsync = createContext(OpenFormAsync);
const OpenFormContextDispatch = createContext({} as OpenFormDispatch);
const OpenFormContextState = createContext({} as OpenFormState);
const OpenFormContextURLParams = createContext({} as [URLSearchParams, SetURLSearchParams]);

export const useOpenFormAsync = () => useContext(OpenFormContextAsync);
export const useOpenFormDispatch = () => useContext(OpenFormContextDispatch);
export const useOpenFormState = () => useContext(OpenFormContextState);
export const useOpenFormURLParams = () => useContext(OpenFormContextURLParams);

export const OpenFormProvider = ({ children }: { children?: ReactNode }) => {
  const params = useURLParams();
  const storage = useMemo(() => new OpenFormStorage(typeof sessionStorage !== 'undefined' ? sessionStorage : null), []);
  const [state, dispatch, getState] = useRefReducer(OpenFormReducer.hook(storage), OpenFormReducer.state());

  useEffect(() => {
    OpenFormAsync.actions = OpenFormReducer.actions(dispatch);
    OpenFormAsync.getState = getState;
    OpenFormAsync.storage = storage;
  }, [dispatch, getState, storage]);

  return (
    <OpenFormContextURLParams.Provider value={params}>
      <OpenFormContextState.Provider value={state}>
        <OpenFormContextDispatch.Provider value={dispatch}>
          <OpenFormContextAsync.Provider value={OpenFormAsync}>{children}</OpenFormContextAsync.Provider>
        </OpenFormContextDispatch.Provider>
      </OpenFormContextState.Provider>
    </OpenFormContextURLParams.Provider>
  );
};
