import { getAddOnPriceFromAssociation } from '../../components/SubscriptionDetails/addOnDependencyUtils.js';
import { newAddon } from './addOnUtils.js';
import type { AddOnRule } from '../types/addOnRule.js';
import type { AddOnRulesResponse } from '../../generated/api/addOnRulesResponse.js';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';

export const newAddOnRule = (association: AssociationRecord): AddOnRule => {
  const addonPrice = getAddOnPriceFromAssociation(association);

  const addOnGroup = association.cspmb__group__c;
  return {
    addOnAssociationCode: association.guid__c,
    addOnCode: association.cspmb__add_on_price_item__r.guid__c,
    addOnGroup,
    addOnId: association.cspmb__add_on_price_item__c,
    addOnProductName: association.add_on_price_item_name__c,
    commercialProductCode: association.cspmb__price_item__r.guid__c,
    price: { listPrice: addonPrice, effectivePrice: addonPrice },
    addOn: newAddon(association, addOnGroup),
  };
};

export const emptyAddOnRulesResponse: AddOnRulesResponse = { associations: [], dependencies: [] };

export const createAddOnAssociationRecordArray = (
  addOnRules: AddOnRulesResponse,
  mobilePbxAddOnRules: AddOnRulesResponse,
  nettiAddOnRules: AddOnRulesResponse,
  netti4GAddOnRules: AddOnRulesResponse
): AssociationRecord[] => {
  return [
    ...((addOnRules?.associations[0] as AssociationRecord[]) || []),
    ...((mobilePbxAddOnRules?.associations[0] as AssociationRecord[]) || []),
    ...((nettiAddOnRules?.associations[0] as AssociationRecord[]) || []),
    ...((netti4GAddOnRules?.associations[0] as AssociationRecord[]) || []),
  ];
};

export const createAddOnDependencyRecordArray = (
  addOnRules: AddOnRulesResponse,
  mobilePbxAddOnRules: AddOnRulesResponse,
  nettiAddOnRules: AddOnRulesResponse,
  netti4GAddOnRules: AddOnRulesResponse
): DependencyRecord[] => {
  return [
    ...((addOnRules?.dependencies[0] as DependencyRecord[]) || []),
    ...((mobilePbxAddOnRules?.dependencies[0] as DependencyRecord[]) || []),
    ...((nettiAddOnRules?.dependencies[0] as DependencyRecord[]) || []),
    ...((netti4GAddOnRules?.dependencies[0] as DependencyRecord[]) || []),
  ];
};
