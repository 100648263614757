import { type BillingAccount } from '../OpenFormContext.js';
import { fetchBillingAccounts } from '../../../common/fetch.js';
import { loadingBillingAccountsMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';

export const useFetchBillingAccounts = ({ sourceSystem = 'MIPA', useSearchService = true } = {}) => {
  const async = useOpenFormAsync();
  const [data, setData] = useState<BillingAccount[] | undefined>(undefined);

  useEffect(() => {
    const { ctrl } = new async(({ signal }) => fetchBillingAccounts({ sourceSystem, useSearchService }, { signal }))
      .resolved(({ searchResults }) =>
        setData(
          searchResults
            ?.map(({ result }) => result)
            .filter((result): result is BillingAccount => !!result.billingAccountId)
        )
      )
      .rejected(text => async.actions.notification({ text, type: 'error' }))
      .cache(sourceSystem, useSearchService)
      .execute(t.RCPW(loadingBillingAccountsMsg));

    return () => {
      ctrl.abort();
      setData(undefined);
    };
  }, [async, sourceSystem, useSearchService]);

  return data;
};
