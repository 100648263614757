import { EmployeeLoginPage } from '../../../../components/EmployeeLoginPage/EmployeeLoginPage.js';
import { SelfServiceMarketingBlock } from '../../../../components/SelfServiceMarketingBlock/SelfServiceMarketingBlock.js';
import { SiteContext } from '../../SiteContext.js';
import { backMsg, t } from '../../../../common/i18n/index.js';
import { fetchElisaIdAuthCode } from '../../async/fetchElisaIdAuthCode.js';
import { initiateElisaIdV2Login } from '../../../common/util/elisaIdLogin.js';
import { paths } from '../../../../common/constants/pathVariables.js';
import { processElisaIdLogin, updateElisaIdSession } from '../../../../selfservice/actions/index.js';
import { useAuth } from '../../AuthProvider.js';
import { useContext } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useElisaIdClient } from '../../../../common/hooks/useElisaIdClient.js';
import LoginForm from '@onlinefirst/elisaid-react-login-form';
import type { ProcessElisaIdLoginResponseProps } from '../../../../components/Employee/Employee.js';
import type { State } from '../../../../selfservice/common/store.js';

// TODO: remove the component EmployeeLoginPath after the removal of elisaIdV2 feature flag
export const EmployeeLoginPath = () => {
  const { anonymousUser } = useAuth();
  const resetLoginForm = anonymousUser?.resetLoginForm;
  const { siteBaseUrl, siteLanguage } = useContext(SiteContext);
  const config = useStore<State>().getState().config;
  const dispatch = useDispatch();
  const consumerSelfServiceUrl = useSelector((s: State) => s.config.consumerMobiilivarmenneUrl);
  const elisaIdClient = useElisaIdClient();

  return (
    <div id="elisaid-login-form-parent">
      <div className="of-employee__employee-portal-home">
        <LoginForm
          client={elisaIdClient}
          // Configurations
          heading={t.DZN3('Login to Employee OmaElisa')}
          disableLinks={false}
          disableMobileLogin={false}
          locale={siteLanguage.substring(0, 2)}
          recoverPasswordHeaders={{
            headerTitle: t.SBDY('Add your email address'),
            subHeaderTitle: t.QD79(`We'll email you instructions how to reset your password`),
          }}
          resetLoginForm={resetLoginForm ? resetLoginForm : false}
          returnToLoginButtonText={t.D1E9(backMsg)}
          // Links
          helpWithLoginLink={{
            title: t.FV8X('Help with login?'),
            url: `${siteBaseUrl}/info-omaelisa-tyontekijalle`,
          }}
          registerLink={{
            title: t.XV9S(`I don't have Elisa ID yet or I can't remember it.`),
            url: config.elisaId?.registrationUrl,
          }}
          recoveryLink={{
            title: t.CSK0('I cannot remember my email address'),
            url: config.elisaId?.recoveryUrl,
          }}
          consumerSelfServiceUrl={consumerSelfServiceUrl}
          companySelfServiceUrl={paths.SELF_SERVICE_HOME}
          // Functions
          getLoginAuthCode={fetchElisaIdAuthCode}
          getMobileLoginAuthCode={fetchElisaIdAuthCode}
          loginSuccess={(loginResponse: ProcessElisaIdLoginResponseProps) => {
            dispatch(updateElisaIdSession());
            dispatch(processElisaIdLogin(loginResponse.token));
          }}
        />
      </div>
      <SelfServiceMarketingBlock />
      <style id="OFI-52861_hide_chat_on_eoe_login" type="text/css">
        {'.embeddedServiceHelpButton,.embeddedServiceSidebar{display:none!important}'}
      </style>
    </div>
  );
};

export const EmployeeLoginPathV2 = () => {
  const { siteLanguage } = useContext(SiteContext);
  return (
    <div id="elisaid-login-form-parent" className="of-employee__employee-portal-home">
      <EmployeeLoginPage initiateLogin={() => initiateElisaIdV2Login(siteLanguage.substring(0, 2))} />
    </div>
  );
};
