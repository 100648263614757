import { BillingAccountDeliveryMethod } from '../../generated/api/billingAccountDeliveryMethod.js';
import { eInvoiceMsg, emailInvoiceMsg, paperInvoiceMsg, t } from '../../common/i18n/index.js';
import type { BillingAccountHeader } from '../../generated/api/billingAccountHeader.js';

interface BillingAccountHeaderInfoProps {
  billingAccountHeader: BillingAccountHeader;
}

export const BillingAccountHeaderInfo = ({ billingAccountHeader }: BillingAccountHeaderInfoProps) => {
  return (
    <>
      <span>{billingAccountHeader.payerName}</span>
      <br />
      <span>
        {billingAccountHeader.deliveryMethod === BillingAccountDeliveryMethod.EMAIL && t.NNV9(emailInvoiceMsg)}
        {billingAccountHeader.deliveryMethod === BillingAccountDeliveryMethod.ELECTRONIC && t.IK1D(eInvoiceMsg)}
        {billingAccountHeader.deliveryMethod === BillingAccountDeliveryMethod.PAPER && t.TER4(paperInvoiceMsg)}
      </span>
    </>
  );
};
