import { BillingAccountForm } from '../../../../../components/BillingAccountForm/BillingAccountForm.js';
import { BreadCrumbsWithTitle } from '../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { DetailsWrapper } from '../../../../../components/DetailsWrapper/index.js';
import {
  billingAccountsMsg,
  createNewBillingAccountMsg,
  omaElisaForCompaniesMsg,
  t,
} from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';

export const BillingAccountCreateNewPath = () => {
  const breadCrumbs = (
    <BreadCrumbsWithTitle
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.ZVMK(billingAccountsMsg), path: paths.BILLING_ACCOUNTS },
        { name: t.W4CZ(createNewBillingAccountMsg) },
      ]}
    />
  );

  return (
    <div className="of-subscriptions-scene">
      <DetailsWrapper
        classes={['of-billing-account-details']}
        detailsTop={breadCrumbs}
        id="billing-account-details"
        heading={t.W4CZ(createNewBillingAccountMsg)}
        heroPicto="billing-account-new"
      >
        <BillingAccountForm />
      </DetailsWrapper>
    </div>
  );
};
