import { useCallback, useState } from 'react';

type Column<Row> = {
  align?: 'left' | 'center' | 'right';
  key: keyof Row;
  label?: string | null;
  sortable?: boolean;
  sorted?: 'asc' | 'desc';
  visible?: boolean;
};

type Pagination = { index?: number; size?: number; total?: number };
type Refresh<Row> = (column?: Column<Row>) => void;
type SetPage = (pagination: Pagination) => void;
type SortDir<Row> = (column?: Column<Row>) => void;

class Table {
  static cols<Row>(column?: Column<Row>) {
    return column?.sortable
      ? (cols: Column<Row>[]) => cols.map(col => ({ ...col, sorted: col.key === column.key ? col.sorted : undefined }))
      : (cols: Column<Row>[]) => cols;
  }

  static page({ index, total, size }: Pagination) {
    return {
      get end() {
        return Math.min(this.total, this.index * this.size);
      },
      get index() {
        return Math.min(this.pages, Math.max(1, index || 0));
      },
      get pages() {
        return Math.ceil(this.total / this.size);
      },
      get size() {
        return size || 0;
      },
      get start() {
        return Math.max(0, this.index * this.size - this.size) + 1;
      },
      get total() {
        return total || 0;
      },
    };
  }

  static rows<Row>(column?: Column<Row>, by?: keyof Row) {
    return column?.sortable && column.key
      ? by
        ? (rows: Row[]) => rows.sort(this.compare(by, column.sorted)).sort(this.compare(column.key, column.sorted))
        : (rows: Row[]) => rows.sort(this.compare(column.key, column.sorted))
      : (rows: Row[]) => rows;
  }

  static sort<Row>(columns: Column<Row>[]): Column<Row> | undefined {
    return columns.find(({ sorted }) => !!sorted);
  }

  private static compare<Row>(key: keyof Row, sorted?: 'asc' | 'desc') {
    return sorted === 'desc'
      ? (a: Row, b: Row) => (a[key] <= b[key] ? 1 : -1)
      : (a: Row, b: Row) => (a[key] >= b[key] ? 1 : -1);
  }
}

export const useTable = <Row>({
  columns = [],
  pagination: { index, size, total } = {},
  ...props
}: {
  by?: keyof Row;
  columns?: Column<Row>[];
  pagination?: Pagination;
  rows: () => Row[];
}): [Column<Row>[], Row[], ReturnType<typeof Table.page>, Refresh<Row>, SortDir<Row>, SetPage] => {
  const [sort, setSort] = useState(Table.sort(columns));
  const [cols, setCols] = useState(Table.cols(sort)(columns));
  const [rows, setRows] = useState(Table.rows(sort, props.by)(props.rows()));
  const [page, setPage] = useState(Table.page({ index, size: size ?? rows.length, total: total ?? rows.length }));

  return [
    cols,
    rows.slice(page.start - 1, page.end),
    page,
    useCallback(
      column => {
        if ((column ??= Table.sort(cols))) {
          setSort(column);
          setCols(Table.cols(column));
          setRows(Table.rows(column, props.by)(props.rows()));
        }
      },
      [props, cols]
    ),
    useCallback(
      column => {
        if (column?.sortable && (column.sorted = column.sorted === 'desc' ? 'asc' : 'desc')) {
          setSort(column);
          setCols(Table.cols(column));
          setRows(Table.rows(column, props.by)(props.rows()));
        }
      },
      [props]
    ),
    useCallback(next => setPage(prev => Table.page({ ...prev, ...next })), []),
  ];
};
