import * as CL from '@design-system/component-library';
import { OpenFormGridRow } from './OpenFormGrid.js';

export type OpenFormLoading = {
  id: string;
  text: string;
};

export const OpenFormLoadingSpinner = ({ heading, loading }: { heading?: string; loading: Set<OpenFormLoading> }) => {
  const text = heading || (loading.values().next().value?.text as string | undefined);

  return !text ? (
    <CL.Logo className="of-openform__bar__elisa-logo" />
  ) : (
    <OpenFormGridRow className="of-openform__loader">
      <div className="of-openform__loader__spinner">
        <CL.LoadingSpinner key={text} size="m" />
      </div>
      <div className="of-openform__loader__text">
        <h4>{text}</h4>
      </div>
    </OpenFormGridRow>
  );
};
