import { CommercialProductSubType } from '../../generated/api/commercialProductSubType.js';
import { CommercialProductType } from '../../generated/api/commercialProductType.js';
import {
  deductibleMsg,
  monthlyChargesMsg,
  monthsMsg,
  noFixedTermMsg,
  oneTimePaymentMsg,
  singleFaresMsg,
  squareTradeDeductibleMsg,
  sumPerMonthMsg,
  t,
  vatPercentageMsg,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { formatSumToString } from '../../common/utils/priceUtils.js';
import {
  getShoppingBasketFromLocalStorage,
  setShoppingBasketToLocalStorage,
} from '../../selfservice/common/localStorageUtils.js';
import { isSquareTradeAddOn } from '../../common/utils/addOnUtils.js';
import type { AddOn } from '../../generated/api/addOn.js';
import type { BasketItem, ShoppingBasketType } from '../../common/types/shoppingBasket.js';
import type { CartItemContainer } from 'components/ProductDetails/ProductDetails.js';
import type { CommercialProduct } from '../../generated/api/commercialProduct.js';
import type { DiscountedPrices } from '../../generated/api/discountedPrices.js';
import type { Offer } from '../../generated/api/offer.js';
import type { OnlineModel } from '../../generated/api/onlineModel.js';
import type { Price } from '../../generated/api/price.js';
import type {
  ShoppingCartItemAddon,
  ShoppingCartPaymentOption,
  ShoppingCartPriceType,
  ShoppingCartTotalPrice,
} from '@design-system/component-library';

const emptyPrice = {
  amount: '',
  unit: '',
};

export const getCommercialProductForGuid = (guid: string, model?: OnlineModel): CommercialProduct | undefined => {
  return model?.offers
    .flatMap(offer => offer.commercialProducts)
    .find(commercialProduct => commercialProduct?.commercialProductCode === guid);
};

export const getPriceForSalesProduct = (offer?: Offer): PriceData => {
  return {
    price: {
      monthlyRecurringCharge: offer?.commercialProducts.find(cp => cp.monthlyRecurringCharge)?.monthlyRecurringCharge,
      oneTimeCharge: offer?.commercialProducts.find(cp => cp.oneTimeCharge)?.oneTimeCharge,
      payments:
        offer?.commercialProducts.find(cp => cp.billingPeriod)?.billingPeriod ||
        offer?.commercialProducts.find(cp => cp.payments)?.payments,
    },
    isDiscountedPrice: false,
  };
};

export const getPriceToDisplay = (price: Price | undefined, quantity: number): ShoppingCartPriceType => {
  if (price?.monthlyRecurringCharge) {
    return {
      amount: formatSumToString(price.monthlyRecurringCharge * quantity, true),
      unit: t.YO7F(sumPerMonthMsg, '€'),
    };
  } else if (price?.oneTimeCharge) {
    return { amount: formatSumToString(price.oneTimeCharge * quantity, true), unit: '€' };
  }
  return emptyPrice;
};

export const findDiscountedPrice = (discountedPrices: DiscountedPrices[], commercialProductCode?: string) => {
  return discountedPrices
    .flatMap(discountedPricesEntry => discountedPricesEntry.prices)
    .find(discountedPrice => discountedPrice.commercialProductCode === commercialProductCode);
};

export interface PriceData {
  isDiscountedPrice?: boolean;
  price?: Price;
}

export interface PriceDisplayData {
  isDiscountedPrice?: boolean;
  shoppingCartPrice: ShoppingCartPriceType;
}

export const getPriceForGuid = (
  guid: string,
  model: OnlineModel | undefined,
  discountedPrices: DiscountedPrices[]
): PriceData | undefined => {
  const commercialProduct = getCommercialProductForGuid(guid, model);
  if (commercialProduct) {
    const discountedPrice = findDiscountedPrice(discountedPrices, commercialProduct.commercialProductCode);
    return {
      isDiscountedPrice: !!discountedPrice,
      price: discountedPrice ?? commercialProduct.price?.listPrice,
    };
  }
  return undefined;
};

export const getPriceForGuidToDisplay = (
  guid: string,
  model: OnlineModel | undefined,
  discountedPricesForModel: DiscountedPrices | undefined,
  quantity: number
): PriceDisplayData => {
  const priceData = getPriceForGuid(guid, model, discountedPricesForModel ? [discountedPricesForModel] : []);
  return {
    isDiscountedPrice: priceData?.isDiscountedPrice || false,
    shoppingCartPrice: priceData ? getPriceToDisplay(priceData.price, quantity) : emptyPrice,
  };
};

export const calculateTotalPrices = (
  models: OnlineModel[],
  discountedPrices: DiscountedPrices[],
  basket?: ShoppingBasketType
): ShoppingCartTotalPrice[] => {
  const calculateAddonPricesOneTime = (addOns: string[], onlineModel: OnlineModel): number => {
    return addOns.reduce(
      (total, addOn) =>
        total + (onlineModel.addOns?.find(modelAddOn => modelAddOn.addOnCode === addOn)?.addOnOneTimeCharge ?? 0),
      0
    );
  };

  const calculateAddonPricesMonthly = (addOns: string[], onlineModel: OnlineModel): number =>
    addOns.reduce(
      (total, addOn) =>
        total +
        (onlineModel.addOns?.find(modelAddOn => modelAddOn.addOnCode === addOn)?.addOnMonthlyRecurringCharge ?? 0),
      0
    );

  const { monthlyRecurringCharges, onetimeCharges } = basket?.items?.reduce(
    (acc, basketItem) => {
      const onlineModelForItem = models?.find(model => model.onlineModelCode === basketItem.guid);
      const commercialProduct = getCommercialProductForGuid(basketItem.offer.commercialProductGuid, onlineModelForItem);

      const discountedPrice = findDiscountedPrice(discountedPrices, commercialProduct?.commercialProductCode);
      const price = discountedPrice ?? commercialProduct?.price?.listPrice;

      if (onlineModelForItem?.category === CommercialProductType.SALES_PRODUCT) {
        const priceData = getPriceForSalesProduct(
          onlineModelForItem.offers.find(offer => offer.offerCode === basketItem.offer.guid)
        );
        acc.monthlyRecurringCharges += (priceData.price?.monthlyRecurringCharge || 0) * basketItem.quantity;
        acc.onetimeCharges += (priceData.price?.oneTimeCharge || 0) * basketItem.quantity;
      } else {
        acc.monthlyRecurringCharges += (price?.monthlyRecurringCharge || 0) * basketItem.quantity;
        acc.onetimeCharges += (price?.oneTimeCharge || 0) * basketItem.quantity;
      }

      acc.onetimeCharges +=
        basketItem.offer.addOns && onlineModelForItem
          ? calculateAddonPricesOneTime(basketItem.offer.addOns, onlineModelForItem) * basketItem.quantity
          : 0;
      acc.monthlyRecurringCharges +=
        basketItem.offer.addOns && onlineModelForItem
          ? calculateAddonPricesMonthly(basketItem.offer.addOns, onlineModelForItem) * basketItem.quantity
          : 0;

      return acc;
    },
    { monthlyRecurringCharges: 0, onetimeCharges: 0 }
  ) || { monthlyRecurringCharges: 0, onetimeCharges: 0 };

  return [
    ...(monthlyRecurringCharges
      ? [
          {
            name: t.P6BC(monthlyChargesMsg),
            amount: formatSumToString(monthlyRecurringCharges, true),
            unit: t.YO7F(sumPerMonthMsg, '€'),
          },
        ]
      : []),
    ...(onetimeCharges
      ? [
          {
            amount: formatSumToString(onetimeCharges, true),
            name: t.GOBY(singleFaresMsg),
            unit: '€',
          },
        ]
      : []),
  ];
};

export const findOfferForCommercialProduct = (offers: Offer[], commercialProductCode?: string) =>
  offers.find(offer =>
    offer.commercialProducts?.some(
      commercialProduct => commercialProduct.commercialProductCode === commercialProductCode
    )
  );

export interface PaymentOptionData {
  id: string;
  price: Price | undefined;
  isEpp: boolean;
  selected: boolean;
}

const getPriceOptionsForGuid = (
  priceGuid: string,
  onlineModel: OnlineModel | undefined,
  isLoggedIn: boolean,
  discountedPrices: DiscountedPrices[]
): PaymentOptionData[] => {
  const commercialProduct = getCommercialProductForGuid(priceGuid, onlineModel);
  const offerForCommercialProduct = findOfferForCommercialProduct(
    onlineModel?.offers || [],
    commercialProduct?.commercialProductCode
  );
  return (
    offerForCommercialProduct?.commercialProducts
      .map(cp => ({
        id: `${cp.commercialProductCode}_${cp?.productSubType}`,
        price: findDiscountedPrice(discountedPrices, cp.commercialProductCode) || cp.price?.listPrice,
        isEpp: cp?.productSubType === CommercialProductSubType.EPP_DEVICE,
        selected: cp.commercialProductCode === priceGuid,
      }))
      // Show EPP payment options only for logged-in users
      .filter(paymentOptionData => isLoggedIn || (!isLoggedIn && !paymentOptionData.isEpp)) || []
  );
};

const getPaymentLabel = (price: Price | undefined, isEpp: boolean) => {
  if (price?.monthlyRecurringCharge) {
    return `${price.payments}${t.XXVX(monthsMsg)}, ${formatSumToString(price.monthlyRecurringCharge, true)} ${t.YO7F(
      sumPerMonthMsg,
      '€'
    )}${isEpp ? ' (EPP)' : ''}`;
  } else {
    return `${t.ASEI(oneTimePaymentMsg)} ${formatSumToString(price?.oneTimeCharge)}`;
  }
};

export const getOffer = (selectedOfferGuid: string, onlineModel: OnlineModel | undefined): Offer | undefined => {
  return onlineModel?.offers.find(offer => offer.offerCode === selectedOfferGuid);
};

export const getPaymentOptions = (
  selectedPriceGuid: string,
  onlineModel: OnlineModel | undefined,
  isLoggedIn: boolean,
  discountedPrices: DiscountedPrices[]
): ShoppingCartPaymentOption[] => {
  const priceOptions = getPriceOptionsForGuid(selectedPriceGuid, onlineModel, isLoggedIn, discountedPrices);
  return (
    priceOptions
      // Sort lowest monthly charge first
      .sort((a, b) => (a.price?.monthlyRecurringCharge || 0) - (b.price?.monthlyRecurringCharge || 0))
      // Sort EPPs first
      .sort((a, b) => Number(b.isEpp) - Number(a.isEpp))
      // Sort one time charges last
      .sort((a, b) => (a.price?.oneTimeCharge || 0) - (b.price?.oneTimeCharge || 0))
      .map(sortedPrice => ({
        id: sortedPrice.id,
        label: getPaymentLabel(sortedPrice.price, sortedPrice.isEpp) || '',
        selected: sortedPrice.selected,
      }))
  );
};

const getAddonDisclaimers = (addOn: AddOn): string[] => {
  const vatMessage = t.A0OJ(vatPercentageMsg, '0');
  if (isSquareTradeAddOn(addOn)) {
    return [`${t.VWSV(deductibleMsg)} ${squareTradeDeductibleMsg}`, t.XJMB(noFixedTermMsg), vatMessage];
  }
  return [vatMessage];
};

const getAddonPriceDisplayData = (addOn: AddOn, quantity: number) => {
  if (addOn.addOnOneTimeCharge) {
    return {
      amount: formatSumToString(addOn.addOnOneTimeCharge * quantity, true),
      unit: '€',
    };
  } else if (addOn.addOnMonthlyRecurringCharge) {
    return {
      amount: formatSumToString(addOn.addOnMonthlyRecurringCharge * quantity, true),
      unit: t.YO7F(sumPerMonthMsg, '€'),
    };
  }
  return {
    amount: '',
    unit: '',
  };
};

export const getAddonsDisplayData = (
  addOns: string[],
  model: OnlineModel,
  quantity: number
): ShoppingCartItemAddon[] => {
  return (
    model.addOns?.flatMap(modelAddOn => {
      if (addOns.includes(modelAddOn.addOnCode)) {
        return [
          {
            disclaimer: modelAddOn && getAddonDisclaimers(modelAddOn),
            id: modelAddOn?.addOnCode,
            name: modelAddOn?.addOnProductName || '',
            price: getAddonPriceDisplayData(modelAddOn, quantity),
            quantity,
          } as ShoppingCartItemAddon,
        ];
      }
      return [];
    }) || []
  );
};

export const getTotalAmount = (basketItems?: ShoppingBasketType) => {
  return basketItems?.items?.reduce((acc, curr) => acc + curr.quantity, 0) || 0;
};

const isItemsEqualInBasket = (newBasketItem: BasketItem, existingBasketItem: BasketItem) => {
  if (existingBasketItem.guid !== newBasketItem.guid) {
    return false;
  }
  return (
    deepEqual(existingBasketItem.offer, newBasketItem.offer) &&
    deepEqual(existingBasketItem.offer.addOns, newBasketItem.offer.addOns)
  );
};

const generateUniqueId = (existingItems: ShoppingBasketType): string => {
  const newId = crypto.randomUUID();
  // Probability is near zero for collision, but handle anyways..
  if (existingItems.items?.find(existingItem => existingItem.id === newId)) {
    return generateUniqueId(existingItems);
  } else {
    return newId;
  }
};

export const addToShoppingBasket = (basketItem: CartItemContainer) => {
  const fromLocalStorage = getShoppingBasketFromLocalStorage();
  const shoppingBasketJson: ShoppingBasketType = fromLocalStorage
    ? JSON.parse(fromLocalStorage)
    : {
        id: crypto.randomUUID(),
        items: [],
        vouchers: [],
      };

  const newItem: BasketItem = {
    id: generateUniqueId(shoppingBasketJson),
    guid: basketItem.onlineModel.onlineModelCode,
    name: basketItem.offer.offerName,
    quantity: basketItem.quantity,
    offer: {
      guid: basketItem.offer.offerCode,
      addOns: basketItem.selectedAddOnBundles.map(addOn => addOn.addOnCode),
      commercialProductGuid: basketItem.commercialProduct.commercialProductCode || '',
      ...(basketItem.commercialProduct.price?.effectivePrice.oneTimeCharge && {
        oneTimePriceInCents: basketItem.commercialProduct.price?.effectivePrice.oneTimeCharge,
      }),
      ...(basketItem.commercialProduct.price?.effectivePrice.monthlyRecurringCharge && {
        periodicPrice: {
          periodicPriceInCents: basketItem.commercialProduct.price?.effectivePrice.monthlyRecurringCharge,
          payments: basketItem.commercialProduct.price?.effectivePrice.payments,
          period: basketItem.commercialProduct.billingPeriod || 1, // Billing period in months, usually not available so default to 1
        },
      }),
    },
    imageUrl:
      (basketItem.offer.images && basketItem.offer.images.length > 0 && basketItem.offer.images[0]) ||
      basketItem.onlineModel.listingImage ||
      '',
    pageUrl: basketItem.onlineModel.pagePath,
  };

  const existingBasketItem = shoppingBasketJson.items?.find(existingItem =>
    isItemsEqualInBasket(newItem, existingItem)
  );
  if (existingBasketItem) {
    existingBasketItem.quantity += newItem.quantity;
  } else {
    shoppingBasketJson.items = [...(shoppingBasketJson.items || []), newItem];
  }

  setShoppingBasketToLocalStorage(JSON.stringify(shoppingBasketJson));
};

export const changeQuantityInBasket = (shoppingBasket: ShoppingBasketType, basketItemId: string, quantity: number) => {
  const basketItem = shoppingBasket.items?.find(item => item.id === basketItemId);
  if (basketItem) {
    if (quantity === 0) {
      const index = shoppingBasket.items?.findIndex(item => item.id === basketItemId);
      if (typeof index === 'number' && index >= 0) {
        shoppingBasket.items?.splice(index, 1);
      }
    } else {
      basketItem.quantity = quantity;
    }
  }

  return JSON.stringify(shoppingBasket);
};
