import * as CL from '@design-system/component-library';
import { BillingAccountSelection } from './EppRedeemBillingAccountSelection.js';
import { EppRedeemTerminateRequestType } from '../../generated/api/models.js';
import {
  billingAccountMsg,
  elisaOmaLaitelaskuMsg,
  emailMsg,
  inUseMsg,
  invoiceDeliveryMethodMsg,
  payerMsg,
  redeemableDeviceMsg,
  redemptionPriceMsg,
  t,
} from '../../common/i18n/index.js';
import { getDeviceCommitmentFields } from '../EmployeeSubscriptionDetails/eppSubscriptionUtils.js';
import { getRedeemPrice } from './EppRedeemUtils.js';
import type { Subscription } from '../../generated/api/models.js';

type RedeemOptionDetailsProps = {
  billingAccountId?: string;
  billingAccountDisplayId?: string;
  subscription: Subscription;
  selectedOption: EppRedeemTerminateRequestType;
  twoColumnView?: boolean;
  additionalFields?: CL.DescriptionItem[];
  companyName?: string;
  setBillingAccountId?: (billingAccountId: string) => void;
  setBillingAccountDisplayId?: (billingAccountDisplayId: string) => void;
};

const { COMPANY_REDEEM, EMPLOYEE_REDEEM, EMPLOYEE_REDEEM_BY_ADMIN } = EppRedeemTerminateRequestType;

export const RedeemOptionDetails = ({
  billingAccountId,
  billingAccountDisplayId,
  subscription,
  selectedOption,
  twoColumnView,
  additionalFields = [],
  companyName,
  setBillingAccountId,
  setBillingAccountDisplayId,
}: RedeemOptionDetailsProps) => {
  const omaLaiteLaskuItem =
    selectedOption === EMPLOYEE_REDEEM_BY_ADMIN
      ? { title: elisaOmaLaitelaskuMsg, description: t.V34H(inUseMsg) }
      : undefined;

  const getPayerItem = (): CL.DescriptionItem | undefined => {
    switch (selectedOption) {
      case COMPANY_REDEEM:
        return { title: t.PB6S(payerMsg), description: companyName };
      case EMPLOYEE_REDEEM_BY_ADMIN:
        return { title: t.PB6S(payerMsg), description: subscription.subscriptionContactName };
      default:
        return undefined;
    }
  };

  const invoiceDeliveryItem =
    selectedOption === EMPLOYEE_REDEEM
      ? { title: t.H272(invoiceDeliveryMethodMsg), description: t.OKYY(emailMsg) }
      : undefined;

  const getBillingAccountItem = (): CL.DescriptionItem | undefined => {
    if (selectedOption !== COMPANY_REDEEM) {
      return undefined;
    }

    return billingAccountDisplayId && billingAccountId
      ? {
          title: t.IFT9(billingAccountMsg),
          description: (
            <BillingAccountSelection
              billingAccountDisplayId={billingAccountDisplayId}
              billingAccountId={billingAccountId}
              setBillingAccountId={setBillingAccountId}
              setBillingAccountDisplayId={setBillingAccountDisplayId}
            />
          ),
        }
      : undefined;
  };

  const items: CL.DescriptionItem[] = [
    { title: t.UDSQ(redeemableDeviceMsg), description: subscription.subscriptionName },
    ...getDeviceCommitmentFields(subscription),
    {
      title: t.MH8K(redemptionPriceMsg),
      description: getRedeemPrice(selectedOption, subscription.details!.device!.eppModificationPrices!),
    },
    omaLaiteLaskuItem,
    getPayerItem(),
    invoiceDeliveryItem,
    getBillingAccountItem(),
    ...additionalFields,
  ].filter((item): item is CL.DescriptionItem => Boolean(item?.description));

  return <CL.Description className="ds-margin--0" columns={twoColumnView ? 2 : 1} items={items} />;
};
