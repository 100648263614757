import '@design-system/component-library/src/main.css';
import { AnalyticsProvider } from './AnalyticsProvider.js';
import { AuthProvider } from './AuthProvider.js';
import { CookieConsentWrapper } from './components/CookieConsentWrapper.js';
import { Footer } from '../../components/Footer/Footer.js';
import { Locale, getLocale, setLocale, setSiteLocale } from '../../common/i18n/index.js';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SUPPORTED_SITE_LANGUAGES } from './siteUtils.js';
import { ScrollToTop } from '../../common/utils/browserUtils.js';
import { SiteContext } from './SiteContext.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { initializeActiveAccountChangeListener, initializeShoppingCart } from '../../selfservice/init.js';
import { isStrictFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHeadJsonLd } from '../../common/hooks/useHeadJsonLd.js';
import { useLegacyStyles } from '../../common/hooks/useLegacyStyles.js';
import type { State } from '../../selfservice/exports.js';

export const SiteFn = () => {
  const dispatch = useDispatch();
  const store = useStore<State>();
  const layoutState = useSelector((state: State) => state.layout, deepEqual);
  const [siteLanguage, setSiteLanguage] = useState(getLocale());
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const featureFlags = useSelector((s: State) => s.config.featureFlags, deepEqual);

  const saveUserPreferredLocale = (locale: Locale) => {
    setLocale(locale);
    setSiteLanguage(locale);
  };

  /**
   * Since CMS content is NOT localized use localization only on these paths.
   * Everything else is shown as Locale.FI. Separate handling on /sv and /en paths.
   * Note, language selection is still stored in localstorage.
   */
  useEffect(() => {
    const localizedPaths = [
      paths.ANONYMOUS_FIXED_BROADBAND_ORDER,
      paths.ANONYMOUS_MOBILE_BROADBAND,
      paths.ANONYMOUS_MOBILE_VOICE,
      paths.AUTHENTICATION_EXT,
      paths.CONTACT,
      paths.CONTACT_FORM,
      paths.CONTACT_INFO,
      paths.DEVICE_CHECKOUT,
      paths.EMPLOYEE_HOME,
      paths.PUNCHOUT_HOME,
      paths.REGISTER_PAGE,
      paths.SELF_SERVICE_HOME,
      paths.SHOPPING_CART,
      paths.NEW_SHOPPING_BASKET,
      paths.TURBONAPPI,
    ];

    const getLocaleBasedOnPath = (path: string): { siteLanguage: Locale; content: Locale } | undefined => {
      if (path === '/sv' || path.startsWith('/sv/')) {
        return { siteLanguage: Locale.SV, content: Locale.SV };
      } else if (path === '/en' || path.startsWith('/en/')) {
        return { siteLanguage: Locale.EN, content: Locale.EN };
      } else if (localizedPaths.some(localizedPath => decodeURI(path).startsWith(localizedPath))) {
        // For localizedPaths we want to honor the selected language, but override Swedish, since we don't support it.
        if (siteLanguage === Locale.SV) {
          return { siteLanguage: Locale.FI, content: Locale.FI };
        } else {
          return undefined;
        }
      } else if (siteLanguage !== Locale.FI) {
        return { siteLanguage: Locale.FI, content: Locale.FI };
      }
      return undefined;
    };

    const locale = getLocaleBasedOnPath(pathname);
    if (locale) {
      setSiteLanguage(locale.siteLanguage);
      setSiteLocale(locale.content);
    }
  }, [pathname, siteLanguage]);

  type Language = 'fi' | 'en' | 'sv';
  const saveUserPreferredLanguage = (language: Language) => {
    saveUserPreferredLocale(SUPPORTED_SITE_LANGUAGES[language]);
  };

  const switchPreferredLanguage = (language: Language) => {
    if (language === 'fi') {
      navigate('/');
    } else {
      navigate(`/${language}`);
    }
    saveUserPreferredLanguage(language);
  };

  useEffect(() => {
    initializeActiveAccountChangeListener(dispatch);
  }, [dispatch]);

  useEffect(() => {
    initializeShoppingCart(store);
  }, [store]);

  const isOverrideOn = useLegacyStyles();
  useHeadJsonLd();

  return (
    <SiteContext.Provider
      value={{
        switchPreferredLanguage,
        saveUserPreferredLanguage,
        saveUserPreferredLocale,
        siteBaseUrl: store.getState().config.siteBaseUrl,
        siteLanguage,
      }}
    >
      <AuthProvider>
        <AnalyticsProvider>
          <main className={isOverrideOn ? 'override-enabled' : ''}>
            <ScrollToTop />
            <CookieConsentWrapper />
            <div className="of-site">
              <Outlet key={siteLanguage} /* TODO: key needs to be removed when the i18n is cleaned up. */ />
            </div>
          </main>
          {!layoutState?.hideHeaderFooter && (
            <Footer isMaintenanceModeForCmsPages={isStrictFeatureEnabled(featureFlags.isMaintenanceModeForCmsPages)} />
          )}
        </AnalyticsProvider>
      </AuthProvider>
    </SiteContext.Provider>
  );
};
