import { BreadCrumbsWithTitle } from '../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { SubscriptionCategory } from '../../../../../common/enums.js';
import { SubscriptionLayout } from '../../../../../components/SubscriptionLayout/SubscriptionLayout.js';
import { SubscriptionService } from '../../../../../components/Subscription/SubscriptionService.js';
import { omaElisaForCompaniesMsg, servicesMsg, t } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useLoaderData, useParams } from 'react-router-dom';
import type { Subscription } from '../../../../../generated/api/subscription.js';
import type { SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const ServicePath = () => {
  const { subscriptionId } = useParams<SubscriptionId>();
  const subscription = useLoaderData() as Subscription;

  const breadCrumbs = (
    <BreadCrumbsWithTitle
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.MBG9(servicesMsg), path: paths.PS_SERVICES },
        { name: subscriptionId || '' },
      ]}
    />
  );

  return (
    <SubscriptionLayout>
      <SubscriptionService
        breadCrumbs={breadCrumbs}
        category={SubscriptionCategory.SERVICE}
        subscription={subscription}
      />
    </SubscriptionLayout>
  );
};
