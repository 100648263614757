import { Grid } from '../Grid/Grid.js';
import { InvoiceDisclaimer } from '../InvoiceDisclaimer/InvoiceDisclaimer.js';
import { OpenCasesNotification } from '../OpenCasesNotification/OpenCasesNotification.js';
import type { SupportCaseHeader } from '../../generated/api/models.js';

export interface SupportCaseNotificationProps {
  openSupportCases?: SupportCaseHeader[];
}

export const SupportCaseNotification = ({ openSupportCases = [] }: SupportCaseNotificationProps) => {
  return (
    <Grid>
      <InvoiceDisclaimer className="ds-margin-top--4" />
      {openSupportCases.length > 0 && <OpenCasesNotification supportCases={openSupportCases} />}
    </Grid>
  );
};
