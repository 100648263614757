import * as CL from '@design-system/component-library';
import { ChangeBillingAccountDialog } from '../ChangeBillingAccountDialog/ChangeBillingAccountDialog.js';
import { Link, generatePath, useLocation } from 'react-router-dom';
import { changeMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useState } from 'react';

type BillingAccountSelectionProps = {
  billingAccountDisplayId: string;
  billingAccountId: string;
  setBillingAccountId?: (billingAccountId: string) => void;
  setBillingAccountDisplayId?: (billingAccountDisplayId: string) => void;
};

export const BillingAccountSelection = ({
  billingAccountDisplayId,
  billingAccountId,
  setBillingAccountId,
  setBillingAccountDisplayId,
}: BillingAccountSelectionProps): JSX.Element => {
  const { search } = useLocation();
  const billingAccountPath = generatePath(paths.BILLING_ACCOUNT, { billingAccountId: billingAccountDisplayId });
  const [displayChangeBillingAccountDialog, setDisplayChangeBillingAccountDialog] = useState(false);

  return (
    <span>
      {displayChangeBillingAccountDialog && (
        <ChangeBillingAccountDialog
          billingAccountId={billingAccountId}
          headerText={t.H41V('Change invoicing agreement')}
          description=""
          detailedView={true}
          displayAddNewOption={false}
          searchable={true}
          onCloseDialog={() => {
            setDisplayChangeBillingAccountDialog(false);
          }}
          onSubmit={(updatedBillingAccountId: string, updatedBillingAccountDisplayId: string) => {
            if (updatedBillingAccountId !== billingAccountId && setBillingAccountId && setBillingAccountDisplayId) {
              setBillingAccountId(updatedBillingAccountId);
              setBillingAccountDisplayId(updatedBillingAccountDisplayId);
            }
            setDisplayChangeBillingAccountDialog(false);
          }}
        />
      )}
      <Link to={billingAccountPath + search}>{billingAccountDisplayId}</Link>
      <span>
        <span className="of-vertical-bar"> | </span>
        <CL.Button
          color="light"
          className="of-subscription-details__change-billing-account"
          onClick={() => {
            setDisplayChangeBillingAccountDialog(true);
          }}
          type="button"
        >
          {t.SEER(changeMsg)}
        </CL.Button>
      </span>
    </span>
  );
};
