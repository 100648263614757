import { SubscriptionAddOnGroup } from '../../../../common/enums.js';
import {
  createCommercialProductAddOnVisibilitiesMap,
  createReducingGroupRenamer,
} from '../../../../common/utils/addOnVisibilityUtils.js';
import { fetchNewSalesAddOnsVisibilities } from '../../../../common/fetch.js';
import { newAddOnRule } from '../../../../common/utils/addOnRulesUtils.js';
import type { AddOn } from '../../../../generated/api/addOn.js';
import type { AddOnRule } from '../../../../common/types/addOnRule.js';
import type { AddOnRulesResponse } from '../../../../generated/api/addOnRulesResponse.js';
import type { AddOnVisibilitiesWithCommercialProductCode } from '../../../../common/utils/addOnVisibilityUtils.js';
import type { AddOnVisibility } from '../../../../generated/api/addOnVisibility.js';
import type { OnlineModel } from '../../../../generated/api/onlineModel.js';

export const IP_ADDRESS_GROUP_NAME = 'IP-osoite';
export const IP_ADDRESS_GROUPS = [SubscriptionAddOnGroup.PUBLIC_IP, SubscriptionAddOnGroup.FIXED_PUBLIC_IP];

export const createPreLoadedAddOnRules = (
  fixeBBAddOnRules: AddOnRulesResponse,
  mobileAddOnRules: AddOnRulesResponse
) => {
  return [
    ...(fixeBBAddOnRules?.associations?.[0]?.map(newAddOnRule) ?? []),
    ...(mobileAddOnRules?.associations?.[0].map(newAddOnRule) ?? []),
  ];
};

export const resolveSelectedCommercialProductCode = (
  offerCode?: string,
  fixedBroadbandOnlineModel?: OnlineModel
): string | undefined => {
  if (!offerCode || !fixedBroadbandOnlineModel) {
    return undefined;
  }

  const matchingOffer = fixedBroadbandOnlineModel?.offers?.find(offer => offer.offerCode === offerCode);

  return matchingOffer?.commercialProducts[0]?.commercialProductCode;
};

const reducedGroupToAddOnGroups = {
  [IP_ADDRESS_GROUP_NAME]: IP_ADDRESS_GROUPS,
};

const groupRenamerFn = createReducingGroupRenamer(reducedGroupToAddOnGroups);

export const isIpAddressGroupAddOn = (group?: string) => IP_ADDRESS_GROUPS.some(ipGroup => ipGroup === group);

const mapAddOnRuleToAddOn = (addOnRule?: AddOnRule): AddOn | undefined =>
  addOnRule
    ? {
        addOnId: addOnRule.addOnId,
        addOnOneTimeCharge: addOnRule.price.effectivePrice.oneTimeCharge,
        addOnMonthlyRecurringCharge: addOnRule.price.effectivePrice.monthlyRecurringCharge,
        addOnCode: addOnRule.addOnCode,
        price: addOnRule.price,
        addOnType: 'SERVICE',
        addOnGroup: addOnRule.addOnGroup,
        addOnProductName: addOnRule.addOnProductName,
      }
    : undefined;

const updateAddOnVisibilityWithAddOn = (
  addOnRules: AddOnRule[],
  commercialProductCode: string,
  addOnVisibilities: AddOnVisibility[]
): AddOnVisibility[] => {
  return addOnVisibilities
    .map(visibility => {
      const foundRule = addOnRules.find(
        addOnRule =>
          addOnRule.commercialProductCode === commercialProductCode && addOnRule.addOnCode === visibility.addOnGuidCode
      );
      return {
        ...visibility,
        addOn: mapAddOnRuleToAddOn(foundRule),
      };
    })
    .filter(visibility => visibility.addOn !== undefined);
};

export const getNewAddOnVisibilities = async (commercialProductCode: string, addOnRules: AddOnRule[]) => {
  const { addOnVisibilities } = await fetchNewSalesAddOnsVisibilities(commercialProductCode);
  const addOnVisibilitiesWithCommercialProductCode: AddOnVisibilitiesWithCommercialProductCode = {
    commercialProductCode,
    addOnVisibilities: addOnVisibilities ?? [],
  };

  const addOnVisibilitiesMapForCp = createCommercialProductAddOnVisibilitiesMap(
    [addOnVisibilitiesWithCommercialProductCode],
    addOnRules,
    groupRenamerFn
  );
  const visibilitiesWithAddOns = updateAddOnVisibilityWithAddOn(
    addOnRules,
    commercialProductCode,
    addOnVisibilities || []
  );

  return {
    addOnVisibilities: visibilitiesWithAddOns,
    commercialProductAddOnVisibilitiesMap: addOnVisibilitiesMapForCp,
  };
};
