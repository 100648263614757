import { ModelType, SfToggles } from '../../common/enums.js';
import { SalesType } from '../../generated/api/models.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getCommercialProductPrices } from '../../common/utils/commercialProductUtils.js';
import { getLegacyOffersContentBase } from './content/CommercialProductContent.js';
import {
  getMobileBroadbandSubscriptionCardContents,
  getMobileM2MCardContents4G,
  getMobileM2MCardContents5G,
  getVoiceSubscriptionCardContents,
} from './content/SubscriptionCardContent.js';
import { getOfferCampaignAssociation } from '../../common/utils/campaignUtils.js';
import { getProductPriceText } from './CampaignProductCard.js';
import { selectSubscriptionMsg, t } from '../../common/i18n/index.js';
import type { AddedAddon } from '../../common/types/addon.js';
import type {
  CampaignAssociation,
  CampaignContext,
  CommercialProduct,
  Offer,
  OnlineModel,
} from '../../generated/api/models.js';
import type { LegacyProductContent } from './content/CommercialProductContent.js';
import type { OfferCard } from './OrderSubscriptionSelection.js';
import type { ReactNode } from 'react';
import type { SubscriptionCardProps } from '../SubscriptionCard/SubscriptionCard.js';

export const getPricesWithAddons = (
  product: CommercialProduct,
  amount: number,
  addOns: AddedAddon[],
  campaignAssociation?: CampaignAssociation,
  salesType?: SalesType
): { oneTimePrice: number; recurringPrice: number } => {
  const addOnOneTimePrices = addOns.reduce((sum, addOn) => (addOn.oneTimePrice || 0) + sum, 0);
  const addOnMonthlyPrices = addOns.reduce((sum, addOn) => (addOn.monthlyPrice || 0) + sum, 0);
  const { oneTimeCharge, monthlyRecurringCharge } = getCommercialProductPrices(product, campaignAssociation, salesType);

  const oneTimePrice = ((oneTimeCharge || 0) + addOnOneTimePrices) * amount;
  const recurringPrice = ((monthlyRecurringCharge || 0) + addOnMonthlyPrices) * amount;
  return { oneTimePrice, recurringPrice };
};

export const getFormattedPricesWithAddons = (
  product: CommercialProduct,
  amount: number,
  addOns: AddedAddon[],
  campaignAssociation?: CampaignAssociation,
  salesType?: SalesType
): { oneTimePrice: string; recurringPrice: string } => {
  // TODO: A bit nasty to "|| ''", would probably need to change "formatSum(...)" so that it always returns a string
  const { oneTimePrice, recurringPrice } = getPricesWithAddons(product, amount, addOns, campaignAssociation, salesType);
  return { oneTimePrice: formatSum(oneTimePrice) || '', recurringPrice: formatSum(recurringPrice) || '' };
};

export const getOffer = (offers: Offer[], offerCode?: string): Offer => {
  const offer = offers.find(o => o.offerCode === offerCode);
  if (!offer) {
    throw new Error(`Could not find offer with offerCode: ${offerCode}`);
  }
  return offer;
};

export const isMobileBroadband = (onlineModel?: OnlineModel) =>
  onlineModel?.onlineModelCode === ModelType.MobileBroadband;

export const getLegacyOfferCodes = (modelType: ModelType, offers: Offer[]): string[] =>
  getLegacyOffersContentBase()
    .filter(productContent => productContent.modelType === modelType)
    .flatMap(productContent => productContent.offerCode)
    .filter(offerCode => offers.some(offer => offer.offerCode === offerCode));

const getLegacyOfferContentWithoutPrice = (offer: Offer, modelType: ModelType): LegacyProductContent => {
  const offerCode = offer.offerCode;
  const legacyContentBase = getLegacyOffersContentBase().find(
    content => content.offerCode === offerCode && content.modelType === modelType
  );

  if (!legacyContentBase) {
    throw new Error(`Could not find 4G content with offer code: ${offerCode}`);
  }

  const commercialProduct = offer.commercialProducts[0];
  const openingFee = commercialProduct.price?.effectivePrice.oneTimeCharge || commercialProduct.oneTimeCharge;
  return {
    ...legacyContentBase,
    title: offer.offerName,
    openingFee,
  };
};

export const getLegacyProductContents = (
  salesType: SalesType,
  campaignContexts: CampaignContext[],
  modelType: ModelType,
  offers: Offer[],
  voucher?: string
): LegacyProductContent[] | undefined => {
  if (offers.length === 0) {
    return undefined;
  }
  const legacyOfferCodes = getLegacyOfferCodes(modelType, offers);
  const legacyOffers: Offer[] = legacyOfferCodes.map(offerCode => getOffer(offers, offerCode));
  return legacyOffers.map(offer => {
    const campaignAssociation = getOfferCampaignAssociation(
      offer,
      salesType,
      campaignContexts,
      voucher
    )?.campaignAssociation;
    const commercialProduct = offer.commercialProducts[0];
    const isChangeOffer = salesType === SalesType.CHANGE_OFFER;
    const contentWithoutPrice = getLegacyOfferContentWithoutPrice(offer, modelType);
    const priceTexts = getProductPriceText(commercialProduct, false, campaignAssociation, isChangeOffer);
    return {
      ...contentWithoutPrice,
      campaignAssociation,
      priceTexts,
    };
  });
};

export const isFixedPeriodActive = (
  isChangeOffer: boolean,
  offerCardContents: OfferCard[],
  subscriptionCardContents: SubscriptionCardProps[]
) => isChangeOffer && offerCardContents.length === 0 && subscriptionCardContents.length === 0;

export const isMbbModel = (modelType: ModelType) => modelType === ModelType.MobileBroadband;

export const isVoiceModel = (modelType: ModelType) => modelType === ModelType.Voice || modelType === ModelType.VoiceSME;

export const getSubscriptionCardStaticContent = (
  modelType: ModelType,
  isChangeOffer?: boolean
): SubscriptionCardProps[] => {
  if (modelType === ModelType.VoiceSME) {
    return getVoiceSubscriptionCardContents(false, isChangeOffer);
  } else if (modelType === ModelType.MobileBroadband) {
    return getMobileBroadbandSubscriptionCardContents(false, isChangeOffer);
  } else if (modelType === ModelType.MobileM2M) {
    return [...getMobileM2MCardContents4G(isChangeOffer), ...getMobileM2MCardContents5G(isChangeOffer)];
  }
  return [];
};

export const showYritysliittymaOldOffers = (modelType: ModelType, toggles: string[] = []) =>
  isVoiceModel(modelType) && toggles.includes(SfToggles.SHOW_YRITYSLIITTYMA_OLD);

export const showYrityspakettiOffers = (modelType: ModelType, toggles: string[] = []) =>
  isVoiceModel(modelType) && toggles.includes(SfToggles.SHOW_YRITYSPAKETTI);

const getVisibleOffers = (offers?: Offer[], commercialProductCode?: string) =>
  offers?.filter(off => off.commercialProducts?.[0].commercialProductCode !== commercialProductCode) || [];

const getMbbOffers = (
  onlineModels: OnlineModel[],
  onlineModelType: ModelType,
  commercialProductCode?: string,
  toggles: string[] = []
) => {
  if (!toggles.includes(SfToggles.SHOW_YRITYSDATA_4G)) {
    return [];
  }
  const mbbModel = onlineModels.find(model => model.onlineModelCode === onlineModelType);
  return getVisibleOffers(mbbModel?.offers, commercialProductCode);
};

export const getLegacyOffersForDropdownDisplay = (
  fixedPeriodActiveFlag: boolean,
  onlineModelType: ModelType,
  onlineModels: OnlineModel[],
  commercialProductCode?: string,
  toggles?: string[]
): Offer[] => {
  if (fixedPeriodActiveFlag) {
    return [];
  }

  if (isMbbModel(onlineModelType)) {
    return getMbbOffers(onlineModels, onlineModelType, commercialProductCode, toggles);
  }

  const oldYritysliittymaModel = showYritysliittymaOldOffers(onlineModelType, toggles || [])
    ? onlineModels.find(model => model.onlineModelCode === ModelType.Voice)
    : undefined;
  const yrityspakettiModel = showYrityspakettiOffers(onlineModelType, toggles || [])
    ? onlineModels.find(model => model.onlineModelCode === ModelType.Yrityspaketti)
    : undefined;

  return [
    ...getVisibleOffers(oldYritysliittymaModel?.offers, commercialProductCode),
    ...getVisibleOffers(yrityspakettiModel?.offers, commercialProductCode),
  ];
};

const FiveGInfoHeader = ({ headerText }: { headerText: string }) => (
  <>
    {headerText}
    <h4>
      {t.D61P('The 5G+ network enables more consistent connections and up to 20% better battery life for the device')}
    </h4>
  </>
);

export const getSubscriptionSelectionMessage = (modelType: ModelType): ReactNode => {
  switch (modelType) {
    case ModelType.MobileBroadband:
      return <FiveGInfoHeader headerText={t.GG1D('Select a 5G+ mobile broadband subscription')} />;
    case ModelType.VoiceSME:
      return <FiveGInfoHeader headerText={t.GG1D('Select a 5G+ voice subscription')} />;
    default:
      return t.V16Q(selectSubscriptionMsg);
  }
};

export const hideNewSubscriptionCards = (toggles?: string[], onlineModelType?: ModelType): boolean =>
  Boolean(
    (toggles?.includes(SfToggles.HIDE_YRITYSLIITTYMA) || toggles?.includes(SfToggles.HIDE_YRITYSLIITTYMA_NEW)) &&
      onlineModelType === ModelType.VoiceSME
  );
