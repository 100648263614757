import { EmptyOrError, getNotFoundError } from '../../../../components/EmptyOrError/index.js';
import { FormStateEnum } from '../../../../common/formik/utils.js';
import { Loading } from '../../../../components/Loading/index.js';
import { ModelType } from '../../../../common/enums.js';
import { NettiLiteOffers } from '../../../../components/NettiLiteOffers/NettiLiteOffers.js';
import { OnlineModelCategory } from '../../../../generated/api/onlineModelCategory.js';
import { applyWizardEvent, getCommercialAvailability } from '../../../../selfservice/actions/index.js';
import { paths } from '../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useNavigate, useRouteLoaderData } from 'react-router-dom';
import type { FixedBBRootLoaderData } from 'common/loaders.js';
import type { OnlineModel } from '../../../../generated/api/onlineModel.js';
import type { State } from '../../../../selfservice/common/store.js';

export const FixedBroadbandOffersPath = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { onlineModels } = useRouteLoaderData('fixedBBRoot') as FixedBBRootLoaderData;
  const { commercialAvailabilityResult, commercialAvailabilityState } = useSelector((s: State) => ({
    commercialAvailabilityResult: s?.resources?.commercialAvailabilityResult,
    commercialAvailabilityState: s?.resources?.commercialAvailabilityState,
  }));
  const { state } = useLocation();

  const fixedBroadbandOnlineModel = onlineModels?.find(
    model => model.category === OnlineModelCategory.FIXED_BROADBAND && model.onlineModelCode !== ModelType.Yritystupla
  );

  const filteredOffers =
    fixedBroadbandOnlineModel?.offers.filter(offer =>
      commercialAvailabilityResult?.offerCodes.includes(offer.offerCode)
    ) || [];
  const filteredOnlineModel = { ...fixedBroadbandOnlineModel, offers: filteredOffers } as OnlineModel;

  useEffect(() => {
    if (
      !commercialAvailabilityResult &&
      commercialAvailabilityState !== FormStateEnum.LOADING &&
      !!state?.searchAddress &&
      state?.postalCode
    ) {
      dispatch(
        getCommercialAvailability({
          address: state?.searchAddress,
          postalCode: state?.postalCode,
          subscriptionType: OnlineModelCategory.FIXED_BROADBAND,
        })
      );
    }
  }, [dispatch, commercialAvailabilityResult, commercialAvailabilityState, state?.searchAddress, state?.postalCode]);

  useEffect(() => {
    dispatch(applyWizardEvent(1, 'FIXED_BROADBAND_ORDER'));
  }, [dispatch]);

  if (commercialAvailabilityState === FormStateEnum.LOADING) {
    return <Loading />;
  }

  if (!state?.previousUrl) {
    return (
      <EmptyOrError {...getNotFoundError()} onButtonClick={() => navigate(paths.ANONYMOUS_FIXED_BROADBAND_ORDER)} />
    );
  }

  if (!fixedBroadbandOnlineModel) {
    return null;
  }

  return (
    <NettiLiteOffers
      fixedBroadBandOnlineModels={filteredOnlineModel}
      mobileBroadBandOnlineModels={fixedBroadbandOnlineModel!}
      availabilityAddress={state?.searchAddress}
      postalCode={state?.postalCode}
    />
  );
};
