import * as CL from '@design-system/component-library';
import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { ContactsList } from '../../../../../components/ContactsList/ContactsList.js';
import { Grid } from '../../../../../components/Grid/Grid.js';
import {
  contactManagementInstructionsMsg,
  dataSourcesForContactDetailsMsg,
  omaElisaClassicSwitchMsg,
  t,
} from '../../../../../common/i18n/index.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useAuth } from '../../../AuthProvider.js';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams.js';
import { useSelector } from 'react-redux';
import type { ConfigState } from '../../../../../common/types/states.js';
import type { ContactsResponse } from '../../../../../generated/api/contactsResponse.js';
import type { DefaultListSearchParams } from '../../../../../components/Table/index.js';
import type { State } from '../../../../../selfservice/common/store.js';

import './CompanyInfoContactsPath.scss';

export const CompanyInfoContactsPath = () => {
  const config: ConfigState = useSelector((state: State) => state.config, deepEqual);
  const navigate = useNavigate();
  const contacts = useLoaderData() as ContactsResponse;
  const { search } = useSearchParams<DefaultListSearchParams>();
  const { authenticatedUser } = useAuth();

  return (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.CONTACTS}>
      <Grid>
        <div className="of-company-info-contacts-path">
          <div className="of-company-info-contacts-path__notification">
            <CL.NotificationBanner
              className="ds-text--m"
              text={t.VYFI(
                'Adding and deleting OmaElisa administrators is currently only possible in OmaElisa Classic. With your Elisa ID, you can log into OmaElisa Classic, OmaElisa for Companies and some Elisa Ring services (e.g. web-admin and file archive).'
              )}
              icon={<CL.Icon icon="information" size="m" type="regular" color="blue-600" />}
              link={{
                text: t.DZMK(omaElisaClassicSwitchMsg),
                href: `${config.classicSiteUrl}/kayttajat`,
              }}
            />
          </div>

          <div className="of-company-info-contacts-path__headline-and-button">
            <div className="ds-color--neu tral-500 of-company-info-contacts-path__instructions">
              <CL.Link
                className="ds-font-size--medium"
                linkTarget="_blank"
                linkHref="https://yrityksille.elisa.fi/ohjeet/omaelisa-kayttajahallinta"
              >
                {t.D8IX(contactManagementInstructionsMsg)}{' '}
                <CL.Icon icon="external-link" size="s" type="regular" color="blue-600" />
              </CL.Link>
            </div>
            <div className="of-company-info-contacts-path__source-table">
              <div className="ds-text--s ds-color--neu tral-500 of-company-info-contacts-path__source">
                {t.U3UL(dataSourcesForContactDetailsMsg)}
              </div>{' '}
              <div className="of-company-info-contacts-path__button">
                <CL.Button
                  className="of-company-info-contacts-path__button"
                  onClick={() => navigate(paths.COMPANY_INFO_CONTACTS_NEW)}
                >
                  {t.QQSJ('Add new user')}
                </CL.Button>
              </div>
            </div>
          </div>
          <ContactsList
            contacts={contacts?.searchResults || []}
            total={contacts?.total || 0}
            searchInput={search}
            authenticatedUser={authenticatedUser}
          />
        </div>
      </Grid>
    </CompanyInfoContainer>
  );
};
