import * as CL from '@design-system/component-library';
import { AuxiliaryEsimOrderError } from './views/AuxiliaryEsimOrderError.js';
import { AuxiliaryEsimOrderLogin } from './views/AuxiliaryEsimOrderLogin.js';
import { AuxiliaryEsimOrderManage } from './views/AuxiliaryEsimOrderManage.js';
import { AuxiliaryEsimOrderPhaseEnum } from './auxiliaryEsimOrderEnums.js';
import { AuxiliaryEsimOrderProductDetails } from './views/AuxiliaryEsimOrderProductDetails.js';
import { AuxiliaryEsimOrderProductSelection } from './views/AuxiliaryEsimOrderProductSelection.js';
import { AuxiliaryEsimOrderProgress } from './views/AuxiliaryEsimOrderProgress.js';
import { AuxiliaryEsimOrderSuccess } from './views/AuxiliaryEsimOrderSuccess.js';
import { SmallHeader } from '../SmallHeader/SmallHeader.js';
import { closeAppleWebsheet, updateAppleDataPlanAccountWithInfo } from './AuxiliaryEsimOrderUtils.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { processingOrderMsg, t } from '../../common/i18n/index.js';
import { setFooterVisibility } from '../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useLayoutEffect } from 'react';
import type { AuxiliaryEsimOrderLoginProps } from './views/AuxiliaryEsimOrderLogin.js';
import type { State } from '../../selfservice/common/store.js';

import './AuxiliaryEsimOrderScene.scss';

export const AuxiliaryEsimOrderContent = (props: AuxiliaryEsimOrderLoginProps) => {
  const dispatch = useDispatch();
  const phase = useSelector(
    (state: State) => state.selfservice?.auxiliaryEsimOrder?.phase || AuxiliaryEsimOrderPhaseEnum.LOGIN
  );

  // Only run the layout effect action once
  useLayoutEffect(() => {
    dispatch(setFooterVisibility(false));
  }, [dispatch]);

  const views = {
    [AuxiliaryEsimOrderPhaseEnum.VALIDATE]: (
      <AuxiliaryEsimOrderProgress
        header={t.L1YX('Validating KelloSIM eligibility')}
        ingress={t.MQ9Q('Please wait for a moment.')}
      />
    ),
    [AuxiliaryEsimOrderPhaseEnum.TYPE]: <AuxiliaryEsimOrderProductSelection />,
    [AuxiliaryEsimOrderPhaseEnum.DETAILS]: <AuxiliaryEsimOrderProductDetails />,
    [AuxiliaryEsimOrderPhaseEnum.PROGRESS]: (
      <AuxiliaryEsimOrderProgress header={t.RW6O(processingOrderMsg)} ingress={t.MQ9Q('Please wait for a moment.')} />
    ),
    [AuxiliaryEsimOrderPhaseEnum.SUCCESS]: <AuxiliaryEsimOrderSuccess onClose={closeAppleWebsheet} />,
    [AuxiliaryEsimOrderPhaseEnum.FAILED]: <AuxiliaryEsimOrderError onClose={closeAppleWebsheet} />,
    [AuxiliaryEsimOrderPhaseEnum.LOGIN]: <AuxiliaryEsimOrderLogin {...props} />,
    [AuxiliaryEsimOrderPhaseEnum.MANAGE]: <AuxiliaryEsimOrderManage onClose={closeAppleWebsheet} />,
  };
  const sceneElement = views[phase] || views[AuxiliaryEsimOrderPhaseEnum.LOGIN];

  return (
    <div className={`of-auxiliary-esim-order-scene of-auxiliary-esim-order-scene_${phase}`}>
      <SmallHeader />
      <CL.Grid>
        <CL.GridRow className="of-auxiliary-esim-order-scene__top">
          <CL.GridCol className="of-auxiliary-esim-order-scene__top-item" colsXS={4} colsM={6} colsL={6}>
            {sceneElement}
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
    </div>
  );
};

export const AuxiliaryEsimOrderScene = (props: AuxiliaryEsimOrderLoginProps) => {
  const { auxiliaryEsimOrder, user } = useSelector((state: State) => {
    return {
      auxiliaryEsimOrder: state.selfservice?.auxiliaryEsimOrder,
      user: state.user,
    };
  }, deepEqual);

  // TODO: Fix this kludge once we target something else apart from Apple Watch!
  if (
    auxiliaryEsimOrder?.phase === AuxiliaryEsimOrderPhaseEnum.SUCCESS &&
    auxiliaryEsimOrder.orderResult?.activationStatus
  ) {
    updateAppleDataPlanAccountWithInfo({
      eid: auxiliaryEsimOrder.orderResult.eid,
      iccid: auxiliaryEsimOrder.orderResult.iccid,
    });
  }

  if (!user) {
    return null;
  }

  return <AuxiliaryEsimOrderContent {...props} />;
};
