import { type OpenFormAnswers } from './OpenFormAnswers.js';
import type { OpenFormChoice } from '../../generated/api/openFormChoice.js';
import type { OpenFormQuestion } from '../../generated/api/openFormQuestion.js';

const PRODUCT_LINE_RULE = 'Product_Line';

type Rule = { type: 'guid' | 'productLine'; value: string };

export class OpenFormRules {
  static isVisible(answers: OpenFormAnswers) {
    return (target?: OpenFormChoice | OpenFormQuestion): target is OpenFormChoice | OpenFormQuestion => {
      switch (target?.rulesType) {
        case 'NONE': {
          return true;
        }
        case 'VISIBILITY_CONDITIONS': {
          return this.parseRules(target.rules).some(this.ruleMatches(answers));
        }
        case 'HIDING_CONDITIONS': {
          return !this.parseRules(target.rules).some(this.ruleMatches(answers));
        }
        default: {
          return false;
        }
      }
    };
  }

  private static parseRules(rules: string[]) {
    return rules
      .map(
        (value): Rule =>
          value.startsWith(PRODUCT_LINE_RULE)
            ? { type: 'productLine', value: value.split(':')[1] ?? '' }
            : { type: 'guid', value }
      )
      .filter(rule => Boolean(rule.value.length));
  }

  private static ruleMatches(answers: OpenFormAnswers) {
    return (rule: Rule) => {
      switch (rule.type) {
        case 'guid': {
          return answers.includes(rule.value);
        }
        case 'productLine': {
          return answers.context.subscriptionTypes.some(([_, types]) => types.includes(rule.value));
        }
      }
    };
  }
}
