import * as CL from '@design-system/component-library';
import { CommercialProductType } from '../../generated/api/commercialProductType.js';
import { LoginBanner } from './LoginBanner.js';
import { ShoppingBasketEntry } from './ShoppingBasketEntry.js';
import { Voucher } from './Voucher.js';
import {
  activationFeeMsg,
  addMsg,
  additionalServicesMsg,
  cartIsEmptyMsg,
  checkoutMsg,
  continueShoppingMsg,
  contractPriceCapitalizedMsg,
  oneTimePaymentMsg,
  paymentPeriodMsg,
  quantityMsg,
  removeMsg,
  shoppingCartContentPluralMsg,
  shoppingCartContentSingularMsg,
  shoppingCartMsg,
  subtractMsg,
  t,
  totalMsg,
  totalWithCurlyBracketsMsg,
  vatPercentageMsg,
} from '../../common/i18n/index.js';
import {
  calculateTotalPrices,
  getOffer,
  getPaymentOptions,
  getPriceForGuid,
  getPriceForGuidToDisplay,
  getPriceForSalesProduct,
  getPriceToDisplay,
  getTotalAmount,
} from './shoppingBasketUtils.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { DiscountedPrices } from '../../generated/api/discountedPrices.js';
import type { OnlineModel } from '../../generated/api/onlineModel.js';
import type { ShoppingBasketType } from '../../common/types/shoppingBasket.js';

import './ShoppingBasket.scss';

export interface ShoppingBasketProps {
  basketItems?: ShoppingBasketType;
  discountedPrices: DiscountedPrices[];
  models: OnlineModel[];
  loggedIn: boolean;
  changeQuantity: (productId: string, quantity: number) => void;
}

const mapItems = (
  basketItems: ShoppingBasketType,
  models: OnlineModel[],
  discountedPrices: DiscountedPrices[],
  loggedIn: boolean,
  onPaymentOptionChange: (productId: string, paymentOptionId: string) => void,
  onQuantityChange: (productId: string, quantity: number) => void
): React.ReactElement[] | undefined => {
  return basketItems.items?.map((basketItem): React.ReactElement => {
    const onlineModelForItem = models?.find(model => model.onlineModelCode === basketItem.guid);
    const offer = getOffer(basketItem.offer.guid, onlineModelForItem);

    // Customer level price
    const discountedPricesForModel = discountedPrices?.find(priceEntry => priceEntry.model === basketItem.guid);

    const isSalesProduct = onlineModelForItem?.category === CommercialProductType.SALES_PRODUCT;

    const priceData = isSalesProduct
      ? getPriceForSalesProduct(offer)
      : getPriceForGuid(basketItem.offer.commercialProductGuid, onlineModelForItem, discountedPrices);

    const hasOneTimeCharge = !!priceData?.price?.oneTimeCharge;

    const priceDisplayData = isSalesProduct
      ? {
          shoppingCartPrice: getPriceToDisplay(priceData?.price, basketItem.quantity),
        }
      : getPriceForGuidToDisplay(
          basketItem.offer.commercialProductGuid,
          onlineModelForItem,
          discountedPricesForModel,
          basketItem.quantity
        );

    const formatOneTimeCharge = formatSum(priceData?.price?.oneTimeCharge) || '';
    const oneTimePaymentText = isSalesProduct
      ? basketItem.quantity > 1
        ? t.HWDV(activationFeeMsg, formatOneTimeCharge)
        : t.HWDU(activationFeeMsg, formatOneTimeCharge)
      : t.ASEI(oneTimePaymentMsg);

    // Corporate VAT is 0
    const oneTimeChargeFields = ['', oneTimePaymentText, t.A0OJ(vatPercentageMsg, '0')];
    const payments = priceData?.price?.payments || 0;
    const monthlyRecurringFields = [
      payments === 0 ? '' : t.CF93('{} month agreement', String(payments)),
      payments === 0
        ? ''
        : t.W1RX(
            totalWithCurlyBracketsMsg,
            formatSum(Number(priceData?.price?.monthlyRecurringCharge) * payments) || ''
          ),
      t.A0OJ(vatPercentageMsg, '0'),
    ];

    // NOTE: elements 1-3 for pricing texts, after those badges (orange, turquoise, and rest light-blue), use empty string to skip
    const badges = priceDisplayData.isDiscountedPrice ? [t.JPKP(contractPriceCapitalizedMsg)] : [];

    const disclaimerFields = [
      ...(hasOneTimeCharge ? oneTimeChargeFields : []),
      ...(!hasOneTimeCharge ? monthlyRecurringFields : []),
      ...badges,
    ];

    const paymentOptions = getPaymentOptions(
      basketItem.offer.commercialProductGuid,
      onlineModelForItem,
      loggedIn,
      discountedPrices
    );

    return (
      <ShoppingBasketEntry
        key={basketItem.id}
        basketItem={basketItem}
        priceDisplayData={priceDisplayData}
        paymentOptions={isSalesProduct ? [] : paymentOptions}
        disclaimerFields={disclaimerFields}
        model={onlineModelForItem!}
        offer={offer}
        onPaymentOptionChange={onPaymentOptionChange}
        onQuantityChange={onQuantityChange}
      />
    );
  });
};

export const ShoppingBasket = ({
  basketItems,
  discountedPrices,
  models,
  loggedIn,
  changeQuantity,
}: ShoppingBasketProps) => {
  const totalProductQuantity = getTotalAmount(basketItems);

  const cancelUrl = document.referrer?.includes(window.location.hostname) ? document.referrer : paths.WEB_SHOP;

  const onPaymentOptionChange = () => {
    alert('onPaymentOptionChange()');
  };

  return (
    <>
      <div className="of-shopping-basket-wrapper">
        {!loggedIn && (
          <div className="of-login-banner">
            <LoginBanner />
          </div>
        )}

        <CL.ShoppingCart
          ariaAddonsLabel={t.LXSR(additionalServicesMsg)}
          ariaPaymentLabel={t.EM2Q(paymentPeriodMsg)}
          ariaQuantityDeleteLabel={t.R3VE(removeMsg)}
          ariaQuantityLabel={t.M0W7(quantityMsg)}
          ariaQuantityMinusLabel={t.C2KQ(subtractMsg)}
          ariaQuantityPlusLabel={t.VKFM(addMsg)}
          ariaTotalsLabel={t.CEQ2(totalMsg)}
          checkoutUrl={paths.NEW_DEVICE_CHECKOUT}
          i18nCheckoutLabel={t.UAAP(checkoutMsg)}
          i18nContinueLabel={t.VLZR(continueShoppingMsg)}
          i18nEmptyLabel={t.PRFW(cartIsEmptyMsg)}
          i18nHeading={t.BE8Q(shoppingCartMsg)}
          cancelUrl={cancelUrl}
          caption={
            totalProductQuantity === 1
              ? t.I23L(shoppingCartContentSingularMsg)
              : t.PS1E(shoppingCartContentPluralMsg, `${totalProductQuantity}`)
          }
          items={
            basketItems &&
            mapItems(basketItems, models, discountedPrices, loggedIn, onPaymentOptionChange, changeQuantity)
          }
          onPaymentOptionChange={() => {}} // Not used at the moment
          onQuantityChange={() => {}} // Not used at the moment
          totals={calculateTotalPrices(models, discountedPrices, basketItems)}
          totalProductQuantity={totalProductQuantity}
        />
        {!!basketItems?.items && basketItems.items.length > 0 && (
          <Voucher
            onDisableClick={() => {
              alert('Voucher clicked');
            }}
          />
        )}
      </div>
    </>
  );
};
