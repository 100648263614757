import { FixedBroadbandOrder } from '../../../../components/FixedBroadbandOrder/FixedBroadbandOrder.js';
import { Header } from '../../../../components/Header/Header.js';
import { useLoaderData, useLocation } from 'react-router-dom';
import type { FixedBBRootLoaderData } from 'common/loaders.js';

export const AnonymousFixedBroadbandOrderPath = () => {
  const { state, pathname } = useLocation();
  const { errors } = useLoaderData() as FixedBBRootLoaderData;

  return (
    <>
      {!state?.disableHeader && <Header />}
      <FixedBroadbandOrder currentPath={pathname} disableBreadcrumbs={state?.disableHeader} errors={errors} />
    </>
  );
};
