/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The party responsible for validating the identity of the user
 */
export type IdentityProvider = 'ELISA_ID' | 'ELISA_ID_V2' | 'SSO' | 'VURBIS' | 'BOOMI' | 'SALESFORCE';

export const IdentityProvider = {
    ELISA_ID: 'ELISA_ID' as IdentityProvider,
    ELISA_ID_V2: 'ELISA_ID_V2' as IdentityProvider,
    SSO: 'SSO' as IdentityProvider,
    VURBIS: 'VURBIS' as IdentityProvider,
    BOOMI: 'BOOMI' as IdentityProvider,
    SALESFORCE: 'SALESFORCE' as IdentityProvider
};

