export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const SSO_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=(.*[\d\W+]){2})[A-Za-z\d\W+]{8,}$/;
export const POSTAL_CODE_REGEX = /^[0-9]{4}[0|2-9]$/;
export const POSTAL_CODE_WITH_PO_BOX_REGEX = /^[0-9]{5}$/;
export const SIM_CARD_REGEX = /^[0-9]{14}$/;
export const HTML_TAG_REGEX = /(<([^>]+)>)/;
export const HOURS_AND_MINUTES_REGEX = /^([01]?\d|2[0-4])[:|.]([0-5]\d)$/;
export const CURRENCY_INPUT_REGEX = /^\d{1,4}([,.]\d{0,2})?$/;
export const ELISA_ICCID_PREFIX = '893580';
export const COST_CENTER_REGEX = /^\d{4}$/;
export const getMinCharRegex = (length: number) => new RegExp(`^.{${length},}$`);
export const PASSWORD_MAX_LENGTH = 100;
export const DEFAULT_COUNTRY_CODE = 'FIN';
export const DEFAULT_MAX_ALLOWED_ITEM_QUANTITY_FOR_ORDER = 99;
export const POSTAL_CODE_MAX_LENGTH = 5;
export const BILLING_ACCOUNT_MAX_LENGTH = 255;
export const BILLING_ACCOUNT_NAME_EXT_MAX_LENGTH = 30;
export const companyNameValidation = {
  maxLength: 256,
};
export const CUSTOMER_NUMBER_REGEX = /^([0-9]{3,9})$/;
export const DATE_FORMAT_REGEX = /^\d{1,2}\.\d{1,2}\.\d{4}$/;
export const ELECTRONIC_ADDRESS_REGEX = /(^0037|^73|^57|^TE0037|^FI)[A-Z0-9]+$/;
export const BUSINESS_ID_REGEX = /^[\d]{7}-[\d]$/;

export const createRegExpValidator = (re: RegExp, error: string) => (value: string) =>
  re.test(value) ? undefined : error;

export const ensureNonNullable = <T>(value: T, paramName: string): void => {
  if (value === null || value === undefined) {
    throw new Error(`Mandatory parameter "${paramName}" missing`);
  }
};

export const isPOBoxAddress = (postalCode?: string) => postalCode?.endsWith('1');
