import { BreadCrumbsWithTitle } from '../../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { CompanySelector } from '../../../../../../components/CompanySelector/CompanySelector.js';
import { Grid } from '../../../../../../components/Grid/Grid.js';
import { ModelType } from '../../../../../../common/enums.js';
import { OrderSubscriptionSelection } from '../../../../../../components/OrderSubscriptionSelection/OrderSubscriptionSelection.js';
import { deepEqual } from '../../../../../../common/utils/objectUtils.js';
import { getUserAccounts } from '../../../../../../components/Header/dynamic/headerFunctions.js';
import {
  internetSubscriptionsMsg,
  omaElisaForCompaniesMsg,
  selectCompanyBodyOrderMsg,
  selectCompanyHeaderMsg,
  t,
} from '../../../../../../common/i18n/index.js';
import { isFeatureEnabled } from '../../../../../../common/utils/featureFlagUtils.js';
import { paths } from '../../../../../../common/constants/pathVariables.js';
import { useAuth } from '../../../../../../public/site/AuthProvider.js';
import { useLoaderData, useNavigate, useRouteLoaderData } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type {
  OrderMobileBBSubLoaderData,
  OrderSubscriptionCommonLoaderData,
} from '../../../../../../common/loaders.js';
import type { State } from 'selfservice/exports.js';

import './OrderSelectionPath.scss';

export const OrderBroadbandSubSelectionPath = () => {
  const navigate = useNavigate();
  const { companyInfo } = useRouteLoaderData('newMobileBBRoot') as OrderSubscriptionCommonLoaderData;
  const { addOnRules, mobileBroadbandModel } = useLoaderData() as OrderMobileBBSubLoaderData;
  const featureFlags = useSelector((state: State) => state.config.featureFlags, deepEqual);
  const isConsolidatedViewsEnabled = isFeatureEnabled(featureFlags.consolidatedViews);
  const { authenticatedUser } = useAuth();

  const uiOptions = companyInfo?.uiOptions as { toggles?: string[] };

  const breadCrumbs = (
    <BreadCrumbsWithTitle
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.SEYV(internetSubscriptionsMsg), path: paths.PS_BROADBAND_SUBSCRIPTIONS },
        { name: t.O222('New mobile broadband subscription') },
      ]}
    />
  );

  return (
    <>
      {isConsolidatedViewsEnabled ? (
        <div className="of-subscription-selection-nav">
          {breadCrumbs}
          <Grid>
            <h3 className="ds-h3--book">{t.BR9A(selectCompanyHeaderMsg)}</h3>
            <p>{t.JUY2(selectCompanyBodyOrderMsg)}</p>
            <CompanySelector
              userAccounts={getUserAccounts(authenticatedUser)}
              onInputChange={(_, option) => {
                navigate(`?companyId=${option.accountMasterId}`, { replace: true });
              }}
            />
          </Grid>
        </div>
      ) : (
        breadCrumbs
      )}
      <OrderSubscriptionSelection
        addOnRules={addOnRules}
        onlineModelType={ModelType.MobileBroadband}
        onlineModels={[mobileBroadbandModel]}
        toggles={uiOptions?.toggles}
      />
    </>
  );
};
