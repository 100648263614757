import { Catalog } from '../../generated/api/models.js';
import { CatalogConfigurationForm, mapFormValuesToBeProcessed } from './CatalogConfigurationForm.js';
import { ContractPeriod } from '../../common/utils/catalogUtils.js';
import { billingAccountDefaultValues } from '../BillingAccount/BillingAccountFieldset.js';
import { createContactsAndBillingAccount } from './utils';
import { deliveryMethodDefaultValues } from '../BillingAccount/DeliveryMethodFieldset.js';
import {
  deviceListSavedAsDraftMsg,
  newDeviceListMsg,
  nextMsg,
  saveMsg,
  savingTheDataFailedMsg,
  t,
} from '../../common/i18n';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { isEppSolutionActive } from '../../common/utils/stateUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { putDraftCatalog } from '../../common/fetch';
import { startNotification } from '../../selfservice/actions';
import { useDispatch } from 'react-redux';
import { userHasEmptyOrSMEPriceGroup } from '../../common/utils/employeeUtils.js';
import type { AuthenticatedUserState, CompanyInfoState } from '../../common/types/states.js';
import type { BillingAccount, BillingAccountHeader, VirtualCatalog } from '../../generated/api/models.js';
import type { CatalogProduct } from '../../common/utils/catalogUtils.js';
import type { FormValues } from './CatalogConfigurationForm.js';

export interface CatalogConfigurationProps {
  companyInfo?: CompanyInfoState;
  user?: AuthenticatedUserState | null;
}

export interface CatalogParams {
  catalog: Partial<Catalog>;
  products?: Record<string, Array<CatalogProduct>>;
  virtualCatalog?: VirtualCatalog;
  billingAccount: BillingAccount;
  billingAccountHeader?: BillingAccountHeader;
}

const getConfigValues = (props: CatalogConfigurationProps) => {
  const companyInfo = props.companyInfo;
  const isEppActive = isEppSolutionActive(props.companyInfo);
  const isSmeOrEmptyPriceGroup = userHasEmptyOrSMEPriceGroup(props.user?.segmentPricingGroup);

  const isAllowedForLEPricingGroup = isEppActive ? companyInfo?.eppSolution?.allowDevicesWithRecurringCharges : false;
  const isAllowedForSmeOrEmptyPricingGroup = !isEppActive || companyInfo?.eppSolution?.allowDevicesWithRecurringCharges;
  const isRecurringChargeAllowed = isSmeOrEmptyPriceGroup
    ? isAllowedForSmeOrEmptyPricingGroup
    : isAllowedForLEPricingGroup;
  return { isEppActive, isRecurringChargeAllowed };
};

export const CatalogConfiguration = (props: CatalogConfigurationProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isEppActive, isRecurringChargeAllowed } = getConfigValues(props);
  const onSubmit = async (values: FormValues) => {
    navigate(paths.COMPANY_INFO_CATALOG_LIST_PRODUCTS, { state: mapFormValuesToBeProcessed(values) });
  };

  return (
    <div>
      <h3>{t.MPFC(newDeviceListMsg)}</h3>
      <p>
        {t.B0GP(
          `You can use the device list to set up your company's device portfolio. There can be more than one device list, and you can select one or more devices for a list. Also note, only products of one catalog list can be ordered together, so create list accordingly. You can also list the price limit that the company pays for each device per month. When the OmaLasku service is activated, the employee can also choose a device above the price limit, in which case they will pay the difference themselves.`
        )}
      </p>

      <CatalogConfigurationForm
        isEppActive={isEppActive}
        isRecurringChargeAllowed={Boolean(isRecurringChargeAllowed)}
        isOnetimeChargeAllowed={Boolean(!isEppActive || props.companyInfo?.eppSolution?.allowDevicesWithOneTimeCharges)}
        onSubmit={onSubmit}
        cancelHref={paths.COMPANY_INFO_CATALOGS}
        submitButtonMsg={t.F0MY(nextMsg)}
        defaultValues={
          location.state?.defaultValues || {
            billingAccount: {
              ...billingAccountDefaultValues(props.companyInfo),
              ...deliveryMethodDefaultValues(),
            },
            catalog: {
              name: '',
              productType: isEppActive ? Catalog.ProductTypeEnum.EPP_RECURRING : Catalog.ProductTypeEnum.RECURRING,
              contractPeriod: `${ContractPeriod.MONTHS_24}`,
              damageInsurance: [],
              corporateShare: '',
              enrollmentProgramConsent: false,
            },
          }
        }
      />
    </div>
  );
};

export const CatalogConfigurationEdit = (props: CatalogConfigurationProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vc = location.state.virtualCatalog;
  const draftOrPublishedVc = vc.draft || vc.published;
  const { isEppActive, isRecurringChargeAllowed } = getConfigValues(props);

  const onSubmit = async (values: FormValues) => {
    const readyFormValues = mapFormValuesToBeProcessed(values);
    try {
      const billingAccountId = await createContactsAndBillingAccount(readyFormValues);
      const catalog = { ...draftOrPublishedVc, ...readyFormValues.catalog, billingAccountId };
      await putDraftCatalog(vc.virtualCatalogCode, catalog);
      dispatch(startNotification(t.P7JV(deviceListSavedAsDraftMsg)));
      navigate(generatePath(paths.COMPANY_INFO_CATALOG, { catalogCode: vc.virtualCatalogCode }));
    } catch (e) {
      dispatch(startNotification(t.RQA1(savingTheDataFailedMsg), 'error'));
    }
  };

  return (
    <CatalogConfigurationForm
      isEppActive={isEppActive}
      isRecurringChargeAllowed={Boolean(isRecurringChargeAllowed)}
      isOnetimeChargeAllowed={Boolean(!isEppActive || props.companyInfo?.eppSolution?.allowDevicesWithOneTimeCharges)}
      submitButtonMsg={t.NIWE(saveMsg)}
      onSubmit={onSubmit}
      cancelHref={generatePath(paths.COMPANY_INFO_CATALOG, { catalogCode: vc.virtualCatalogCode })}
      defaultValues={{
        billingAccount: {
          ...billingAccountDefaultValues(props.companyInfo),
          ...deliveryMethodDefaultValues(),
        },
        catalog: {
          name: draftOrPublishedVc.name,
          productType: draftOrPublishedVc.productType,
          contractPeriod: draftOrPublishedVc.contractPeriod.toString(),
          damageInsurance: draftOrPublishedVc.damageInsurance,
          corporateShare: draftOrPublishedVc.corporateShare ? (draftOrPublishedVc.corporateShare / 100).toFixed(2) : '',
          corporateMessage: draftOrPublishedVc.corporateMessage,
          enrollmentProgramConsent: draftOrPublishedVc.enrollmentProgramConsent,
          enrollmentProgramAlias: draftOrPublishedVc.enrollmentProgramAlias,
          billingAccountId: draftOrPublishedVc.billingAccountId,
        },
        isCorporateMessageAdded: Boolean(draftOrPublishedVc.corporateMessage),
      }}
    />
  );
};
