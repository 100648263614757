import { OpenFormGridRow } from './OpenFormGrid.js';
import classNames from 'classnames';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';

export const OpenFormSectionLabel = ({ section }: { section: OpenFormSection }) => (
  <OpenFormGridRow>
    <OpenFormGridRow className={classNames({ hidden: !section?.label })}>
      <h3>{section?.label}</h3>
    </OpenFormGridRow>
    <OpenFormGridRow className={classNames({ hidden: !section?.description }, 'ds-margin-bottom--5')}>
      {section?.description}
    </OpenFormGridRow>
  </OpenFormGridRow>
);
