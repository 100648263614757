import * as CL from '@design-system/component-library';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { DialogType } from '../../common/enums.js';
import { Link, generatePath, useLocation, useNavigate } from 'react-router-dom';
import { Loading } from '../Loading/index.js';
import { SystemError } from '../SystemError/SystemError.js';
import {
  billingAccountMsg,
  deductibleMsg,
  deviceMsg,
  idNumberMsg,
  monthlyChargesMsg,
  squareTradeDeductibleMsg,
  statusMsg,
  t,
} from '../../common/i18n/index.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getSubscriptionStatus, openSubscriptionDialog } from '../../common/utils/subscriptionUtils.js';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { isSquareTradeAddOn } from '../../common/utils/addOnUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { resetErrors, showDialog } from '../../selfservice/actions/index.js';
import { useDispatch, useStore } from 'react-redux';
import type * as CLT from '@design-system/component-library';
import type { AddOn, Subscription, SubscriptionAction } from '../../generated/api/models.js';
import type { State } from '../../selfservice/common/store.js';
import type { SubscriptionsState } from '../../common/types/states.js';

import './AddonDetails.scss';

export interface AddonDetailsProps {
  breadCrumbs: JSX.Element;
  addOn?: AddOn;
  subscription?: Subscription;
  subscriptionActions?: SubscriptionAction[];
  subscriptionPath: string;
  subscriptionsState?: SubscriptionsState;
}

export const AddonDetails = ({
  breadCrumbs,
  addOn,
  subscription,
  subscriptionActions,
  subscriptionPath,
  subscriptionsState,
}: AddonDetailsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const config = useStore<State>().getState().config;
  const addonDisplayId = addOn ? addOn.addOnId : '…';
  const addOnName = addOn ? addOn.addOnProductName : '...';
  const subscriptionDisplayId = subscription ? subscription.subscriptionDisplayId : '';
  const addOnCode = addOn ? addOn.addOnCode : '...';
  const subscriptionId = subscription ? subscription.subscriptionId : undefined;

  if (subscriptionsState?.errors) {
    return (
      <SystemError
        errors={subscriptionsState.errors}
        onButtonClick={() => {
          dispatch(resetErrors());
          navigate(paths.SELF_SERVICE_HOME);
        }}
      />
    );
  }

  if (!(addOn && subscription)) {
    return (
      <DetailsWrapper
        classes={['of-addon-details']}
        detailsTop={breadCrumbs}
        id={`addon-details-${addonDisplayId}`}
        heading={`${addOnName}`}
        headingBottom={isSquareTradeAddOn(addOn) ? t.E0RW('Additional warranty for device') : undefined}
        heroPicto="square-trade"
      >
        <Loading />
      </DetailsWrapper>
    );
  }

  const subscriptionStatus = getSubscriptionStatus(subscription, subscriptionActions);

  const fields: CLT.DescriptionItem[] = [
    {
      title: t.ASQT(statusMsg),
      description: (
        <div className={`ea-disc ea-disc--small ea-disc--${subscriptionStatus.color}`}>{subscriptionStatus.text}</div>
      ),
    },
    {
      title: t.YTH3(idNumberMsg),
      description: subscription.subscriptionDisplayId,
    },
  ];

  if (subscription.billingAccountDisplayId) {
    fields.push({
      title: t.IFT9(billingAccountMsg),
      description: (
        <Link
          to={generatePath(paths.BILLING_ACCOUNT, { billingAccountId: subscription.billingAccountDisplayId }) + search}
        >
          {subscription.billingAccountDisplayId}
        </Link>
      ),
    });
  }

  const formattedStartDate = formatTimestampToUTCDDMMYYYY(subscription.startDate);
  if (formattedStartDate !== undefined) {
    fields.push({
      title: t.STLY('Contract start date'),
      description: formattedStartDate,
    });
  }

  fields.push({
    title: t.P6BC(monthlyChargesMsg),
    description: formatSum(addOn.addOnMonthlyRecurringCharge),
  });

  fields.push({
    title: t.TPVQ(deviceMsg),
    description: (
      <Link to={generatePath(subscriptionPath, { subscriptionId: subscriptionDisplayId }) + search}>
        {subscription.subscriptionName}
      </Link>
    ),
  });

  if (isSquareTradeAddOn(addOn)) {
    fields.push({
      title: t.VWSV(deductibleMsg),
      description: squareTradeDeductibleMsg,
    });
  }

  return (
    <DetailsWrapper
      classes={['of-addon-details']}
      detailsTop={breadCrumbs}
      id={`addon-details-${addonDisplayId}`}
      heading={`${addOnName}`}
      headingBottom={isSquareTradeAddOn(addOn) ? t.E0RW('Additional warranty for device') : undefined}
      heroPicto="square-trade"
    >
      <div className="of-addon-details__content">
        <CL.Description items={fields} />
        <div className="of-addon-details__actions">
          {isFeatureEnabled(config.featureFlags.deleteSubscriptionAddOn) && (
            <CL.Button
              key="terminate"
              onClick={() =>
                openSubscriptionDialog(subscriptionStatus, params => dispatch(showDialog(params)), {
                  addOnCode: addOnCode!,
                  subscriptionId: subscriptionId!,
                  type: DialogType.SUBSCRIPTION_TERMINATE_DEVICE_SERVICE,
                })
              }
              color="link"
            >
              <>
                <span className="ea-icon ea-icon--medium ea-icon--power" />
                {t.NKZA('Terminate agreement')}
              </>
            </CL.Button>
          )}
        </div>
        {isSquareTradeAddOn(addOn) && (
          <div className="of-addon-details__disclaimer ea-disclaimertext">
            <p>
              {t.HZ90(
                'In the event of damage, please contact SquareTrade’s customer service. Tel. 0800 915 801, Mon—Fri 9am-7pm, Sat 10am—4.30pm. You can also submit a damage report via the website'
              )}{' '}
              <a href="https://www.squaretrade.fi">www.squaretrade.fi</a>
            </p>
          </div>
        )}
      </div>
    </DetailsWrapper>
  );
};
