import * as CL from '@design-system/component-library';
import { type ReactNode } from 'react';

export const OpenFormGridCol = ({ children, ...props }: { children?: ReactNode } & CL.GridColProps) => (
  <CL.GridCol colWidth={12} {...props}>
    {children}
  </CL.GridCol>
);

export const OpenFormGridRow = ({ children, ...props }: { children?: ReactNode } & CL.GridRowProps) => (
  <CL.GridRow justifyCenter {...props}>
    {children}
  </CL.GridRow>
);

export const OpenFormGrid = ({ children, className }: { children?: ReactNode; className?: string }) => (
  <CL.Grid>
    <OpenFormGridRow>
      <OpenFormGridCol className={className}>{children}</OpenFormGridCol>
    </OpenFormGridRow>
  </CL.Grid>
);
