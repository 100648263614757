import { type Contact } from '../OpenFormContext.js';
import { OFPageType } from '../../../generated/api/oFPageType.js';
import { OFQuestionType } from '../../../generated/api/oFQuestionType.js';
import { type OpenFormAnswers } from '../OpenFormAnswers.js';
import { OpenFormRules } from '../OpenFormRules';
import { getDate, getQuestions, getValue } from '../OpenFormUtils.js';
import { parseCurrencyToNumber } from '../../../common/utils/priceUtils.js';
import type { OpenFormCustomerNeedAdditionalInformation } from '../../../generated/api/openFormCustomerNeedAdditionalInformation.js';
import type { OpenFormCustomerNeedPackage } from '../../../generated/api/openFormCustomerNeedPackage.js';
import type { OpenFormCustomerNeedProduct } from '../../../generated/api/openFormCustomerNeedProduct.js';
import type { OpenFormCustomerNeedQuestion } from '../../../generated/api/openFormCustomerNeedQuestion.js';
import type { OpenFormCustomerNeedRequest } from '../../../generated/api/openFormCustomerNeedRequest.js';
import type { OpenFormProductSummary } from '../../../generated/api/openFormProductSummary.js';
import type { OpenFormQuestion } from '../../../generated/api/openFormQuestion.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';
import type { OpenFormSummaryResponse } from '../../../generated/api/openFormSummaryResponse.js';

enum SF {
  ONE_TIME_CHARGE = 'one_time_charge__c',
  RECURRING_CHARGE = 'recurring_charge__c',
  ORDERING_CONTACT = 'Ordering_Contact__c',
  PRODUCT_LINE = 'Product_line__c',
  INSTALLATION_ADDRESS = 'Installation_Address__c',
}

export class OpenFormSummary {
  constructor(
    private readonly formId: string | undefined,
    private readonly answers: OpenFormAnswers,
    private readonly sections: OpenFormSection[],
    private readonly response: OpenFormSummaryResponse | undefined,
    private readonly enriched = getQuestions(sections, OFPageType.ORDER_ENRICHMENT),
    private readonly records = Object.fromEntries(enriched.map(q => [q.guid, q]))
  ) {}

  get additionalInformation(): OpenFormCustomerNeedAdditionalInformation[] {
    return this.enriched
      .map(({ fieldApiName, guid }) => ({
        fieldLabel: fieldApiName,
        fieldValue: getValue(this.answers.get(guid)),
      }))
      .concat({
        // Product line
        fieldLabel: SF.PRODUCT_LINE,
        fieldValue: String(Array.from(new Set(this.answers.context.subscriptionTypes.flatMap(([_, t]) => t || [])))),
      })
      .concat(
        // Installation address will be added here if missing
        this.enriched.every(({ fieldApiName }) => fieldApiName !== SF.INSTALLATION_ADDRESS)
          ? this.answers.context.addresses.map(([_, address]) => ({
              fieldLabel: SF.INSTALLATION_ADDRESS,
              fieldValue: address.addressId,
            }))
          : []
      );
  }

  get additionalServices() {
    return this.response?.products.flatMap(this.summary(OFPageType.QUESTION_ADDITIONAL_SERVICES));
  }

  get address() {
    return this.answers.context.addresses.find(([_, address]) => address)?.[1];
  }

  get billingAccounts() {
    return this.answers.context.billingAccounts;
  }

  get deliveryDate() {
    const question = this.enriched.find(({ guid, type }) => guid && type === OFQuestionType.DATE_OF_DELIVERY);
    return !question?.guid ? undefined : this.answers.get(question.guid)?.[0];
  }

  get deliveryTime() {
    const question = this.enriched.find(({ guid, type }) => guid && type === OFQuestionType.TIME_OF_DELIVERY);
    return !question?.guid ? undefined : this.answers.get(question.guid)?.[0];
  }

  get mainProducts() {
    return this.response?.products.flatMap(this.summary(OFPageType.QUESTION_MAIN_PRODUCTS));
  }

  get orderingContact() {
    return this.answers.context.contacts.find(
      ([guid]) => this.records[guid]?.fieldApiName === SF.ORDERING_CONTACT
    )?.[1];
  }

  get otherContacts() {
    return this.answers.context.contacts
      .map(([guid, contact]): [Contact, OpenFormQuestion] => [contact, this.records[guid]])
      .filter(([_, question]) => question && question.fieldApiName !== SF.ORDERING_CONTACT);
  }

  get request(): OpenFormCustomerNeedRequest | undefined {
    return this.formId && this.package
      ? {
          formId: this.formId,
          questions: this.sections.flatMap(s => s.questions.flatMap(this.question)),
          rootProducts: [this.package],
        }
      : undefined;
  }

  private get package(): OpenFormCustomerNeedPackage | undefined {
    const main = this.mainProducts?.find(p => p.productCode);
    return main
      ? {
          product: this.product(main),
          childProducts: this.additionalServices?.map(summary => this.product(summary)),
          additionalInformation: this.additionalInformation,
        }
      : undefined;
  }

  private get question() {
    const visible = OpenFormRules.isVisible(this.answers);
    return (question: OpenFormQuestion): OpenFormCustomerNeedQuestion | [] => {
      const choices = visible(question) && this.answers.hasAnswer(question) && this.answers.get(question.guid);
      if (!choices) {
        return [];
      }
      switch (question.type) {
        case 'LIST_OF_OBJECTS_MULTI_SELECT':
        case 'LIST_OF_OBJECTS_SINGLE_SELECT':
        case 'MULTI_SELECT':
        case 'SINGLE_SELECT': {
          return { guid: question.guid, answers: choices.map(choice => ({ guid: choice })) };
        }
        case 'BA_SELECTION':
        case 'INSTALLATION_ADDRESS':
        case 'INSTALLATION_CONTACT':
        case 'TECHNICAL_CONTACT':
        case 'DELIVERY_CONTACT':
        case 'FAULT_INCIDENT_CONTACT':
        case 'ORDERING_CONTACT':
        case 'TIME_OF_DELIVERY': {
          return { guid: question.guid, answers: choices.map(choice => ({ value: choice })) };
        }
        case 'DATE_OF_DELIVERY': {
          return { guid: question.guid, answers: choices.map(choice => ({ value: getDate(choice) })) };
        }
        case 'FREE_TEXT': {
          switch (question.dataType) {
            case 'DATE_FIELD': {
              return { guid: question.guid, answers: choices.map(choice => ({ value: getDate(choice) })) };
            }
            case 'NUMBER_INTEGER': {
              return {
                guid: question.guid,
                answers: choices.map(choice => ({ value: Number(choice).toFixed() })),
              };
            }
            case 'EMAIL':
            case 'PHONE':
            case 'TEXT':
            case 'TIME_FIELD': {
              return { guid: question.guid, answers: choices.map(choice => ({ value: choice })) };
            }
          }
        }
      }
    };
  }

  private product(summary: OpenFormProductSummary): OpenFormCustomerNeedProduct {
    return {
      productCode: summary.productCode,
      oneOffCharge: String(summary.oneOffCharge),
      recurringCharge: String(summary.recurringCharge),
    };
  }

  private summary(...types: OFPageType[]) {
    const choices = getQuestions(this.sections, types).flatMap(q => q.choices.map(c => c.guid));
    const rows = this.answers.context.rows;
    return ({ choice, oneOffCharge, recurringCharge, ...rest }: OpenFormProductSummary): OpenFormProductSummary | [] =>
      choice && choices.includes(choice)
        ? {
            ...rest,
            choice: choice,
            oneOffCharge: parseCurrencyToNumber(rows[choice]?.[SF.ONE_TIME_CHARGE] ?? String(oneOffCharge))!,
            recurringCharge: parseCurrencyToNumber(rows[choice]?.[SF.RECURRING_CHARGE] ?? String(recurringCharge))!,
          }
        : [];
  }
}
