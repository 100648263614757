import { AddressSearch } from '../../AddressSearch/AddressSearch.js';
import { type Choices } from '../OpenFormAnswers.js';
import { type Context } from '../OpenFormContext.js';
import { OpenFormGridCol, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { type SetAnswer } from '../OpenFormHooks/useDispatcher.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { getValue } from '../OpenFormUtils.js';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import type { State } from '../../../selfservice/common/store.js';

export const OpenFormQuestionInstallationAddress = ({
  context,
  required,
  selected,
  setAnswer,
}: {
  context: Context | undefined;
  required: boolean;
  selected: Choices | undefined;
  setAnswer: SetAnswer;
}) => {
  const addressSearchResult =
    useSelector((state: State) => state?.resources?.addressSearchResult, deepEqual) ??
    (context?.address
      ? {
          match: { addressId: context.address.addressId, addressText: context.address.addressText },
          postalCode: context.address.postalCode,
        }
      : undefined);

  const { match, postalCode } = addressSearchResult ?? {};

  useEffect(() => {
    if (match?.addressId?.toString() === getValue(selected)) {
      return;
    }

    setAnswer(
      match?.addressId ? [match.addressId] : undefined,
      'address',
      match && postalCode ? { ...match, postalCode } : undefined
    );
  }, [match, postalCode, selected, setAnswer]);

  return (
    <OpenFormGridRow className="of-openform__address-check">
      <OpenFormGridCol
        colWidth={6}
        className={classNames('ds-justify-content--center', { ['label--mandatory']: required })}
      >
        <AddressSearch
          addressSearchResult={addressSearchResult}
          heading={null}
          hideSubmitButton={true}
          initialPostalCodeValue={addressSearchResult?.postalCode}
          initialStreetAddressValue={addressSearchResult?.match?.addressText}
          onSubmit={() => {}}
        />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
