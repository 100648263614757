import { OFPageType } from '../../../generated/api/oFPageType.js';
import { type OpenFormAnswers } from '../OpenFormAnswers.js';
import { OpenFormButton } from '../OpenFormComponents/OpenFormButton.js';
import { OpenFormGridCol, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { OpenFormIcon } from '../OpenFormComponents/OpenFormIcon.js';
import { OpenFormPageType } from '../OpenFormPage/OpenFormPageType.js';
import { type UseFormReturn } from 'react-hook-form';
import { nextMsg, previousMsg, t } from '../../../common/i18n/index.js';
import { scrollIntoView } from '../OpenFormUtils.js';
import { useMemo } from 'react';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';

// 'next' button will not be visible on the following page types
const HIDE_NEXT_BUTTON = [OFPageType.AVAILABILITY_CHECK] as const;

export const OpenFormViewSection = ({
  methods: { formState },
  answers,
  sections,
  disabled,
  index,
  next,
  prev,
}: {
  methods: UseFormReturn;
  answers: OpenFormAnswers;
  sections: OpenFormSection[];
  disabled: boolean;
  index: number;
  next: () => void;
  prev: () => void;
}) => {
  const section = useMemo(() => sections[index - 1], [sections, index]);
  const invalid = useMemo(() => section && answers.nextInvalid(section, formState), [formState, answers, section]);
  const missing = useMemo(() => section && answers.nextMissing(section), [answers, section]);

  return !section ? null : (
    <div id={section.guid} className="of-openform__view__section">
      <OpenFormPageType answers={answers} section={section} disabled={disabled} next={next} />
      <OpenFormGridRow className="of-openform__view__bottom">
        <OpenFormGridCol className="of-openform__view__bottom__buttons">
          <OpenFormButton
            iconPosition="left"
            icon={<OpenFormIcon icon="arrow-left" />}
            text={t.CACI(previousMsg)}
            color="link"
            size="m"
            disabled={disabled || index === 1}
            onClick={prev}
          />
          {HIDE_NEXT_BUTTON.includes(section.pageType) ? null : invalid || missing ? (
            <OpenFormButton
              iconPosition="right"
              icon={<OpenFormIcon icon="arrow-right" />}
              text={t.F0MY(nextMsg)}
              color="link"
              size="m"
              disabled={disabled}
              onClick={() => scrollIntoView(invalid ?? missing)}
            />
          ) : (
            <OpenFormButton
              iconPosition="right"
              icon={<OpenFormIcon icon="arrow-right" />}
              text={t.F0MY(nextMsg)}
              color="primary"
              size="m"
              disabled={disabled}
              onClick={next}
            />
          )}
        </OpenFormGridCol>
      </OpenFormGridRow>
    </div>
  );
};
