import { SELECTED_COMMERCIAL_PRODUCT_INDEX } from '../../common/constants/commonConstants.js';
import { SalesType } from '../../generated/api/models.js';
import { getTotalSums } from '../../common/utils/commercialProductUtils.js';
import type { AddedAddon } from '../../common/types/addon.js';
import type { AddressSearchMatch } from '../AddressSearch/AddressSearch.js';
import type { BillingAccount, CommercialProduct, Contact, Offer } from '../../generated/api/models.js';
import type { CompanyInfoState } from '../../common/types/states.js';
import type { ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { FixedBroadbandInstallationDetails } from './FixedBroadbandSetupDate.js';
import type { PurposeOfUseOrContact, SimCardConfiguration } from '../../common/types/subscription.js';

interface YritystuplaProperties {
  offerName: string;
  purposeOfUseOrContact: PurposeOfUseOrContact;
}
export interface FixedBroadbandOrder {
  broadbandInstallationDetails?: FixedBroadbandInstallationDetails;
  commercialProductName: string;
  companyInfo: {
    postalAddress?: string;
    postCode?: string;
    postOffice?: string;
    name?: string;
    businessId?: string;
  };
  purposeOfUseOrContact: PurposeOfUseOrContact;
  installationAddress: {
    streetAddress: string;
    postalCode: string;
  };
  yritystupla?: YritystuplaProperties;
  monthlyRecurringCharge: number;
  offerName: string;
  offerTechnology?: string;
  oneTimeCharge: number;
  billingDetailInfo: BillingAccount;
  campaignName: string | undefined;
  simCardConfiguration: SimCardConfiguration | undefined;
  addedAddOns: AddedAddon[];
  billingAccountDisplayId?: string;
}

const TAAPI_TECHNOLOGY = 'taapiTechnology';

const getRow = (header: string, value: string | undefined | boolean) => {
  if (!value) {
    return '';
  }
  return `${header}: ${value}`;
};

const getContactOrPurposeOfUse = (fixedBroadbandOrder: FixedBroadbandOrder) => {
  if (fixedBroadbandOrder.purposeOfUseOrContact.purposeOfUse) {
    return `Netti Lite käyttötarkoitus: ${fixedBroadbandOrder.purposeOfUseOrContact.purposeOfUse}`;
  }

  const { firstName, lastName, phoneNumber, email } = fixedBroadbandOrder.purposeOfUseOrContact;

  return `Netti Lite käyttäjäyhteyshenkilö:
    Etunimi: ${firstName}
    Sukunimi: ${lastName}
    Puhelinnumero: ${phoneNumber}
    Sähköpostiosoite: ${email}`;
};

const getExistingSubscriptionInformation = (fixedBroadbandOrder?: FixedBroadbandOrder) => {
  return `Kyllä
     Liittymän operaattori: ${
       fixedBroadbandOrder?.broadbandInstallationDetails?.currentOperator ||
       fixedBroadbandOrder?.broadbandInstallationDetails?.existingBroadbandOperator
     }
     Liittymän sopimustunniste: ${fixedBroadbandOrder?.broadbandInstallationDetails?.existingSubscriptionContractNumber}
      `;
};

const getAdditionalServices = (order: FixedBroadbandOrder) => {
  const { addedAddOns, yritystupla } = order;
  if (addedAddOns.length === 0 && !yritystupla) {
    return '';
  }

  const uniqueAddOns = Object.values(
    addedAddOns.reduce((addOns: { [k: string]: AddedAddon }, addOn) => {
      if (!addOns[addOn.addOnCode]) {
        addOns[addOn.addOnCode] = addOn;
      }
      return addOns;
    }, {})
  );

  return `Lisäpalvelut:

  ${uniqueAddOns.map(addon => addon?.addOnAttributes?.displayNameOverride ?? addon.displayName).join('\n\n')}`;
};

const getYritystuplaInformation = ({ yritystupla }: FixedBroadbandOrder) => {
  if (yritystupla) {
    const { purposeOfUseOrContact } = yritystupla;

    return `
      ${yritystupla.offerName}
      ${getRow('Yritystupla liittymän käyttötarkoitus', purposeOfUseOrContact.purposeOfUse)}
      ${getRow('Yritystupla liittymän kustannuspaikka', purposeOfUseOrContact.costCenter)}
      ${getRow('Yritystupla liittymän viite', purposeOfUseOrContact.employeeNumber)}
      Huom!. Muista täyttää Yritystuplan tilauksen yhteydessä käyttötarkoitus kenttään ensin Netti Lite liittymän SUB numero, ja sen perään haluttu tarkenne. Kts, Yritystuplan tilausohje Knowledgesta.
      `;
  }
  return '';
};

export const getSupportCaseDescription = (fixedBroadband: FixedBroadbandOrder): string => {
  return `
      Valitut tuotteet & saatavuustiedot:
      Valittu saatavilla oleva tuote: ${fixedBroadband?.offerName}${
        fixedBroadband?.offerTechnology ? ` (${fixedBroadband?.offerTechnology})` : ''
      }

      Asennusosoite: ${fixedBroadband?.installationAddress.streetAddress}
      Asennusosoitteen postinumero: ${fixedBroadband?.installationAddress.postalCode}
      Toivottu asennuspäivämäärä: ${fixedBroadband?.broadbandInstallationDetails?.installationDate || ''}
      ${fixedBroadband?.campaignName ? `Kampanja: ${fixedBroadband?.campaignName}` : ''}

      ${getContactOrPurposeOfUse(fixedBroadband)}

      ${getRow('Netti Lite liittymän kustannuspaikka', fixedBroadband.purposeOfUseOrContact.costCenter)}
      ${getRow('Netti Lite liittymän viite', fixedBroadband.purposeOfUseOrContact.employeeNumber)}

      Olemassa olevan laajakaistaliittymän tiedot:
      Onko kohteessa olemassa olevaa liittymää?: ${
        fixedBroadband?.broadbandInstallationDetails?.existingBroadband === 'yes'
          ? getExistingSubscriptionInformation(fixedBroadband)
          : 'Ei'
      }

      ${getAdditionalServices(fixedBroadband)}
      ${getYritystuplaInformation(fixedBroadband)}
      Laskutussopimuksen numero: ${fixedBroadband.billingAccountDisplayId}
      `
    .replace(/  +/g, '')
    .replace(/\n{3,}/g, '\n\n');
};

interface FixedBroadBandSupportCaseProps {
  contacts: Contact[];
  purposeOfUseOrContact: PurposeOfUseOrContact;
  yritystuplaPurposeOfUse?: PurposeOfUseOrContact;
  companyInfo: CompanyInfoState;
  broadbandInstallationDetails?: FixedBroadbandInstallationDetails;
  selectedBillingAccount: BillingAccount;
  selectedCommercialProduct: CommercialProduct;
  addressSearchMatch: AddressSearchMatch;
  currentUrl: string;
  configuredOffer: ConfiguredOffer;
}

const getOfferTechnology = (offer: Offer) => {
  return offer.properties?.find(prop => prop.name === TAAPI_TECHNOLOGY)?.value;
};

export const createFixedBroadbandOrder = ({
  contacts,
  purposeOfUseOrContact,
  yritystuplaPurposeOfUse,
  companyInfo,
  broadbandInstallationDetails,
  selectedBillingAccount,
  selectedCommercialProduct,
  addressSearchMatch,
  configuredOffer,
}: FixedBroadBandSupportCaseProps) => {
  const selectedContact = {
    ...purposeOfUseOrContact,
    ...contacts.find((contact: Contact) => contact.contactId === purposeOfUseOrContact.contactId),
  };

  const populatedPurposeOfUseOrContact: PurposeOfUseOrContact = {
    ...purposeOfUseOrContact,
    ...selectedContact.person,
    ...selectedContact.userContact,
  };

  const { businessId = '', companyName = '' } = companyInfo || {};
  const price = getTotalSums(configuredOffer.selectedCommercialProducts, undefined, SalesType.NEW_SALE);

  const configuredCommercialProduct = configuredOffer.selectedCommercialProducts[SELECTED_COMMERCIAL_PRODUCT_INDEX];

  const yritystuplaOffer = configuredOffer.selectedCommercialProducts.find(product =>
    product.commercialProduct.commercialProductName.match(/yritystupla/i)
  );

  const fixedBroadBandOrder: FixedBroadbandOrder = {
    billingDetailInfo: selectedBillingAccount!,
    companyInfo: {
      postalAddress: companyInfo.address?.line1,
      postCode: companyInfo.address?.postalCode,
      postOffice: companyInfo.address?.postOffice,
      name: companyName,
      businessId,
    },
    purposeOfUseOrContact: populatedPurposeOfUseOrContact,
    commercialProductName: selectedCommercialProduct.commercialProductName,
    broadbandInstallationDetails,
    installationAddress: {
      streetAddress: addressSearchMatch.addressText,
      postalCode: addressSearchMatch.postalCode,
    },
    yritystupla:
      yritystuplaOffer && yritystuplaPurposeOfUse
        ? {
            offerName: yritystuplaOffer.commercialProduct.commercialProductName,
            purposeOfUseOrContact: yritystuplaPurposeOfUse,
          }
        : undefined,
    monthlyRecurringCharge: price.totalMonthlyRecurringCharge,
    offerName: configuredOffer.offer.offerName,
    offerTechnology: getOfferTechnology(configuredOffer.offer),
    oneTimeCharge: price.totalOneTimeCharge,
    campaignName: configuredCommercialProduct.campaignName,
    simCardConfiguration: configuredCommercialProduct.simCardConfiguration,
    addedAddOns: configuredCommercialProduct.addedAddOns || [],
    billingAccountDisplayId: selectedBillingAccount?.billingAccountDisplayId,
  };

  return fixedBroadBandOrder;
};
