import { Anchor } from '../Anchor/Anchor.js';
import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { Grid } from '../Grid/Grid.js';
import { StickyFooter } from '../StickyFooter/index.js';
import { addBillingAgreementMsg, saveMsg, t } from '../../common/i18n/index.js';
import { buildPathVariable, paths } from '../../common/constants/pathVariables.js';
import { useNavigate } from 'react-router-dom';
import type { BillingAccountHeader } from '../../generated/api/models.js';

export const EditCatalogButtonGroup = (editCatalogButtonElementProps: {
  isSaving?: boolean;
  onSave?: () => void;
  onCancel: () => void;
  saveDisabled?: boolean;
}): JSX.Element => {
  return (
    <StickyFooter active={true}>
      <Grid>
        <ButtonGroupForSubmitAndBack
          className="ds-text-align--right ds-margin-vertical--3"
          onCancel={() => {
            editCatalogButtonElementProps.onCancel();
          }}
          onSubmit={() => {
            editCatalogButtonElementProps.onSave?.();
          }}
          submitButtonText={t.NIWE(saveMsg)}
          submitting={editCatalogButtonElementProps.isSaving}
          size="l"
          submitDisabled={editCatalogButtonElementProps.saveDisabled}
        />
      </Grid>
    </StickyFooter>
  );
};

export const BillingAccountSelection = (billingAccountSelectionProps: {
  billingAccounts?: BillingAccountHeader[];
  currentBillingAccountId?: string;
  onUpdateBillingAccount: () => void;
}): JSX.Element => {
  const navigate = useNavigate();
  const { billingAccounts, currentBillingAccountId, onUpdateBillingAccount } = billingAccountSelectionProps;

  if (billingAccounts) {
    const currentBillingAccount =
      currentBillingAccountId &&
      billingAccounts.find(billingAccount => billingAccount.billingAccountId === currentBillingAccountId);

    return (
      <span>
        {currentBillingAccount ? (
          <span>
            <Anchor
              className="of-catalog-details__to-billing-account"
              onClick={() => {
                if (currentBillingAccount.billingAccountDisplayId) {
                  navigate(buildPathVariable(paths.BILLING_ACCOUNT, currentBillingAccount.billingAccountDisplayId));
                }
              }}
            >
              {currentBillingAccount.billingAccountDisplayId}
            </Anchor>
          </span>
        ) : (
          <span>
            <Anchor className="of-catalog-details__change-billing-account" onClick={() => onUpdateBillingAccount()}>
              {t.G18X(addBillingAgreementMsg)}
            </Anchor>
          </span>
        )}
      </span>
    );
  }
  return <></>;
};
