import { AddressSearch } from '../../AddressSearch/AddressSearch.js';
import { OpenFormGridCol, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { type SetContext } from '../OpenFormHooks/useDispatcher.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { useSelector } from 'react-redux';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';
import type { State } from '../../../selfservice/common/store.js';

export const OpenFormPageAvailability = ({
  section,
  next,
  setContext,
}: {
  section: OpenFormSection;
  next: () => void;
  setContext: SetContext;
}) => {
  const { addressSearchResult } = useSelector(
    (state: State) => ({
      addressSearchResult: state?.resources?.addressSearchResult,
      commercialAvailabilityResult: state?.resources?.commercialAvailabilityResult,
    }),
    deepEqual
  );

  return (
    <OpenFormGridRow className="of-openform__address-check ds-margin-top--8 ds-padding--3">
      <OpenFormGridCol colWidth={5} className="ds-padding--6 ds-background-color--neutral-200 label--mandatory">
        <AddressSearch
          addressSearchResult={addressSearchResult}
          heading={<h3>{section.description}</h3>}
          initialPostalCodeValue={addressSearchResult?.postalCode}
          initialStreetAddressValue={addressSearchResult?.match?.addressText}
          onSubmit={(_, postalCode) => {
            const match = addressSearchResult?.match;
            if (match) {
              setContext('address', { ...match, postalCode });
              next();
            }
          }}
        />
      </OpenFormGridCol>
      <OpenFormGridCol
        colWidth={4}
        className="of-openform__address-check__image-container ds-padding--6 label--mandatory"
      >
        <div className="of-openform__address-check--picture ds-margin-vertical--4" />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
