import { BreadCrumbs } from '../../../../../components/BreadCrumbs';
import { DetailsWrapper } from '../../../../../components/DetailsWrapper';
import { VirtualCatalogSummary } from '../../../../../components/CatalogSummary/VirtualCatalogSummary.js';
import { catalogDetailsMsg, deviceListsMsg, omaElisaForCompaniesMsg, t } from '../../../../../common/i18n';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { CatalogParams } from '../../../../../components/CatalogConfiguration/CatalogConfiguration.js';

export const CompanyInfoCatalogListInfoPath = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params: CatalogParams = location.state;

  useEffect(() => {
    if (!location.state) {
      navigate(paths.COMPANY_INFO_CATALOGS, { replace: true });
    }
  }, [location.state, navigate]);

  if (!params) {
    return null;
  }

  return (
    <DetailsWrapper
      classes={['of-catalog-products-summary']}
      detailsTop={
        <BreadCrumbs
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: t.COBB(deviceListsMsg), path: paths.COMPANY_INFO_CATALOGS },
            { name: t.XE4X(catalogDetailsMsg) },
          ]}
        />
      }
      heading={t.XE4X(catalogDetailsMsg)}
      heroWhiteBackground={true}
      id="catalog-products-summary"
    >
      <VirtualCatalogSummary />
    </DetailsWrapper>
  );
};
