import {
  SubscriptionAdditionalServiceStatusEnum,
  SubscriptionAddonCode,
  SubscriptionCategory,
} from '../../common/enums.js';
import { SubscriptionPbxDetails } from '../../generated/api/models.js';
import { englishMsg, finnishMsg, inUseMsg, monthMsg, notInUseMsg, swedishMsg, t } from '../../common/i18n/index.js';
import { eurosToCents, formatSum } from '../../common/utils/priceUtils.js';
import {
  getAssociationsAvailability,
  getRequiredDependencyActions,
} from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type {
  AddOn,
  AddOnVisibility,
  CommercialProduct,
  Offer,
  Price,
  Subscription,
  SubscriptionDetails,
  SubscriptionDetailsSelectedAddOns,
} from '../../generated/api/models.js';
import type { AddedAddon } from '../../common/types/addon.js';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { SubscriptionAddOnAttributesMap } from '../../selfservice/actions/index.js';
import type { SubscriptionAddOnRulesState } from '../../common/types/states.js';
import type { SubscriptionAdditionalServiceStatus } from '../SubscriptionAdditionalService/SubscriptionAdditionalService.js';

export interface BaseRules {
  commercialProductId: string;
  commercialProductCode: string;
  addOnAssociations: AssociationRecord[];
  addOnDependencies: DependencyRecord[];
}

type InvalidCategory = 'ADD_RELATED' | 'DELETE_RELATED' | 'EXCLUSION' | 'TOO_MANY' | 'TOO_LESS' | 'SYSTEM';

export interface AddOnEditabilityRecord {
  addOnCode: string;
  isEditable: boolean;
  addOnCodesToAdd: string[];
  addOnCodesToDelete: string[];
  addOnCodesToExclude: string[];
  potentialCategory: InvalidCategory[];
  currentCategory: InvalidCategory[];
  groupName: string;
  groupMax: number;
  groupMin: number;
  groupUsed: number;
  groupMemberCodes: string[];
  removableOnlyFromEoe?: boolean;
}

interface GroupDetails {
  addOnCode: string;
  groupName: string;
  max: number;
  min: number;
  default: number;
  members: string[];
  used: number;
}

interface DependencyResult {
  dependentCodesToAdd: string[];
  dependentCodesToDelete: string[];
  incompatibleAddOnCodes: string[];
  openingFeeCodesToDelete: string[];
}

// Assets (Tellus based subscriptions) may have Mobiiliturva addon but no commercial product, which could be used to fetch the addon data,
// so this hard coded version has to be used instead.
export const MOBIILITURVA_ADDON: AddOnVisibility = {
  addOnGuidCode: SubscriptionAddonCode.MOBIILITURVA,
  addOn: {
    addOnId: 'a205r000000Z1VHAA0',
    addOnType: 'SERVICE',
    addOnGroup: 'DNS',
    addOnCode: SubscriptionAddonCode.MOBIILITURVA,
    addOnProductName: 'Elisa Mobiiliturva yrityksille',
  },
  removableOnlyFromEoe: true,
};

export interface AddOnStatusInfo {
  status: SubscriptionAdditionalServiceStatus;
  text: string;
}

export const getAddOnStatus = (isInUse: boolean, changedFeatures: string[], addOnGuid?: string): AddOnStatusInfo => {
  if (addOnGuid && changedFeatures.includes(addOnGuid)) {
    return {
      status: SubscriptionAdditionalServiceStatusEnum.CHANGE_REQUESTED,
      text: t.I9KT('Change request in progress'),
    };
  }
  return isInUse
    ? { status: SubscriptionAdditionalServiceStatusEnum.IN_USE, text: t.V34H(inUseMsg) }
    : { status: SubscriptionAdditionalServiceStatusEnum.NOT_IN_USE, text: t.MUF5(notInUseMsg) };
};

export const getRecurringPriceIfPresent = (details: SubscriptionDetails): number | undefined => {
  if (details.monthlyRecurringCharge === 0) {
    return undefined;
  }
  return details.monthlyRecurringCharge || details.monthlyRecurringListCharge;
};

export const getSubscriptionPrice = (category: string, subscription: Subscription): number | undefined => {
  if (!subscription.details) {
    return undefined;
  }
  switch (category) {
    case SubscriptionCategory.BROADBAND:
    case SubscriptionCategory.VOICE:
      return getRecurringPriceIfPresent(subscription.details);
    case SubscriptionCategory.DEVICE:
      return subscription.details.device?.omaLaiteLasku ? undefined : subscription.details.monthlyRecurringCharge;
    case SubscriptionCategory.SERVICE:
      return subscription.details.monthlyRecurringCharge;
    default:
      return undefined;
  }
};

// Opening fee AddOn Guids against selfservice online models, which gets removed when MACD is performed
const openingFeesAddonCodes = [
  '7cc7b5bf-e990-aed8-2e5c-330d81a3b0e3',
  '0b3f0db4-2819-c912-8718-7e76f0aa63d1',
  '8fe53e0c-c867-2b25-2e9e-cdde4660083f',
  '31d96d9c-0c86-0608-da54-be33c561d9f0',
  '015fe61e-6cd3-0e51-69a7-066a38ba6112',
  'ec5da085-4942-b34e-2441-071189237c5e',
  '511bc4ce-8c18-ad13-06cf-0fe9808f4ea5',
];

export const getAddOnPrice = (price?: Price): { value?: number; text: string } => {
  if (price?.monthlyRecurringCharge !== undefined) {
    const isOneTimePrice = price.monthlyRecurringCharge === 0 && (price.oneTimeCharge || 0) > 0;
    const priceValue = isOneTimePrice ? price.oneTimeCharge || 0 : price.monthlyRecurringCharge;
    const priceTextSuffix = isOneTimePrice ? '' : ` /${t.XXVX(monthMsg)}`;
    return {
      value: priceValue,
      text: `${formatSum(priceValue)}${priceTextSuffix}`,
    };
  }
  const oneTimeCharge = price?.oneTimeCharge ? price.oneTimeCharge : undefined;
  return { value: oneTimeCharge, text: `${oneTimeCharge ? formatSum(oneTimeCharge) : '-'}` };
};

export const calculateUpdatedPrice = (
  addedAddOns: AddOn[],
  removedAddOns: AddOn[],
  category: string,
  subscription: Subscription
) => {
  const existingPrice = getSubscriptionPrice(category, subscription);
  if (!existingPrice) {
    return undefined;
  }
  const priceToAdd = addedAddOns
    .map(addOn => getAddOnPrice(addOn.price?.effectivePrice).value || 0)
    .reduce((total, val) => total + val, 0);
  const priceToSubtract = removedAddOns
    .map(addOn => getAddOnPrice(addOn.price?.effectivePrice).value || 0)
    .reduce((total, val) => total + val, 0);

  return existingPrice + priceToAdd - priceToSubtract;
};

export const getAddOnCodesByIds = (baseRules: BaseRules, addOnIds: string[]) => {
  return addOnIds.map(
    addOnId =>
      baseRules.addOnAssociations.find(assoc => assoc.cspmb__add_on_price_item__c === addOnId)
        ?.cspmb__add_on_price_item__r.guid__c || ''
  );
};

export const getAddOnIdsByCodes = (baseRules: BaseRules, addOnCodes: string[]) => {
  return addOnCodes.map(
    addOnCode =>
      baseRules.addOnAssociations.find(assoc => assoc.cspmb__add_on_price_item__r.guid__c === addOnCode)
        ?.cspmb__add_on_price_item__c || ''
  );
};

export const getAddOnNamesByCodes = (baseRules: BaseRules, addOnCodes: string[]) => {
  return addOnCodes.map(
    addOnCode =>
      baseRules.addOnAssociations.find(assoc => assoc.cspmb__add_on_price_item__r.guid__c === addOnCode)
        ?.add_on_price_item_name__c || ''
  );
};

export const getAddOnNamesByIds = (baseRules: BaseRules, addOnIds: string[]) => {
  return addOnIds.map(
    addOnId =>
      baseRules.addOnAssociations.find(assoc => assoc.cspmb__add_on_price_item__c === addOnId)
        ?.add_on_price_item_name__c || ''
  );
};

export const getAddOnsByCodes = (
  addOnCodes: string[],
  addOnVisibilities: AddOnVisibility[],
  subscriptionAddOns?: AddOn[]
) => {
  const foundAddOns: AddOn[] = [];
  addOnCodes.forEach(code => {
    const visibleAddOns = addOnVisibilities.map(visibility => visibility.addOn);
    const foundAddOn = [...visibleAddOns, ...(subscriptionAddOns || [])].find(addOn => addOn?.addOnCode === code);
    if (foundAddOn) {
      foundAddOns.push(foundAddOn);
    }
  });
  return foundAddOns;
};

const updateGroupWithMemberCount = (selectedCodes: string[], groupMapping: GroupDetails[]) =>
  groupMapping.map(currentGroup => {
    const members = groupMapping
      .filter(group => group.groupName === currentGroup.groupName)
      .map(group => group.addOnCode);
    return {
      ...currentGroup,
      members: [...members],
      used: members.filter(member => selectedCodes.includes(member)).length,
    };
  });

export const populateGroupDetails = (
  associations: AssociationRecord[],
  allCodes: string[],
  selectedCodes: string[],
  commercialProductCode: string
) => {
  const groupMapping: GroupDetails[] = allCodes.map(addOnCode => {
    const foundAssoc = associations
      .filter(assoc => assoc.cspmb__price_item__r.guid__c === commercialProductCode)
      .find(assoc => assoc.cspmb__add_on_price_item__r.guid__c === addOnCode);
    return {
      groupName: foundAssoc?.cspmb__group__c || '',
      max: foundAssoc?.cspmb__max__c || 100,
      min: foundAssoc?.cspmb__min__c || 0,
      addOnCode: addOnCode,
      default: foundAssoc?.cspmb__default_quantity__c || 0,
      members: [],
      used: 0,
    };
  });
  return updateGroupWithMemberCount(selectedCodes, groupMapping);
};

const getAddOnAssociationIds = (associations: AssociationRecord[], cpCode: string, selectedAddOnCodes: string[]) => {
  return selectedAddOnCodes
    .map(
      selectedCode =>
        associations
          .filter(assoc => assoc.cspmb__price_item__r.guid__c === cpCode)
          .find(assoc => assoc.cspmb__add_on_price_item__r.guid__c === selectedCode)?.id
    )
    .filter(assocId => assocId !== undefined) as string[];
};

export const isItemChecked = (
  addOnCodesToAdd: string[],
  addOnCodesToRemove: string[],
  currentAddOnCode: string,
  subscriptionAddOnCodes: string[]
) => {
  const baseAddOns = subscriptionAddOnCodes.filter(sub => !addOnCodesToRemove.some(remove => sub === remove));
  return [...baseAddOns, ...addOnCodesToAdd].includes(currentAddOnCode);
};

export const isChangeCauseInvalidGroup = (selected: boolean, used: number, max: number, min: number) => {
  if (selected && used - 1 < min) {
    return true;
  }
  return !selected && used + 1 > max;
};

const mergeArraysAndDeduplicate = (list1: string[], list2: string[]) => {
  return list1.filter(item => !list2.includes(item)).concat(list2);
};

export const calculateDependency = (
  baseRules: BaseRules,
  selectedAddOnCodes: string[],
  allAddOnCodes: string[],
  shouldFlipCurrentAddOn: boolean,
  currentAddOnCode?: string
): DependencyResult => {
  const potentialAddOns: string[] = [];

  if (shouldFlipCurrentAddOn && currentAddOnCode) {
    // if current-addon already present then remove from list otherwise add to list
    const targetCodes = selectedAddOnCodes.includes(currentAddOnCode)
      ? selectedAddOnCodes.filter(addOnCode => addOnCode !== currentAddOnCode)
      : [currentAddOnCode, ...selectedAddOnCodes];
    potentialAddOns.push(...targetCodes);
  } else {
    potentialAddOns.push(...selectedAddOnCodes);
  }

  const selectedAssociationIds = getAddOnAssociationIds(
    baseRules.addOnAssociations,
    baseRules.commercialProductCode,
    potentialAddOns
  );

  const dependencyActions = getRequiredDependencyActions(
    baseRules.commercialProductId,
    baseRules.addOnAssociations,
    baseRules.addOnDependencies,
    selectedAssociationIds
  );

  const associationsAvailability = getAssociationsAvailability(
    baseRules.commercialProductId,
    baseRules.addOnAssociations,
    baseRules.addOnDependencies,
    selectedAssociationIds
  );

  // list of incompatible addons due to exclusion rule based on current combination
  // removing !item.isAvailable from below filter, since the interpretation of isAvailable flag is misleading
  // An item is not-available does not mean that item is incompatible. The addon combination might still be valid
  const incompatibleAddOnCodes = associationsAvailability
    .filter(item => item.isExcluded)
    .map(item => item.assoc.cspmb__add_on_price_item__r.guid__c)
    .filter(code => potentialAddOns.includes(code));

  // ignore self-dependency, sometime self ids are present as dependency - need to ignore that
  const dependentCodesToAdd = getAddOnCodesByIds(baseRules, dependencyActions.dependentAddOnsIdToAdd).filter(
    code => code !== currentAddOnCode
  );
  const dependentCodesToDelete = getAddOnCodesByIds(baseRules, dependencyActions.dependentAddOnsIdToDelete).filter(
    code => code !== currentAddOnCode
  );

  const openingFeeCodesToDelete = mergeArraysAndDeduplicate(dependentCodesToDelete, incompatibleAddOnCodes).filter(
    code => openingFeesAddonCodes.includes(code)
  );

  return {
    dependentCodesToAdd,
    dependentCodesToDelete: dependentCodesToDelete.filter(code => !openingFeesAddonCodes.includes(code)),
    incompatibleAddOnCodes: incompatibleAddOnCodes.filter(code => !openingFeesAddonCodes.includes(code)),
    openingFeeCodesToDelete,
  };
};

const processGroupRules = (
  subscriptionAddOnCodes: string[],
  visibleGuids: string[],
  addOnGuidCode: string,
  currentEditability: AddOnEditabilityRecord,
  foundGroup?: GroupDetails
): AddOnEditabilityRecord => {
  const isSelected = subscriptionAddOnCodes.includes(addOnGuidCode);
  if (foundGroup) {
    const isPotentialInvalid = isChangeCauseInvalidGroup(isSelected, foundGroup.used, foundGroup.max, foundGroup.min);
    const editableGroupMembers = foundGroup.members.filter(
      code => visibleGuids.includes(code) && subscriptionAddOnCodes.includes(code)
    );
    const isCurrentValid = foundGroup.used >= foundGroup.min && foundGroup.used <= foundGroup.max;
    const isEditable = !(isCurrentValid && isPotentialInvalid && editableGroupMembers.length === 1);
    if (isEditable) {
      if (foundGroup.used < foundGroup.min) {
        currentEditability.currentCategory.push('TOO_LESS');
      }
      if (foundGroup.used > foundGroup.max) {
        currentEditability.currentCategory.push('TOO_MANY');
      }
    } else {
      if (isSelected && foundGroup.used - 1 < foundGroup.min) {
        currentEditability.potentialCategory.push('TOO_LESS');
      }
      if (!isSelected && foundGroup.used + 1 > foundGroup.max) {
        currentEditability.potentialCategory.push('TOO_MANY');
      }
    }
    currentEditability.isEditable = currentEditability.isEditable && isEditable;
  }
  return currentEditability;
};

const processExclusionRules = (
  dependencyInfo: DependencyResult,
  currentDependencyInfo: DependencyResult,
  subscriptionAddOnCodes: string[],
  currentEditability: AddOnEditabilityRecord
) => {
  const isIncompatiblesUnderSelecteds = dependencyInfo.incompatibleAddOnCodes
    .filter(code => !openingFeesAddonCodes.includes(code))
    .some(code => subscriptionAddOnCodes.includes(code));
  if (dependencyInfo.incompatibleAddOnCodes.length > 0) {
    currentEditability.isEditable =
      currentEditability.isEditable &&
      !dependencyInfo.incompatibleAddOnCodes.includes(currentEditability.addOnCode) &&
      !isIncompatiblesUnderSelecteds;
    currentEditability.potentialCategory.push('EXCLUSION');
  }
  if (currentDependencyInfo.incompatibleAddOnCodes.length > 0) {
    currentEditability.addOnCodesToExclude = currentDependencyInfo.incompatibleAddOnCodes;
    currentEditability.currentCategory.push('EXCLUSION');
  }
};

const processAssociationRules = (
  dependencyInfo: DependencyResult,
  currentDependencyInfo: DependencyResult,
  nonEditables: string[],
  currentEditability: AddOnEditabilityRecord
) => {
  const isDeletionsUnderNonEditables = dependencyInfo.dependentCodesToDelete
    .filter(code => !openingFeesAddonCodes.includes(code))
    .some(code => nonEditables.includes(code));
  if (dependencyInfo.dependentCodesToDelete.length > 0 || dependencyInfo.dependentCodesToAdd.length > 0) {
    if (dependencyInfo.dependentCodesToAdd.length > 0) {
      currentEditability.potentialCategory.push('ADD_RELATED');
    }
    if (dependencyInfo.dependentCodesToDelete.length > 0) {
      currentEditability.potentialCategory.push('DELETE_RELATED');
    }
    if (currentDependencyInfo.dependentCodesToAdd.length > 0) {
      currentEditability.currentCategory.push('ADD_RELATED');
    }
    if (currentDependencyInfo.dependentCodesToDelete.length > 0) {
      currentEditability.currentCategory.push('DELETE_RELATED');
    }
    currentEditability.isEditable = currentEditability.isEditable && !isDeletionsUnderNonEditables;
  }
};

export const isAddonVisible = (
  isAssetWithMobiiliturva: boolean,
  subscriptionAddOnCodes: string[],
  addOnVisibility: AddOnVisibility,
  isEmployee: boolean
): boolean => {
  if (addOnVisibility.addOn && subscriptionAddOnCodes.includes(addOnVisibility.addOnGuidCode)) {
    return true;
  } else if (!isEmployee && addOnVisibility.selfServiceEditable) {
    return true;
  } else if (isEmployee && addOnVisibility.selfServiceEditable && !addOnVisibility.removableOnlyFromEoe) {
    return true;
  } else if (isEmployee && isAssetWithMobiiliturva) {
    return true;
  }
  return false;
};

export const calculateBaseDependency = (
  baseRules: BaseRules,
  subscriptionAddOnCodes: string[],
  addOnVisibilities: AddOnVisibility[],
  isAssetWithMobiiliturva: boolean,
  isEmployee: boolean,
  currentAddOnCode?: string
): AddOnEditabilityRecord[] => {
  const editabilityMap: AddOnEditabilityRecord[] = [];
  const visibleGuids = addOnVisibilities.map(visibility => visibility.addOnGuidCode);

  // tagged to subscription but not editable due to hidden
  const nonEditables = subscriptionAddOnCodes.filter(addOnCode => !visibleGuids.includes(addOnCode));

  const visibleAndHiddenAddOnCodes = [
    // visibility.addOn is never undefined as ui-api adds this, though optional in schema because SF doesn't send this
    ...addOnVisibilities.map(visibility => visibility.addOnGuidCode),
    ...nonEditables,
  ];
  const groupMapping = populateGroupDetails(
    baseRules.addOnAssociations,
    visibleAndHiddenAddOnCodes,
    subscriptionAddOnCodes,
    baseRules.commercialProductCode
  );
  const visibleAddOns = addOnVisibilities.filter(visibility =>
    isAddonVisible(isAssetWithMobiiliturva, subscriptionAddOnCodes, visibility, isEmployee)
  );

  visibleAddOns.forEach(visibility => {
    const shouldFlipCurrentAddOn = currentAddOnCode !== visibility.addOnGuidCode;
    const foundGroup = groupMapping.find(group => group.addOnCode === visibility.addOnGuidCode);
    const dependencyInfo = calculateDependency(
      baseRules,
      subscriptionAddOnCodes,
      visibleAndHiddenAddOnCodes,
      shouldFlipCurrentAddOn,
      visibility.addOnGuidCode
    );
    const currentDependencyInfo = calculateDependency(
      baseRules,
      subscriptionAddOnCodes,
      visibleAndHiddenAddOnCodes,
      false,
      visibility.addOnGuidCode
    );

    // populate editability object with default values
    const currentEditability: AddOnEditabilityRecord = {
      addOnCode: visibility.addOnGuidCode,
      isEditable: true,
      addOnCodesToAdd: dependencyInfo.dependentCodesToAdd,
      addOnCodesToDelete: dependencyInfo.dependentCodesToDelete,
      addOnCodesToExclude: dependencyInfo.incompatibleAddOnCodes,
      groupMemberCodes: foundGroup?.members || [],
      groupName: foundGroup?.groupName || '',
      groupMax: foundGroup?.max || 100,
      groupMin: foundGroup?.min || 0,
      groupUsed: foundGroup?.used || 0,
      potentialCategory: [],
      currentCategory: [],
      removableOnlyFromEoe: visibility?.removableOnlyFromEoe,
    };

    if (isAssetWithMobiiliturva) {
      currentEditability.removableOnlyFromEoe = true;
    } else if (visibility.removableOnlyFromEoe) {
      currentEditability.isEditable = false;
    } else if (!visibility.selfServiceEditable) {
      currentEditability.isEditable = false;
      currentEditability.potentialCategory.push('SYSTEM');
    } else {
      // if dependents-to-delete fall under hiddens then disable editing, dependent-to-add should always fall under visibles
      processAssociationRules(dependencyInfo, currentDependencyInfo, nonEditables, currentEditability);

      // if incompatibles fall under hiddens then disable editing
      processExclusionRules(dependencyInfo, currentDependencyInfo, subscriptionAddOnCodes, currentEditability);

      // if current addon doesn't satisfy group-level min-max rule
      processGroupRules(subscriptionAddOnCodes, visibleGuids, visibility.addOnGuidCode, currentEditability, foundGroup);
    }
    editabilityMap.push(currentEditability);
  });

  return editabilityMap;
};

export const reCalculateBaseDependency = (
  baseRules: BaseRules,
  addedAddOnCodes: string[],
  removedAddOnCodes: string[],
  currentAddOnCode: string,
  subscriptionAddOnCodes: string[],
  addOnVisibilities: AddOnVisibility[],
  isAssetWithMobiiliturva: boolean,
  isEmployee: boolean
): AddOnEditabilityRecord[] => {
  // determine current list of selected addon codes
  const remainingBaseAddOnCodes = subscriptionAddOnCodes.filter(code => !removedAddOnCodes.includes(code));
  const currentlySelectedAddOnCodes = [...remainingBaseAddOnCodes, ...addedAddOnCodes];

  return calculateBaseDependency(
    baseRules,
    currentlySelectedAddOnCodes,
    addOnVisibilities,
    isAssetWithMobiiliturva,
    isEmployee,
    currentAddOnCode
  );
};

export const checkCurrentCombination = (
  baseRules: BaseRules,
  addedAddOnCodes: string[],
  removedAddOnCodes: string[],
  subscriptionAddOnCodes: string[],
  addOnVisibilities: AddOnVisibility[]
) => {
  // determine current list of selected addon codes
  const remainingBaseAddOnCodes = subscriptionAddOnCodes.filter(code => !removedAddOnCodes.includes(code));
  const currentlySelectedAddOnCodes = [...remainingBaseAddOnCodes, ...addedAddOnCodes];

  const visibleGuids = addOnVisibilities.map(visibility => visibility.addOnGuidCode);

  // tagged to subscription but not editable due to hidden
  const nonEditables = subscriptionAddOnCodes.filter(addOnCode => !visibleGuids.includes(addOnCode));
  const visibleAndHiddenAddOnCodes = [...visibleGuids, ...nonEditables];

  const dependencyInfo = calculateDependency(baseRules, currentlySelectedAddOnCodes, visibleAndHiddenAddOnCodes, false);
  const groupDetails = populateGroupDetails(
    baseRules.addOnAssociations,
    visibleAndHiddenAddOnCodes,
    currentlySelectedAddOnCodes,
    baseRules.commercialProductCode
  );

  return {
    isValid:
      dependencyInfo.incompatibleAddOnCodes.length === 0 &&
      dependencyInfo.dependentCodesToDelete &&
      !groupDetails.some(group => group.used < group.min || group.used > group.max),
    openingFeeCodesToDelete: dependencyInfo.openingFeeCodesToDelete,
  };
};

export const isAddonCheckboxEnabled = (
  subscriptionAddOnCodes: string[],
  addOnVisibility: AddOnVisibility,
  isEmployee: boolean,
  editRecord?: AddOnEditabilityRecord
) =>
  editRecord?.isEditable ||
  (isEmployee && editRecord?.removableOnlyFromEoe && subscriptionAddOnCodes.includes(addOnVisibility.addOnGuidCode));

export const isMobileVoiceAddOn = (addOnCode: string) => addOnCode === SubscriptionAddonCode.MOBILE_VOICE;

export const getMobileVoiceLanguageOptions = () => [
  { label: t.EXXX(finnishMsg), value: 'FI' },
  { label: t.CYY2(swedishMsg), value: 'SV' },
  { label: t.R1PB(englishMsg), value: 'EN' },
];

export const getDefaultSelectedLanguage = () => getMobileVoiceLanguageOptions()[0].value;

export const determineSelectedLanguage = (subscriptionAddOns?: SubscriptionDetailsSelectedAddOns[]) => {
  const voiceMailAddOn = subscriptionAddOns?.find(addOn => isMobileVoiceAddOn(addOn.addOnCode));
  return voiceMailAddOn ? voiceMailAddOn.addOnAttributes?.language || getDefaultSelectedLanguage() : undefined;
};

export const isMobileVoiceLanguageUpdated = (
  addedOrRemovedCodes: string[],
  subscriptionAddOns?: SubscriptionDetailsSelectedAddOns[],
  selectedLanguage?: string
) =>
  !addedOrRemovedCodes.includes(SubscriptionAddonCode.MOBILE_VOICE) &&
  determineSelectedLanguage(subscriptionAddOns) !== selectedLanguage;

export const getMobileVoiceAttributes = (
  addedCodes: string[],
  updatedCodes: string[],
  selectedLanguage?: string
): SubscriptionAddOnAttributesMap | undefined =>
  [...addedCodes, ...updatedCodes].includes(SubscriptionAddonCode.MOBILE_VOICE) && selectedLanguage
    ? { [SubscriptionAddonCode.MOBILE_VOICE]: { language: selectedLanguage } }
    : undefined;

export const updateVisibilitiesIfRing = (
  subscription: Subscription,
  pbxSolutions?: Subscription[],
  addOnVisibilities?: AddOnVisibility[]
) => {
  const pbxConfiguration = subscription.details?.mobile?.pbxConfiguration;
  const isRingAttached =
    pbxConfiguration &&
    pbxSolutions?.some(pbxSolution => pbxSolution.subscriptionId === pbxConfiguration.pbxSolutionId) &&
    pbxConfiguration.pbxConfigurationDetails?.pbxType === SubscriptionPbxDetails.PbxTypeEnum.RING;

  return isRingAttached
    ? addOnVisibilities?.map(visibility =>
        isMobileVoiceAddOn(visibility.addOnGuidCode) ? { ...visibility, selfServiceEditable: false } : visibility
      )
    : addOnVisibilities;
};

export const filterVisibilitiesBasedOnRing = (
  isRingSelected: boolean,
  addOnVisibilities: AddOnVisibility[] = []
): AddOnVisibility[] => {
  if (!isRingSelected) {
    return addOnVisibilities;
  }

  return addOnVisibilities.map(visibility => {
    if (isMobileVoiceAddOn(visibility.addOnGuidCode)) {
      return { ...visibility, selfServiceEditable: false };
    }
    return visibility;
  });
};

export const getAddOnPriceFromAssociation = (association: AssociationRecord): Price => {
  const monthlyPrice = association.cspmb__overrides_add_on_charges__c
    ? association.cspmb__recurring_charge__c
    : association.cspmb__add_on_price_item__r?.cspmb__recurring_charge__c;
  const oneTimePrice = association.cspmb__overrides_add_on_charges__c
    ? association.cspmb__one_off_charge__c
    : association.cspmb__add_on_price_item__r?.cspmb__one_off_charge__c;

  const monthlyRecurringCharge = monthlyPrice !== undefined ? eurosToCents(monthlyPrice) : undefined;
  const oneTimeCharge = oneTimePrice !== undefined ? eurosToCents(oneTimePrice) : undefined;
  return { monthlyRecurringCharge, oneTimeCharge };
};

export const getAddedAddOn = (association: AssociationRecord): AddedAddon => {
  const { monthlyRecurringCharge, oneTimeCharge } = getAddOnPriceFromAssociation(association);

  return {
    addOnCode: association.cspmb__add_on_price_item__r?.guid__c,
    addOnAssociationCode: association.guid__c,
    displayName: association.cspmb__add_on_price_item__r?.name,
    monthlyPrice: monthlyRecurringCharge,
    oneTimePrice: oneTimeCharge,
  };
};

export const getAddedAddOns = (
  addOnCodes: string[],
  offer: Offer,
  addOnRules?: SubscriptionAddOnRulesState | { associations?: AssociationRecord[] } | null
): AddedAddon[] => {
  if (!addOnRules) {
    return [];
  }
  const addedAddOns: AddedAddon[] = [];
  addOnCodes.forEach(addOnCode => {
    const foundAssociation: AssociationRecord | undefined = addOnRules?.associations?.find(
      association =>
        association?.cspmb__price_item__r?.guid__c === offer.commercialProducts[0]?.commercialProductCode &&
        association?.cspmb__add_on_price_item__r.guid__c === addOnCode &&
        association.is_active__c
    );
    if (foundAssociation) {
      addedAddOns.push(getAddedAddOn(foundAssociation));
    }
  });
  return addedAddOns;
};

export const getAddOnFromRules = (
  addOnCode: string,
  cpCode: string,
  associations: AssociationRecord[]
): AddOn | undefined => {
  const foundAssociation: AssociationRecord | undefined = associations.find(
    association =>
      association?.cspmb__price_item__r?.guid__c === cpCode &&
      association?.cspmb__add_on_price_item__r.guid__c === addOnCode &&
      association.is_active__c
  );
  const { monthlyRecurringCharge, oneTimeCharge } = foundAssociation
    ? getAddOnPriceFromAssociation(foundAssociation)
    : { monthlyRecurringCharge: undefined, oneTimeCharge: undefined };
  return {
    addOnCode,
    addOnGroup: foundAssociation?.cspmb__group__c || '',
    addOnId: foundAssociation?.cspmb__add_on_price_item__c,
    addOnProductName: foundAssociation?.cspmb__add_on_price_item__r.name || '',
    addOnType: 'SERVICE',
    price: {
      listPrice: { monthlyRecurringCharge, oneTimeCharge },
      effectivePrice: { monthlyRecurringCharge, oneTimeCharge },
    },
    addOnMonthlyRecurringCharge: monthlyRecurringCharge,
    addOnOneTimeCharge: oneTimeCharge,
    display: true,
  };
};

export const getBaseRules = (
  commercialProduct: CommercialProduct,
  associations: AssociationRecord[],
  dependencies: DependencyRecord[]
): BaseRules => ({
  commercialProductId: commercialProduct.commercialProductId || '',
  commercialProductCode: commercialProduct.commercialProductCode,
  addOnAssociations: associations,
  addOnDependencies: dependencies,
});
