import { DialogWrapper } from '../DialogWrapper/index.js';
import { cancelMsg, confirmMsg, t } from '../../common/i18n/index.js';
import { convertStringMapToCommonErrors } from '../../common/utils/errorUtils.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getContactInfo } from '../../common/utils/stateUtils.js';
import { updateUserDetails } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import type { ContactPerson } from '../../generated/api/contactPerson.js';
import type { State } from '../../selfservice/common/store.js';

export interface EmployeeYttContactChangeDialogProps {
  onCloseDialog: () => void;
  values: ContactPerson;
  onConfirm: () => void;
  validationErrors?: { [s: string]: string };
}

export const EmployeeYttContactChangeDialog = ({
  onCloseDialog,
  onConfirm,
  values,
  validationErrors,
}: EmployeeYttContactChangeDialogProps) => {
  const { authenticatedUser } = useAuth();
  const customerType = useSelector((state: State) => state.selfservice?.companyInfo?.customerType, deepEqual);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  return (
    <DialogWrapper
      buttons={[
        {
          color: 'link',
          onClick: onCloseDialog,
          text: t.WOYD(cancelMsg),
        },
        {
          onClick: () => {
            dispatch(
              updateUserDetails(
                { ...getContactInfo(authenticatedUser!), ...values },
                customerType,
                convertStringMapToCommonErrors(validationErrors)
              )
            );
            setLoading(true);
            onConfirm();
          },
          text: t.QVYK(confirmMsg),
          isLoading: loading,
        },
      ]}
      closeable
      header={t.V02H('Please verify, this will change the credentials for Elisa Yritystietoturva')}
      onCloseDialog={onCloseDialog}
    >
      <div>
        {t.OB4C(
          'You are the administrator of Elisa Yritystietoturva. Note that if you change your name, mobile phone number, or email address, the administrator credentials to Elisa Yritystietoturva will also change, and you will be logged out of the software.'
        )}
      </div>
      <div className="ds-margin-top--4">
        {t.FZAU(
          'You will then receive new credentials from F-Secure via email. Use the new credentials to log back in.'
        )}
      </div>
    </DialogWrapper>
  );
};
