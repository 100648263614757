import { CheckoutLoginOrContactInfoStep } from '../../../components/Checkout/steps/CheckoutLoginOrContactInfoStep.js';
import { loadBillingAccounts } from '../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import type { CheckoutStepProps } from '../FixedBroadbandCheckout.js';

export const StepLoginOrContactInfo = (props: CheckoutStepProps) => {
  const dispatch = useDispatch();

  const { enterNextStep } = props;

  return (
    <CheckoutLoginOrContactInfoStep
      onNext={() => {
        dispatch(loadBillingAccounts());
        enterNextStep(2);
      }}
    />
  );
};
