import { ACTIVE_ACCOUNT_MASTER_ID_LOCAL_STORAGE_KEY } from './common/localStorageUtils.js';
import {
  catalogToValidationRules,
  eppSolutionToValidationRules,
  loadVirtualCatalogs,
  postProcessCartItems,
  setActiveAccount,
} from './actions/index.js';
import { findCatalogById } from '../common/utils/catalogUtils.js';
import { isEmployeePortal } from '../common/utils/browserUtils.js';
import { isInBrowser } from '../common/utils/ssrUtils.js';
import type { Dispatch, Store } from 'redux';
import type { State } from './common/store.js';

const CART_ITEMS_LOCAL_STORAGE_KEY = 'ngStorage-cartItems-default';

export const initializeShoppingCart = (store: Store<State>): void => {
  const isEmployee = isEmployeePortal(location.pathname);

  if (isEmployee) {
    store.dispatch(loadVirtualCatalogs());
  }

  if (isInBrowser()) {
    window.addEventListener('storage', event => {
      if (event.key === CART_ITEMS_LOCAL_STORAGE_KEY) {
        const state = store.getState();
        const selectedCatalog = findCatalogById(
          state.selfservice?.virtualCatalogs?.items,
          state.selfservice?.virtualCatalogs?.selectedCatalogCode
        );

        const validationRules = isEmployee
          ? catalogToValidationRules(selectedCatalog)
          : eppSolutionToValidationRules(state.selfservice?.companyInfo?.eppSolution);

        store.dispatch(
          postProcessCartItems(
            true,
            isEmployee,
            event.newValue || '[]',
            validationRules,
            undefined,
            undefined,
            undefined,
            true
          )
        );
      }
    });
  }
};

export const initializeActiveAccountChangeListener = (dispatch: Dispatch): void => {
  if (isInBrowser()) {
    window.addEventListener('storage', event => {
      if (event.key === ACTIVE_ACCOUNT_MASTER_ID_LOCAL_STORAGE_KEY && event.newValue) {
        dispatch(setActiveAccount(event.newValue));
      }
    });
  }
};
