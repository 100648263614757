import { BreadCrumbsWithTitle } from '../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { DetailsWrapper } from '../../../../../components/DetailsWrapper';
import { EppRedeemAdmin } from '../../../../../components/EppRedeem/EppRedeemAdmin.js';
import { SubscriptionAction } from '../../../../../generated/api/models.js';
import { devicesMsg, omaElisaForCompaniesMsg, redemptionMsg, t } from '../../../../../common/i18n';
import { generatePath, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { isEppDeviceSubscription } from '../../../../../components/EmployeeSubscriptionDetails/eppSubscriptionUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useEffect } from 'react';
import type { DeviceSubPostChangeRequestLoaderResponse } from '../../../../../common/loaders.js';

const generateDeviceDetailsPath = (subscriptionDisplayId: string, search: string) =>
  generatePath(paths.PS_DEVICE, { subscriptionId: subscriptionDisplayId }) + search;

export const DeviceRedemptionPath = () => {
  const navigate = useNavigate();
  const { companyInfo, pendingSubscriptionActions, subscription } =
    useLoaderData() as DeviceSubPostChangeRequestLoaderResponse;
  const { search } = useLocation();
  const companyName = companyInfo.companyName;
  const subscriptionDisplayId = subscription?.subscriptionDisplayId;
  const deviceNotRedeemable = pendingSubscriptionActions?.some(
    subscriptionAction =>
      subscriptionAction.subscriptionId === subscription.subscriptionId &&
      [
        SubscriptionAction.StatusEnum.PENDING,
        SubscriptionAction.StatusEnum.PENDING_APPROVAL,
        SubscriptionAction.StatusEnum.ERROR,
      ].includes(subscriptionAction.status)
  );
  const isRedeemableEppSubscription = isEppDeviceSubscription(subscription) && !deviceNotRedeemable;

  useEffect(() => {
    if (!isRedeemableEppSubscription) {
      navigate(generateDeviceDetailsPath(subscriptionDisplayId, search), {
        replace: true,
      });
    }
  }, [isRedeemableEppSubscription, navigate, search, subscriptionDisplayId]);

  if (!isRedeemableEppSubscription) {
    return null;
  }

  return (
    <DetailsWrapper
      classes={['of-subscription-details']}
      detailsTop={
        <BreadCrumbsWithTitle
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: t.JJTM(devicesMsg), path: paths.PS_DEVICES },
            {
              name: subscriptionDisplayId,
              path: generateDeviceDetailsPath(subscriptionDisplayId, search),
            },
            { name: t.B9EG(redemptionMsg) },
          ]}
        />
      }
      heading={t.KK9D('Redeeming your device')}
      headingBottom={
        `${subscription.subscriptionName}` +
        (subscription?.subscriptionContactName ? ` | ${subscription.subscriptionContactName}` : '')
      }
      heroPicto={undefined}
      heroIcon="invoice"
      id={`subscription-details-${subscriptionDisplayId}`}
    >
      <EppRedeemAdmin
        companyName={companyName}
        onBackClick={() => navigate(generateDeviceDetailsPath(subscriptionDisplayId, search))}
        subscription={subscription}
      />
    </DetailsWrapper>
  );
};
