import { BreadCrumbsWithTitle } from '../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { SimActivation } from '../../../../../components/SimActivation/SimActivation.js';
import { SubscriptionAction } from '../../../../../generated/api/models.js';
import { SubscriptionCategory } from '../../../../../common/enums.js';
import {
  activateSimCardMsg,
  internetSubscriptionsMsg,
  omaElisaForCompaniesMsg,
  t,
} from '../../../../../common/i18n/index.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { findSubscription, getSubscriptionStatus } from '../../../../../common/utils/subscriptionUtils.js';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  loadBillChannels,
  loadSubscriptionAddOnRules,
  loadSubscriptions,
} from '../../../../../selfservice/actions/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { State } from '../../../../../selfservice/common/store.js';
import type { SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const BroadbandSubActivateSimPath = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { subscriptionId } = useParams<SubscriptionId>();
  const broadbandSubs = useSelector(
    (state: State) => state.selfservice?.subscriptions?.broadband || undefined,
    deepEqual
  );
  const companyInfo = useSelector((state: State) => state?.selfservice?.companyInfo, deepEqual);
  const subscriptionActions = useSelector((state: State) => state?.selfservice?.subscriptionActions, deepEqual);

  useEffect(() => {
    dispatch(loadSubscriptions({ category: SubscriptionCategory.BROADBAND, displayId: subscriptionId }));
    dispatch(loadBillChannels());
    dispatch(loadSubscriptionAddOnRules());
  }, [dispatch, pathname, subscriptionId]);

  if (!subscriptionId || !companyInfo) {
    return null;
  }
  const subscription = findSubscription(subscriptionId, broadbandSubs);
  const { pendingActionType, pendingActions } = getSubscriptionStatus(subscription, subscriptionActions?.items);
  const pendingSimChanges = pendingActionType === SubscriptionAction.SubscriptionActionTypeEnum.CHANGE_SIM;

  return (
    <SimActivation
      breadCrumbs={
        <BreadCrumbsWithTitle
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: t.SEYV(internetSubscriptionsMsg), path: paths.PS_BROADBAND_SUBSCRIPTIONS },
            {
              name: subscriptionId,
              path: generatePath(paths.PS_BROADBAND_SUBSCRIPTION, { subscriptionId }),
            },
            { name: t.M1E7(activateSimCardMsg) },
          ]}
        />
      }
      category={SubscriptionCategory.BROADBAND}
      companyInfo={companyInfo}
      disallowBillableSimChanges={false}
      pendingSimChanges={pendingSimChanges}
      pendingSubscriptionChanges={pendingActions}
      onClickOrderSim={() => navigate(generatePath(paths.PS_BROADBAND_SUBSCRIPTION_ORDER_SIM, { subscriptionId }))}
      onClickSubscription={() => {
        navigate(
          generatePath(paths.PS_BROADBAND_SUBSCRIPTION, { subscriptionId: subscription?.subscriptionDisplayId })
        );
      }}
      subscription={subscription}
      simChangeServiceFee={
        broadbandSubs?.serviceFees?.find(
          serviceFee => serviceFee.subscriptionDisplayId === subscription?.subscriptionDisplayId
        )?.price
      }
    />
  );
};
