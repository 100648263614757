import { FixedBroadbandBillingInformation } from '../FixedBroadbandBillingInformation.js';
import { SELECTED_COMMERCIAL_PRODUCT_INDEX } from '../../../common/constants/commonConstants.js';
import { SiteContext } from '../../../public/site/SiteContext.js';
import { createFixedBroadbandOrder } from '../FixedBroadbandSupportCaseUtils.js';
import { createNettiliteEcommerceItemsFromConfiguredOffer } from '../../../selfservice/common/googleEcommerceEvent.js';
import { getAddedAddOns } from '../FixedBroadbandUtils.js';
import { submitFixedBroadbandOrder } from '../../../selfservice/actions/fixedBroadbandActions.js';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import type { AddOn } from '../../../generated/api/addOn.js';
import type { AddressSearchMatch } from '../../../components/AddressSearch/AddressSearch.js';
import type { BillingAccount } from '../../../generated/api/billingAccount.js';
import type { CampaignAssociation } from '../../../generated/api/campaignAssociation.js';
import type { CheckoutStepProps } from '../FixedBroadbandCheckout.js';
import type { CompanyInfoState } from '../../../common/types/states.js';
import type { ConfiguredOffer } from '../../../common/types/commercialProduct.js';
import type { Contact } from '../../../generated/api/contact.js';
import type { FixedBBCheckoutLoaderData } from '../../../common/loaders.js';
import type { FixedBroadbandInstallationDetails } from '../FixedBroadbandSetupDate.js';
import type { OnlineModel } from '../../../generated/api/onlineModel.js';
import type { PurposeOfUseOrContact } from '../../../common/types/subscription.js';
import type { State } from '../../../selfservice/exports.js';

interface StepBillingInformationProps extends CheckoutStepProps {
  configuredOffer: ConfiguredOffer;
  fixedBroadbandOnlineModel: OnlineModel;
  selectedCampaign?: CampaignAssociation;
  setCampaignAutoSelect: React.Dispatch<React.SetStateAction<boolean>>;
  additionalAddOns: AddOn[];
  addressSearchMatch: AddressSearchMatch;
  purposeOfUseOrContact?: PurposeOfUseOrContact;
  yritystuplaPurposeOfUse?: PurposeOfUseOrContact;
  broadbandInstallationDetails?: FixedBroadbandInstallationDetails;
  companyInfo?: CompanyInfoState | null;
  fixedBBCheckoutLoaderData: FixedBBCheckoutLoaderData;
}

export const StepBillingInformation = (props: StepBillingInformationProps) => {
  const dispatch = useDispatch();
  const { siteBaseUrl } = useContext(SiteContext);
  const { pathname, search } = useLocation();

  const {
    configuredOffer,
    fixedBroadbandOnlineModel,
    selectedCampaign,
    setCampaignAutoSelect,
    additionalAddOns,
    addressSearchMatch,
    purposeOfUseOrContact,
    yritystuplaPurposeOfUse,
    broadbandInstallationDetails,
    companyInfo,
    fixedBBCheckoutLoaderData,
  } = props;

  const { contacts } = useSelector((state: State) => ({
    contacts: state?.selfservice?.contacts?.items || [],
  }));

  const configuredCommercialProduct = configuredOffer.selectedCommercialProducts[SELECTED_COMMERCIAL_PRODUCT_INDEX];
  const selectedCommercialProduct = configuredCommercialProduct.commercialProduct;

  const submitFixedBroadBandOrderCase = (
    selectedBillingAccount: BillingAccount,
    createNewBillingAccount: boolean,
    newBillingAccountCommonFunction?: Contact
  ) => {
    configuredCommercialProduct.addedAddOns = [
      ...(configuredCommercialProduct.addedAddOns || []),
      ...getAddedAddOns(additionalAddOns),
    ];

    const fixedBroadbandOrder = createFixedBroadbandOrder({
      contacts,
      addressSearchMatch,
      selectedCommercialProduct,
      purposeOfUseOrContact: purposeOfUseOrContact!,
      yritystuplaPurposeOfUse,
      broadbandInstallationDetails,
      currentUrl: `${siteBaseUrl}${pathname}${search}`,
      companyInfo: companyInfo!,
      selectedBillingAccount: selectedBillingAccount!,
      configuredOffer,
    });

    const eCommerceEventProducts = createNettiliteEcommerceItemsFromConfiguredOffer(configuredOffer);

    dispatch(
      submitFixedBroadbandOrder({
        fixedBroadbandOrder,
        newBillingAccount: createNewBillingAccount ? selectedBillingAccount : undefined,
        newBillingAccountCommonFunction,
        configuredOffer,
        eCommerceEventProducts,
      })
    );
  };

  return (
    <FixedBroadbandBillingInformation
      onSubmit={submitFixedBroadBandOrderCase}
      configuredOffer={configuredOffer}
      fixedBroadBandOnlineModel={fixedBroadbandOnlineModel}
      selectedCampaign={selectedCampaign}
      setCampaingAutoSelect={setCampaignAutoSelect}
      billChannels={fixedBBCheckoutLoaderData.billChannels}
    />
  );
};
