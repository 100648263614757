import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { CreateNewContact } from '../../../../../components/CreateNewContact/CreateNewContact.js';
import { useStore } from 'react-redux';
import type { State } from '../../../../../selfservice/common/store.js';

export const CompanyInfoNewContactPath = () => {
  const config = useStore<State>().getState().config;

  return (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.CONTACTS} isSingular={true}>
      <CreateNewContact config={config} />
    </CompanyInfoContainer>
  );
};
