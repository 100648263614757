import { CommonErrorType, ModelType } from '../../../../common/enums.js';
import { EmptyOrError, getNotFoundError } from '../../../../components/EmptyOrError/index.js';
import { FixedBroadbandCheckout } from '../../../../components/FixedBroadbandCheckout/FixedBroadbandCheckout.js';
import { OnlineModelCategory } from '../../../../generated/api/onlineModelCategory.js';
import { applyWizardEvent, searchAddress as searchAddressAction } from '../../../../selfservice/actions/index.js';
import { getNewAddOnVisibilities, resolveSelectedCommercialProductCode } from './fixedBroadbandAddOnUtil.js';
import { paths } from '../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLoaderData, useLocation, useNavigate, useRouteLoaderData } from 'react-router-dom';
import type { AddOnVisibility } from '../../../../generated/api/addOnVisibility.js';
import type { CommercialProductAddOnVisibilitiesMap } from '../../../../common/utils/addOnVisibilityUtils.js';
import type { FixedBBCheckoutLoaderData, FixedBBRootLoaderData } from '../../../../common/loaders.js';
import type { State } from '../../../../selfservice/common/store.js';

export const FixedBroadbandCheckoutPath = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { onlineModels, errors } = useRouteLoaderData('fixedBBRoot') as FixedBBRootLoaderData;
  const { addressSearchResult, companySearchResult } = useSelector((s: State) => ({
    addressSearchResult: s?.resources?.addressSearchResult,
    companySearchResult: s?.resources?.companySearchResult,
  }));
  const { state } = useLocation();
  const fixedBBCheckoutLoaderData = useLoaderData() as FixedBBCheckoutLoaderData;
  const { preLoadedAddOnRules } = fixedBBCheckoutLoaderData;
  const [publicAddOnVisibilities, setPublicAddOnVisibilities] = useState<AddOnVisibility[]>();
  const [commercialProductAddOnVisibilitiesMap, setCommercialProductAddOnVisibilitiesMap] =
    useState<CommercialProductAddOnVisibilitiesMap>({});

  const fixedBroadbandOnlineModel = onlineModels?.find(
    model => model.category === OnlineModelCategory.FIXED_BROADBAND && model.onlineModelCode !== ModelType.Yritystupla
  );

  const commercialProductCode = resolveSelectedCommercialProductCode(
    state?.selectedOfferCode,
    fixedBroadbandOnlineModel
  );

  const yritystuplaModel = onlineModels?.find(onlineModel => onlineModel.onlineModelCode === ModelType.Yritystupla);

  const validationErrors = errors?.filter(err => err.type === CommonErrorType.VALIDATION);

  useEffect(() => {
    if (!addressSearchResult) {
      dispatch(searchAddressAction(state?.searchAddress, state?.postalCode));
    }
  }, [addressSearchResult, dispatch, state?.searchAddress, state?.postalCode]);

  useEffect(() => {
    dispatch(applyWizardEvent(2, 'FIXED_BROADBAND_ORDER'));
  }, [dispatch]);

  useEffect(() => {
    if (!commercialProductCode) {
      return;
    }

    if (commercialProductCode in commercialProductAddOnVisibilitiesMap) {
      return;
    }

    (async () => {
      const newVisibilities = await getNewAddOnVisibilities(commercialProductCode, preLoadedAddOnRules);

      const combinedVisibilities = {
        ...commercialProductAddOnVisibilitiesMap,
        ...newVisibilities.commercialProductAddOnVisibilitiesMap,
      };

      setPublicAddOnVisibilities(newVisibilities.addOnVisibilities);
      setCommercialProductAddOnVisibilitiesMap(combinedVisibilities);
    })();
  }, [commercialProductAddOnVisibilitiesMap, commercialProductCode, preLoadedAddOnRules]);

  if (
    !(
      state?.selectedOfferCode &&
      addressSearchResult?.postalCode &&
      addressSearchResult?.match &&
      fixedBroadbandOnlineModel
    )
  ) {
    return !state?.previousUrl ? (
      <EmptyOrError {...getNotFoundError()} onButtonClick={() => navigate(paths.ANONYMOUS_FIXED_BROADBAND_ORDER)} />
    ) : null;
  }

  return (
    <FixedBroadbandCheckout
      yritystuplaModel={yritystuplaModel}
      selectedOfferCode={state?.selectedOfferCode}
      addressSearchMatch={{ ...addressSearchResult?.match, postalCode: addressSearchResult?.postalCode }}
      companySearchResult={companySearchResult}
      fixedBroadbandOnlineModel={fixedBroadbandOnlineModel!}
      validationErrors={validationErrors}
      isMobileProduct={state?.isMobileProduct}
      commercialProductAddOnVisibilities={commercialProductAddOnVisibilitiesMap}
      publicAddOnVisibilities={publicAddOnVisibilities}
      fixedBBCheckoutLoaderData={fixedBBCheckoutLoaderData}
    />
  );
};
