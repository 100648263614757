import { type OpenFormAnswers } from '../OpenFormAnswers.js';
import { type OpenFormDispatcher } from '../OpenFormHooks/useDispatcher.js';
import { OpenFormGridCol, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { OpenFormQuestionType } from '../OpenFormQuestion/OpenFormQuestionType.js';
import { OpenFormRules } from '../OpenFormRules.js';
import { OpenFormSectionLabel } from '../OpenFormComponents/OpenFormSectionLabel.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';

export const OpenFormPageQuestion = ({
  answers,
  section,
  disabled,
  dispatcher,
}: {
  answers: OpenFormAnswers;
  section: OpenFormSection;
  disabled: boolean;
  dispatcher: OpenFormDispatcher;
}) => (
  <OpenFormGridRow>
    <OpenFormSectionLabel section={section} />
    {section.questions.filter(OpenFormRules.isVisible(answers)).map(question => (
      <OpenFormGridRow id={question.guid} key={question.guid}>
        <OpenFormGridCol className="of-openform__view__choices">
          <OpenFormQuestionType answers={answers} question={question} disabled={disabled} dispatcher={dispatcher} />
        </OpenFormGridCol>
      </OpenFormGridRow>
    ))}
  </OpenFormGridRow>
);
