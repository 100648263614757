import * as CL from '@design-system/component-library';
import { type Choices } from '../OpenFormAnswers.js';
import { type Contact } from '../OpenFormContext.js';
import { OpenFormGridCol, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { type SetAnswer } from '../OpenFormHooks/useDispatcher.js';
import { getValue } from '../OpenFormUtils.js';
import { selectMsg, t } from '../../../common/i18n/index.js';
import { useFetchContacts } from '../OpenFormHooks/useFetchContacts.js';
import { useMemo } from 'react';
import classNames from 'classnames';

const toComboboxItem = (contact: Contact) => ({
  html: (
    <div>
      <div>{`${contact.firstName} ${contact.lastName}`}</div>
      <div>{[contact.email, contact.phoneNumber].filter(Boolean).join(' | ')}</div>
    </div>
  ),
  id: contact.contactId!,
  label: `${contact.firstName} ${contact.lastName}`,
  value: contact.contactId!,
});

export const OpenFormQuestionContact = ({
  disabled,
  required,
  label,
  selected,
  setAnswer,
}: {
  disabled: boolean;
  required: boolean;
  label: string;
  selected: Choices | undefined;
  setAnswer: SetAnswer;
}) => {
  const contacts = useFetchContacts();

  return (
    <OpenFormGridRow>
      <OpenFormGridCol
        colWidth={6}
        className={classNames('ds-justify-content--center', { ['label--mandatory']: required })}
      >
        <CL.Combobox
          label={label}
          i18n_combobox_placeholderText={t.QRYV(selectMsg)}
          disabled={disabled}
          items={useMemo(() => contacts?.map(toComboboxItem) ?? [], [contacts])}
          selectedValue={getValue(selected)}
          onValueSelect={el =>
            setAnswer(
              el?.dataset.value,
              'contact',
              contacts?.find(c => c.contactId === el?.dataset.value)
            )
          }
        />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
