import { CREATED_CONTACT } from '../../common/react-hook-form/fields/Contact.js';
import { ContactType } from '../../generated/api/contactType.js';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { DuplicateContactsDialog } from './DuplicateContact.js';
import { Email, Name, PhoneNumber } from '../../common/react-hook-form/fields/index.js';
import { FormProvider, type UseFormReturn, useForm } from 'react-hook-form';
import { ModalCloseAndSubmitButtons } from '../Modal/ModalCloseAndSubmitButtons.js';
import { addContactMsg, addMsg, firstNameMsg, lastNameMsg, t } from '../../common/i18n/index.js';
import { fetchDuplicateContactCheck } from '../../common/fetch.js';
import { useState } from 'react';
import type { ContactAndDuplicateContactsProps } from '../../common/react-hook-form/fields/Contact.js';
import type { ContactHeader } from '../../generated/api/contactHeader.js';
import type { Contact as ContactInterface } from '../../generated/api/contact.js';
import type { ContactPerson } from '../../generated/api/contactPerson.js';

const getContactAndDuplicateContacts = async (contact: ContactInterface): Promise<ContactAndDuplicateContactsProps> => {
  const contactsDuplicateCheck = { contacts: [{ identifier: '', contact: contact }] };
  const { duplicateContacts } = await fetchDuplicateContactCheck(contactsDuplicateCheck);
  return { contact, duplicateContacts: duplicateContacts || [] };
};

interface NewContactProps {
  name: string;
  id: string;
  formContext: UseFormReturn;
}

interface NewContactFunctions {
  selectNewContact: (cp: ContactPerson) => void;
  contactAndDuplicateContacts?: ContactAndDuplicateContactsProps;
  setContactAndDuplicateContacts: (cAndDCs: ContactAndDuplicateContactsProps) => void;
}

export const NewContactDialog = (props: NewContactProps & NewContactFunctions) => {
  const methods = props.formContext;
  const formMethods = useForm<ContactPerson>({
    mode: 'all',
    defaultValues: props.contactAndDuplicateContacts?.contact.person || {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
    },
  });
  const { formState } = formMethods;

  const values = methods.watch();
  const handleClose = () => {
    methods.setValue(props.id, undefined);
    methods.setValue('key', (values.key || 0) + 1);
  };

  const onSubmit = async (person: ContactPerson) => {
    const contact = { person, contactType: ContactType.PERSON };
    const cAndDCs = await getContactAndDuplicateContacts(contact);
    if (cAndDCs.duplicateContacts?.length === 0) {
      props.selectNewContact(contact.person);
    } else {
      props.setContactAndDuplicateContacts(cAndDCs);
    }
  };

  return (
    <DialogWrapper closeable header={t.CSTF(addContactMsg)} onCloseDialog={handleClose}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>
          <Name name="firstName" label={t.AIK7(firstNameMsg)} placeholder="" />
          <Name name="lastName" label={t.Y41S(lastNameMsg)} placeholder="" />
          <PhoneNumber name="phoneNumber" placeholder="" />
          <Email name="email" placeholder="" />
          <ModalCloseAndSubmitButtons
            onCancel={handleClose}
            onSubmit={() => {}}
            submitButtonText={t.VKFM(addMsg)}
            submitting={formState.isSubmitting}
          />
        </form>
      </FormProvider>
    </DialogWrapper>
  );
};

export const NewContact = (props: NewContactProps) => {
  const [contactAndDuplicateContacts, setContactAndDuplicateContacts] = useState<ContactAndDuplicateContactsProps>();
  const methods = props.formContext;
  const values = methods.watch();

  const selectOldContact = (contactHeader: ContactHeader) => {
    methods.setValue(props.id, contactHeader.contactId);
    methods.setValue('key', (values.key || 0) + 1);
  };

  const selectNewContact = (person: ContactPerson) => {
    const contact = {
      person,
      contactId: CREATED_CONTACT,
      contactType: ContactType.PERSON,
    };
    methods.setValue(props.name, contact);
    methods.setValue(props.id, CREATED_CONTACT);
    methods.setValue('key', (values.key || 0) + 1);
  };

  if (contactAndDuplicateContacts && contactAndDuplicateContacts?.duplicateContacts.length > 0) {
    return (
      <DuplicateContactsDialog
        {...contactAndDuplicateContacts}
        onClose={() => {
          setContactAndDuplicateContacts({ contact: contactAndDuplicateContacts!.contact, duplicateContacts: [] });
        }}
        onSelectOldContact={selectOldContact}
        onSelectNewContact={selectNewContact}
      />
    );
  }

  return (
    <NewContactDialog
      {...props}
      selectNewContact={selectNewContact}
      contactAndDuplicateContacts={contactAndDuplicateContacts}
      setContactAndDuplicateContacts={setContactAndDuplicateContacts}
    />
  );
};
