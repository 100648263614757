import { EmptyOrError } from '../../EmptyOrError/index.js';
import { type Location } from 'react-router-dom';
import { OpenFormAction } from '../OpenFormReducer.js';
import { type OpenFormDispatch } from '../OpenFormReducer.js';
import { OpenFormGrid } from './OpenFormGrid.js';
import { dialogSystemErrorHeaderMsg, returnMsg, t } from '../../../common/i18n/index.js';
import { useEffect } from 'react';

export const OpenFormError = ({
  id = 'of-openform__error',
  className = 'of-openform__error',
  dispatch,
  location,
  back,
  error,
  critical,
}: {
  id?: string;
  className?: string;
  dispatch: OpenFormDispatch;
  location: Location;
  back: () => void;
  error: string;
  critical: boolean;
}) => {
  useEffect(
    () => (critical ? undefined : () => dispatch(OpenFormAction.error(undefined))),
    [critical, dispatch, location]
  );

  return (
    <div className={className}>
      {critical ? (
        <EmptyOrError id={id} critical={true} text={error} buttonText={t.VH59(returnMsg)} onButtonClick={back} />
      ) : (
        <EmptyOrError
          id={id}
          critical={false}
          text={t.LW2K(dialogSystemErrorHeaderMsg)}
          buttonText={t.VH59(returnMsg)}
          onButtonClick={back}
          bottomElement={
            <OpenFormGrid>
              <pre>{JSON.stringify(error, null, 2)}</pre>
            </OpenFormGrid>
          }
        />
      )}
    </div>
  );
};
