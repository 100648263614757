import { AutoComplete } from '../../../components/AutoComplete/AutoComplete.js';
import { fieldCantBeEmptyMsg, noSelectionMsg, t } from '../../i18n/index.js';
import { getContactOptions } from '../../formik/Fields/index.js';
import { getTextError } from '../utils.js';
import { useController, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import type { BasicAutoCompleteItem } from '../../../components/AutoComplete/AutoComplete.js';
import type { Contact as ContactInterface } from '../../../generated/api/contact.js';
import type { DuplicateContact } from '../../../generated/api/duplicateContact.js';

interface ContactProps {
  className?: string;
  contacts: Array<ContactInterface>;
  name: string;
  reference: string;
}

export interface ContactAndDuplicateContactsProps {
  contact: ContactInterface;
  duplicateContacts: DuplicateContact[];
}

export const CREATED_CONTACT = 'CREATED_CONTACT';

export const Contact = (props: ContactProps) => {
  const { name, reference } = props;
  const methods = useFormContext();
  const values = methods.watch();
  const reset = values.key || 0;
  const newContact = values[reference]?.contactId === CREATED_CONTACT ? [values[reference]] : [];
  const contacts = getContactOptions([...newContact, ...props.contacts], true);
  const { field, fieldState, formState } = useController({
    name,
    control: methods.control,
    rules: { required: { value: true, message: t.VPVR(fieldCantBeEmptyMsg) } },
  });
  const value = name.split('.').reduce((a, c) => (a ? a[c] : undefined), formState.defaultValues);
  const defaultValue = contacts.find(i => (reset === 0 ? i.value === value : i.value === field.value));
  const error = getTextError(formState.isSubmitted, fieldState.isTouched, fieldState.error);

  return (
    <>
      <AutoComplete<BasicAutoCompleteItem>
        className={classNames('large-min-height', props.className)}
        inputRef={field.ref}
        options={contacts}
        defaultOption={defaultValue}
        getUniqueId={i => i.value}
        getDisplayHtml={i => i.html}
        getDisplayValue={i => i.label}
        onInputBlur={field.onBlur}
        onInputChange={(e, item) => {
          methods.setValue(name, item.value);
          methods.setError(name, { message: undefined });
        }}
        id={`${name}-autocomplete`}
        noOptionsMsg={t.ZW5W(noSelectionMsg)}
        label={t.VYZS('Contact person')}
        error={error}
        config={{ isSortable: false }}
        // If key changes = AutoComplete resets (unmount and mount)
        // This is the correct way to update value from the parent component
        key={`${name}-autocomplete${reset}`}
      />
    </>
  );
};
