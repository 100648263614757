import { type Dispatch, type MutableRefObject, type Reducer, useCallback, useReducer, useRef } from 'react';

export type RefReducer<S, A> = [S, Dispatch<A>, () => S, MutableRefObject<S>];

export const useRefReducer = <S, A>(reducer: Reducer<S, A>, initialState: S): RefReducer<S, A> => {
  const refState = useRef(initialState);
  const getState = useCallback(() => refState.current, []);

  const [state, dispatch] = useReducer((prev: S, action: A) => {
    refState.current = reducer(prev, action);
    return refState.current;
  }, initialState);

  return [state, dispatch, getState, refState];
};
