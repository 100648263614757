import { ExternalLink } from '../../ExternalLink/ExternalLink.js';
import { OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { OpenFormSectionLabel } from '../OpenFormComponents/OpenFormSectionLabel.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';

// We split the given paragraph by `[title](url)` elements
const URL_SPLIT_REGEX = /(\[[^\]]+\]\([^)]+\))/;
// Regex that captures the title and url as separate objects
const URL_CAPTURE_REGEX = /\[([^\]]+)\]\(([^)]+)\)/;

// Converts markdown-style links into <a> elements.
const convertLinks = (paragraph: string, id: number) => (
  <p key={id}>
    {paragraph.split(URL_SPLIT_REGEX).map((part, index) => {
      const [match, title, url] = URL_CAPTURE_REGEX.exec(part) ?? [];
      return match ? (
        <ExternalLink key={index} href={new URL(url).toString()}>
          {title}
        </ExternalLink>
      ) : (
        part
      );
    })}
  </p>
);

export const OpenFormPageInformative = ({ section }: { section: OpenFormSection }) => (
  <OpenFormGridRow>
    <OpenFormSectionLabel section={section} />
    <OpenFormGridRow>{section.displayData?.split('\n').filter(Boolean).map(convertLinks)}</OpenFormGridRow>
  </OpenFormGridRow>
);
