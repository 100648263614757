import * as CL from '@design-system/component-library';
import { type Address, type BillingAccount, type Contact } from '../OpenFormContext.js';
import { type OpenFormAnswers } from '../OpenFormAnswers.js';
import { OpenFormButton } from '../OpenFormComponents/OpenFormButton.js';
import { OpenFormGridCol, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { OpenFormIcon } from '../OpenFormComponents/OpenFormIcon.js';
import { OpenFormSummary } from './OpenFormSummary.js';
import { type UseFormReturn } from 'react-hook-form';
import {
  billingContactMsg,
  billingDetailsMsg,
  confirmMsg,
  contactsMsg,
  deliveryDetailsMsg,
  deliveryMethodMsg,
  eurosPerMonthMsg,
  formSubmittedMsg,
  monthlyChargeMsg,
  oneTimePaymentMsg,
  orderingContactMsg,
  previousMsg,
  productsMsg,
  selectedBillingAccountMsg,
  submittingFormMsg,
  summaryMsg,
  t,
} from '../../../common/i18n/index.js';
import { formatSumToString } from '../../../common/utils/priceUtils.js';
import { postOpenFormCustomerNeed } from '../../../common/fetch.js';
import { useCallback, useMemo } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { OpenFormCustomerNeedRequest } from '../../../generated/api/openFormCustomerNeedRequest.js';
import type { OpenFormProductSummary } from '../../../generated/api/openFormProductSummary.js';
import type { OpenFormQuestion } from '../../../generated/api/openFormQuestion.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';
import type { OpenFormSummaryResponse } from '../../../generated/api/openFormSummaryResponse.js';

const DeliveryDateTime = ({ deliveryDate, deliveryTime }: { deliveryDate?: string; deliveryTime?: string }) =>
  !deliveryDate && !deliveryTime ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={9}>
        <h4>{t.GLO4(deliveryDetailsMsg)}</h4>
        <OpenFormGridRow>
          <OpenFormGridCol>
            <dd className="ds-description__list-item-data">{`${deliveryDate ?? ''} ${deliveryTime ?? ''}`}</dd>
          </OpenFormGridCol>
        </OpenFormGridRow>
        <hr />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );

const BillingAccounts = ({ billingAccounts }: { billingAccounts: [string, BillingAccount][] }) =>
  !billingAccounts.length ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={9}>
        <h4>{t.RPMR(billingDetailsMsg)}</h4>
        {billingAccounts.map(([_, ba]) => (
          <OpenFormGridRow key={ba.billingAccountId}>
            <OpenFormGridCol colWidth={4}>
              <dt className="ds-description__list-item-title">{t.HVS2(selectedBillingAccountMsg)}</dt>
              <dd className="ds-description__list-item-data">
                <span>{`${ba.billingAccountDisplayId} ${ba.payerName}`}</span>
              </dd>
            </OpenFormGridCol>
            <OpenFormGridCol colWidth={4}>
              <dt className="ds-description__list-item-title">{t.XZH6(deliveryMethodMsg)}</dt>
              <dd className="ds-description__list-item-data">{ba.deliveryMethod}</dd>
            </OpenFormGridCol>
            <OpenFormGridCol colWidth={4}>
              <dt className="ds-description__list-item-title">{t.EY5Q(billingContactMsg)}</dt>
              <dd className="ds-description__list-item-data">{ba.billingContactName}</dd>
            </OpenFormGridCol>
          </OpenFormGridRow>
        ))}
        <hr />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );

const OrderingContact = ({ address, orderingContact }: { address?: Address; orderingContact?: Contact }) =>
  !address && !orderingContact ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={9}>
        <h4>{t.IY5V(orderingContactMsg)}</h4>
        <OpenFormGridRow>
          <OpenFormGridCol>
            <dd className="ds-description__list-item-data">
              {!orderingContact ? null : (
                <>
                  <div className="ds-margin-top--1">
                    {orderingContact.firstName} {orderingContact.lastName}
                  </div>
                  <div className="ds-margin-top--1">{orderingContact.email}</div>
                  <div className="ds-margin-top--1">{orderingContact.phoneNumber}</div>
                </>
              )}
              {!address ? null : (
                <>
                  {address.addressText}
                  <br />
                  {address.postalCode}
                </>
              )}
            </dd>
          </OpenFormGridCol>
        </OpenFormGridRow>
        <hr />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );

const OtherContacts = ({ otherContacts }: { otherContacts: [Contact, OpenFormQuestion][] }) =>
  !otherContacts.length ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={9}>
        <h4>{t.WA1M(contactsMsg)}</h4>
        <OpenFormGridRow className="ds-justify-content--space-between">
          {otherContacts.map(([contact, question]) => (
            <OpenFormGridCol colWidth={4} key={question.guid + contact.contactId}>
              <dt className="ds-description__list-item-title">{question.label}</dt>
              <dd className="ds-description__list-item-data">
                <div className="ds-margin-top--1">
                  {contact.firstName} {contact.lastName}
                </div>
                <div className="ds-margin-top--1">{contact.email}</div>
                <div className="ds-margin-top--1">{contact.phoneNumber}</div>
              </dd>
            </OpenFormGridCol>
          ))}
        </OpenFormGridRow>
        <hr />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );

const ProductSummaryTable = ({
  additionalServices = [],
  mainProducts = [],
}: {
  additionalServices?: OpenFormProductSummary[];
  mainProducts?: OpenFormProductSummary[];
}) => (
  <OpenFormGridRow>
    <OpenFormGridCol colWidth={9}>
      <CL.Table
        className="ds-margin-top--5"
        columns={[
          { key: 'product', label: t.OT3E(productsMsg) },
          { key: 'oneOffCharge', label: t.ASEI(oneTimePaymentMsg) },
          { key: 'recurringCharge', label: t.P0PS(monthlyChargeMsg) },
        ]}
        rows={mainProducts.concat(additionalServices).map(({ oneOffCharge, productName, recurringCharge }) => ({
          product: <div className="ds-margin-vertical--2 ds-font-weight--bold">{productName}</div>,
          oneOffCharge: <span>{formatSumToString(oneOffCharge)}</span>,
          recurringCharge: <span>{`${formatSumToString(recurringCharge, true)} ${t.H37G(eurosPerMonthMsg)}`}</span>,
        }))}
      />
    </OpenFormGridCol>
  </OpenFormGridRow>
);

export const OpenFormViewSummary = ({
  methods: { formState },
  answers,
  sections,
  response,
  disabled,
  preview,
  clear,
  prev,
}: {
  methods: UseFormReturn;
  answers: OpenFormAnswers;
  sections: OpenFormSection[];
  response: OpenFormSummaryResponse | undefined;
  disabled: boolean;
  preview: boolean;
  clear: () => void;
  prev: () => void;
}) => {
  const async = useOpenFormAsync();
  const ready = useMemo(() => formState.isValid && sections.every(answers.isCompleted), [formState, answers, sections]);
  const submit = useCallback(
    (data: OpenFormCustomerNeedRequest) =>
      new async(() => postOpenFormCustomerNeed(data))
        .resolved(() => async.actions.notification({ text: t.OCA5(formSubmittedMsg), hide: clear, icon: 'check' }))
        .rejected(text => async.actions.notification({ text, type: 'error' }))
        .execute(t.JNCH(submittingFormMsg)),
    [clear, async]
  );

  const {
    additionalServices,
    address,
    billingAccounts,
    deliveryDate,
    deliveryTime,
    mainProducts,
    orderingContact,
    otherContacts,
    request,
  } = new OpenFormSummary(async.getState().formId, answers, sections, response);

  return (
    <div className="of-openform__view__summary">
      <OpenFormGridRow>
        <h3>{t.QF5T(summaryMsg)}</h3>
      </OpenFormGridRow>

      <OrderingContact address={address} orderingContact={orderingContact} />
      <BillingAccounts billingAccounts={billingAccounts} />
      <OtherContacts otherContacts={otherContacts} />
      <DeliveryDateTime deliveryDate={deliveryDate} deliveryTime={deliveryTime} />
      <ProductSummaryTable additionalServices={additionalServices} mainProducts={mainProducts} />

      <OpenFormGridRow className="of-openform__view__bottom">
        <OpenFormGridCol className="of-openform__view__bottom__buttons">
          <OpenFormButton
            iconPosition="left"
            icon={<OpenFormIcon icon="arrow-left" />}
            text={t.CACI(previousMsg)}
            color="link"
            size="m"
            disabled={disabled}
            onClick={prev}
          />
          {preview ? null : (
            <OpenFormButton
              iconPosition="right"
              icon={<OpenFormIcon icon="check" />}
              text={t.QVYK(confirmMsg)}
              color="primary"
              size="m"
              disabled={disabled || !ready || !request}
              onClick={() => submit(request!)}
            />
          )}
        </OpenFormGridCol>
      </OpenFormGridRow>
    </div>
  );
};
