import { AddonDetails } from '../../../../../components/AddonDetails/AddonDetails.js';
import { BreadCrumbs } from '../../../../../components/BreadCrumbs/index.js';
import { devicesMsg, omaElisaForCompaniesMsg, t } from '../../../../../common/i18n/index.js';
import { generatePath, useLoaderData, useLocation, useParams } from 'react-router-dom';
import { paths } from '../../../../../common/constants/pathVariables.js';
import type { AddonId } from '../../../../../common/constants/pathInterfaces.js';
import type { DeviceAddonLoaderResponse } from '../../../../../common/loaders.js';

export const DeviceAddonPath = () => {
  const { addonId } = useParams<AddonId>();
  const { search } = useLocation();
  const { pendingSubscriptionActions, subscription } = useLoaderData() as DeviceAddonLoaderResponse;
  const subscriptionId = subscription.subscriptionDisplayId;
  const addOn = (subscription?.details?.selectedAddOns || []).find(it => it.addOnCode === addonId);

  if (!addOn) {
    throw new Response('Device addon not found', { status: 404 });
  }

  return (
    <AddonDetails
      addOn={addOn}
      breadCrumbs={
        <BreadCrumbs
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: t.JJTM(devicesMsg), path: paths.PS_DEVICES },
            {
              name: subscriptionId,
              path: generatePath(paths.PS_DEVICE, { subscriptionId }) + search,
            },
            { name: addOn?.addOnProductName || '...' },
          ]}
        />
      }
      subscription={subscription}
      subscriptionActions={pendingSubscriptionActions}
      subscriptionPath={paths.PS_DEVICE}
    />
  );
};
