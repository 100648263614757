import { OpenForm } from '../OpenForm.js';
import { OpenFormListTable } from './OpenFormListTable.js';
import { useCallback } from 'react';
import { useFetchFormList } from '../OpenFormHooks/useFetchFormList.js';
import { useOpenFormAsync, useOpenFormState } from '../OpenFormProvider.js';
import { useParams } from 'react-router-dom';
import { useReload } from '../OpenFormHooks/useReload.js';
import type { ListId } from '../../../common/constants/pathInterfaces.js';

export const OpenFormListLayout = () => {
  const { disabled, indexes, listId } = useOpenFormState();
  const async = useOpenFormAsync();
  const params = useParams<ListId>();
  const list = useFetchFormList(params);
  const [ready, reload] = useReload();

  const clear = useCallback(() => {
    reload();
    async.cache.clear();
    async.storage.clear();
    async.actions.list({ indexes: undefined, listId });
  }, [listId, async, reload]);

  return (
    <OpenForm clear={async.storage.length ? clear : undefined}>
      {!ready(list) ? null : <OpenFormListTable list={list} disabled={disabled} indexes={indexes} />}
    </OpenForm>
  );
};
