import { Locale, getLocale, setSiteLocale } from '../../../common/i18n/index.js';
import { type SetURLSearchParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

export const useURLParams = (): [URLSearchParams, SetURLSearchParams] => {
  const [params, setParams] = useSearchParams();

  // Only Finnish supported in the first version
  useEffect(() => {
    switch (params.get('locale')?.substring(0, 2).toUpperCase()) {
      default: {
        getLocale() !== Locale.FI && setSiteLocale(Locale.FI);
        break;
      }
    }
    if (params.has('locale')) {
      params.delete('locale');
      setParams(params);
    }
  }, [params, setParams]);

  return [params, setParams];
};
