import { CreateOrSelectContact } from './CreateOrSelectContact.js';
import { PurposeOfUseSelectGroup } from './PurposeOfUseSelectGroup.js';
import { SelectGroupItem } from '../../SelectGroup/SelectGroupItem.js';
import { SelectedPurposeOfUseOrContact } from '../../../common/enums.js';
import { TextInput } from '../../../common/react-hook-form/fields/index.js';
import { purposeOfUseExampleMsg, purposeOfUseMsg, t, userTextMsg } from '../../../common/i18n/index.js';
import { useFormContext } from 'react-hook-form';
import type { Contact } from '../../../generated/api/contact.js';
import type { ContactHeader } from '../../../generated/api/contactHeader.js';

interface ContactOrPurposeOfUseSelectorProps {
  contacts?: Contact[] | ContactHeader[];
  fieldPrefix?: string;
  isEmailMandatory?: boolean;
  isPhoneNumberMandatory?: boolean;
  onChangeContact?: (selectedContactId: string) => void;
}

export const PurposeOfUseSelector = ({
  contacts,
  fieldPrefix = '',
  isEmailMandatory = true,
  isPhoneNumberMandatory = true,
  onChangeContact,
}: ContactOrPurposeOfUseSelectorProps) => {
  const { watch } = useFormContext();
  const selectedFieldValue = watch(`${fieldPrefix}selected`) || SelectedPurposeOfUseOrContact.CONTACT;

  return (
    <>
      <h4 className="ds-margin-bottom--3 ds-margin-top--2">{t.WPXQ('Select user or purpose of use')}</h4>
      <p className="ds-margin-vertical--3">
        {t.PV9I(
          'By adding user information, you can manage products and services easier in the OmaElisa Users view. Select the purpose of use only when it involves, for example, a group of users.'
        )}
      </p>
      <PurposeOfUseSelectGroup
        className="ds-font-weight--600 ds-padding--0 ds-padding-bottom--2 of-purpose-of-use__select-group"
        selectedItem={selectedFieldValue}
        fieldPrefix={fieldPrefix}
      >
        <SelectGroupItem title={t.LUZM(userTextMsg)} id={SelectedPurposeOfUseOrContact.CONTACT}>
          <CreateOrSelectContact
            contacts={contacts}
            fieldPrefix={fieldPrefix}
            isEmailMandatory={isEmailMandatory}
            isPhoneNumberMandatory={isPhoneNumberMandatory}
            onContactChange={onChangeContact}
          />
        </SelectGroupItem>
        <SelectGroupItem title={t.Y8KX(purposeOfUseMsg)} id={SelectedPurposeOfUseOrContact.PURPOSEOFUSE}>
          <div className="of-purpose-of-use__purpose_of_use_field ds-padding-top--4">
            <TextInput
              name={`${fieldPrefix}purposeOfUse`}
              hint={t.TTZ4(purposeOfUseExampleMsg)}
              maxLength={100}
              placeholder={t.Y8KX(purposeOfUseMsg)}
            />
          </div>
        </SelectGroupItem>
      </PurposeOfUseSelectGroup>
    </>
  );
};
