import { AddOnRulesSubscriptionType, CustomerOrderListFields, ModelType, SubscriptionCategory } from './enums.js';
import { CustomerOrderStatus } from '../generated/api/customerOrderStatus.js';
import { OnlineModelCategory } from '../generated/api/onlineModelCategory.js';
import { SUBSCRIPTION_TYPE_SEARCH_FILTER_PARAM } from '../components/Subscriptions/SubscriptionsVoice.js';
import { SourceSystem } from '../generated/api/sourceSystem.js';
import { SubscriptionType } from '../generated/api/subscriptionType.js';
import { TableSortOrder, TableUrlParams, getItemsPerPageFromOptionsOrDefault } from '../components/Table';
import { convertToCommonErrors } from './utils/errorUtils.js';
import { createDnsRecordsLoader } from '../components/DnsManagement/dnsManagementUtils.js';
import { createPreLoadedAddOnRules } from '../public/site/path/FixedBroadbandOrder/fixedBroadbandAddOnUtil.js';
import { defer } from 'react-router-dom';
import {
  fetchAddOnRules,
  fetchBillChannels,
  fetchBillingAccount,
  fetchBillingAccounts,
  fetchCatalogOnlineModelHeaders,
  fetchCompanyInfo,
  fetchCompanyInfoAndEnabledOnlineModels,
  fetchContactFromES,
  fetchContacts,
  fetchCustomerOrder,
  fetchCustomerOrderAdditionalInfo,
  fetchCustomerOrders,
  fetchDnsRecordHistory,
  fetchDnsRecords,
  fetchDnsRecordsHistory,
  fetchHolidays,
  fetchInvoice,
  fetchInvoiceDocuments,
  fetchInvoices,
  fetchOnlineModelEffectivePriceForCode,
  fetchOnlineModelForCode,
  fetchOnlineModels,
  fetchOnlineModelsForCategory,
  fetchOpenSupportCases,
  fetchPendingSubsciptionActions,
  fetchSubscription,
  fetchSubscriptionAction,
  fetchSubscriptionActions,
  fetchSubscriptionAggregates,
  fetchSubscriptions,
  fetchSupportCase,
  fetchSupportCaseHistory,
  fetchSupportCases,
  fetchVirtualCatalogs,
  getMessagesFromChatHistory,
} from './fetch.js';
import {
  getActiveAccountMasterId,
  getAiChatSessionId,
  getShoppingBasketFromLocalStorage,
} from '../selfservice/common/localStorageUtils.js';
import { getContactSort } from './utils/contactUtils.js';
import { getOnlineModelFilteringOutNonOfferAddOns, mergeArrays } from '../selfservice/reducers/reducerUtils.js';
import { getSubscriptionTypes } from '../public/common/util/category.js';
import { mergeObjects } from './utils/objectUtils.js';
import { replacePipeWithCommaInQueryParams } from './utils/filterUtils.js';
import { resolveSort } from './utils/supportCaseUtils.js';
import type { AddOnRule } from './types/addOnRule.js';
import type { AddOnRulesResponse } from '../generated/api/addOnRulesResponse.js';
import type { BillChannel } from '../generated/api/billChannel.js';
import type { BillingAccount } from '../generated/api/billingAccount.js';
import type { BillingAccountHeader } from '../generated/api/billingAccountHeader.js';
import type { BillingAccountSearchResponse } from '../generated/api/billingAccountSearchResponse.js';
import type { BillingAccountsResponse } from '../generated/api/billingAccountsResponse.js';
import type { CommonError } from './types/errors.js';
import type { CompanyInfoResponse } from '../generated/api/companyInfoResponse.js';
import type { CompanyInfoState } from './types/states.js';
import type { Contact } from '../generated/api/contact.js';
import type { ContactsResponse } from '../generated/api/contactsResponse.js';
import type { CustomerOrder } from '../generated/api/customerOrder.js';
import type { CustomerOrderAdditionalInfo } from '../generated/api/customerOrderAdditionalInfo.js';
import type { DefaultListSearchParams } from '../components/Table';
import type { Invoice } from '../generated/api/invoice.js';
import type { InvoiceDocumentsResponse } from 'generated/api/invoiceDocumentsResponse.js';
import type { InvoicesResponse } from 'generated/api/invoicesResponse.js';
import type { LoaderFunctionArgs } from 'react-router-dom';
import type { OnlineModel } from '../generated/api/onlineModel.js';
import type { OnlineModelsResponse } from '../generated/api/onlineModelsResponse.js';
import type { ShoppingBasketType } from './types/shoppingBasket.js';
import type { Subscription } from '../generated/api/subscription.js';
import type { SubscriptionAction } from '../generated/api/subscriptionAction.js';
import type { SubscriptionActionsResponse } from '../generated/api/subscriptionActionsResponse.js';
import type { SubscriptionAggregationsResponse } from '../generated/api/subscriptionAggregationsResponse.js';
import type { SubscriptionSearchResponse } from '../generated/api/subscriptionSearchResponse.js';
import type { SubscriptionsQuery } from './fetch.js';
import type { SubscriptionsResponse } from '../generated/api/subscriptionsResponse.js';
import type { SupportCaseDataBundle } from '../generated/api/supportCaseDataBundle.js';
import type { SupportCaseHeader } from '../generated/api/supportCaseHeader.js';
import type { SupportCaseHistory } from '../generated/api/supportCaseHistory.js';
import type { SupportCasesResponse } from 'generated/api/supportCasesResponse.js';
import type { SupportCasesSearchResponse } from '../generated/api/supportCasesSearchResponse.js';

export interface SupportCaseLoaderResponse {
  supportCase: SupportCaseDataBundle;
  history: SupportCaseHistory[];
}

export interface BillingAccountLoaderResponse {
  billingAccount: BillingAccount;
  billChannels: BillChannel[];
  contacts: Contact[];
}

export interface InvoiceLoaderResponse {
  invoice: Invoice;
  billChannels: BillChannel[];
  openSupportCases: SupportCaseHeader[];
  billingAccount?: BillingAccount;
}

export interface DeviceSubscriptionLoaderResponse {
  billingAccounts: BillingAccountHeader[];
  companyInfo: CompanyInfoState;
  contacts: Contact[];
  pendingSubscriptionActions: SubscriptionAction[];
  subscription: Subscription;
}

export type DeviceSubPostChangeRequestLoaderResponse = Pick<
  DeviceSubscriptionLoaderResponse,
  'companyInfo' | 'pendingSubscriptionActions' | 'subscription'
>;

export type DeviceAddonLoaderResponse = Pick<
  DeviceSubscriptionLoaderResponse,
  'pendingSubscriptionActions' | 'subscription'
>;

export interface MobileSubscriptionLoaderResponse {
  billingAccounts: BillingAccountHeader[];
  companyInfo: CompanyInfoState;
  contacts: Contact[];
  pendingSubscriptionActions: SubscriptionAction[];
  subscription: Subscription;
}

export type MobileSubPostChangeRequestLoaderResponse = Pick<
  MobileSubscriptionLoaderResponse,
  'companyInfo' | 'pendingSubscriptionActions' | 'subscription'
>;

const defaultRequest = {
  offset: 0,
  order: 'desc',
};

type LoaderDefaultParams = DefaultListSearchParams & {
  params: URLSearchParams;
  itemsPerPage?: number;
};

const getCompanyIdFromRequest = (request: Request): string | undefined =>
  new URL(request.url).searchParams.get('companyId') || undefined;

export const getSearchParams = (request: Request): LoaderDefaultParams => {
  const params = new URLSearchParams(new URL(request.url).search);
  const itemsPerPage = getItemsPerPageFromOptionsOrDefault(params.get('limit') || undefined);
  const offset = params.get('offset') || '0';
  const order = params.get('order') || undefined;
  const search = params.get('search') || undefined;
  const sort = params.get('sort') || undefined;
  return { itemsPerPage, offset, order, search, sort, params };
};

const buildSubscriptionsQuery = (
  category: SubscriptionCategory,
  args: LoaderFunctionArgs,
  contactId?: string
): SubscriptionsQuery => {
  const { params, offset, itemsPerPage, ...rest } = getSearchParams(args.request);
  const subscriptionType =
    (params.get(SUBSCRIPTION_TYPE_SEARCH_FILTER_PARAM) as SubscriptionCategory | null) ||
    getSubscriptionTypes(category);
  const subscriptionSubType = params.get('subscriptionSubType');
  const subscriptionContactId = contactId;
  return mergeObjects(defaultRequest, {
    details: true,
    offset: Number(offset),
    limit: itemsPerPage,
    subscriptionType,
    subscriptionSubType,
    subscriptionContactId,
    useSearchService: true,
    ...replacePipeWithCommaInQueryParams(Object.fromEntries(params), ['limit']),
    ...rest,
  });
};

export const supportCaseLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<SupportCaseLoaderResponse> => {
  if (params.supportCaseDisplayId === undefined) {
    throw new Error('missing supportCaseDisplayId');
  }
  const companyId = getCompanyIdFromRequest(request);
  return Promise.all([
    fetchSupportCase(params.supportCaseDisplayId, companyId),
    fetchSupportCaseHistory(params.supportCaseDisplayId, companyId),
  ]).then(fetchResponse => ({ supportCase: fetchResponse[0], history: fetchResponse[1].caseHistory || [] }));
};

export const getSupportCases = ({ request }: LoaderFunctionArgs) => {
  const { params, sort, offset, itemsPerPage, ...rest } = getSearchParams(request);
  return fetchSupportCases(
    mergeObjects(defaultRequest, {
      feature: params.get('feature'),
      status: params.get('status'),
      offset: Number(offset),
      limit: itemsPerPage,
      sort: resolveSort(sort),
      ...rest,
    })
  );
};

export const billingAccountLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<BillingAccountLoaderResponse> => {
  if (params.billingAccountId === undefined) {
    throw new Error('missing billingAccountId');
  }
  const companyId = getCompanyIdFromRequest(request);
  const [billingAccounts, billChannels, contactsResponse] = await Promise.all([
    fetchBillingAccount(params.billingAccountId, companyId),
    fetchBillChannels(),
    fetchContacts({ offset: 0 }, { mdmId: companyId }),
  ]);
  const billingAccount = billingAccounts.billingAccounts?.[0] as BillingAccount;
  if (!billingAccount) {
    throw new Response('Billing account not found', { status: 404 });
  }
  return {
    billingAccount,
    billChannels,
    contacts: contactsResponse.contacts || [],
  };
};

export interface BillingAccountListLoaderData {
  billingAccounts?: BillingAccountsResponse;
}

/**
 * Get billing accounts from Elasticsearch so that they can be shown in the
 * billing account list.
 *
 * For the billing account list billing accounts from all source systems (SFDC,
 * MIPA, TELLUS) should be shown without restrictions. Restrictions may apply
 * to billing accounts shown in dropdowns when they are selected for orders
 * and such.
 */
export const billingAccountListLoader = async ({ request }: LoaderFunctionArgs) => {
  const { itemsPerPage, offset, search, sort, order } = getSearchParams(request);
  return {
    billingAccounts: await fetchBillingAccounts({
      limit: itemsPerPage,
      offset: Number(offset),
      search,
      sort,
      order,
      useSearchService: true,
    }),
  };
};

/**
 * Loader for Catalog Configurations including billing-accounts, bill-channels and contacts
 * billing-accounts and bill-contacts calls are fast, so they're required from the start
 * contacts are slow, so the data is referred and used later in the application for optimal UX
 */
export const ccLoader = async () => {
  return defer({
    billingAccounts: await fetchBillingAccounts({ useSearchService: true, sourceSystem: SourceSystem.SFDC }),
    billChannels: await fetchBillChannels(),
    contacts: fetchContacts({ offset: 0 }),
  });
};

export interface BaLoaderData {
  billChannels: BillChannel[];
  contacts: ContactsResponse;
  companyInfo: CompanyInfoState;
}

export const baLoader = async ({ request }: LoaderFunctionArgs): Promise<BaLoaderData> => {
  const companyId = getCompanyIdFromRequest(request);
  return {
    billChannels: await fetchBillChannels(),
    contacts: await fetchContacts({ offset: 0 }, { mdmId: companyId }),
    companyInfo: await fetchCompanyInfo(companyId || getActiveAccountMasterId() || undefined),
  };
};

export const customerOrdersLoader = ({ request }: LoaderFunctionArgs) => {
  const { params, sort, offset, itemsPerPage, ...rest } = getSearchParams(request);
  return fetchCustomerOrders(
    mergeObjects(defaultRequest, {
      useSearchService: true,
      offset: Number(offset),
      limit: itemsPerPage,
      status: params.get('status') || undefined,
      sort: sort || CustomerOrderListFields.CREATED,
      ...rest,
    })
  );
};

export interface CustomerOrderLoaderData {
  customerOrder: CustomerOrder;
  billingAccounts: BillingAccountsResponse;
  additionalInfo?: CustomerOrderAdditionalInfo;
  companyInfo?: CompanyInfoResponse;
  contacts?: ContactsResponse;
}

export interface ContactSubscriptionsLoaderData {
  device: SubscriptionSearchResponse[];
  voice: SubscriptionSearchResponse[];
  broadband: SubscriptionSearchResponse[];
  service: SubscriptionSearchResponse[];
  billingAccounts: BillingAccountSearchResponse[];
}

export interface OnlineModelLoaderData {
  phones: OnlineModelsResponse;
  accessories: OnlineModelsResponse;
  tablets: OnlineModelsResponse;
  computers: OnlineModelsResponse;
  networkEquipment: OnlineModelsResponse;
}

export const customerOrderLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<CustomerOrderLoaderData> => {
  if (params.orderId === undefined) {
    throw new Error('missing customerOrderId');
  }
  const companyId = new URL(request.url).searchParams.get('companyId') || undefined;
  const customerOrder = (await fetchCustomerOrder(params.orderId, companyId)).customerOrders?.[0] as CustomerOrder;
  if (!customerOrder) {
    throw new Response('Customer order not found', { status: 404 });
  }
  const pending = customerOrder.status === CustomerOrderStatus.PENDING_APPROVAL;
  return {
    customerOrder,
    additionalInfo: pending ? await fetchCustomerOrderAdditionalInfo(params.orderId) : undefined,
    companyInfo: pending ? await fetchCompanyInfo(companyId) : undefined,
    billingAccounts: await fetchBillingAccounts(
      { useSearchService: true, sourceSystem: SourceSystem.SFDC },
      { mdmId: companyId }
    ),
    contacts: await fetchContacts({ useSearchService: true, offset: 0 }),
  };
};

export const dnsRecordHistoryLoader = ({ params }: LoaderFunctionArgs) =>
  fetchDnsRecordHistory(params.subscriptionId!, Number(params.historyId!));

export const dnsRecordsHistoryLoader = createDnsRecordsLoader(fetchDnsRecordsHistory, {
  [TableUrlParams.SORT]: 'id',
  [TableUrlParams.ORDER]: TableSortOrder.DESC,
  [TableUrlParams.LIMIT]: '30',
});

export const getDnsRecords = createDnsRecordsLoader(fetchDnsRecords, {
  [TableUrlParams.SORT]: 'type',
  [TableUrlParams.ORDER]: TableSortOrder.ASC,
  [TableUrlParams.LIMIT]: '30',
});

export interface SubscriptionsLoaderData {
  subscriptions: SubscriptionsResponse;
  aggregations: SubscriptionAggregationsResponse;
}

export const broadbandSubscriptionsLoader = (args: LoaderFunctionArgs) => {
  const mdmId = getCompanyIdFromRequest(args.request);
  const query = buildSubscriptionsQuery(SubscriptionCategory.BROADBAND, args);
  return fetchSubscriptions(query, mdmId);
};

export const serviceSubscriptionsLoader = (args: LoaderFunctionArgs) => {
  const mdmId = getCompanyIdFromRequest(args.request);
  const query = buildSubscriptionsQuery(SubscriptionCategory.SERVICE, args);
  return fetchSubscriptions(query, mdmId);
};

export const contactSubscriptionsLoader = async (args: LoaderFunctionArgs) => {
  // contactId needed, get that from the contact
  const contactMasterId = args.params.contactMasterId;
  const mdmId = getCompanyIdFromRequest(args.request);
  const res = await fetchContactFromES({
    contactMasterId: contactMasterId!,
    mdmId,
  });
  const searchResults = res.searchResults || [];
  if (searchResults.length !== 1) {
    throw new Error('Contact not found');
  }
  const contactId = searchResults[0].result.contactId;

  // And now fetch the subscriptions for the contact using contactId
  const [deviceResponse, voiceResponse, broadbandResponse, serviceResponse, billingAccountResponse] = await Promise.all(
    [
      fetchSubscriptions(buildSubscriptionsQuery(SubscriptionCategory.DEVICE, args, contactId), mdmId),
      fetchSubscriptions(buildSubscriptionsQuery(SubscriptionCategory.VOICE, args, contactId), mdmId),
      fetchSubscriptions(buildSubscriptionsQuery(SubscriptionCategory.BROADBAND, args, contactId), mdmId),
      fetchSubscriptions(buildSubscriptionsQuery(SubscriptionCategory.SERVICE, args, contactId), mdmId),
      fetchBillingAccounts(
        { billingContactId: contactId, useSearchService: true, sourceSystem: SourceSystem.SFDC },
        { mdmId }
      ),
    ]
  );
  return {
    device: deviceResponse.searchResults,
    voice: voiceResponse.searchResults,
    broadband: broadbandResponse.searchResults,
    service: serviceResponse.searchResults,
    billingAccounts: billingAccountResponse.searchResults,
  };
};

export const deviceSubscriptionsLoader = async (args: LoaderFunctionArgs) => {
  const mdmId = getCompanyIdFromRequest(args.request);
  const query = buildSubscriptionsQuery(SubscriptionCategory.DEVICE, args);
  return {
    subscriptions: await fetchSubscriptions(query, mdmId),
    aggregations: await fetchSubscriptionAggregates(SubscriptionCategory.DEVICE, args),
  };
};

export const deviceSubscriptionLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<DeviceSubscriptionLoaderResponse> => {
  const companyId = getCompanyIdFromRequest(request);
  const [billingAccountsResponse, companyInfo, contactsResponse, pendingSubscriptionActions, subscription] =
    await Promise.all([
      fetchBillingAccounts({ useSearchService: true, sourceSystem: SourceSystem.SFDC }, { mdmId: companyId }),
      fetchCompanyInfo(companyId),
      fetchContacts({ offset: 0 }, { mdmId: companyId }),
      fetchPendingSubsciptionActions(companyId),
      fetchSubscription(SubscriptionCategory.DEVICE, params.subscriptionId, companyId),
    ]);
  const mappedBillingAccounts = billingAccountsResponse.searchResults?.map(res => res.result) || [];
  return {
    billingAccounts: mappedBillingAccounts,
    companyInfo,
    contacts: contactsResponse.contacts || [],
    pendingSubscriptionActions,
    subscription,
  };
};

export const deviceSubPostChangeRequestLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<DeviceSubPostChangeRequestLoaderResponse> => {
  const companyId = getCompanyIdFromRequest(request);
  const [companyInfo, pendingSubscriptionActions, subscription] = await Promise.all([
    fetchCompanyInfo(companyId),
    fetchPendingSubsciptionActions(companyId),
    fetchSubscription(SubscriptionCategory.DEVICE, params.subscriptionId, companyId),
  ]);
  return { companyInfo, pendingSubscriptionActions, subscription };
};

export const voiceSubscriptionsLoader = async (args: LoaderFunctionArgs) => {
  const mdmId = getCompanyIdFromRequest(args.request);
  const query = buildSubscriptionsQuery(SubscriptionCategory.VOICE, args);
  return {
    subscriptions: await fetchSubscriptions(query, mdmId),
    aggregations: await fetchSubscriptionAggregates(SubscriptionCategory.VOICE, args),
  };
};

export const deviceAddonLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<DeviceAddonLoaderResponse> => {
  const companyId = getCompanyIdFromRequest(request);
  const [pendingSubscriptionActions, subscription] = await Promise.all([
    fetchPendingSubsciptionActions(companyId),
    fetchSubscription(SubscriptionCategory.DEVICE, params.subscriptionId, companyId),
  ]);
  return { pendingSubscriptionActions, subscription };
};

export const serviceSubscriptionLoader = async ({ params, request }: LoaderFunctionArgs): Promise<Subscription> => {
  const companyId = getCompanyIdFromRequest(request);
  return await fetchSubscription(SubscriptionCategory.SERVICE, params.subscriptionId, companyId);
};

export const getDnsSubscriptions = async (args: LoaderFunctionArgs) => {
  const mdmId = getCompanyIdFromRequest(args.request);
  const query = buildSubscriptionsQuery(SubscriptionCategory.DOMAIN, args);
  return fetchSubscriptions(query, mdmId);
};

export const contactsLoader = ({ request }: LoaderFunctionArgs) => {
  const { sort, offset, itemsPerPage, ...rest } = getSearchParams(request);

  return fetchContacts(
    mergeObjects(defaultRequest, {
      useSearchService: true,
      offset: Number(offset),
      limit: itemsPerPage,
      sort: getContactSort(sort),
      ...rest,
    })
  );
};

export const subscriptionActionLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<SubscriptionActionsResponse> => {
  if (params.requestId === undefined) {
    throw new Error('missing requestId');
  }
  return fetchSubscriptionAction(params.requestId, getCompanyIdFromRequest(request));
};

export const getSubscriptionActions = ({ request }: LoaderFunctionArgs) => {
  const { offset, itemsPerPage, ...rest } = getSearchParams(request);

  return fetchSubscriptionActions(
    mergeObjects(defaultRequest, {
      useSearchService: true,
      offset: Number(offset),
      limit: itemsPerPage,
      ...rest,
    })
  );
};

export const onlineModelsForBasketItemsLoader = async () => {
  const basketFromLocalstorage = getShoppingBasketFromLocalStorage();

  if (basketFromLocalstorage) {
    const cartJson: ShoppingBasketType = JSON.parse(basketFromLocalstorage);
    const onlineModelCodeGuids = [...new Set(cartJson.items?.map(cartItem => cartItem.guid))];
    return await fetchOnlineModels(onlineModelCodeGuids);
  }
  // no need to load anything if cart is empty..
  return {};
};

export const onlineModelsForCatalogLoader = async () => {
  const [phones, accessories, tablets, computers, networkEquipment] = await Promise.all([
    fetchCatalogOnlineModelHeaders(OnlineModelCategory.PHONE),
    fetchCatalogOnlineModelHeaders(OnlineModelCategory.ACCESSORIES),
    fetchCatalogOnlineModelHeaders(OnlineModelCategory.TABLET),
    fetchCatalogOnlineModelHeaders(OnlineModelCategory.COMPUTERS),
    fetchCatalogOnlineModelHeaders(OnlineModelCategory.NETWORK_EQUIPMENT),
  ]);
  return {
    phones,
    accessories,
    tablets,
    computers,
    networkEquipment,
  };
};

export const virtualCatalogsLoader = ({ request }: LoaderFunctionArgs) => {
  const { params, offset, sort, itemsPerPage, ...rest } = getSearchParams(request);
  return fetchVirtualCatalogs(
    mergeObjects(defaultRequest, {
      useSearchService: true,
      productType: params.get('productType'),
      contractPeriod: params.get('contractPeriod'),
      status: params.get('status'),
      limit: itemsPerPage,
      offset: Number(offset),
      sort: sort || 'publishedOrDraftLastModified',
      ...rest,
    })
  );
};

export const virtualCatalogsForAllAccountsLoader = () => {
  return fetchVirtualCatalogs(
    mergeObjects(defaultRequest, {
      useSearchService: true,
      searchAllAccounts: true,
    })
  );
};

export const chatHistoryLoader = () => {
  const sessionId = getAiChatSessionId();
  return sessionId ? getMessagesFromChatHistory(sessionId) : Promise.resolve({ sessionId: '', messages: [] });
};

export const invoiceLoader = async ({ params, request }: LoaderFunctionArgs): Promise<InvoiceLoaderResponse> => {
  if (params.invoiceId === undefined) {
    throw new Error('missing invoiceId');
  }
  const companyId = getCompanyIdFromRequest(request);
  const [invoicesResponse, billChannels, billingAccountsResponse] = await Promise.all([
    fetchInvoice(params.invoiceId, companyId),
    fetchBillChannels(),
    fetchBillingAccounts({ offset: 0, useSearchService: true }, { mdmId: companyId }),
  ]);
  const invoice = invoicesResponse?.invoices?.[0] as Invoice;
  if (!invoice) {
    throw new Response('Invoice not found', { status: 404 });
  }
  const openSupportCases = await fetchOpenSupportCases({ search: invoice.invoiceDisplayId }, companyId);
  const mappedSupportCases =
    openSupportCases?.searchResults?.map((res: SupportCasesSearchResponse) => res.result) || [];
  const billingAccount =
    billingAccountsResponse?.billingAccounts?.find(ba => ba.billingAccountId === invoice.billingAccountId) ??
    invoicesResponse.billingAccounts?.find(ba => ba.billingAccountId === invoice.billingAccountId);
  return {
    invoice,
    billChannels: billChannels || [],
    openSupportCases: mappedSupportCases,
    billingAccount: billingAccount,
  };
};

export interface InvoicesLoaderData {
  invoices: InvoicesResponse;
  supportCases: SupportCasesResponse;
}

export const invoicesLoader = async ({ request }: LoaderFunctionArgs): Promise<InvoicesLoaderData> => {
  const { itemsPerPage, offset, sort, order, search } = getSearchParams(request);
  return {
    invoices: await fetchInvoices({
      ...defaultRequest,
      useSearchService: true,
      search,
      order,
      limit: itemsPerPage,
      offset: Number(offset),
      sort,
    }),
    supportCases: await fetchOpenSupportCases({ search, order: 'desc', offset: 0 }),
  };
};

export interface DocumentsLoaderData {
  documents: InvoiceDocumentsResponse;
}

export const documentsLoader = async ({ request }: LoaderFunctionArgs): Promise<DocumentsLoaderData> => {
  const { itemsPerPage, offset, sort, order, search } = getSearchParams(request);
  return {
    documents: await fetchInvoiceDocuments({
      ...defaultRequest,
      useSearchService: true,
      offset: Number(offset),
      sort,
      order,
      search,
      limit: itemsPerPage,
    }),
  };
};

export interface FixedBBRootLoaderData {
  onlineModels?: OnlineModel[];
  errors?: CommonError[];
}

export const fixedBBRootLoader = async () => {
  try {
    const onlineModels = await fetchOnlineModelsForCategory(OnlineModelCategory.FIXED_BROADBAND, false);
    const broadbandModel = await fetchOnlineModelForCode(ModelType.MobileBroadband);
    // This filtering is the same as in the onlineModelsReducer.ts line 50
    const filteredModel = getOnlineModelFilteringOutNonOfferAddOns(broadbandModel);
    const mergedModels = mergeArrays<OnlineModel>('onlineModelCode', 'lastModified', onlineModels.models, [
      filteredModel,
    ]);

    return {
      onlineModels: mergedModels,
    };
  } catch (error) {
    return {
      errors: convertToCommonErrors(error.message, error.status, error.json()),
    };
  }
};

export interface FixedBBCheckoutLoaderData {
  preLoadedAddOnRules: AddOnRule[];
  addOnRules: AddOnRulesResponse;
  mobilePbxAddOnRules: AddOnRulesResponse;
  nettiAddOnRules: AddOnRulesResponse;
  netti4GAddOnRules: AddOnRulesResponse;
  holidays: Date[];
  billChannels: BillChannel[];
}

export const fixedBBCheckoutLoader = async () => {
  const addOnRules = await fetchAddOnRules();
  const mobilePbxAddOnRules = await fetchAddOnRules(SubscriptionType.MOBILE_PBX);
  const nettiAddOnRules = await fetchAddOnRules(AddOnRulesSubscriptionType.ELISA_NETTI);
  const netti4GAddOnRules = await fetchAddOnRules(AddOnRulesSubscriptionType.ELISA_NETTI_4G);
  const holidays = await fetchHolidays();
  const billChannels = await fetchBillChannels();

  return {
    preLoadedAddOnRules: createPreLoadedAddOnRules(nettiAddOnRules, netti4GAddOnRules),
    addOnRules,
    mobilePbxAddOnRules,
    nettiAddOnRules,
    netti4GAddOnRules,
    holidays: holidays.dates.map(date => new Date(date)),
    billChannels,
  };
};

export interface CheckoutRootLoaderData {
  holidays: Date[];
}

export const checkoutRootLoader = async () => {
  const holidays = await fetchHolidays();

  return {
    holidays: holidays.dates.map(date => new Date(date)),
  };
};

export const mobileSubPostChangeRequestLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<MobileSubPostChangeRequestLoaderResponse> => {
  const companyId = getCompanyIdFromRequest(request);
  const [companyInfo, pendingSubscriptionActions, subscription] = await Promise.all([
    fetchCompanyInfo(companyId),
    fetchPendingSubsciptionActions(companyId),
    fetchSubscription(SubscriptionCategory.VOICE, params.subscriptionId, companyId),
  ]);
  return {
    companyInfo,
    pendingSubscriptionActions,
    subscription,
  };
};

export interface MobileSubUpdateLoaderData {
  addOnRules: AddOnRulesResponse;
  companyInfo: CompanyInfoResponse;
  onlineModels: OnlineModel[];
  subscription: Subscription;
}

export const mobileSubUpdateLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<MobileSubUpdateLoaderData> => {
  const companyId = getCompanyIdFromRequest(request);
  const [addOnRules, companyInfo, subscription, voiceSMEModel, voiceModel] = await Promise.all([
    fetchAddOnRules(),
    fetchCompanyInfo(companyId),
    fetchSubscription(SubscriptionCategory.VOICE, params.subscriptionId, companyId),
    fetchOnlineModelForCode(ModelType.VoiceSME),
    fetchOnlineModelForCode(ModelType.Voice),
  ]);
  return {
    addOnRules,
    companyInfo,
    onlineModels: [
      getOnlineModelFilteringOutNonOfferAddOns(voiceSMEModel),
      getOnlineModelFilteringOutNonOfferAddOns(voiceModel),
    ],
    subscription,
  };
};

export interface MobileSubUpdateConfirmationLoaderData {
  addOnRules: AddOnRulesResponse;
  addOnRulesMobilePbx: AddOnRulesResponse;
  pbxSolutions: Subscription[];
}

export const mobileSubUpdateConfirmationLoader = async ({
  request,
}: LoaderFunctionArgs): Promise<MobileSubUpdateConfirmationLoaderData> => {
  const companyId = getCompanyIdFromRequest(request);
  const [addOnRules, addOnRulesMobilePbx, companyInfo] = await Promise.all([
    fetchAddOnRules(),
    fetchAddOnRules(SubscriptionType.MOBILE_PBX),
    fetchCompanyInfo(companyId),
  ]);
  return {
    addOnRules,
    addOnRulesMobilePbx,
    pbxSolutions: companyInfo.pbxSolutions,
  };
};

export interface OrderVoiceSubLoaderData {
  voiceModel: OnlineModel;
  addOnRules: AddOnRulesResponse;
}

export const orderVoiceSubLoader = async () => {
  const [addOnRules, onlineModel] = await Promise.all([fetchAddOnRules(), fetchOnlineModelForCode(ModelType.VoiceSME)]);

  return {
    voiceModel: getOnlineModelFilteringOutNonOfferAddOns(onlineModel),
    addOnRules,
  };
};

export interface OrderMobileBBSubLoaderData {
  addOnRules: AddOnRulesResponse;
  mobileBroadbandModel: OnlineModel;
}

export const orderMobileBBSubLoader = async () => {
  const [addOnRules, onlineModel] = await Promise.all([
    fetchAddOnRules(),
    fetchOnlineModelForCode(ModelType.MobileBroadband),
  ]);

  return {
    addOnRules,
    mobileBroadbandModel: getOnlineModelFilteringOutNonOfferAddOns(onlineModel),
  };
};

export interface OrderSubscriptionCommonLoaderData {
  companyInfo: CompanyInfoResponse;
  onlineModels: OnlineModel[];
  contacts: ContactsResponse;
}

export const orderSubscriptionCommonLoader = async ({ request }: LoaderFunctionArgs) => {
  const mdmId = getCompanyIdFromRequest(request) || getActiveAccountMasterId() || undefined;
  const [companyData, contacts] = await Promise.all([
    fetchCompanyInfoAndEnabledOnlineModels(mdmId),
    fetchContacts({ useSearchService: true, offset: 0 }, { mdmId }),
  ]);
  return {
    companyInfo: companyData.companyInfo,
    onlineModels: companyData.onlineModels.map(getOnlineModelFilteringOutNonOfferAddOns),
    contacts,
  };
};

export interface OrderSubscriptionConfigLoaderData {
  holidays: Date[];
  onlineModel: OnlineModel;
}

export const orderSubscriptionConfigLoader = async (_: LoaderFunctionArgs, modelType: string) => {
  const [holidays, onlineModel] = await Promise.all([fetchHolidays(), fetchOnlineModelForCode(modelType)]);

  return {
    holidays: holidays.dates.map(date => new Date(date)),
    onlineModel: getOnlineModelFilteringOutNonOfferAddOns(onlineModel),
  };
};

export interface OrderSubscriptionDeliveryOptionsLoaderData {
  billingAccounts: BillingAccountsResponse;
  billChannels: BillChannel[];
}

export const orderSubscriptionDeliveryOptionsLoader = async ({ request }: LoaderFunctionArgs) => {
  const mdmId = getCompanyIdFromRequest(request) || getActiveAccountMasterId() || undefined;
  const [billingAccounts, billChannels] = await Promise.all([
    fetchBillingAccounts({ useSearchService: true, sourceSystem: SourceSystem.SFDC }, { mdmId }),
    fetchBillChannels(),
  ]);

  return {
    billingAccounts,
    billChannels,
  };
};

export interface MobileSubAttachRingLoaderData {
  addOnRules: AddOnRulesResponse;
  companyInfo?: CompanyInfoState;
  onlineModel: OnlineModel;
  pendingSubscriptionActions: SubscriptionAction[];
  subscription: Subscription;
}

export const mobileSubAttachRingLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<MobileSubAttachRingLoaderData> => {
  const companyId = getCompanyIdFromRequest(request);
  const [addOnRules, companyInfo, onlineModel, pendingSubscriptionActions, subscription] = await Promise.all([
    fetchAddOnRules(SubscriptionType.MOBILE_PBX),
    fetchCompanyInfo(companyId),
    fetchOnlineModelEffectivePriceForCode(ModelType.Ring, companyId),
    fetchPendingSubsciptionActions(companyId),
    fetchSubscription(SubscriptionCategory.VOICE, params.subscriptionId, companyId),
  ]);
  return {
    addOnRules,
    companyInfo,
    onlineModel,
    pendingSubscriptionActions,
    subscription,
  };
};
