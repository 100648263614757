import { ChangeSimCardConfirmDialog } from '../ChangeSimCardConfirmDialog/ChangeSimCardConfirmDialog.js';
import { ConfirmationDialog } from '../ConfirmationDialog/index.js';
import { DialogType, SwitchAccountJourneyPoint } from '../../common/enums.js';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { EmployeeYttContactChangeDialog } from '../YttContactChangeDialog/EmployeeYttContactChangeDialog.js';
import { EppRedeemDialog } from '../EppRedeem/EppRedeemDialog.js';
import { EppTerminateDialog } from '../EppDialogs/EppTerminateDialog.js';
import { ReviewOnlineOrderDialog } from '../ReviewOnlineOrderDialog/ReviewOnlineOrderDialog.js';
import { SupportCase } from '../../generated/api/models.js';
import { SupportCaseDialog } from '../SupportCaseDialog/SupportCaseDialog.js';
import { authValidate, closeDialog, switchAccount } from '../../selfservice/actions/index.js';
import { closeMsg, confirmMsg, logOutMsg, subChangePendingMsg, t } from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { GenericConfirmationDialogParams, SwitchAccountDialogParams } from '../../common/types/dialog.js';
import type { State } from '../../selfservice/common/store.js';

export const EmployeeDialog = () => {
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dialog = useSelector((state: State) => state.dialog, deepEqual);
  const onCloseDialog = () => dispatch(closeDialog());

  if (!dialog) {
    return null;
  }

  if (dialog?.params?.type) {
    switch (dialog.params.type) {
      case DialogType.SSO_FORBIDDEN:
        return (
          <DialogWrapper
            header={t.LQ3X(logOutMsg)}
            onCloseDialog={onCloseDialog}
            buttons={[{ onClick: () => logout(), text: t.LQ3X(logOutMsg) }]}
          >
            <p>
              {t.KDZD(
                'It seems that you are an admin user and logged in from OmaElisa. Please log out and register your ElisaID (if not done already) and then log into Employee Portal. Click confirm to log out of OmaElisa.'
              )}
            </p>
          </DialogWrapper>
        );

      case DialogType.EPP_TERMINATE:
        return (
          <EppTerminateDialog
            params={dialog.params}
            onCloseDialog={onCloseDialog}
            isLoading={dialog.submitInProgress}
            errors={dialog.errors}
            isEmployee={true}
          />
        );

      case DialogType.EPP_REDEEM:
        return (
          <EppRedeemDialog
            onCloseDialog={onCloseDialog}
            params={dialog.params}
            errors={dialog.errors}
            submitInProgress={dialog.submitInProgress}
          />
        );

      case DialogType.SUBSCRIPTION_ACTION_PENDING:
        return (
          <DialogWrapper closeable header={t.I9KT('Change order in progress')} onCloseDialog={onCloseDialog}>
            {t.MQHO(subChangePendingMsg)}
          </DialogWrapper>
        );

      case DialogType.CHANGE_SIM_CARD_CONFIRMATION:
        return (
          <ChangeSimCardConfirmDialog
            category={dialog.params.category}
            simCardNumber={dialog.params.simCardNumber}
            subscriptionDisplayId={dialog.params.subscriptionDisplayId}
            subscriptionId={dialog.params.subscriptionId}
            onCloseDialog={onCloseDialog}
            isLoading={dialog.submitInProgress}
            errors={dialog.errors}
          />
        );

      case DialogType.GENERIC_INFO_DIALOG:
        return (
          <DialogWrapper
            buttons={[{ onClick: onCloseDialog, text: t.WOYD(closeMsg) }]}
            header={dialog.params.header}
            onCloseDialog={onCloseDialog}
            size={dialog.params.wide ? 'l' : 'm'}
          >
            <div className="ds-padding-top--4">{dialog.params.body}</div>
          </DialogWrapper>
        );

      case DialogType.DUPLICATE_CONTACT:
        return (
          <DialogWrapper
            buttons={[
              {
                onClick: () => {
                  onCloseDialog();
                  dispatch(authValidate());
                },
                text: 'OK',
              },
            ]}
            onCloseDialog={onCloseDialog}
            header={dialog.params.header}
          >
            <p>{dialog.params.body}</p>
          </DialogWrapper>
        );

      case DialogType.GENERIC_CONFIRMATION_DIALOG:
        return (
          <ConfirmationDialog
            body={dialog.params.body}
            confirmButtonText={dialog.params.confirmButtonText}
            errors={dialog.errors}
            header={dialog.params.header}
            hideClosingCross={dialog.params.hideClosingCross}
            isLoading={dialog.submitInProgress}
            onCloseDialog={() => {
              const params = dialog.params as GenericConfirmationDialogParams;
              if (params.onCancel) {
                params.onCancel();
              }
              onCloseDialog();
            }}
            onConfirm={dialog.params.onConfirm}
          />
        );

      case DialogType.SWITCH_ACCOUNT:
        return (
          <ConfirmationDialog
            body={dialog.params.body}
            confirmButtonText={t.QVYK(confirmMsg)}
            errors={dialog.errors}
            header={dialog.params.header}
            isLoading={dialog.submitInProgress}
            onCloseDialog={() => {
              const params = dialog.params as SwitchAccountDialogParams;
              if (params.journeyPoint === SwitchAccountJourneyPoint.MOBILE_SUB_PAIRING) {
                navigate(paths.EMPLOYEE_VOICE_SUBSCRIPTION_ADD);
              } else if (params.journeyPoint === SwitchAccountJourneyPoint.ONBOARDING) {
                dispatch(authValidate());
              }
              onCloseDialog();
            }}
            onConfirm={() => dispatch(switchAccount())}
          />
        );

      case DialogType.REVIEW_ONLINE_ORDER:
        return (
          <ReviewOnlineOrderDialog
            onlineOrderReview={dialog.params.onlineOrderReview}
            customerOrder={dialog.params.customerOrder}
            approve={dialog.params.approved}
            onCloseDialog={onCloseDialog}
            errors={dialog.errors}
            ordererEmail={dialog.params.ordererEmail}
            isLoading={dialog.submitInProgress}
          />
        );

      case DialogType.EPP_REDEEM_REJECT:
        return (
          <SupportCaseDialog
            action="EPP_REDEEM_REJECT"
            body=""
            errors={dialog.errors}
            featureName={SupportCase.FeatureEnum.SUBSCRIPTIONS}
            header={t.W692('Confirm redeem rejection request')}
            isSubmitInProgress={dialog.submitInProgress}
            onCloseDialog={onCloseDialog}
            onSubmitDialog={dialog.params.onRedeemReject}
            placeholder={t.EBNB('Additional info to employee')}
            saveButtonLabel={t.QVYK(confirmMsg)}
          />
        );

      case DialogType.UPDATE_USER_YTT_WARNING: {
        return (
          <EmployeeYttContactChangeDialog
            onCloseDialog={onCloseDialog}
            values={dialog.params.values}
            onConfirm={dialog.params.onConfirm}
            validationErrors={dialog.params.validationErrors}
          />
        );
      }

      case DialogType.GENERIC_EMPTY_DIALOG:
        return (
          <DialogWrapper
            closeable
            header={dialog.params.header}
            onCloseDialog={onCloseDialog}
            size={dialog.params.wide ? 'l' : 'm'}
          >
            <div className="ds-padding-top--4">{dialog.params.body}</div>
          </DialogWrapper>
        );
    }
  }
  return null;
};
