import { type Contact } from '../OpenFormContext.js';
import { fetchContacts } from '../../../common/fetch.js';
import { loadingContactsMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';

export const useFetchContacts = ({ useSearchService = true } = {}) => {
  const async = useOpenFormAsync();
  const [data, setData] = useState<Contact[] | undefined>(undefined);

  useEffect(() => {
    const { ctrl } = new async(({ signal }) => fetchContacts({ useSearchService }, { signal }))
      .resolved(({ searchResults }) => setData(searchResults?.map(({ result }) => result)))
      .rejected(text => async.actions.notification({ text, type: 'error' }))
      .cache(useSearchService)
      .execute(t.Z77W(loadingContactsMsg));

    return () => {
      ctrl.abort();
      setData(undefined);
    };
  }, [async, useSearchService]);

  return data;
};
