import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { CompanySettings } from '../../../../../components/CompanySettings/CompanySettings.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { useSelector } from 'react-redux';
import type { State } from '../../../../../selfservice/common/store.js';

export const CompanyInfoSettingsPath = () => {
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  return (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.SETTINGS}>
      <CompanySettings companyInfo={companyInfo} />
    </CompanyInfoContainer>
  );
};
