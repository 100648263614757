import * as CL from '@design-system/component-library';
import { AccountStatus, AuthenticatedUserRole, ContactRole, UserProfile } from '../../generated/api/models.js';
import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { DialogType } from '../../common/enums.js';
import { LinkableAccordion } from '../LinkableAccordion/index.js';
import { ToggleCheckbox } from '../ToggleCheckbox/ToggleCheckbox.js';
import {
  accessRightsMsg,
  addAccessToOtherCompaniesAdditionalInformationMsg,
  addAccessToOtherCompaniesMsg,
  administratorAndOrderAcceptorMsg,
  administratorMsg,
  confirmMsg,
  editMsg,
  fromOldElisaMsg,
  noRightsMsg,
  orderAcceptorAdditionalInformationMsg,
  orderAcceptorMsg,
  otherCompaniesMsg,
  t,
  userRightsAdditionalInformationMsg,
  userRightsInstructionMsg,
} from '../../common/i18n/index.js';
import { createAdminUser, deleteAdminUser, upsertContactUser } from '../../common/fetch.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getCompanyName, getPrimaryAccountId } from '../../common/utils/accountUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { updateUserRightsFailed, updateUserRightsFulfilled } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import type { AccountContactRelationship, AccountHeader, Contact } from '../../generated/api/models.js';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { State } from '../../selfservice/common/store.js';

import './UserRolesAndAdminRights.scss';

interface UserRolesAndAdminRightsProps {
  isNewDisconnectedContact: boolean;
  ignoreAccordion: boolean;
  companyName?: string;
  contact?: Contact;
  secondaryAccounts?: AccountHeader[];
  onShowDialog: (params: DialogParams) => void;
  onChangeAdmin?: (isAdmin: boolean) => void;
  onChangeApproverRole?: (isApprover: boolean) => void;
  onChangeContactACRs?: (acrs: AccountContactRelationship[]) => void;
  companyId?: string;
  authenticatedUser: AuthenticatedUserState;
}

const isContactInitiallyAdmin = (contact?: Contact): boolean => {
  return contact?.person?.userProfile === AuthenticatedUserRole.KEY_USER;
};

const isContactInitiallyApprover = (contact?: Contact): boolean => {
  return contact?.person?.roles?.includes(ContactRole.APPROVER) || false;
};

const getSecondaryACRs = (accountContactRelationships: AccountContactRelationship[]) =>
  accountContactRelationships.filter(acr => !acr.isPrimaryAccount);

const getSecondaryACRsFromContact = (contact?: Contact): string[] => {
  return getSecondaryACRs(contact?.accountContactRelationships || [])?.map(account => account.accountMasterId) || [];
};

const getSecondaryACRsCompanyName = (
  secondaryACRsMdmIds: string[],
  secondaryAccountsHeader: AccountHeader[]
): string[] => {
  return secondaryACRsMdmIds
    .map(secondaryACRsMdmId => {
      const accountHeader = secondaryAccountsHeader.find(item => item.accountMasterId === secondaryACRsMdmId);
      if (accountHeader) {
        return accountHeader.accountName;
      } else {
        return '';
      }
    })
    .filter(e => e !== '');
};

const isAccountCheckboxSelected = (accountMasterId: string, items: string[]): boolean | undefined => {
  return items.includes(accountMasterId);
};

const toggleElement = (arr: string[], val: string) =>
  arr.includes(val) ? arr.filter(el => el !== val) : [...arr, val];

const getTitleForSection = (isContactAdmin: boolean, isContactApprover: boolean) => {
  if (isContactAdmin && !isContactApprover) {
    return (
      <div>
        <CL.Icon icon="check" size="s" color="success" type="filled" /> {t.R51P(administratorMsg)}
      </div>
    );
  } else if (!isContactAdmin && isContactApprover) {
    return (
      <div>
        <CL.Icon icon="check" size="s" color="success" type="filled" /> {t.POX2(orderAcceptorMsg)}
      </div>
    );
  } else if (isContactAdmin && isContactApprover) {
    return (
      <div>
        <CL.Icon icon="check" size="s" color="success" type="filled" /> {t.Y7UG(administratorAndOrderAcceptorMsg)}
      </div>
    );
  } else {
    return <div>{t.VXXY(noRightsMsg)}</div>;
  }
};

const getUpdatedSecondaryACRs = (selectedSecondaryAccounts: string[], contact?: Contact) => {
  const existingSecondaryACRs = getSecondaryACRs(contact?.accountContactRelationships || []);
  const updatedSecondaryACRs: AccountContactRelationship[] = selectedSecondaryAccounts.map(accountMasterId => {
    return {
      isPrimaryAccount: false,
      accountMasterId,
    };
  });
  const isSecondaryACRsUpdated = !deepEqual(existingSecondaryACRs, updatedSecondaryACRs);

  return {
    isSecondaryACRsUpdated,
    updatedSecondaryACRs,
  };
};

function getSecondaryCompanyNamesSection(secondaryACRsCompanyName: string[]) {
  return (
    <>
      <h5 className="ds-margin-bottom--1">{t.Y37N(otherCompaniesMsg)}</h5>
      <div>
        <CL.Icon icon="check" size="s" color="success" type="filled" />{' '}
        <span>
          {t.E112('Administrator')}: {secondaryACRsCompanyName.join(', ')}
        </span>
      </div>
    </>
  );
}

export const UserRolesAndAdminRights = (props: UserRolesAndAdminRightsProps) => {
  const { logout } = useAuth();
  const dispatch = useDispatch();

  const {
    companyId,
    contact,
    ignoreAccordion,
    isNewDisconnectedContact,
    onShowDialog,
    onChangeAdmin,
    onChangeApproverRole,
    onChangeContactACRs,
    authenticatedUser,
  } = props;
  const companyName =
    // two separate function calls in case companyId is non-empty nonsense
    getCompanyName(authenticatedUser, companyId) ||
    getCompanyName(authenticatedUser, getPrimaryAccountId(authenticatedUser));

  const [isEdit, setIsEdit] = useState(isNewDisconnectedContact || ignoreAccordion);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [secondaryACRs, setSecondaryACRs] = useState(() => getSecondaryACRsFromContact(contact) || []);
  const [isContactApprover, setIsContactApprover] = useState(() => isContactInitiallyApprover(contact));
  const [isContactAdmin, setIsContactAdmin] = useState(() => isContactInitiallyAdmin(contact));
  const [showLimitedAccountsList, setShowLimitedAccountsList] = useState(true);

  const otherAccessibleAccountsWithUserRightsInSalesforce = authenticatedUser?.secondaryAccounts || [];

  const currentAccount = {
    accountMasterId: authenticatedUser.mdmId!,
    accountName: authenticatedUser.companyName!,
    businessId: authenticatedUser.businessId!,
    accountStatus: AccountStatus.ACTIVE,
    deviceStoreInOec: authenticatedUser.deviceStoreInOec,
    userRightsInSalesforce: authenticatedUser.userRightsInSalesforce,
  } satisfies AccountHeader;

  // because we are using company selection from dropdown,
  // we need to combine "secondary" accounts (i.e. accessible accounts other than active account)
  // with the active account to have a list of all accounts,
  // and then filter out the currently selected company for "Add administrator rights to other companies"
  const accountsWithUserRightsInSalesforce =
    [...otherAccessibleAccountsWithUserRightsInSalesforce, currentAccount].filter(
      (acc: AccountHeader) => acc.userRightsInSalesforce === true && acc.accountMasterId !== companyId
    ) || [];

  const maxAccountSelectionCount = showLimitedAccountsList ? 20 : accountsWithUserRightsInSalesforce?.length || 20;
  const visibleSecondaryAccounts = showLimitedAccountsList
    ? accountsWithUserRightsInSalesforce?.slice(0, maxAccountSelectionCount) || []
    : accountsWithUserRightsInSalesforce;
  const showMoreAccountsLink =
    showLimitedAccountsList &&
    accountsWithUserRightsInSalesforce &&
    accountsWithUserRightsInSalesforce.length > maxAccountSelectionCount;

  const { classicSiteUrl } = useSelector(
    (state: State) => ({
      classicSiteUrl: state.config.classicSiteUrl,
    }),
    deepEqual
  );

  const submitContact = async (contactToBeUpdated: Contact) => {
    const updatedContact: Contact = JSON.parse(JSON.stringify(contactToBeUpdated));

    if (!updatedContact.contactId || !authenticatedUser?.mdmId) {
      return;
    }

    const doCreateAdmin = isContactAdmin && contact?.person?.userProfile !== AuthenticatedUserRole.KEY_USER;
    const doDeleteAdmin = !isContactAdmin && contact?.person?.userProfile === AuthenticatedUserRole.KEY_USER;
    const { isSecondaryACRsUpdated, updatedSecondaryACRs } = getUpdatedSecondaryACRs(secondaryACRs, contact);
    if (isSecondaryACRsUpdated) {
      updatedContact.accountContactRelationships = updatedSecondaryACRs;
    }

    let approverRoleUpdated = false;

    if (updatedContact.person) {
      updatedContact.person.userProfile = isContactAdmin ? UserProfile.KEY_USER : UserProfile.EMPLOYEE;
      // Append approver role if selected and doesn't already exist
      if (isContactApprover && !contact?.person?.roles?.includes(ContactRole.APPROVER)) {
        updatedContact.person.roles?.push(ContactRole.APPROVER);
        approverRoleUpdated = true;
      }
      // Remove approver role if removed from selection
      if (!isContactApprover && contact?.person?.roles?.includes(ContactRole.APPROVER)) {
        updatedContact.person.roles = updatedContact.person.roles?.filter(role => role !== ContactRole.APPROVER);
        approverRoleUpdated = true;
      }
    }

    setIsSubmitting(true);

    if (doDeleteAdmin && updatedContact.contactId === authenticatedUser?.contact?.contactId) {
      setIsSubmitting(false);
      return onShowDialog({
        header: t.B7GO('Are you sure you want to remove your own OmaElisa administrator rights?'),
        confirmButtonText: t.R3VE('Remove'),
        body: (
          <div className="ds-padding-top--2 ds-padding-bottom--2">
            {t.U3TB(
              'You are about to remove your own OmaElisa administrator rights. You will be logged out immediately from the OmaElisa for Companies. The removal can not be canceled after it is done.'
            )}
          </div>
        ),
        onConfirm: async (onCloseDialog: () => void) => {
          onCloseDialog();
          try {
            await deleteAdminUser(updatedContact);
            setIsEdit(false);
            dispatch(updateUserRightsFulfilled());
            logout(paths.SELF_SERVICE_LOGOUT);
          } catch (err) {
            dispatch(updateUserRightsFailed());
          } finally {
            setIsSubmitting(false);
          }
        },
        type: DialogType.GENERIC_CONFIRMATION_DIALOG,
      });
    }

    try {
      if (doCreateAdmin) {
        await createAdminUser(updatedContact);
        dispatch(updateUserRightsFulfilled());
      } else if (doDeleteAdmin) {
        await deleteAdminUser(updatedContact);
        dispatch(updateUserRightsFulfilled());
      } else if (isSecondaryACRsUpdated || approverRoleUpdated) {
        await upsertContactUser(updatedContact, false, undefined);
        dispatch(updateUserRightsFulfilled());
      }
      setIsEdit(false);
    } catch (err) {
      dispatch(updateUserRightsFailed());
    } finally {
      setIsSubmitting(false);
    }
  };

  const adminInstructions = (
    <>
      {t.KVCU(userRightsInstructionMsg)}
      {!authenticatedUser?.userRightsInSalesforce && (
        <div>
          {t.JP50(userRightsAdditionalInformationMsg)}{' '}
          <a href={`${classicSiteUrl}`} rel="noopener noreferrer">
            {t.RR0O(fromOldElisaMsg)}
          </a>
          .
        </div>
      )}
    </>
  );

  const accountsSelectionSection = (accounts: AccountHeader[]) => (
    <div className="of-contact-access-rights-accordion__content__account-selections">
      {accounts.map(item => (
        <CL.Checkbox
          key={item.accountMasterId}
          className="ds-color--blue"
          checked={isAccountCheckboxSelected(item.accountMasterId, secondaryACRs)}
          onChange={() => {
            const acrs = toggleElement(secondaryACRs, item.accountMasterId);
            setSecondaryACRs(acrs);
            if (onChangeContactACRs) {
              const { updatedSecondaryACRs } = getUpdatedSecondaryACRs(acrs, contact);
              onChangeContactACRs(updatedSecondaryACRs);
            }
          }}
        >
          {item.accountName}
        </CL.Checkbox>
      ))}
      {showLimitedAccountsList && showMoreAccountsLink && (
        <div className="of-contact-access-rights-accordion__content__account-selections--show-all-link">
          <CL.Button color="link" onClick={() => setShowLimitedAccountsList(false)}>
            {t.SF4C('Show all')}
          </CL.Button>
        </div>
      )}
    </div>
  );

  const onUpdateAdministratorRole = (isAdmin: boolean) => {
    const { updatedSecondaryACRs } = getUpdatedSecondaryACRs(secondaryACRs, contact);
    if (isAdmin) {
      const acrs = getSecondaryACRsFromContact(contact) || [];
      setIsContactAdmin(true);
      setSecondaryACRs(acrs);
      onChangeAdmin && onChangeAdmin(true);
      onChangeContactACRs && onChangeContactACRs(updatedSecondaryACRs);
    } else {
      setIsContactAdmin(false);
      setSecondaryACRs([]);
      onChangeAdmin && onChangeAdmin(false);
      onChangeContactACRs && onChangeContactACRs([]);
    }
  };

  const onUpdateApprovalRole = (isApprover: boolean) => {
    setIsContactApprover(isApprover);
    onChangeApproverRole && onChangeApproverRole(isApprover);
  };

  const requiredInformationMissing =
    !contact?.person?.firstName ||
    !contact?.person?.lastName ||
    !contact?.person?.phoneNumber ||
    !contact?.person?.email;

  const content = (
    <>
      <div className="of-contact-access-rights-accordion__content">
        <h5 className="of-contact-access-rights-accordion__content-title ds-margin-bottom--1">{companyName}</h5>
        {!isEdit && getTitleForSection(isContactInitiallyAdmin(contact), isContactInitiallyApprover(contact))}
        {!isEdit &&
          secondaryACRs.length > 0 &&
          authenticatedUser?.secondaryAccounts &&
          getSecondaryCompanyNamesSection(
            getSecondaryACRsCompanyName(secondaryACRs, authenticatedUser.secondaryAccounts)
          )}
        {isEdit && (
          <div className="of-contact-access-rights-accordion__content-options">
            {requiredInformationMissing && !isNewDisconnectedContact && (
              <CL.Disclaimer
                title={t.UV5C('Contact information is missing')}
                text={t.HP5L('User must have a name, phone number and email address in order to add access rights.')}
                disclaimerType="warning"
                icon={<CL.Icon icon="notification" type="regular" aria-hidden="true" />}
                visible
              />
            )}
            <div>
              <ToggleCheckbox
                title={t.R51P(administratorMsg)}
                additionalInformation={adminInstructions}
                initialValue={isContactAdmin}
                onChange={onUpdateAdministratorRole}
                disabled={
                  !authenticatedUser?.userRightsInSalesforce ||
                  (!isNewDisconnectedContact && requiredInformationMissing)
                }
              />
            </div>
            <div>
              <ToggleCheckbox
                title={t.POX2(orderAcceptorMsg)}
                additionalInformation={t.UCRF(orderAcceptorAdditionalInformationMsg)}
                initialValue={isContactApprover}
                onChange={onUpdateApprovalRole}
                disabled={!isNewDisconnectedContact && requiredInformationMissing}
              />
            </div>
          </div>
        )}
        {isEdit &&
          isContactAdmin &&
          accountsWithUserRightsInSalesforce &&
          accountsWithUserRightsInSalesforce?.length > 0 && (
            <>
              <h5>
                {t.Y37N(otherCompaniesMsg)} ({accountsWithUserRightsInSalesforce?.length || 0})
              </h5>
              {isContactAdmin && authenticatedUser?.userRightsInSalesforce && (
                <div>
                  <div className="of-contact-access-rights-accordion__other_companies_section ds-padding--3">
                    <div>
                      <span>{t.FD6H(addAccessToOtherCompaniesMsg)}</span>
                    </div>
                    <div>
                      <span className="ds-font-size--small">
                        {t.HWZF(addAccessToOtherCompaniesAdditionalInformationMsg)}
                      </span>
                    </div>
                    {accountsSelectionSection(visibleSecondaryAccounts)}
                  </div>
                </div>
              )}
            </>
          )}
      </div>
      <div className="of-contact-access-rights-accordion__buttons">
        {isEdit && !isNewDisconnectedContact && contact && (
          <ButtonGroupForSubmitAndBack
            onCancel={() => {
              setSecondaryACRs(getSecondaryACRsFromContact(contact) || []);
              setIsEdit(false);
            }}
            onSubmit={() => submitContact(contact)}
            submitButtonText={t.QVYK(confirmMsg)}
            submitting={isSubmitting}
            submitDisabled={requiredInformationMissing}
            className="of-contact-user-rights-accordion__button-group"
            submitOnLeft={true}
          />
        )}
        {!isEdit && (
          <CL.Button type="button" color="light" onClick={() => setIsEdit(true)}>
            {t.NVPK(editMsg)}
          </CL.Button>
        )}
      </div>
    </>
  );

  if (ignoreAccordion) {
    return content;
  } else {
    return (
      <LinkableAccordion id="access-rights-accordion" heading={t.Y8LP(accessRightsMsg)}>
        {content}
      </LinkableAccordion>
    );
  }
};
