import { DialogWrapper } from '../DialogWrapper/index.js';
import { DuplicateContact } from '../../generated/api/duplicateContact.js';
import {
  chooseThisContactMsg,
  contactAlreadyExistsMsg,
  contactCanBeFoundInCompanyListingMsg,
  duplicateContactHeaderMsg,
  t,
} from '../../common/i18n/index.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { DialogWrapperButton } from '../DialogWrapper/index.js';
import type { GenericErrorDuplicateContact } from '../../generated/api/genericErrorDuplicateContact.js';

export interface DuplicateContactDialogProps {
  onCloseDialog: () => void;
  onSelectDuplicateContact: (contactId: string) => void;
  duplicateContact: GenericErrorDuplicateContact;
  duplicateContactType: string;
  hideUseContactDuplicateButton?: boolean;
}

export const DuplicateContactDialog = ({
  duplicateContact,
  duplicateContactType,
  hideUseContactDuplicateButton,
  onCloseDialog,
  onSelectDuplicateContact,
}: DuplicateContactDialogProps) => {
  const duplicateContactPageUrl = paths.COMPANY_INFO_CONTACTS + '/' + duplicateContact.contactId;

  const buttons: DialogWrapperButton[] = [
    {
      color: hideUseContactDuplicateButton ? 'primary' : 'link',
      onClick: () => {
        onCloseDialog();
      },
      text: t.B2V1('Cancel'),
    },
    ...(!hideUseContactDuplicateButton
      ? [
          {
            onClick: () => {
              onSelectDuplicateContact(duplicateContact.contactId);
              onCloseDialog();
            },
            text: t.T51P(chooseThisContactMsg),
          },
        ]
      : []),
  ];

  return (
    <DialogWrapper
      buttons={buttons}
      onCloseDialog={onCloseDialog}
      header={
        duplicateContactType === DuplicateContact.DuplicateTypeEnum.POSSIBLE_CONTACT_DUPLICATE
          ? t.CVJF(duplicateContactHeaderMsg)
          : t.USHS(contactAlreadyExistsMsg)
      }
    >
      <p>
        {duplicateContactType === DuplicateContact.DuplicateTypeEnum.POSSIBLE_CONTACT_DUPLICATE
          ? t.AR17('Is this the user you are looking for? Select the found user or create a new user.')
          : t.HFUO(contactCanBeFoundInCompanyListingMsg)}
      </p>
      <div>
        <div>
          <strong>{t.VA44('Found contact')}:</strong>
        </div>
        <div>
          {duplicateContact.firstName} {duplicateContact.lastName}
        </div>
        <div>{duplicateContact.email}</div>
        <div>{formatPhoneNumber(duplicateContact.phoneNumber)}</div>
      </div>
      {hideUseContactDuplicateButton && (
        <p>
          <a href={duplicateContactPageUrl}>{t.BHBQ(`Open contact's details`)}</a>
        </p>
      )}
    </DialogWrapper>
  );
};
