import { type OpenFormActions, type OpenFormState } from './OpenFormReducer.js';
import { type OpenFormStorage } from './OpenFormStorage.js';

export class OpenFormAsync<Resolved = unknown> {
  static readonly cache = new Map<string, Promise<unknown>>();
  static actions: OpenFormActions;
  static getState: () => OpenFormState;
  static storage: OpenFormStorage;

  readonly ctrl = new AbortController();
  readonly signal = this.ctrl.signal;

  private readonly promise: () => Promise<void | Resolved>;
  private resolve?: (value?: Resolved) => void | Resolved;
  private reject?: (reason?: Response) => void;
  private settle?: (result?: Resolved) => void;

  private fulfilled?: Promise<void | Resolved> | Resolved;
  private id: string;
  private store: boolean;
  private text?: string;

  constructor(private readonly callback: (self: OpenFormAsync) => Promise<void | Resolved>) {
    this.promise = () => {
      this.text && OpenFormAsync.actions.loading({ id: this.id, text: this.text });
      return (this.fulfilled ??= this.callback(this)
        .then(this.resolved().resolve)
        .catch(this.rejected().reject)
        .finally(this.settled().settle)) as Promise<void | Resolved>;
    };
  }

  readonly evict = () => this.store && OpenFormAsync.cache.delete(this.id);

  cache(...key: (boolean | null | number | string)[]) {
    this.id ??= btoa(String(this.callback) + String(key.length && key));
    this.store ??= true;
    return this;
  }

  execute(text?: string) {
    this.id ??= btoa(String(this.callback));
    this.store ??= false;
    this.text ??= text;

    this.store
      ? OpenFormAsync.cache.has(this.id)
        ? OpenFormAsync.cache.get(this.id)!.then(this.resolve)
        : OpenFormAsync.cache.set(this.id, this.promise())
      : this.promise();

    return this;
  }

  resolved(resolve?: (value: Resolved) => void) {
    this.resolve ??= (value?: Resolved) => {
      resolve && !this.signal.aborted && value !== undefined && resolve(value);
      return this.fulfilled ? (this.fulfilled = value) : value;
    };
    return this;
  }

  rejected(reject?: (reason: string) => void) {
    this.reject ??= (reason?: Response) => {
      reject && !this.signal.aborted && (reason?.text?.().then(reject) || reject(String(reason)));
      this.evict();
    };
    return this;
  }

  settled(settle?: (result?: Resolved) => void) {
    this.settle ??= () => {
      settle?.(this.fulfilled as Resolved) || delete this.fulfilled;
      this.text && OpenFormAsync.actions.loading({ id: this.id, text: undefined });
    };
    return this;
  }
}
