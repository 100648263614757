import { type Choices, OpenFormAnswers } from './OpenFormAnswers.js';
import { type Context } from './OpenFormContext.js';
import { type Dispatch } from 'react';
import { type OpenFormLoading } from './OpenFormComponents/OpenFormLoadingSpinner.js';
import { type OpenFormNotification } from './OpenFormComponents/OpenFormNotifications.js';
import { type OpenFormStorage } from './OpenFormStorage.js';

export type OpenFormActions = {
  [K in keyof typeof OpenFormAction]: (
    ...args: (typeof OpenFormAction)[K] extends (...args: infer P) => unknown ? P : never
  ) => void;
};

export type OpenFormDispatch = Dispatch<Action>;

export type OpenFormState = Required<ReturnType<typeof OpenFormReducer.state>>;

type Action =
  | ReturnType<typeof OpenFormAction.answer>
  | ReturnType<typeof OpenFormAction.confirm>
  | ReturnType<typeof OpenFormAction.context>
  | ReturnType<typeof OpenFormAction.error>
  | ReturnType<typeof OpenFormAction.form>
  | ReturnType<typeof OpenFormAction.index>
  | ReturnType<typeof OpenFormAction.list>
  | ReturnType<typeof OpenFormAction.loading>
  | ReturnType<typeof OpenFormAction.notification>;

const type: unique symbol = Symbol('OpenFormAction');

export class OpenFormAction {
  static readonly answer = (payload: { guid: string; value: string | string[] | undefined }) => ({
    [type]: 'answer' as const,
    payload,
  });

  static readonly confirm = (payload: boolean) => ({
    [type]: 'confirm' as const,
    payload,
  });

  static readonly context = <K extends keyof Context>(payload: { guid: string; key?: K; patch?: Context[K] }) => ({
    [type]: 'context' as const,
    payload,
  });

  static readonly error = (payload: Error | string | undefined) => ({
    [type]: 'error' as const,
    payload,
  });

  static readonly form = (payload: {
    answers: Record<string, Choices> | undefined;
    context: Record<string, Context> | undefined;
    formId: string | undefined;
  }) => ({
    [type]: 'form' as const,
    payload,
  });

  static readonly index = (payload: { formId: string; index: number }) => ({
    [type]: 'index' as const,
    payload,
  });

  static readonly list = (payload: { indexes: [string, number][] | undefined; listId?: string }) => ({
    [type]: 'list' as const,
    payload,
  });

  static readonly loading = (payload: { id: string; text: string | undefined }) => ({
    [type]: 'loading' as const,
    payload,
  });

  static readonly notification = (payload: OpenFormNotification | undefined) => ({
    [type]: 'notification' as const,
    payload,
  });
}

export class OpenFormReducer {
  private constructor(
    readonly answers: OpenFormAnswers = new OpenFormAnswers(),
    readonly confirm: boolean = false,
    readonly disabled: boolean = false,
    readonly error: string | undefined = undefined,
    readonly formId: string | undefined = undefined,
    readonly indexes: Map<string, number> = new Map(),
    readonly listId: string | undefined = undefined,
    readonly loading: Set<OpenFormLoading> = new Set(),
    readonly notifications: Set<OpenFormNotification> = new Set()
  ) {}

  static actions(dispatch: OpenFormDispatch) {
    return Object.fromEntries(
      Object.entries(OpenFormAction)
        .filter(([_, prop]) => typeof prop === 'function')
        .map(([name, func]) => [name, (...args: unknown[]) => dispatch(func(...args))])
    ) as OpenFormActions;
  }

  static hook(storage?: OpenFormStorage) {
    const store = storage?.enabled() && OpenFormReducer.store(storage);

    return (state: OpenFormState, action: Action) => {
      state = OpenFormReducer.reduce(state, action);

      switch (action[type]) {
        case 'answer':
        case 'context':
        case 'form':
        case 'index': {
          store && state.formId && store(state.formId, state);
          return state;
        }
        case 'confirm':
        case 'loading':
        case 'notification': {
          const disabled = state.confirm || !!state.loading.size || !!state.notifications.size;
          return state.disabled !== disabled ? { ...state, disabled } : state;
        }
        case 'error':
        case 'list': {
          return state;
        }
      }
    };
  }

  static reduce(state: OpenFormState, action: Action) {
    switch (action[type]) {
      case 'answer': {
        const { guid, value } = action.payload;
        return typeof value === 'string'
          ? { ...state, answers: new OpenFormAnswers(state.answers.set(guid, [value]), state.answers.context) }
          : value?.length
            ? { ...state, answers: new OpenFormAnswers(state.answers.set(guid, value), state.answers.context) }
            : state.answers.delete(guid)
              ? { ...state, answers: new OpenFormAnswers(state.answers, state.answers.context) }
              : state;
      }
      case 'confirm': {
        const confirm = action.payload;
        return state.confirm !== confirm ? { ...state, confirm } : state;
      }
      case 'context': {
        const { guid, key, patch } = action.payload;
        return key
          ? { ...state, answers: new OpenFormAnswers(state.answers, state.answers.context.assign(guid, key, patch)) }
          : state.answers.context.delete(guid)
            ? { ...state, answers: new OpenFormAnswers(state.answers, state.answers.context) }
            : state;
      }
      case 'error': {
        const error = action.payload;
        try {
          return error
            ? error instanceof Error
              ? { ...state, error: String(error) }
              : { ...state, error: JSON.parse(error) }
            : state.error
              ? { ...state, error }
              : state;
        } catch {
          return { ...state, error };
        }
      }
      case 'form': {
        const { answers, context, formId } = action.payload;
        return { ...state, answers: new OpenFormAnswers(answers, context), formId };
      }
      case 'index': {
        const { formId, index } = action.payload;
        return state.indexes.get(formId) !== index
          ? { ...state, indexes: new Map(state.indexes.set(formId, index)) }
          : state;
      }
      case 'list': {
        const { indexes, listId = state.listId } = action.payload;
        return { ...state, indexes: new Map(indexes), listId };
      }
      case 'loading': {
        const { id, text } = action.payload;
        return text
          ? { ...state, loading: new Set(state.loading.add({ id, text })) }
          : state.loading.delete(Array.from(state.loading.values()).find(loading => loading.id === id)!)
            ? { ...state, loading: new Set(state.loading) }
            : state;
      }
      case 'notification': {
        const notification = action.payload;
        return notification
          ? { ...state, notifications: new Set(state.notifications.add(notification)) }
          : state.notifications.delete(state.notifications.values().next().value)
            ? { ...state, notifications: new Set(state.notifications) }
            : state;
      }
    }
  }

  static state() {
    return { ...new OpenFormReducer() } as const;
  }

  static store(storage: OpenFormStorage) {
    return (formId: string, state: OpenFormState) => {
      !state.answers.size && !state.answers.context.size
        ? storage.removeItem(formId)
        : storage.setItem(formId, {
            index: state.indexes.get(formId),
            answers: Object.fromEntries(state.answers),
            context: Object.fromEntries(state.answers.context),
          });
    };
  }
}
