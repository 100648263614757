import { BreadCrumbsWithTitle } from '../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { SubscriptionCategory } from '../../../../../common/enums.js';
import { SubscriptionDevice } from '../../../../../components/Subscription/SubscriptionDevice.js';
import { SubscriptionLayout } from '../../../../../components/SubscriptionLayout/SubscriptionLayout.js';
import { devicesMsg, omaElisaForCompaniesMsg, t } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useLoaderData, useParams } from 'react-router-dom';
import type { DeviceSubscriptionLoaderResponse } from '../../../../../common/loaders.js';
import type { SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const DevicePath = () => {
  const { subscriptionId } = useParams<SubscriptionId>();
  const { billingAccounts, companyInfo, contacts, pendingSubscriptionActions, subscription } =
    useLoaderData() as DeviceSubscriptionLoaderResponse;

  const breadCrumbs = (
    <BreadCrumbsWithTitle
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.JJTM(devicesMsg), path: paths.PS_DEVICES },
        { name: subscriptionId || '' },
      ]}
    />
  );

  return (
    <SubscriptionLayout>
      <SubscriptionDevice
        billingAccounts={billingAccounts}
        breadCrumbs={breadCrumbs}
        category={SubscriptionCategory.DEVICE}
        companyInfo={companyInfo}
        contacts={contacts}
        pendingSubscriptionActions={pendingSubscriptionActions}
        subscription={subscription}
      />
    </SubscriptionLayout>
  );
};
