import { AuthenticatedUserRole } from '../../generated/api/authenticatedUserRole.js';
import { IdentityProvider } from '../../generated/api/identityProvider.js';
import { OpenForm } from './OpenForm.js';
import { Outlet } from 'react-router-dom';
import { SelfServiceLogin } from '../SelfServiceLoginScene/SelfServiceLogin.js';
import { authenticatingMsg, authenticationFailedMsg, t } from '../../common/i18n/index.js';
import { isIframe } from './OpenFormUtils.js';
import { useCallback } from 'react';
import { useOpenFormAsync, useOpenFormURLParams } from './OpenFormProvider.js';
import type { AuthProps } from '../../public/site/AuthProvider.js';

export const OpenFormOutlet = ({ authenticatedUser, isAuthenticated, isLoading }: AuthProps) => {
  const async = useOpenFormAsync();
  const [params] = useOpenFormURLParams();

  const idPAuthenticated = useCallback(
    () =>
      IdentityProvider.SALESFORCE === authenticatedUser?.identityProvider &&
      [AuthenticatedUserRole.ADMIN, AuthenticatedUserRole.KEY_USER].includes(authenticatedUser.userRole!),
    [authenticatedUser]
  );

  const localAuthenticated = useCallback(
    () => isAuthenticated && window.location.hostname.includes('localhost'),
    [isAuthenticated]
  );

  switch (true) {
    case isLoading: {
      return <OpenForm heading={t.TA3J(authenticatingMsg)} />;
    }
    case idPAuthenticated():
    case localAuthenticated(): {
      async.storage.namespace = authenticatedUser?.activeAccountMasterId;
      return <Outlet />;
    }
    case !isIframe(): {
      return <SelfServiceLogin />;
    }
    case !!params.get('retry'): {
      return <OpenForm critical={t.J9TT(authenticationFailedMsg)} />;
    }
    default: {
      params.set('retry', Date.now().toString());
      window.location.assign('?' + params.toString());
      return null;
    }
  }
};
