export class OpenFormStorage implements Storage {
  #namespace = ':';

  constructor(private readonly storage: typeof sessionStorage | null) {}

  get keys() {
    return this.storage ? Object.keys(this.storage).filter(key => key.startsWith(this.#namespace)) : [];
  }

  get length() {
    return this.keys.length;
  }

  set namespace(namespace: string | undefined) {
    this.#namespace = (namespace ?? '') + ':';
  }

  clear() {
    this.keys.forEach(key => this.storage!.removeItem(key));
  }

  enabled() {
    return !!this.storage;
  }

  getItem(key: string) {
    try {
      return JSON.parse((this.storage?.getItem(this.#namespace + key) ?? null)!);
    } catch {
      return null;
    }
  }

  hasItem(key: string) {
    return this.storage && this.#namespace + key in this.storage;
  }

  key(index: number) {
    try {
      return JSON.parse((this.storage?.getItem(this.keys[index]) ?? null)!);
    } catch {
      return null;
    }
  }

  removeItem(key: string) {
    this.storage?.removeItem(this.#namespace + key);
  }

  setItem<T>(key: string, value: T) {
    try {
      this.storage?.setItem(this.#namespace + key, JSON.stringify(value));
    } catch {
      return;
    }
  }
}
