import { DeviceCheckoutBillSummaryHeader } from '../DeviceCheckoutBillSummaryHeader/DeviceCheckoutBillSummaryHeader.js';
import { FixedBroadbandCheckoutStepManager } from './FixedBroadbandCheckoutStepManager.js';
import { SalesType } from '../../generated/api/models.js';
import { StepBillingInformation } from './Steps/StepBillingInformation.js';
import { StepBroadbandProduct } from './Steps/StepBroadbandProduct.js';
import { StepBroadbandSetupDate } from './Steps/StepBroadbandSetupDate.js';
import { StepLoginOrContactInfo } from './Steps/StepLoginOrContactInfo.js';
import { StepProductSelection } from './Steps/StepProductSelection.js';
import {
  clearDuplicateContact,
  googleEcommerceAddPaymentInfo,
  googleEcommerceAddShippingInfo,
  googleEcommerceBeginCheckout,
} from '../../selfservice/actions/index.js';
import { createNettiliteEcommerceItemsFromConfiguredOffer } from '../../selfservice/common/googleEcommerceEvent.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import {
  getAddedAddOns,
  getCheapestCampaignAssociation,
  getConfiguredOffer,
  getGFastDevicePackageAddon,
  getSelectedOffer,
} from './FixedBroadbandUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n/index.js';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { AddOn, AddOnVisibility, Company, Offer, OnlineModel } from '../../generated/api/models.js';
import type { AddedAddon } from '../../common/types/addon.js';
import type { AddressSearchMatch } from '../AddressSearch/AddressSearch.js';
import type { CommercialProductAddOnVisibilitiesMap } from '../../common/utils/addOnVisibilityUtils.js';
import type { CommonError } from '../../common/types/errors.js';
import type { FixedBBCheckoutLoaderData } from '../../common/loaders.js';
import type { FixedBroadbandInstallationDetails } from './FixedBroadbandSetupDate.js';
import type { IpAddressAddOnSelection } from '../FixedBroadbandProductSelection/FixedBroadbandIpAddOn.js';
import type { PurposeOfUseOrContact } from '../../common/types/subscription.js';
import type { State } from '../../selfservice/common/store.js';
import type { YritysWifiSelection } from '../FixedBroadbandProductSelection/FixedBroadbandYritysWifiAddOn.js';

export interface FixedBroadbandCheckoutProps {
  addressSearchMatch: AddressSearchMatch;
  companySearchResult?: Company[];
  fixedBroadbandOnlineModel: OnlineModel;
  yritystuplaModel?: OnlineModel;
  validationErrors?: CommonError[];
  isMobileProduct: boolean;
  selectedOfferCode: string;
  commercialProductAddOnVisibilities?: CommercialProductAddOnVisibilitiesMap;
  publicAddOnVisibilities?: AddOnVisibility[];
  fixedBBCheckoutLoaderData: FixedBBCheckoutLoaderData;
}

export interface YritystuplaOfferSelection {
  enabled: boolean;
  selectedOffer: Offer | undefined;
}

export interface InstallationServiceAddOnSelection {
  enabled: boolean;
  selectedAddOn?: AddedAddon;
}

export interface CheckoutStepProps {
  enterNextStep: (step: number) => void;
}

const getYritysWifiForSelectedAddOns = (selection: YritysWifiSelection) => {
  if (!selection.enabled || !selection.selectedAddOn) {
    return [];
  }

  const quantitySpecifier = t.P3SF('MESH device');

  const addOns = Array(selection.quantity).fill({
    ...selection.selectedAddOn,
    addOnAttributes: {
      displayNameOverride: `${selection.selectedAddOn.displayName}, ${selection.quantity}×MESH-laite`,
      localizedDisplayNameOverride: `${selection.selectedAddOn.displayName}, ${quantitySpecifier}`,
    },
  });

  addOns.push({
    ...selection.deliveryFeeAddOn,
  });

  return addOns;
};

export const FixedBroadbandCheckout = (props: FixedBroadbandCheckoutProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasRunOnceRef = useRef(false);

  const { campaigns, companyInfo, createdContact, supportCaseSent } = useSelector(
    (state: State) => ({
      campaigns: state.campaigns,
      companyInfo: state?.selfservice?.companyInfo,
      createdContact: state?.selfservice?.subscriptionActions?.contactCreated,
      supportCaseSent: state?.selfservice?.fixedBroadbandOrders?.supportCaseSent || false,
    }),
    deepEqual
  );

  useEffect(() => {
    if (supportCaseSent) {
      navigate(paths.ANONYMOUS_FIXED_BROADBAND_ORDER_CONFIRMATION);
    }
  }, [supportCaseSent, navigate]);

  const [goToNextStep, setGoToNextStep] = useState(() => () => {});
  const [purposeOfUseOrContact, setPurposeOfUseOrContact] = useState<PurposeOfUseOrContact>();
  const [yritystuplaPurposeOfUse, setYritystuplaPurposeOfUse] = useState<PurposeOfUseOrContact>();
  const [broadbandInstallationDetails, setBroadbandInstallationDetails] = useState<FixedBroadbandInstallationDetails>();
  const [additionalAddOns, setAdditionalAddOns] = useState<AddOn[]>([]);
  const [campaignAutoSelect, setCampaignAutoSelect] = useState(true);
  const [yritystuplaOffer, setYritystuplaOffer] = useState<YritystuplaOfferSelection>({
    enabled: false,
    selectedOffer: undefined,
  });

  const {
    addressSearchMatch,
    fixedBroadbandOnlineModel,
    isMobileProduct,
    selectedOfferCode,
    yritystuplaModel,
    commercialProductAddOnVisibilities,
    publicAddOnVisibilities,
    fixedBBCheckoutLoaderData,
  } = props;

  const campaignContextsFromVoucher = useMemo(() => campaigns?.campaignContexts || [], [campaigns]);
  const campaignVoucherPromotions = useMemo(() => campaigns?.campaigns || [], [campaigns?.campaigns]);
  const selectedOffer = getSelectedOffer(fixedBroadbandOnlineModel.offers, selectedOfferCode);
  const commercialProduct = selectedOffer.commercialProducts[0];
  const [selectedCampaign, setSelectedCampaign] = useState(
    getCheapestCampaignAssociation(commercialProduct, campaignContextsFromVoucher)
  );
  const [yritysWifiSelection, setYritysWifiSelection] = useState<YritysWifiSelection>({
    enabled: false,
    selectedAddOn: undefined,
    quantity: 1,
  });

  const [installationServiceSelection, setInstallationServiceSelection] = useState<InstallationServiceAddOnSelection>({
    enabled: false,
  });
  const [ipAddressAddOnSelection, setIpAddressAddOnSelection] = useState<IpAddressAddOnSelection>({
    enabled: false,
  });
  const [isGFastDeviceIncluded, setGFastDeviceIncluded] = useState(false);
  const [additionalAddOnSelections, setAdditionalAddOnSelections] = useState<AddedAddon[]>([]);

  const selectedAddOns = useMemo(
    () => [
      ...(isGFastDeviceIncluded ? getAddedAddOns([getGFastDevicePackageAddon()]) : []),
      ...(installationServiceSelection.enabled ? [installationServiceSelection.selectedAddOn!] : []),
      ...(ipAddressAddOnSelection.enabled ? [ipAddressAddOnSelection.selectedAddOn!] : []),
      ...additionalAddOnSelections,
      ...getYritysWifiForSelectedAddOns(yritysWifiSelection),
    ],
    [
      isGFastDeviceIncluded,
      installationServiceSelection,
      ipAddressAddOnSelection,
      additionalAddOnSelections,
      yritysWifiSelection,
    ]
  );

  const configuredOffer = getConfiguredOffer(
    selectedOffer,
    commercialProduct,
    fixedBroadbandOnlineModel.campaigns || [],
    campaignVoucherPromotions,
    selectedAddOns,
    yritystuplaOffer,
    selectedCampaign,
    campaigns?.voucher
  );

  // First step doesn't fire enterNextStep so we need this.
  const eCommerceItemProducts = createNettiliteEcommerceItemsFromConfiguredOffer(configuredOffer);
  useEffect(() => {
    if (!hasRunOnceRef.current) {
      dispatch(googleEcommerceBeginCheckout(eCommerceItemProducts));
      hasRunOnceRef.current = true;
    }
  }, [dispatch, eCommerceItemProducts]);

  const enterNextStep = useCallback(
    (step: number) => {
      goToNextStep();
      switch (step) {
        case 3: {
          dispatch(googleEcommerceAddShippingInfo(eCommerceItemProducts));
          break;
        }
        case 4: {
          dispatch(googleEcommerceAddPaymentInfo(eCommerceItemProducts));
          break;
        }
      }
    },
    [dispatch, eCommerceItemProducts, goToNextStep]
  );

  useEffect(() => {
    if (campaignAutoSelect) {
      setSelectedCampaign(getCheapestCampaignAssociation(commercialProduct, campaignContextsFromVoucher));
    }
  }, [campaignAutoSelect, campaignContextsFromVoucher, commercialProduct]);

  useEffect(() => {
    if (createdContact) {
      setPurposeOfUseOrContact(createdContact);
      enterNextStep(3);
      dispatch(clearDuplicateContact());
    }
  }, [createdContact, dispatch, enterNextStep]);

  const steps = [
    {
      id: 'productSelection',
      content: (
        <StepProductSelection
          selectedOffer={selectedOffer}
          yritystuplaModel={yritystuplaModel}
          yritystuplaOffer={yritystuplaOffer}
          setYritystuplaOffer={setYritystuplaOffer}
          yritysWifiSelection={yritysWifiSelection}
          setYritysWifiSelection={setYritysWifiSelection}
          configuredOffer={configuredOffer}
          campaignContextsFromVoucher={campaignContextsFromVoucher}
          campaigns={campaigns}
          selectedCampaign={selectedCampaign}
          installationServiceSelection={installationServiceSelection}
          setInstallationServiceSelection={setInstallationServiceSelection}
          ipAddressAddOnSelection={ipAddressAddOnSelection}
          setIpAddressAddOnSelection={setIpAddressAddOnSelection}
          setGFastDeviceIncluded={setGFastDeviceIncluded}
          setCampaignAutoSelect={setCampaignAutoSelect}
          setSelectedCampaign={setSelectedCampaign}
          fixedBroadbandOnlineModel={fixedBroadbandOnlineModel}
          selectedAddOns={selectedAddOns}
          enterNextStep={enterNextStep}
          commercialProductAddOnVisibilities={commercialProductAddOnVisibilities}
          onAddOnSelectionChange={setAdditionalAddOnSelections}
          publicAddOnVisibilities={publicAddOnVisibilities}
          fixedBBCheckoutLoaderData={fixedBBCheckoutLoaderData}
        />
      ),
      name: t.UV57('Contract and additional services'),
      isVisible: true,
    },
    {
      id: 'loginOrContactInfo',
      content: <StepLoginOrContactInfo enterNextStep={enterNextStep} />,
      name: t.NF60('Customer details'),
      isVisible: true,
    },
    {
      id: 'broadbandProduct',
      content: (
        <StepBroadbandProduct
          addressSearchMatch={addressSearchMatch}
          configuredOffer={configuredOffer}
          selectedCampaign={selectedCampaign}
          companyInfo={companyInfo}
          selectedPurposeOfUseOrContact={purposeOfUseOrContact}
          yritystuplaOffer={yritystuplaOffer}
          setPurposeOfUseOrContact={setPurposeOfUseOrContact}
          setYritystuplaPurposeOfUse={setYritystuplaPurposeOfUse}
          setAdditionalAddOns={setAdditionalAddOns}
          enterNextStep={enterNextStep}
        />
      ),
      name: t.M9H0('Subscription and user details'),
      isVisible: true,
    },
    {
      id: 'setupDate',
      content: (
        <StepBroadbandSetupDate
          enterNextStep={enterNextStep}
          isMobileProduct={isMobileProduct}
          setBroadbandInstallationDetails={setBroadbandInstallationDetails}
          fixedBBCheckoutLoaderData={fixedBBCheckoutLoaderData}
        />
      ),
      name: t.SI9A('Installation date'),
      isVisible: !isMobileProduct || installationServiceSelection.enabled,
    },
    {
      id: 'billingInformation',
      content: (
        <StepBillingInformation
          configuredOffer={configuredOffer}
          fixedBroadbandOnlineModel={fixedBroadbandOnlineModel}
          selectedCampaign={selectedCampaign}
          setCampaignAutoSelect={setCampaignAutoSelect}
          additionalAddOns={additionalAddOns}
          addressSearchMatch={addressSearchMatch}
          purposeOfUseOrContact={purposeOfUseOrContact}
          yritystuplaPurposeOfUse={yritystuplaPurposeOfUse}
          broadbandInstallationDetails={broadbandInstallationDetails}
          companyInfo={companyInfo}
          enterNextStep={enterNextStep}
          fixedBBCheckoutLoaderData={fixedBBCheckoutLoaderData}
        />
      ),
      name: t.RPMR('Billing account details'),
      isVisible: true,
    },
  ].filter(step => step.isVisible);

  return (
    <div className="ds-padding-top--10">
      <DeviceCheckoutBillSummaryHeader
        commercialProducts={configuredOffer.selectedCommercialProducts}
        deliveryCharges={0}
        hideZeroDeliveryCharges={true}
        salesType={SalesType.NEW_SALE}
      />
      <div className="ds-grid__row">
        <div className="ds-grid__col--12">
          <h3 className="of-fixed-bb-checkout__heading ds-margin-bottom--6">{selectedOffer.offerName}</h3>
        </div>
      </div>
      <div className="ds-grid__row">
        <FixedBroadbandCheckoutStepManager
          enableOpenPreviousStep={true}
          getNextStepFn={goNextFunc => setGoToNextStep(() => goNextFunc)}
          steps={steps}
        />
      </div>
    </div>
  );
};
