import * as CL from '@design-system/component-library';
import * as React from 'react';
import { AddOnPurpose, CommercialProductType, CompanyInfoResponse, SimType } from '../../generated/api/models.js';
import { AdditionalFieldType, ProductType } from '../../selfservice/common/shopping-cart/shoppingCartEnums.js';
import { ContactCreationType } from '../ContactOrPurposeOfUse/ContactsOrPurposeOfUseUtils.js';
import { ContactOrPurposeOfUse } from '../ContactOrPurposeOfUse/ContactOrPurposeOfUse.js';
import { DEFAULT_MAX_ALLOWED_ITEM_QUANTITY_FOR_ORDER } from '../../common/utils/validationUtils.js';
import {
  DeviceChangeOption,
  DialogType,
  PhoneNumberType,
  SelectedPurposeOfUseOrContact,
  SimCardSelection,
  SubscriptionCategory,
} from '../../common/enums.js';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { Email, PhoneNumber, TextInput } from '../../common/react-hook-form/fields/index.js';
import { EnrollmentProgramConsent } from '../EnrollmentProgramConsent/EnrollmentProgramConsent.js';
import { FeatureFlagWrapper } from '../ShoppingBasket/FeatureFlagWrapper.js';
import { KeyUserDeviceChange } from './KeyUserDeviceChange.js';
import { NumberPrivacy } from '../NumberPrivacy/NumberPrivacy.js';
import { Picture } from '../Picture/Picture.js';
import { ProductDetailsAvailability } from '../ProductDetails/partials/ProductDetailsAvailability.js';
import { RetainExistingPhoneNumber } from '../RetainExistingPhoneNumber/RetainExistingPhoneNumber.js';
import { SelectPhoneNumber } from '../SelectPhoneNumber/SelectPhoneNumber.js';
import {
  SimCardDuringNewSubscriptionOrder,
  SimOption,
} from '../SimCardDuringNewSubscriptionOrder/SimCardDuringNewSubscriptionOrder.js';
import {
  cancelMsg,
  contractPriceCapitalizedMsg,
  elisaDevicesServiceMsg,
  monthsMsg,
  oneTimePaymentMsg,
  ourCompanyMsg,
  removeMsg,
  t,
} from '../../common/i18n/index.js';
import {
  cartItemIndex,
  enrollmentProgramAliasKey,
  enrollmentProgramConsentKey,
  existingPhoneNumberKey,
  getCartItemUniqueKey,
  getEnrollmentProgramSelectionByIndex,
} from './deviceCheckoutCartProductUtil.js';
import {
  commercialProductsAmountChange,
  loadSubscriptions,
  prereserveNumbers,
  showDialog,
  updateCartItem,
  validatePhoneNumber,
} from '../../selfservice/actions/index.js';
import { deepEqual, isDefined } from '../../common/utils/objectUtils.js';
import { findDiscountedPrice } from '../ShoppingBasket/shoppingBasketUtils.js';
import { formatSum, formatSumToString } from '../../common/utils/priceUtils.js';
import {
  getApplicableEnrollmentProgram,
  getOffer,
  isEnrollmentProgramAliasApplicable,
  isEnrollmentProgramCompatible,
} from '../DeviceCheckout/deviceCheckoutUtils.js';
import { getExpiringEppDeviceSubscriptions } from '../SubscriptionDetails/subscriptionDetailsCommon.js';
import {
  getNextAllowedDay,
  isAllowedTransferDay,
} from '../OrderSubscriptionConfiguration/OrderSubscriptionConfigurationFields.js';
import { getPublishedCatalogs, getSelectedCatalog } from '../../common/utils/catalogUtils.js';
import { isEppDevicePriceSubType } from '../../common/utils/checkoutUtils.js';
import { isEppSolutionActive } from '../../common/utils/stateUtils.js';
import { isSquareTradeAddOn } from '../../common/utils/addOnUtils.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { userHasEmptyOrSMEPriceGroup } from '../../common/utils/employeeUtils.js';
import type { ActionsHistory, State } from '../../selfservice/common/store.js';
import type { AliasSupplier } from '../Checkout/steps/CartProductsStep.js';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type {
  Catalog,
  ContactPerson,
  DiscountedPrices,
  EppRedeemTerminateRequestType,
  Subscription,
} from '../../generated/api/models.js';
import type { ConfiguredCommercialProduct } from '../../common/types/commercialProduct.js';
import type { ContactOrPurposeOfUseCallables } from '../ContactOrPurposeOfUse/ContactOrPurposeOfUse.js';
import type { DeviceChangeOrRedeemRequest, DeviceChangeRequest } from '../../common/types/device.js';
import type { OrderConfigurationItem } from '../OrderSubscriptionConfiguration/FormWrapper.js';
import type { Price, ShoppingCartAddOn, ShoppingCartItemForCheckout } from '../../common/types/checkout.js';
import type { PurposeOfUseOrContact } from '../../common/types/subscription.js';
import type { ValidatedPhoneNumber } from '../../common/utils/phoneNumberUtils.js';

import './DeviceCheckoutCartProduct.scss';

export type InitialPurposeOfUseOrContact = PurposeOfUseOrContact & { isCopiedUserInfo?: boolean };

export type DeviceChangeSelectedContactIndex = {
  cartItemUUID: string;
  productItemIndex: number;
};

export type DeviceCheckoutCartProductProps = {
  cartItem: ShoppingCartItemForCheckout;
  getAdditionalParametersFns: (
    prepareSaveValuesFns: Record<string, AliasSupplier> | undefined,
    cartProductUnMounted?: boolean
  ) => void;
  setGetContactOrPurposeOfUseFn: (cartItemIndex?: number, fn?: ContactOrPurposeOfUseCallables['getValues']) => void;
  getCartItemDomRefFns: (
    CartItemDomRefs: () => { [parentKey: string]: { [elementKey: string]: HTMLElement } },
    cartProductUnMounted?: boolean
  ) => void;
  readOnlyMode?: boolean;
  shoppingCartMode?: boolean;
  employeeUserMode?: boolean;
  cartProductIndex: number;
  deviceChangeRequest?: DeviceChangeRequest;
  deviceChangeSelectedContactIndex?: DeviceChangeSelectedContactIndex;
  onUpdateDeviceChangeSelection: (
    value?: DeviceChangeOrRedeemRequest,
    selectedIndex?: DeviceChangeSelectedContactIndex
  ) => void;
  subscriptionDetailForDeviceChange?: {
    contactId?: string | undefined;
    subscriptionId?: string | undefined;
  };
  isDeviceChangeSelectedWithSubscriptionId?: boolean;
  initialPurposeOfUseOrContact: InitialPurposeOfUseOrContact;
  setInitialPurposeOfUseOrContact: (initialPurposeOfUseOrContact: InitialPurposeOfUseOrContact) => void;
  isCartItemBackordered?: boolean;
  enrollmentProgramAlias?: string;
  setEnrollmentProgramAlias?: (alias?: string) => void;
  holidays: Date[];
  validatedPhoneNumbers: ValidatedPhoneNumber[];
  phoneNumbers: string[];
  phoneNumbersLoading: boolean;
  user?: AuthenticatedUserState & ActionsHistory;
};

const TotalPrice = ({ price, quantity }: { price: Price; quantity: number }) => {
  const billingPeriod = price.periodic?.billingPeriod;
  const billingPeriodPrice =
    price.periodic?.price &&
    `${formatSumToString(price.periodic?.price * quantity, true)} €/${billingPeriod} ${t.XXVX(monthsMsg)}`;
  const periodicPrice = price.periodic?.price
    ? t.YO7F('{}/Month', formatSumToString(price.periodic.price * quantity))
    : '';

  return (
    <>
      {price.periodic && (
        <div>
          <div className="of-device-checkout-cart-product__details--price ds-h4">
            {billingPeriod ? billingPeriodPrice : periodicPrice}
          </div>
        </div>
      )}
      {price.onetime && (
        <div className="of-device-checkout-cart-product__details--price ds-h4">
          {price.onetime.price ? formatSumToString(price.onetime.price * quantity) : ''}
        </div>
      )}
    </>
  );
};

const PaymentType = ({ price }: { price: Price }) => (
  <div className="of-device-checkout-cart-product__details--payment-details left-column">
    {price.onetime?.price ? t.ASEI(oneTimePaymentMsg) : ''}
    {price.periodic?.payments
      ? isEppDevicePriceSubType(price)
        ? t.BCWX(`${elisaDevicesServiceMsg}, {} month contract`, price.periodic.payments.toString())
        : t.RA9V('Term of payment {} Month', price.periodic.payments.toString())
      : ''}
  </div>
);

const TotalFixedTermPrice = ({ price, quantity }: { price: Price; quantity: number }) => (
  <div className="of-device-checkout-cart-product__details--disclaimer right-column">
    {price.periodic?.payments && price.periodic.price
      ? t.W1RX('Total {}', formatSumToString(price.periodic.payments * price.periodic.price * quantity))
      : ''}
  </div>
);

const getProductTypeTranslation = (productType: ProductType) => {
  switch (productType) {
    case ProductType.SUBSCRIPTION:
      return t.PPHJ('Subscription');
    case ProductType.DEVICE:
      return t.O3TY('Device');
    case ProductType.PRODUCT:
      return t.PM7G('Product');
  }
};

const AdditionalDetailHeader = ({
  itemId,
  productType,
  quantity,
}: {
  itemId: number;
  productType: ProductType;
  quantity: number;
}) => {
  const product = getProductTypeTranslation(productType);
  const item = quantity === 1 ? ' ' : ` ${itemId + 1} `;

  return (
    <h4 className="of-device-checkout-cart-product__additional-detail-header ds-h3 ds-margin-vertical--0">
      {product}
      {item}
      {t.W547('details')}
    </h4>
  );
};

export const DeviceCheckoutCartProduct = ({ ...props }: DeviceCheckoutCartProductProps) => {
  const {
    cartProductIndex,
    cartItem,
    getAdditionalParametersFns,
    setGetContactOrPurposeOfUseFn,
    getCartItemDomRefFns,
    readOnlyMode,
    shoppingCartMode,
    employeeUserMode,
    deviceChangeRequest,
    deviceChangeSelectedContactIndex,
    onUpdateDeviceChangeSelection,
    subscriptionDetailForDeviceChange,
    isDeviceChangeSelectedWithSubscriptionId: isDeviceChangeOptionSelected,
    initialPurposeOfUseOrContact,
    setInitialPurposeOfUseOrContact,
    isCartItemBackordered,
    enrollmentProgramAlias,
    setEnrollmentProgramAlias,
    holidays,
    validatedPhoneNumbers,
    phoneNumbers,
    phoneNumbersLoading,
    user,
  } = props;
  const dispatch = useDispatch();
  const {
    companyInfo,
    contacts,
    offer,
    deviceSubscriptions,
    pendingSubscriptionActions,
    isLastCartItem,
    onlineModels,
    selectedCatalogCode,
    virtualCatalogItems,
  } = useSelector(
    (state: State) => ({
      companyInfo: state.selfservice?.companyInfo,
      contacts: state.selfservice?.contacts?.items,
      offer: getOffer(cartItem.offerCode, state.selfservice?.onlineModels || undefined),
      deviceSubscriptions: state.selfservice?.subscriptions?.device,
      pendingSubscriptionActions: state.selfservice?.pendingSubscriptionActions?.items || [],
      isLastCartItem: state.deviceCheckout?.cartItems.length === 1,
      onlineModels: state.selfservice?.onlineModels,
      selectedCatalogCode: state.selfservice?.virtualCatalogs?.selectedCatalogCode,
      virtualCatalogItems: state.selfservice?.virtualCatalogs?.items,
    }),
    deepEqual
  );

  const catalogs: Catalog[] = getPublishedCatalogs(virtualCatalogItems) || [];
  const catalog = getSelectedCatalog(catalogs, selectedCatalogCode);
  const companyName = companyInfo?.companyName || `${t.HV47(ourCompanyMsg)}`;
  const isInternalCustomer = companyInfo?.customerType === CompanyInfoResponse.CustomerTypeEnum.INTERNAL_CUSTOMERS;
  const isEppCustomer = isEppSolutionActive(companyInfo);

  const {
    selectedAddOns,
    imageListingUrl,
    price,
    offerCode,
    productName,
    quantity,
    errors,
    commercialProductCodes,
    additionalFields,
    productType,
  } = cartItem;
  const [prepareSaveValuesFns, setPrepareSaveValuesFns] = useState<Record<string, AliasSupplier>>();
  const [domRefs] = useState({});
  const [selectedContacts, setSelectedContacts] = useState<Record<string, string>>({});
  const [isLastItemModalVisible, setIsLastItemModalVisible] = useState(false);
  const [enrollmentProgram, setEnrollmentProgram] = useState<Record<string, boolean>>();
  const [expiringEppDeviceSubscription, setExpiringEppDeviceSubscription] = useState<Subscription[]>();
  const [enrollmentProgramCompatible, setEnrollmentProgramCompatible] = useState(false);
  const [enrollmentProgramAliasCompatible, setEnrollmentProgramAliasApplicable] = useState(false);
  const [enrollmentProgramName, setEnrollmentProgramName] = useState<string | undefined>();

  const additionalParameterSaveValues = (
    newPrepareSaveValuesFns: AliasSupplier,
    quantityIndex: number,
    parameterKey: string
  ) =>
    setPrepareSaveValuesFns((prevPrepareSaveValuesFns: Record<string, AliasSupplier>) => {
      return {
        ...prevPrepareSaveValuesFns,
        [`${parameterKey}[${quantityIndex.toString()}]`]: newPrepareSaveValuesFns,
      };
    });

  useEffect(() => {
    getAdditionalParametersFns(prepareSaveValuesFns);
  }, [prepareSaveValuesFns]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getCartItemDomRefFns(() => domRefs);
  }, [domRefs]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      getAdditionalParametersFns(prepareSaveValuesFns, true);
      getCartItemDomRefFns(() => domRefs, true);
      setGetContactOrPurposeOfUseFn();
    };
  }, []); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialPurposeOfUseOrContact?.contactId) {
      setSelectedContacts(
        Array.from({ length: quantity }).reduce<Record<number, string>>(
          (acc, _cur, productItemIndex) => ({
            ...acc,
            [cartItemIndex(cartItem.id, productItemIndex)]: initialPurposeOfUseOrContact.contactId || '',
          }),
          {}
        )
      );
    }
  }, [initialPurposeOfUseOrContact?.contactId, initialPurposeOfUseOrContact?.purposeOfUse]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  useMemo(() => {
    if (deviceSubscriptions?.items && deviceSubscriptions?.items?.length > 0) {
      if (deviceSubscriptions?.items?.length !== deviceSubscriptions?.total) {
        dispatch(loadSubscriptions({ category: SubscriptionCategory.DEVICE, getAllItems: true }));
      } else {
        setExpiringEppDeviceSubscription(
          getExpiringEppDeviceSubscriptions(deviceSubscriptions?.items, pendingSubscriptionActions)
        );
      }
    } else if (deviceSubscriptions?.items && deviceSubscriptions?.total === 0) {
      setExpiringEppDeviceSubscription([]);
    }
  }, [dispatch, deviceSubscriptions?.items?.length, pendingSubscriptionActions?.length]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (companyInfo?.enrollmentPrograms && cartItem.offerCode && onlineModels) {
      if (!employeeUserMode || (employeeUserMode && catalog && catalog.enrollmentProgramConsent)) {
        setEnrollmentProgramCompatible(
          isEnrollmentProgramCompatible(companyInfo.enrollmentPrograms, cartItem.offerCode, onlineModels)
        );
      }
    }
  }, [companyInfo, cartItem, onlineModels, catalog, employeeUserMode]);

  useEffect(() => {
    if (cartItem.offerCode && onlineModels) {
      if (companyInfo?.enrollmentPrograms) {
        setEnrollmentProgramAliasApplicable(isEnrollmentProgramAliasApplicable(cartItem.offerCode, onlineModels));
        setEnrollmentProgramName(getApplicableEnrollmentProgram(cartItem.offerCode, onlineModels));
      } else if (employeeUserMode) {
        setEnrollmentProgramAliasApplicable(isEnrollmentProgramAliasApplicable(cartItem.offerCode, onlineModels));
      }
    }
  }, [companyInfo, cartItem, onlineModels, employeeUserMode]);

  const setEnrollmentProgramValueByIndex = (currentIndex: number, value: boolean) => {
    setEnrollmentProgram(existingValue => ({
      ...existingValue,
      [`${enrollmentProgramConsentKey}[${Number(`${currentIndex}`)}]`]: value,
    }));
    additionalParameterSaveValues(
      () => ({
        obj: value,
      }),
      currentIndex,
      enrollmentProgramConsentKey
    );
  };

  const changeCartAmount = (item: ShoppingCartItemForCheckout, newQuantity: number) => {
    if (prepareSaveValuesFns && Object.keys(prepareSaveValuesFns).length >= newQuantity) {
      const newPrepareSaveValuesFns = Object.keys(prepareSaveValuesFns).reduce((prev, current) => {
        let value = { ...prev };
        const valueIndex = Number(current.substring(current.indexOf('[') + 1, current.indexOf(']')));
        if (valueIndex < newQuantity) {
          value = { ...value, [current]: prepareSaveValuesFns[current] };
        }
        return value;
      }, {});
      if (newQuantity > 0) {
        // If quantity is updated to 0, then this component is going to be unmounted anyway
        setPrepareSaveValuesFns(newPrepareSaveValuesFns);
      }
    }

    // Clear getContactOrPurposeOfUseFns and selectedContacts for the removed quantity
    if (newQuantity < item.quantity) {
      for (let i = newQuantity; i < item.quantity; i++) {
        setGetContactOrPurposeOfUseFn(i);
        const removedIndex = cartItemIndex(cartItem.id, i).toString();
        const remainingSelectedContacts = Object.fromEntries(
          Object.entries(selectedContacts).filter(([key]) => key !== removedIndex)
        );
        setSelectedContacts(remainingSelectedContacts);
      }
    }
    // Set selectedContacts based on initialPurposeOfUseOrContact for the added quantity
    if (newQuantity > item.quantity && initialPurposeOfUseOrContact?.contactId) {
      for (let i = item.quantity; i < newQuantity; i++) {
        setSelectedContacts(prevState => ({
          ...prevState,
          [cartItemIndex(cartItem.id, i)]: initialPurposeOfUseOrContact?.contactId || '',
        }));
      }
    }

    // remove the device change selection, if it falls between newQuantity and old quantity
    if (deviceChangeSelectedContactIndex?.cartItemUUID === cartItem.id) {
      if (newQuantity < item.quantity && onUpdateDeviceChangeSelection) {
        if (deviceChangeSelectedContactIndex.productItemIndex > newQuantity - 1) {
          onUpdateDeviceChangeSelection(undefined, undefined);
        }
      }
    }
    dispatch(updateCartItem(item, newQuantity));

    // set default enrollmentProgram to the new item added to cart.
    if (newQuantity > item.quantity && enrollmentProgramCompatible) {
      setEnrollmentProgramValueByIndex(newQuantity - 1, true);
    }
  };

  const removeLastCartItem = () => {
    setIsLastItemModalVisible(false);
    changeCartAmount(cartItem, 0);
  };

  const setRefCallback = (parent: string, key: string, ref: HTMLElement | null) => {
    // TODO: mutating the state variable here is not a good idea
    if (ref) {
      // @ts-ignore
      if (!domRefs[parent]) {
        // @ts-ignore
        domRefs[parent] = {};
      }
      // @ts-ignore
      domRefs[parent][key] = ref;
    }
    // @ts-ignore
    if (domRefs[parent] && !ref) {
      // @ts-ignore
      delete domRefs[parent];
    }
  };

  const getConfiguredCommercialProduct = (): ConfiguredCommercialProduct => {
    return {
      category: cartItem.category,
      commercialProduct: {
        active: true,
        addOnAssociations: cartItem.selectedAddOns,
        commercialProductCode: cartItem.commercialProductCodes[0],
        commercialProductName: cartItem.productName,
        monthlyRecurringCharge: cartItem.price.periodic && cartItem.price.periodic.price,
        oneTimeCharge: cartItem.price.onetime && cartItem.price.onetime.price,
        payments: cartItem.price.periodic && cartItem.price.periodic.payments,
        productType: CommercialProductType.SALES_PRODUCT,
      },
      commercialProductCodes: cartItem.commercialProductCodes,
      purposeOfUseOrContact: cartItem.purposeOfUseOrContacts[0],
    };
  };

  const isFirstCartItem = (productItemIndex: number) => cartProductIndex === 0 && productItemIndex === 0;

  const getContacts = (productItemIndex: number) => {
    return initialPurposeOfUseOrContact.contactId === ContactCreationType.COPIED_CONTACT &&
      !isFirstCartItem(productItemIndex)
      ? (contacts || []).concat({
          contactId: ContactCreationType.COPIED_CONTACT,
          contactType: 'PERSON',
          person: { ...(initialPurposeOfUseOrContact as ContactPerson), roles: [] },
        })
      : contacts;
  };

  const hasAdditionalFields = additionalFields.length > 0;
  const hasAdditionalFieldOfType = (type: string) => additionalFields.some(field => field.type === type);
  const hasNumberOrSimCardSelection =
    hasAdditionalFieldOfType(AdditionalFieldType.NUMBER_PRIVACY_AND_SIM_CARD_SELECTION_FOR_NEW_PHONE_NUMBER) ||
    hasAdditionalFieldOfType(AdditionalFieldType.NUMBER_PRIVACY_AND_SIM_CARD_SELECTION_FOR_EXISTING_PHONE_NUMBER) ||
    hasAdditionalFieldOfType(AdditionalFieldType.SIM_CARD_SELECTION) ||
    hasAdditionalFieldOfType(AdditionalFieldType.NUMBER_AND_NUMBER_PRIVACY_AND_SIM_CARD_SELECTION);
  const hasNumberPrivacyAndSimCardSelectionForNewPhoneNumber = additionalFields.some(
    field => field.type === AdditionalFieldType.NUMBER_PRIVACY_AND_SIM_CARD_SELECTION_FOR_NEW_PHONE_NUMBER
  );
  const hasNumberPrivacyAndSimCardSelectionForExistingPhoneNumber = additionalFields.some(
    field => field.type === AdditionalFieldType.NUMBER_PRIVACY_AND_SIM_CARD_SELECTION_FOR_EXISTING_PHONE_NUMBER
  );
  const hasNumberAndNumberPrivacyAndSimCardSelection = additionalFields.some(
    field => field.type === AdditionalFieldType.NUMBER_AND_NUMBER_PRIVACY_AND_SIM_CARD_SELECTION
  );

  const handleAmountChange = (item: ShoppingCartItemForCheckout, newQuantity: number) => {
    // If product has option to select new number, make sure we have enough numbers prereserved
    if (hasNumberAndNumberPrivacyAndSimCardSelection) {
      dispatch(commercialProductsAmountChange(newQuantity));
    }
    if (!(isLastCartItem && newQuantity === 0)) {
      changeCartAmount(item, newQuantity);
    } else {
      setIsLastItemModalVisible(true);
    }
  };

  useEffect(() => {
    if (!readOnlyMode && hasNumberAndNumberPrivacyAndSimCardSelection) {
      // If product has option to select new number, make sure we have enough numbers prereserved
      if (phoneNumbers.length === 0) {
        dispatch(prereserveNumbers(quantity, true));
      }
    }
  }, [quantity, dispatch, phoneNumbers, hasNumberAndNumberPrivacyAndSimCardSelection, readOnlyMode]);

  const currentConfiguration: Record<string, OrderConfigurationItem> = useWatch({ name: `configuration` });
  const disabledPhoneNumbers: string[] = Object.keys(currentConfiguration || {})
    .map(key => currentConfiguration[key])
    .filter(configuration => configuration?.selectPhoneNumber?.type === PhoneNumberType.NEW)
    .map(configuration => configuration.selectPhoneNumber?.newPhoneNumber)
    .filter(isDefined);

  const simCardNumbers: string[] = Object.keys(currentConfiguration || {})
    .map(key => currentConfiguration[key])
    .filter(configuration => configuration?.simCardConfiguration?.simCardNumber !== undefined)
    .map(configuration => configuration?.simCardConfiguration?.simCardNumber)
    .filter(isDefined);

  const getAdditionalFieldsComponent = (productItemIndex: number) => (
    <div
      key={productItemIndex}
      id={`sales-product-additional-parameters-${productItemIndex + 1}`}
      className={`of-sales-product-additional-parameters of-sales-product-additional-parameters-${
        productItemIndex + 1
      }`}
    >
      {hasNumberAndNumberPrivacyAndSimCardSelection && (
        <SelectPhoneNumber
          holidays={holidays}
          companyName={companyName}
          selectedCommercialProduct={getConfiguredCommercialProduct()}
          phoneNumbers={phoneNumbers}
          disabledPhoneNumbers={disabledPhoneNumbers}
          phoneNumbersLoading={phoneNumbersLoading}
          cartItemInstanceId={cartItemIndex(cartItem.id, productItemIndex)}
          isAllowedTransferDay={isAllowedTransferDay}
          getNextAllowedDay={getNextAllowedDay}
          selectedPhoneNumber={{
            type: PhoneNumberType.EXISTING,
            transferDate: getNextAllowedDay(holidays),
          }}
          onSelectUseExistingPhoneNumber={existingPhoneNumber =>
            dispatch(validatePhoneNumber(existingPhoneNumber, true))
          }
          validatedPhoneNumbers={validatedPhoneNumbers}
        />
      )}
      {hasNumberPrivacyAndSimCardSelectionForExistingPhoneNumber && (
        <>
          <h5>{`${t.YBX5('Current phone number')}`}</h5>
          <RetainExistingPhoneNumber
            errors={errors}
            cartItemInstanceId={cartItemIndex(cartItem.id, productItemIndex)}
            indexNumber={productItemIndex + 1}
            existingNumberKey={existingPhoneNumberKey}
            companyName={companyName}
            existingNumberPrepareSaveValuesFn={newPrepareSaveValues => {
              additionalParameterSaveValues(newPrepareSaveValues, productItemIndex, existingPhoneNumberKey);
            }}
            existingNumberSetRefCallback={(key, ref) =>
              setRefCallback(`${existingPhoneNumberKey}[${cartItemIndex(cartItem.id, productItemIndex)}]`, key, ref)
            }
          />
        </>
      )}

      {(hasNumberPrivacyAndSimCardSelectionForNewPhoneNumber ||
        hasNumberPrivacyAndSimCardSelectionForExistingPhoneNumber ||
        hasNumberAndNumberPrivacyAndSimCardSelection) && (
        <>
          <h5>{`${t.L8XT('Number publicity')}`}</h5>
          <NumberPrivacy
            cartItemInstanceId={cartItemIndex(cartItem.id, productItemIndex)}
            hideHeader={true}
            selectedCommercialProduct={getConfiguredCommercialProduct()}
          />
        </>
      )}

      {hasNumberOrSimCardSelection && (
        <>
          <h5>{`${t.PIZC('SIM-card')}`}</h5>
          <SimCardDuringNewSubscriptionOrder
            {...{
              cartItemInstanceId: cartItemIndex(cartItem.id, productItemIndex),
              indexNumber: productItemIndex + 1,
              hideHeader: true,
              onClickSimCardNumberHelp: () => {
                dispatch(
                  showDialog({
                    type: DialogType.ORDER_SIM_CARD_NUMBER_HELP,
                  })
                );
              },
              simCardConfiguration: hasAdditionalFieldOfType(AdditionalFieldType.SIM_CARD_SELECTION)
                ? {
                    simSelection: SimCardSelection.ORDER_NEW,
                    simType: SimType.PHYSICAL_MINI_MICRO,
                  }
                : {
                    simSelection: SimCardSelection.ORDER_NEW,
                    simType: SimType.PHYSICAL,
                  },
              simOptions: hasAdditionalFieldOfType(AdditionalFieldType.SIM_CARD_SELECTION)
                ? [
                    SimOption.ACTIVATE_EXISTING_PHYSICAL,
                    SimOption.ORDER_NEW_PHYSICAL_MINI_MICRO,
                    SimOption.ORDER_NEW_PHYSICAL_NANO,
                  ]
                : [SimOption.ACTIVATE_EXISTING_PHYSICAL, SimOption.ORDER_NEW_ESIM, SimOption.ORDER_NEW_PHYSICAL],
              simCardNumbers,
            }}
          />
        </>
      )}

      {additionalFields.map(field => {
        if ('name' in field) {
          switch (field.type) {
            case AdditionalFieldType.EMAIL:
              return (
                <div className="of-device-checkout-cart-product__additional-field">
                  <Email
                    key={`${cartItemIndex(cartItem.id, productItemIndex)}-${field.name}`}
                    name={`configuration.${cartItemIndex(cartItem.id, productItemIndex)}.additionalFields.${
                      field.name
                    }`}
                    required={field.required}
                    label={t[field.label.key](field.label.translation)}
                    placeholder=""
                  />
                </div>
              );
            case AdditionalFieldType.PHONE_NUMBER:
              return (
                <div className="of-device-checkout-cart-product__additional-field">
                  <PhoneNumber
                    key={`${cartItemIndex(cartItem.id, productItemIndex)}-${field.name}`}
                    name={`configuration.${cartItemIndex(cartItem.id, productItemIndex)}.additionalFields.${
                      field.name
                    }`}
                    required={field.required}
                    label={t[field.label.key](field.label.translation)}
                    placeholder=""
                  />
                </div>
              );
            default:
              return (
                <div className="of-device-checkout-cart-product__additional-field">
                  <TextInput
                    key={`${cartItemIndex(cartItem.id, productItemIndex)}-${field.name}`}
                    name={`configuration.${cartItemIndex(cartItem.id, productItemIndex)}.additionalFields.${
                      field.name
                    }`}
                    required={field.required}
                    label={t[field.label.key](field.label.translation)}
                    placeholder=""
                  />
                </div>
              );
          }
        } else {
          return null;
        }
      })}
    </div>
  );

  const isEppOrInternalCustomer = isEppCustomer || isInternalCustomer || false;
  const isEppDevice = isEppCustomer && isEppDevicePriceSubType(price);

  const handleCopiedContactsOnOriginContactChange = (productItemIndex: number, selectedContactId?: string) => {
    if (isFirstCartItem(productItemIndex)) {
      if (initialPurposeOfUseOrContact.contactId === ContactCreationType.COPIED_CONTACT) {
        // User has copied new contact to other products but now trying to select some existing contact or POU
        // Fix other products as well as new contact details are available anymore
        setInitialPurposeOfUseOrContact(
          selectedContactId
            ? { contactId: selectedContactId, isCopiedUserInfo: true }
            : { selected: SelectedPurposeOfUseOrContact.PURPOSEOFUSE }
        );
      } else if (initialPurposeOfUseOrContact.isCopiedUserInfo) {
        // User has copied existing contact or POU to other products but now trying to select something else
        // Remove the copied flag from other products as user info is not copied anymore
        setInitialPurposeOfUseOrContact({
          ...initialPurposeOfUseOrContact,
          isCopiedUserInfo: false,
        });
      }
    }
  };

  const getPurposeOfUseOrContactComponent = (productItemIndex: number) => (
    <div key={productItemIndex} className="of-device-checkout-cart-product--purpose-of-use">
      <ContactOrPurposeOfUse
        purposeOfUseOrContact={
          isFirstCartItem(productItemIndex) &&
          initialPurposeOfUseOrContact.contactId === ContactCreationType.COPIED_CONTACT
            ? { ...initialPurposeOfUseOrContact, contactId: ContactCreationType.CREATE_NEW_CONTACT }
            : initialPurposeOfUseOrContact
        }
        saving={false}
        id={`contact-or-purpose-of-use-${cartItemIndex(cartItem.id, productItemIndex)}`}
        ref={callables => {
          if (callables) {
            setGetContactOrPurposeOfUseFn(productItemIndex, callables.getValues);
          }
        }}
        contacts={getContacts(productItemIndex)}
        isEmailAndPhoneRequired={true}
        isCostCenterRequired={isInternalCustomer}
        enableCostCenterAndReference={true}
        onChangeContact={(selectedContactId?: string) => {
          setSelectedContacts(prevSelectedContacts => ({
            ...prevSelectedContacts,
            [cartItemIndex(cartItem.id, productItemIndex)]: selectedContactId || '',
          }));
          if (
            deviceChangeSelectedContactIndex?.cartItemUUID === cartItem.id &&
            deviceChangeSelectedContactIndex?.productItemIndex === productItemIndex
          ) {
            onUpdateDeviceChangeSelection(undefined, undefined);
          }
          handleCopiedContactsOnOriginContactChange(productItemIndex, selectedContactId);
        }}
        onCopyUserInfo={
          isFirstCartItem(productItemIndex) && !(isLastCartItem && quantity === 1)
            ? purposeOfUseOrContact => {
                setInitialPurposeOfUseOrContact({
                  ...purposeOfUseOrContact,
                  contactId:
                    purposeOfUseOrContact.contactId === ContactCreationType.CREATE_NEW_CONTACT
                      ? ContactCreationType.COPIED_CONTACT
                      : purposeOfUseOrContact.contactId,
                  isCopiedUserInfo: true,
                });
              }
            : undefined
        }
        isCopiedUserInfo={!isFirstCartItem(productItemIndex) && initialPurposeOfUseOrContact.isCopiedUserInfo}
        required={isInternalCustomer || isEppDevice}
      />
      {isEppDevice && selectedContacts[cartItemIndex(cartItem.id, productItemIndex)] && (
        <KeyUserDeviceChange
          onSelectSubscriptionForDeviceChange={(
            contactId,
            subscriptionId?: string,
            subscriptionBillingAccountId?: string,
            redeemType?: EppRedeemTerminateRequestType
          ) => {
            let deviceChangeRequestData;
            let selectedIndex;
            if (subscriptionId) {
              deviceChangeRequestData = {
                deviceChangeOption: redeemType ? DeviceChangeOption.REDEEM : DeviceChangeOption.RETURN,
                replacementDeviceCommercialProductCode: commercialProductCodes[0],
                replacedSubscriptionId: subscriptionId,
                replacedSubscriptionContactId: contactId,
                replacedSubscriptionBillingAccountId: subscriptionBillingAccountId,
                redeemRequestType: redeemType,
              };
              selectedIndex = { cartItemUUID: cartItem.id, productItemIndex: productItemIndex };
            }
            onUpdateDeviceChangeSelection(deviceChangeRequestData, selectedIndex);
          }}
          selectedPouIndex={cartItemIndex(cartItem.id, productItemIndex)}
          isDeviceChangeAlreadySelected={
            deviceChangeSelectedContactIndex !== undefined &&
            (deviceChangeSelectedContactIndex?.cartItemUUID !== cartItem.id ||
              deviceChangeSelectedContactIndex?.productItemIndex !== productItemIndex)
          }
          deviceChangeRequest={deviceChangeRequest}
          expiringEppDeviceSubscription={expiringEppDeviceSubscription}
          contactId={selectedContacts[cartItemIndex(cartItem.id, productItemIndex)]}
          subscriptionIdForDeviceChange={subscriptionDetailForDeviceChange?.subscriptionId}
          isDeviceChangeSelectedWithSubscriptionId={isDeviceChangeOptionSelected}
          isFirstCartItem={isFirstCartItem(productItemIndex)}
          companyName={companyName}
        />
      )}
    </div>
  );

  React.useEffect(() => {
    // Once onload of component set the defaults for enrollment program
    if (enrollmentProgramCompatible) {
      Array.from({ length: quantity }, (_, currentIndex) => {
        setEnrollmentProgramValueByIndex(currentIndex, true);
      });
    }
  }, [enrollmentProgramCompatible]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (enrollmentProgramAliasCompatible && catalog?.enrollmentProgramAlias) {
      Array.from({ length: quantity }, (_, currentIndex) => {
        setPrepareSaveValuesFns((prevPrepareSaveValuesFns: Record<string, AliasSupplier>) => {
          return {
            ...prevPrepareSaveValuesFns,
            [`${enrollmentProgramAliasKey}[${currentIndex.toString()}]`]: () => ({
              obj: catalog.enrollmentProgramAlias ?? '',
            }),
          };
        });
      });
    }
  }, [catalog, enrollmentProgramAliasCompatible, quantity, setPrepareSaveValuesFns]);

  useEffect(() => {
    if (enrollmentProgramAliasCompatible && !employeeUserMode) {
      Array.from({ length: quantity }, (_, currentIndex) => {
        setPrepareSaveValuesFns((prevPrepareSaveValuesFns: Record<string, AliasSupplier>) => {
          return {
            ...prevPrepareSaveValuesFns,
            [`${enrollmentProgramAliasKey}[${currentIndex.toString()}]`]: () => ({
              obj: enrollmentProgramAlias ?? '',
            }),
            [`${enrollmentProgramConsentKey}[${currentIndex.toString()}]`]: () => ({
              obj: enrollmentProgramAlias !== undefined,
            }),
          };
        });
      });
    }
  }, [enrollmentProgramAlias, enrollmentProgramAliasCompatible, quantity, setPrepareSaveValuesFns, employeeUserMode]);

  const getEnrollmentProgramConsentComponent = (currentIndex: number) => {
    const selectedIndex = cartItemIndex(cartItem.id, currentIndex);
    return (
      <div
        key={`enrollment-program-container-${currentIndex}`}
        className="of-device-checkout-cart-product--enrollment-program-consent"
      >
        <EnrollmentProgramConsent
          enrollKey={`${enrollmentProgramConsentKey}-${selectedIndex}`}
          onChange={(ev: React.FormEvent<HTMLInputElement>) => {
            setEnrollmentProgramValueByIndex(currentIndex, ev.currentTarget.checked);
            if (enrollmentProgramAliasCompatible) {
              ev.currentTarget.checked
                ? setEnrollmentProgramAlias?.(enrollmentProgramAlias || '')
                : setEnrollmentProgramAlias?.(undefined);
            }
          }}
          isChecked={
            enrollmentProgramAliasCompatible
              ? enrollmentProgramAlias !== undefined
              : getEnrollmentProgramSelectionByIndex(enrollmentProgramConsentKey, currentIndex, enrollmentProgram)
          }
          showAlias={enrollmentProgramAliasCompatible}
          enrollAliasKey={`${enrollmentProgramAliasKey}-${selectedIndex}`}
          onValueChange={ev => setEnrollmentProgramAlias?.(ev?.currentTarget?.value)}
          enrollmentProgramAlias={enrollmentProgramAlias}
          applicableEnrollmentProgram={enrollmentProgramName}
        />
      </div>
    );
  };

  const addOnsToShow = selectedAddOns.filter(addOn => addOn.display);

  const getAdditionalDetailsComponent = (itemId: number) => {
    const header = (
      <AdditionalDetailHeader itemId={itemId} productType={productType ?? ProductType.DEVICE} quantity={quantity} />
    );

    const enrollmentProgramItem =
      isEppOrInternalCustomer &&
      enrollmentProgramCompatible &&
      !employeeUserMode &&
      getEnrollmentProgramConsentComponent(itemId);

    // Contact/POU is mandatory for INTERNAL_CUSTOMERS e.g elisa users, while ordering voice or mbb from public page
    // external customer users can skip Contact/POU if ordering from public page voice or mbb subscriptions
    // But Contact/POU is mandatory if subscription is being ordered from NOE
    if (isEppDevice || isInternalCustomer) {
      return (
        <div
          key={`purposeOfUseOrContact_${offerCode}_${itemId}}`}
          className="of-device-checkout-cart-product__additional-detail"
        >
          {header}
          {getPurposeOfUseOrContactComponent(itemId)}
          {enrollmentProgramItem}
          {hasAdditionalFields && getAdditionalFieldsComponent(itemId)}
        </div>
      );
    }

    return (
      <>
        <div className="ds-margin-top--6 ds-margin-bottom--3">{header}</div>
        <CL.Accordion key={`purposeOfUseOrContact_${offerCode}_${itemId}}`}>
          <CL.AccordionItem
            defaultOpen={Boolean(initialPurposeOfUseOrContact?.contactId || initialPurposeOfUseOrContact?.purposeOfUse)}
            id={`${getCartItemUniqueKey(cartItem)}_${itemId}`}
            heading={`${t.EMYP('Add user or purpose of use')}`}
          >
            <div className="of-device-checkout-cart-product__additional-detail">
              {getPurposeOfUseOrContactComponent(itemId)}
            </div>
          </CL.AccordionItem>
        </CL.Accordion>
        {enrollmentProgramItem}
        {hasAdditionalFields && (
          <div key={`${offerCode}_${itemId}}`} className="of-device-checkout-cart-product__additional-detail">
            {getAdditionalFieldsComponent(itemId)}
          </div>
        )}
      </>
    );
  };

  const shouldShowSquareTradeError = (addOn: ShoppingCartAddOn) => {
    return !userHasEmptyOrSMEPriceGroup(user?.segmentPricingGroup) && isSquareTradeAddOn(addOn);
  };

  const shouldShowRemoveAddOnButton = (addOn: ShoppingCartAddOn) =>
    !readOnlyMode && (addOn.addOnPurpose !== AddOnPurpose.BY_DEFAULT || shouldShowSquareTradeError(addOn));

  const discountedPriceBadge = (cartItemPriceGuid: string, discountedPrices: DiscountedPrices[]) => {
    const isDiscountedPrice = !!findDiscountedPrice(discountedPrices, cartItemPriceGuid);
    return isDiscountedPrice ? <CL.Badge color="orange-light" text={t.JPKP(contractPriceCapitalizedMsg)} /> : null;
  };

  // TODO refactor this to use DeviceCheckoutListingItem
  return (
    <div
      id={getCartItemUniqueKey(cartItem)}
      className={`of-device-checkout-cart-product of-device-checkout-cart-product-${cartItem.id} ${
        readOnlyMode ? 'readonly' : 'writable'
      }`}
    >
      <CL.Grid>
        <CL.GridRow className="ds-no-gutter">
          <CL.GridCol colWidthXS={1} colWidthM={1} className="of-checkout__images">
            <Picture
              src={imageListingUrl}
              alt={productName}
              offerWidthAlternatives={[94]}
              renderedImageSize={{ onPhone: '100vw' }}
            />
          </CL.GridCol>
          <CL.GridCol colWidthXS={3} colWidthM={5} className="of-device-checkout-cart-product__details">
            <CL.Grid className="grid-push-1">
              <CL.GridRow>
                <CL.GridCol colWidthM={3}>
                  <h3 className={`${shoppingCartMode ? 'ds-h3' : 'ds-h4'} ds-margin-vertical--0 ds-padding-bottom--2`}>
                    {productName}
                  </h3>
                  {offer && <ProductDetailsAvailability offer={offer} />}
                </CL.GridCol>
                <CL.GridCol colWidthM={3}>
                  <CL.Grid>
                    <CL.GridRow>
                      <CL.GridCol colWidthM={6}>
                        {readOnlyMode ? (
                          <div className="of-device-checkout-cart-product__details--quantity ds-text--l">
                            {t.UDN6('{} pcs', quantity.toString())}
                          </div>
                        ) : (
                          <CL.Quantity
                            currentValue={quantity}
                            onChange={newQuantity => handleAmountChange(cartItem, newQuantity)}
                            minValue={1}
                            maxValue={DEFAULT_MAX_ALLOWED_ITEM_QUANTITY_FOR_ORDER}
                            onRemove={() => handleAmountChange(cartItem, 0)}
                            removeLessThanMin
                          />
                        )}
                      </CL.GridCol>
                    </CL.GridRow>
                    <CL.GridRow>
                      <CL.GridCol colWidthM={6} className="ds-margin-top--4">
                        <TotalPrice price={price} quantity={quantity} />
                      </CL.GridCol>
                    </CL.GridRow>
                    <FeatureFlagWrapper>
                      <CL.GridRow>
                        <CL.GridCol colWidthM={6} className="ds-margin-bottom--4 ds-text-align--right">
                          {discountedPriceBadge(cartItem.price.guid, companyInfo?.discountedPrices || [])}
                        </CL.GridCol>
                      </CL.GridRow>
                    </FeatureFlagWrapper>
                  </CL.Grid>
                </CL.GridCol>
              </CL.GridRow>
              <CL.GridRow>
                <CL.GridCol colWidthM={6} className={isCartItemBackordered ? '' : 'bottom-row'}>
                  <PaymentType price={price} />
                  {!readOnlyMode && <TotalFixedTermPrice price={price} quantity={quantity} />}
                </CL.GridCol>
              </CL.GridRow>
              {readOnlyMode && isCartItemBackordered && (
                <CL.GridRow>
                  <CL.GridCol colWidthM={6} className="bottom-row">
                    <p>{t.JVNS('Backordered')}</p>
                  </CL.GridCol>
                </CL.GridRow>
              )}
            </CL.Grid>
          </CL.GridCol>
        </CL.GridRow>
        {addOnsToShow.length > 0 && (
          <CL.GridRow className="ds-no-gutter ds-justify-content--flex-end">
            <CL.GridCol colWidthXS={3} colWidthM={5}>
              <CL.Grid className="of-device-checkout-cart-product__addOn-details grid-push-1">
                {addOnsToShow.map((addOn, i) => (
                  <CL.GridRow key={`addon${i}`}>
                    {shouldShowSquareTradeError(addOn) && (
                      <div className="ds-color--red-600 ds-text--s">
                        {t.Z85X('SquareTrade is not available and it needs to be removed from the basket.')}
                      </div>
                    )}
                    <CL.GridCol colWidthXS={4} colWidthM={3}>
                      <h4 className="ds-margin-vertical--0">{addOn.addOnProductName}</h4>
                    </CL.GridCol>
                    <CL.GridCol colWidthXS={4} colWidthM={3}>
                      <CL.Grid className="of-device-checkout-cart-product__addOn-details--price">
                        <CL.GridRow>
                          <CL.GridCol colWidthM={6}>
                            <div className="ds-h4">
                              {addOn.addOnOneTimeCharge && <div>{formatSum(addOn.addOnOneTimeCharge * quantity)}</div>}
                              {addOn.addOnMonthlyRecurringCharge && (
                                <div>
                                  {t.YO7F('{}/Month', formatSum(addOn.addOnMonthlyRecurringCharge * quantity) || '')}
                                </div>
                              )}
                            </div>
                          </CL.GridCol>
                        </CL.GridRow>
                        <CL.GridRow>
                          <CL.GridCol colWidthM={6}>
                            {shouldShowRemoveAddOnButton(addOn) && (
                              <div className="of-device-checkout-cart-product__addOn-details--remove">
                                <CL.Link
                                  className="of-link"
                                  onClick={() =>
                                    dispatch(updateCartItem(cartItem, cartItem.quantity, [addOn.addOnCode]))
                                  }
                                >
                                  {t.R3VE(removeMsg)}
                                </CL.Link>
                              </div>
                            )}
                          </CL.GridCol>
                        </CL.GridRow>
                      </CL.Grid>
                    </CL.GridCol>
                  </CL.GridRow>
                ))}
              </CL.Grid>
            </CL.GridCol>
          </CL.GridRow>
        )}
        {!(readOnlyMode || employeeUserMode) && (
          <CL.GridRow className="ds-no-gutter">
            <CL.GridCol colWidthXS={4} colWidthM={6} className="of-device-checkout-cart-product__additional-details">
              {Array.from({ length: quantity }, (_, itemId) => getAdditionalDetailsComponent(itemId))}
            </CL.GridCol>
          </CL.GridRow>
        )}
      </CL.Grid>
      {isLastItemModalVisible && (
        <DialogWrapper
          buttons={[
            {
              color: 'link',
              onClick: () => setIsLastItemModalVisible(false),
              text: t.B2V1(cancelMsg),
            },
            {
              onClick: () => removeLastCartItem(),
              text: t.FV8F('Remove item and return to shop'),
            },
          ]}
          closeable
          header={t.F97B('Are you sure you want to remove the last item from your cart?')}
          onCloseDialog={() => setIsLastItemModalVisible(false)}
        />
      )}
    </div>
  );
};
