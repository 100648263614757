import { BreadCrumbs } from '../BreadCrumbs/index.js';
import { CatalogReplaceOperation, VirtualCatalogReplaceProducts } from './VirtualCatalogReplaceProducts.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { EditCatalogButtonGroup } from '../VirtualCatalogDetails/VirtualCatalogPartials.js';
import { FormProvider, useForm } from 'react-hook-form';
import { TypeKeys, loadVirtualCatalogs } from '../../selfservice/actions/index.js';
import { VirtualCatalogReplaceError } from './VirtualCatalogReplaceError.js';
import { deviceListsMsg, listOfDevicesMsg, omaElisaForCompaniesMsg, t } from '../../common/i18n/index.js';
import { generatePath, useNavigate } from 'react-router-dom';
import { getTreeItems } from './VirtualCatalogReplaceUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { replaceCatalogs } from '../../common/fetch.js';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import type { Catalog } from '../../generated/api/catalog.js';
import type { CompanyVirtualCatalogs } from './VirtualCatalogReplaceUtils.js';
import type { FormValues } from './VirtualCatalogReplaceProducts.js';
import type { VirtualCatalog } from '../../generated/api/virtualCatalog.js';
import type { VirtualCatalogReplaceResponse } from '../../generated/api/virtualCatalogReplaceResponse.js';

export interface VirtualCatalogReplaceProps {
  virtualCatalog: VirtualCatalog;
  catalog: Catalog;
  companyName: string;
  companyVirtualCatalogs: CompanyVirtualCatalogs[];
}

export const VirtualCatalogReplace = ({
  catalog,
  virtualCatalog,
  companyName,
  companyVirtualCatalogs,
}: VirtualCatalogReplaceProps) => {
  const [replaceErrorResponse, setReplaceErrorResponse] = useState<VirtualCatalogReplaceResponse>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sendBannerNotification = (type: TypeKeys) => dispatch({ type });

  const methods = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      selectedCatalogs: [],
      selectedSavingOption: CatalogReplaceOperation.PUBLISH,
    },
  });
  const runtimeValues = methods.watch();

  const catalogTreeItems = getTreeItems(companyVirtualCatalogs, catalog.productType);

  const onReplaceCatalogs = async (values: FormValues) => {
    return new Promise(() => {
      if (catalog?.catalogCode) {
        replaceCatalogs({
          sourceCatalogCode: catalog.catalogCode,
          targetCatalogCodes: values.selectedCatalogs,
          publish: values.selectedSavingOption === CatalogReplaceOperation.PUBLISH,
        })
          .then(res => {
            if (res.failed.length > 0) {
              sendBannerNotification(TypeKeys.REPLACE_VIRTUAL_CATALOGS_FAILED);
              setReplaceErrorResponse(res);
            } else {
              navigate(paths.COMPANY_INFO_CATALOGS, { replace: true });
              sendBannerNotification(TypeKeys.REPLACE_VIRTUAL_CATALOGS_FULFILLED);
              // reload catalogs to make changes visible
              dispatch(loadVirtualCatalogs(true));
            }
          })
          .catch(() => {
            navigate(paths.COMPANY_INFO_CATALOGS, { replace: true });
            sendBannerNotification(TypeKeys.REPLACE_VIRTUAL_CATALOGS_FAILED);
          });
      }
    });
  };

  const breadCrumbs = (
    <BreadCrumbs
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.COBB(deviceListsMsg), path: paths.COMPANY_INFO_CATALOGS },
        { name: catalog.name },
      ]}
    />
  );

  const content = replaceErrorResponse ? (
    <VirtualCatalogReplaceError
      replaceErrorResponse={replaceErrorResponse}
      companyVirtualCatalogs={companyVirtualCatalogs}
    />
  ) : (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onReplaceCatalogs)} noValidate>
          <VirtualCatalogReplaceProducts
            treeItems={catalogTreeItems}
            catalog={catalog}
            virtualCatalog={virtualCatalog}
            isSaving={methods.formState.isSubmitting}
          />
          <EditCatalogButtonGroup
            onCancel={() => {
              navigate(generatePath(paths.COMPANY_INFO_CATALOG, { catalogCode: virtualCatalog.virtualCatalogCode }));
            }}
            saveDisabled={runtimeValues.selectedCatalogs.length === 0 || catalog.catalogCode === null}
            isSaving={methods.formState.isSubmitting}
          />
        </form>
      </FormProvider>
    </div>
  );

  return (
    <DetailsWrapper
      detailsTop={breadCrumbs}
      id={`catalog-details-${catalog.catalogCode}`}
      classes={[`of-catalog-details`]}
      heading={catalog.name}
      headingBottom={companyName}
      headingTop={t.Z0HF(listOfDevicesMsg)}
      heroPicto="billing-account"
    >
      {content}
    </DetailsWrapper>
  );
};
