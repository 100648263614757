import { AttachRing } from '../../../../../components/AttachRing/AttachRing.js';
import { SubscriptionCategory } from '../../../../../common/enums.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { generatePath, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { getSubscriptionStatus } from '../../../../../common/utils/subscriptionUtils.js';
import { onSubmitOrder } from '../../../../../common/utils/dispatcherUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { MobileSubAttachRingLoaderData } from '../../../../../common/loaders';
import type { State } from '../../../../../selfservice/common/store.js';

export const MobileSubAttachRingPath = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const numberRanges = useSelector((state: State) => state.resources?.numberRanges, deepEqual);
  const { addOnRules, companyInfo, onlineModel, pendingSubscriptionActions, subscription } =
    useLoaderData() as MobileSubAttachRingLoaderData;
  const subscriptionStatus = getSubscriptionStatus(subscription, pendingSubscriptionActions);
  const { search } = useLocation();

  useEffect(() => {
    if (subscriptionStatus.pendingActions) {
      navigate(
        generatePath(paths.PS_MOBILE_SUBSCRIPTION, { subscriptionId: subscription.subscriptionDisplayId }) + search,
        {
          replace: true,
        }
      );
    }
  }, [navigate, search, subscription.subscriptionDisplayId, subscriptionStatus.pendingActions]);

  return (
    <AttachRing
      category={SubscriptionCategory.VOICE}
      companyInfo={companyInfo}
      numberRanges={numberRanges}
      onClickSubscription={(subId: string) =>
        navigate(generatePath(paths.PS_MOBILE_SUBSCRIPTION, { subscriptionId: subId }) + search)
      }
      onSubmitOrder={onSubmitOrder(dispatch)}
      subscription={subscription}
      onlineModels={[onlineModel]}
      associations={(addOnRules?.associations?.[0] as AssociationRecord[]) || []}
      dependencies={(addOnRules?.dependencies?.[0] as DependencyRecord[]) || []}
    />
  );
};
