import { AddOnVisibilityOperationType } from '../../../../../common/enums.js';
import { Spinner } from '../../../siteUtils.js';
import { SubscriptionChangeConfirmation } from '../../../../../components/OrderSubscriptionSelection/SubscriptionChangeConfirmation.js';
import { ensureNonNullable } from '../../../../../common/utils/validationUtils.js';
import { useAddOnVisibility } from '../../../../../common/hooks/useAddOnVisibility.js';
import { useLoaderData, useLocation } from 'react-router-dom';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams';
import type { AddedAddon } from '../../../../../common/types/addon.js';
import type { CampaignAssociation } from '../../../../../generated/api/campaignAssociation.js';
import type { CommercialProduct } from '../../../../../generated/api/commercialProduct.js';
import type { MobileSubUpdateConfirmationLoaderData } from '../../../../../common/loaders';
import type { Subscription } from '../../../../../generated/api/subscription.js';

export interface SubscriptionUpdateNavigationState {
  selectedCommercialProduct: CommercialProduct;
  subscription: Subscription;
  selectedAddOns?: AddedAddon[];
  campaignAssociation?: CampaignAssociation;
  campaignDescription?: string;
}

const validateMandatoryParameters = (
  subscription: Subscription,
  selectedCommercialProduct: CommercialProduct
): void => {
  ensureNonNullable(subscription, 'subscription');
  ensureNonNullable(selectedCommercialProduct, 'selectedCommercialProduct');
};

export const MobileSubUpdateConfirmationPath = () => {
  const location = useLocation();
  const { selectedCommercialProduct, subscription, selectedAddOns, campaignAssociation, campaignDescription } =
    (location.state as SubscriptionUpdateNavigationState) || {};
  const { addOnRules, addOnRulesMobilePbx, pbxSolutions } = useLoaderData() as MobileSubUpdateConfirmationLoaderData;
  const { companyId } = useSearchParams<{ companyId?: string }>();

  validateMandatoryParameters(subscription, selectedCommercialProduct);

  const { addOnVisibilities, addOnVisibilitiesError } = useAddOnVisibility(
    selectedCommercialProduct?.commercialProductCode,
    AddOnVisibilityOperationType.CHANGE,
    undefined,
    companyId
  );

  if (addOnVisibilitiesError) {
    throw new Error(
      `Cannot fetch addon visibilities for commercial product ${selectedCommercialProduct?.commercialProductCode}}`
    );
  }

  if (!addOnVisibilities) {
    return <Spinner />;
  }

  return (
    <SubscriptionChangeConfirmation
      selectedCommercialProduct={selectedCommercialProduct}
      subscription={subscription}
      addOnRules={addOnRules}
      addOnRulesMobilePbx={addOnRulesMobilePbx}
      selectedAddOns={selectedAddOns}
      campaignAssociation={campaignAssociation}
      addOnVisibilities={addOnVisibilities}
      campaignDescription={campaignDescription}
      pbxSolutions={pbxSolutions}
    />
  );
};
