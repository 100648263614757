import * as CL from '@design-system/component-library';
import { BillingAccountDeliveryMethod, ContactType, Invoice } from '../../generated/api/models.js';
import { BreadCrumbsWithTitle } from '../BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { DialogType } from '../../common/enums.js';
import { InvoiceDisclaimer } from '../InvoiceDisclaimer/InvoiceDisclaimer.js';
import { LinkableAccordion } from '../LinkableAccordion/index.js';
import { Loading } from '../Loading/index.js';
import { OpenCasesNotification } from '../OpenCasesNotification/OpenCasesNotification.js';
import {
  askAboutInvoiceMsg,
  billingAccountExtensionNameMsg,
  billingAccountMsg,
  billingAccountNameMsg,
  changeBillingAddressMsg,
  changeDeliveryMethodMsg,
  deliveryMethodMsg,
  dueDateMsg,
  eInvoicingAddressMsg,
  eInvoicingOperatorMsg,
  invoiceLanguageMsg,
  invoiceMsg,
  invoicesMsg,
  movePaymentDateMsg,
  omaElisaForCompaniesMsg,
  openMsg,
  paidMsg,
  payMsg,
  payerDetailsMsg,
  payerMsg,
  recipientEmailMsg,
  recipientMsg,
  referenceMsg,
  t,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { finalizePayment, finalizePaymentFailed, showDialog } from '../../selfservice/actions/index.js';
import { formatBalance, formatSum } from '../../common/utils/priceUtils.js';
import {
  formatTimestampToDDMMYYYY,
  formatTimestampToUTCDDMMYYYY,
  formatTimestampToUTCYYYYMMDD,
  formatTimestampToYYYYMMDD,
} from '../../common/utils/dateUtils.js';
import { generatePath, useNavigate } from 'react-router-dom';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import {
  getDeliveryMethod,
  getElectronicInvoiceOperatorDisplayValue,
  isBillingAccountInSfdc,
} from '../../common/utils/billingAccountUtils.js';
import { getLanguageDisplayValue } from '../../common/utils/languageUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type { BillChannel, BillingAccount, SupportCaseHeader } from '../../generated/api/models.js';
import type { ButtonColor } from '@design-system/component-library';
import type { ConfigState } from '../../common/types/states.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { IconColor } from '../Icon/Icon.js';
import type { State } from '../../selfservice/common/store.js';

import './InvoiceDetails.scss';

export const getInvoiceState = (balance: number, due: number): { text: string; color: string } => {
  if (!balance || balance < 0) {
    return {
      color: 'green',
      text: t.RJ27(paidMsg),
    };
  } else if (formatTimestampToYYYYMMDD(Date.now())! > formatTimestampToUTCYYYYMMDD(due)!) {
    return {
      color: 'red',
      text: t.YRYX('Overdue'),
    };
  }
  return {
    color: 'linkblue',
    text: t.TJ54(openMsg),
  };
};

export const getStatusColumnInvoiceState = (balance = 0, due: number): { text: string; color: IconColor } => {
  if (balance <= 0) {
    return {
      color: 'green-600',
      text: t.RJ27(paidMsg),
    };
  } else if (formatTimestampToYYYYMMDD(Date.now())! > formatTimestampToUTCYYYYMMDD(due)!) {
    return {
      color: 'red-600',
      text: t.YRYX('Overdue'),
    };
  }
  return {
    color: 'blue-600',
    text: t.TJ54(openMsg),
  };
};

export interface InvoiceDetailsProps {
  config: ConfigState;
  billChannels?: BillChannel[];
  billingAccount?: BillingAccount;
  invoice?: Invoice;
  openSupportCases?: SupportCaseHeader[];
}

const MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;
export const isTimeWithinLast24Hrs = (time: number) => {
  return new Date().getTime() - time < MILLISECONDS_IN_A_DAY;
};

function pdfLinkify(invoiceId: string): string {
  return `/api/ui/v2/private/invoices/${invoiceId}/pdf`;
}

const BillingAccountLinkElement = ({
  billingAccount,
  config,
  linkId,
  linkText,
  color,
}: {
  billingAccount: BillingAccount;
  config: ConfigState;
  linkId: string;
  linkText: string;
  color?: ButtonColor;
}): JSX.Element => {
  const navigate = useNavigate();
  return isBillingAccountInSfdc(billingAccount) ? (
    <CL.Button
      id={linkId}
      color={color ?? 'link'}
      onClick={() => {
        if (billingAccount.billingAccountDisplayId) {
          const path = generatePath(paths.BILLING_ACCOUNT, {
            billingAccountId: billingAccount.billingAccountDisplayId,
          });
          navigate(path);
        }
      }}
    >
      {linkText}
    </CL.Button>
  ) : (
    <a id={linkId} href={`${config.classicSiteUrl}/laskutustiedot`}>
      {linkText}
    </a>
  );
};

const getBillingAccountDescriptionItems = (
  billingAccount: BillingAccount,
  config: ConfigState,
  billChannels?: BillChannel[]
): CL.DescriptionItem[] => {
  const billingAccountDescriptionItems: CL.DescriptionItem[] = [
    {
      title: t.PB6S(payerMsg),
      description: billingAccount.payerName,
    },
    {
      title: t.IFT9(billingAccountMsg),
      description: (
        <BillingAccountLinkElement
          billingAccount={billingAccount}
          config={config}
          linkId="to-billing-account-details-top"
          linkText={billingAccount.billingAccountDisplayId || ''}
        />
      ),
    },
    { title: t.RH6T(billingAccountNameMsg), description: billingAccount.billingAccountName },
    { title: t.KUTS(billingAccountExtensionNameMsg), description: billingAccount.billingAccountExtensionName },
    { title: t.YLAI(payerDetailsMsg), description: billingAccount.payerNameExtension },
    {
      title: t.LVRN(referenceMsg) + ' 1',
      description: billingAccount.customerReference1,
    },
    {
      title: t.LVRN(referenceMsg) + ' 2',
      description: billingAccount.customerReference2,
    },
    {
      title: t.LVRN(referenceMsg) + ' 3',
      description: billingAccount.customerReference3,
    },
    {
      title: t.LVRN(referenceMsg) + ' 4',
      description: billingAccount.customerReference4,
    },
    {
      title: t.G0QN(deliveryMethodMsg),
      description: getDeliveryMethod(billingAccount.deliveryMethod),
    },
  ];

  if (
    billingAccount.deliveryMethod === BillingAccountDeliveryMethod.ELECTRONIC &&
    billingAccount.billElectronicOperator
  ) {
    billingAccountDescriptionItems.push(
      {
        title: t.WVLB(eInvoicingOperatorMsg),
        description: getElectronicInvoiceOperatorDisplayValue(billingAccount.billElectronicOperator, billChannels),
      },
      {
        title: t.OL7B(eInvoicingAddressMsg),
        description: billingAccount.billElectronicAddress ?? '—',
      }
    );
  } else if (billingAccount.deliveryMethod === BillingAccountDeliveryMethod.EMAIL) {
    billingAccountDescriptionItems.push({
      title: billingAccount.billReceiverType === ContactType.PERSON ? t.SY1D(recipientMsg) : t.W1PP(recipientEmailMsg),

      description:
        billingAccount.billReceiverType === ContactType.PERSON
          ? billingAccount.billReceiverName
          : billingAccount.billReceiverEmail,
    });
  }

  billingAccountDescriptionItems.push({
    title: t.A7DR(invoiceLanguageMsg),
    description: getLanguageDisplayValue(billingAccount.billLanguage),
  });

  return billingAccountDescriptionItems.filter(item => item.description);
};

export const InvoiceDetails = ({
  billChannels,
  billingAccount,
  config,
  invoice,
  openSupportCases,
}: InvoiceDetailsProps) => {
  let content: JSX.Element;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));
  const invoiceDisplayId = invoice ? invoice.invoiceDisplayId : '…';
  const { invoiceId = null } = invoice || {};
  // Refer to Nets api specification: https://developers.nets.eu/netaxept/en-EU/api/response-codes/
  const searchParams = useSearchParams<{
    maksa: string;
    responseCode?: string;
    transactionId: string;
    companyId: string;
  }>();
  const { transactionId, maksa: triggerPayment, responseCode } = searchParams;
  const payments = useSelector((state: State) => state.payments, deepEqual);
  const { authenticatedUser } = useAuth();
  const companyName = getCompanyName(authenticatedUser, searchParams.companyId);

  useEffect(() => {
    if (invoiceId && transactionId && responseCode) {
      if (responseCode === 'OK') {
        dispatch(finalizePayment(invoiceId, transactionId));
      } else {
        dispatch(finalizePaymentFailed(`External payment system returned '${responseCode}' code`, 400));
      }
    }
  }, [dispatch, invoiceId, responseCode, transactionId]);

  useEffect(() => {
    if (triggerPayment !== undefined && invoice) {
      onShowDialog({
        balance: invoice.balance || 0,
        invoiceId: invoice.invoiceId,
        type: DialogType.BANK_BUTTONS,
      });
    }
  }, [invoice, triggerPayment]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  if (payments && payments.paymentCheckInProgress === true) {
    content = <Loading />;
  } else if (invoice && invoiceId && billingAccount) {
    const state = getInvoiceState(invoice.balance || 0, invoice.due);
    const pdfLink = pdfLinkify(invoiceId);
    const showInvoiceBalance: boolean = invoice.balance !== invoice.sum && invoice.balance !== 0;

    const invoiceDetailsDescriptionItems: CL.DescriptionItem[] = [
      {
        title: t.YE3V('Invoice status'),
        description: <span className={`ea-disc ea-disc--small ea-disc--${state.color}`}>{state.text}</span>,
      },
      {
        title: t.BVO5('Billing period'),
        description: invoice.billPeriod,
      },
      {
        title: t.LA93(dueDateMsg),
        description: formatTimestampToUTCDDMMYYYY(invoice.due),
      },
      {
        title: t.RJ27(paidMsg),
        description: formatBalance(invoice.sum, invoice.balance || 0),
      },
      {
        title: t.TJ3Q('Total taxable sum'),
        description: formatSum(invoice.sum),
      },
    ];

    if (showInvoiceBalance) {
      invoiceDetailsDescriptionItems.push({
        title:
          invoice.balance === undefined || invoice.balance < 0 ? t.AX9O('Overpayment') : t.Q4V8('Outstanding fees'),
        description: formatSum(invoice.balance),
      });
    }
    if (
      invoice.balance !== undefined &&
      invoice.balance > 0 &&
      invoice.sourceSystem !== Invoice.SourceSystemEnum.SFDC
    ) {
      invoiceDetailsDescriptionItems.push({
        description: invoice.balance ? (
          <CL.Link
            linkStyle="link-button"
            buttonColor="primary"
            buttonSize="l"
            linkHref={`${config.classicSiteUrl}/laskut/currentId/${invoiceDisplayId}`}
          >
            {t.LK73(payMsg)}
          </CL.Link>
        ) : (
          ''
        ),
      });
    } else if (
      invoice.balance !== undefined &&
      invoice.balance > 0 &&
      invoice.sourceSystem === Invoice.SourceSystemEnum.SFDC
    ) {
      invoiceDetailsDescriptionItems.push({
        description: (
          <CL.Button
            size="l"
            onClick={() =>
              onShowDialog({
                balance: invoice.balance || 0,
                invoiceId: invoice.invoiceId,
                type: DialogType.BANK_BUTTONS,
              })
            }
          >
            {t.LK73(payMsg)}
          </CL.Button>
        ),
      });
      invoiceDetailsDescriptionItems.push({
        description: (
          <CL.Button
            color="light"
            onClick={() =>
              onShowDialog({
                invoiceId,
                type: DialogType.MOVE_PAYMENT_DATE,
              })
            }
          >
            {t.YW9I(movePaymentDateMsg)}
          </CL.Button>
        ),
      });
    }

    const isInvoiceGeneratedWithinOneDay = () => {
      if (isTimeWithinLast24Hrs(invoice.created)) {
        return (
          <div className="ds-margin-top--2">{t.Y30X('PDF invoice from new invoice is available in 24 hours.')}</div>
        );
      }
      return;
    };

    content = (
      <div className="of-invoice-details__content">
        <CL.Description items={invoiceDetailsDescriptionItems} className="ds-margin--0 invoice-details-description" />
        <InvoiceDisclaimer className="of-invoice-details__disclaimer ds-margin-top--4">
          {isInvoiceGeneratedWithinOneDay()}
        </InvoiceDisclaimer>

        {(openSupportCases || []).length > 0 && <OpenCasesNotification supportCases={openSupportCases || []} />}

        <div className="of-invoice-details__actions">
          <a
            className="ds-button ds-button--color-link ds-button--size-m"
            href={pdfLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="ea-icon ea-icon--small ea-icon--pdf-file" /> {t.WURW('Open invoice')}
          </a>
          <CL.Button
            color="link"
            onClick={() =>
              searchParams.companyId
                ? onShowDialog({
                    invoiceDisplayId,
                    invoiceId,
                    companyName: companyName,
                    accountMasterId: searchParams.companyId,
                    type: DialogType.ASK_ABOUT_INVOICE,
                  })
                : () => {
                    // throw Error here to allow returning from Nets without companyId
                    throw new Error('Missing companyId');
                  }
            }
          >
            <>
              <span className="ea-icon ea-icon--small ea-icon--omaguru" />
              {t.AM3R(askAboutInvoiceMsg)}
            </>
          </CL.Button>
          {isBillingAccountInSfdc(billingAccount) ? (
            <>
              <CL.Button
                color="link"
                onClick={() => {
                  if (billingAccount?.billingAccountDisplayId) {
                    const navigateToPath = generatePath(paths.BILLING_ACCOUNT, {
                      billingAccountId: billingAccount.billingAccountDisplayId,
                    });
                    navigate(navigateToPath, { state: { redirectToPath: location.pathname } });
                  }
                }}
              >
                <>
                  <span className="ea-icon ea-icon--small ea-icon--invoice" />
                  {t.LZMG(changeDeliveryMethodMsg)}
                </>
              </CL.Button>
              <CL.Button
                color="link"
                onClick={() => {
                  if (billingAccount.billingAccountDisplayId) {
                    const navigateToPath = generatePath(paths.BILLING_ACCOUNT, {
                      billingAccountId: billingAccount.billingAccountDisplayId,
                    });
                    navigate(navigateToPath, { state: { redirectToPath: location.pathname } });
                  }
                }}
              >
                <>
                  <span className="ea-icon ea-icon--small ea-icon--mappin" />
                  {t.HKFZ(changeBillingAddressMsg)}
                </>
              </CL.Button>
            </>
          ) : (
            <>
              <a
                href={`${config.classicSiteUrl}/laskutustiedot`}
                className="ds-button ds-button--color-link ds-button--size-m"
              >
                <span className="ea-icon ea-icon--small ea-icon--invoice" />
                {t.LZMG(changeDeliveryMethodMsg)}
              </a>
              <a
                href={`${config.classicSiteUrl}/laskutustiedot`}
                className="ds-button ds-button--color-link ds-button--size-m"
              >
                <span className="ea-icon ea-icon--small ea-icon--mappin" />
                {t.HKFZ(changeBillingAddressMsg)}
              </a>
            </>
          )}
        </div>
        <div className="ds-padding-top--5">
          <LinkableAccordion
            heading={t.AMRD('Billing account details')}
            headingLevel="h3"
            id="billing-account-info"
            defaultOpen={false}
          >
            <CL.Description
              items={getBillingAccountDescriptionItems(billingAccount, config, billChannels)}
              className="ds-margin--0 ds-padding-bottom--4"
            />
            <div className="ds-padding-bottom--5">
              <BillingAccountLinkElement
                billingAccount={billingAccount}
                config={config}
                color="light"
                linkId="to-billing-account-details-bottom"
                linkText={t.V19M('See billing account')}
              />
              <div className="ds-padding-top--5">
                <small>
                  ({t.NALH('Billing agreement modified')} {formatTimestampToDDMMYYYY(billingAccount.lastModified)})
                </small>
                <br />
                <small>{t.YM3W('Showing the current information of the billing account.')}</small>
              </div>
            </div>
          </LinkableAccordion>
          <LinkableAccordion
            heading={t.EE4N('Payment details')}
            headingLevel="h3"
            id="payment-details"
            defaultOpen={false}
          >
            <CL.Description
              items={[
                {
                  title: t.FY9Z('Account number'),
                  description: (
                    <div className="of-invoice-details__accounts">
                      <small>Danske Bank A/S</small>
                      <span>FI87 8000 1770 2297 98</span>
                      <small>Nordea</small>
                      <span>FI48 1574 3000 0101 89</span>
                      <small>OP</small>
                      <span>FI03 5000 0120 1496 11</span>
                    </div>
                  ),
                },
                {
                  title: t.P87N('Reference number'),
                  description: invoice.referenceNumber,
                },
              ]}
              className="ds-margin--0 ds-padding-bottom--4"
            />
          </LinkableAccordion>
        </div>
      </div>
    );
  } else {
    content = <Loading />;
  }

  const breadCrumbs = (
    <BreadCrumbsWithTitle
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.Y7C0(invoicesMsg), path: paths.INVOICES },
        { name: invoiceDisplayId },
      ]}
    />
  );

  return (
    <DetailsWrapper
      classes={['of-invoice-details']}
      detailsTop={breadCrumbs}
      id={`invoice-details-${invoiceDisplayId}`}
      heading={invoiceDisplayId}
      headingTop={t.JF65(invoiceMsg)}
      headingBottom={companyName}
      heroPicto="invoice"
    >
      {content}
    </DetailsWrapper>
  );
};
