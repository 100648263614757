import * as CL from '@design-system/component-library';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { Form, Formik } from 'formik';
import { ProvidedRadio } from '../../common/formik/index.js';
import { PureComponent } from 'react';
import { closeMsg, continueMsg, t } from '../../common/i18n/index.js';
import type { AccountHeader } from '../../generated/api/models.js';
import type { CommonError } from '../../common/types/errors.js';
import type { DialogWrapperButton } from '../DialogWrapper/index.js';

import './SelectPrimaryAccountDialog.scss';

export interface SelectPrimaryAccountDialogProps {
  isLoading?: boolean;
  errors?: CommonError[];
  accounts: AccountHeader[];
  onSelectPrimaryAccount: (selected: AccountHeader) => void;
  onCloseDialog: () => void;
}

export interface SelectPrimaryAccountDialogStateInternal {
  selected: AccountHeader | undefined;
}

export class SelectPrimaryAccountDialog extends PureComponent<
  SelectPrimaryAccountDialogProps,
  SelectPrimaryAccountDialogStateInternal
> {
  constructor(props: SelectPrimaryAccountDialogProps) {
    super(props);
    this.state = { selected: undefined };
  }

  setSelected = (accountMasterId: string, accounts: AccountHeader[]) => {
    const selected = accounts.find(acc => acc.accountMasterId === accountMasterId);
    this.setState({ ...this.state, selected });
  };

  render() {
    const { isLoading, errors, onSelectPrimaryAccount, accounts, onCloseDialog } = this.props;
    const { selected } = this.state;

    const buttons: DialogWrapperButton[] = [
      errors
        ? {
            onClick: () => onCloseDialog(),
            text: t.WOYD(closeMsg),
          }
        : {
            disabled: selected === undefined,
            id: 'select-primary-account-dialog-continue',
            isLoading: isLoading,
            onClick: () => {
              if (selected) {
                onSelectPrimaryAccount(selected);
              }
            },
            text: t.I62A(continueMsg),
          },
    ];

    return (
      <div className="of-select-primary-account">
        <DialogWrapper
          buttons={buttons}
          closeable={Boolean(errors)}
          header={t.XIFY('Please select your employer company from the list')}
          onCloseDialog={onCloseDialog}
        >
          <div className="ds-margin-top--2 ds-margin-bottom--4">
            {t.NERD(
              `Your choice only affects which company's user list your information will appear on. You can manage companies according to your user rights.`
            )}
          </div>
          <Formik onSubmit={() => {}} initialValues={{ option: '' }}>
            <Form>
              <div className="of-select-primary-account__options">
                <ProvidedRadio
                  name="option"
                  radioButtons={accounts.map(account => ({
                    disabled: false,
                    label: `${account.accountName}, ${account.businessId}`,
                    value: account.accountMasterId,
                    onChange: () => this.setSelected(account.accountMasterId, accounts),
                  }))}
                  withError={true}
                />
              </div>
            </Form>
          </Formik>
          {errors &&
            errors.map((error, i) => (
              <CL.Disclaimer className="ds-text--m" icon={<CL.Icon icon="warning" />} key={i} text={error.message} />
            ))}
        </DialogWrapper>
      </div>
    );
  }
}
