import * as CL from '@design-system/component-library';
import { ToggleCheckbox } from '../ToggleCheckbox/ToggleCheckbox.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { startingAtMsg, t } from '../../common/i18n/index.js';
import type { AddOn, CampaignContext } from '../../generated/api/models.js';
import type { AddedAddon } from '../../common/types/addon.js';

import './FixedBroadbandInstallationService.scss';

export interface FixedBroadbandInstallationAddOnOptions {
  nettiLiteInstallationServiceAddOn?: AddedAddon;
  yritysWifiInstallationServiceAddOn?: AddedAddon;
  comboInstallationServiceAddOn?: AddedAddon;
}

interface FixedBroadbandInstallationServiceProps {
  addOnOptions: FixedBroadbandInstallationAddOnOptions;
  enableYritysWifiInstallationOptions: boolean;
  selectedInstallationAddOn?: AddedAddon;
  installationAddOnChanged: (addOn: AddedAddon) => void;
  numberOfMeshDevices?: number;
  onChange: (installationServiceSelected: boolean) => void;
  campaignContextsFromVoucher?: CampaignContext;
  campaignVoucher?: string;
  showRecommendedDisclaimer?: boolean;
}

const getPriceInfo = (addOns: AddOn[], campaignContextsFromVoucher?: CampaignContext) => {
  const cheapestAddOn = addOns.sort(
    (left, right) => (left.price?.effectivePrice.oneTimeCharge ?? 0) - (right.price?.effectivePrice.oneTimeCharge ?? 0)
  )[0];

  const price = cheapestAddOn.price?.listPrice.oneTimeCharge || 0;
  const voucherPrice = campaignContextsFromVoucher?.campaignAssociations[0].oneTimeCharge;
  const discountedPrice = cheapestAddOn.price?.effectivePrice.oneTimeCharge;

  return discountedPrice && discountedPrice < price
    ? {
        value: `${t.OD1L(startingAtMsg)} ${formatSum(voucherPrice ? discountedPrice - voucherPrice : discountedPrice)}`,
        additionalText: formatSum(price),
      }
    : {
        value: `${t.OD1L(startingAtMsg)} ${formatSum(voucherPrice ? price - voucherPrice : price)}`,
        additionalText: voucherPrice ? formatSum(price) : undefined,
      };
};

export const FixedBroadbandInstallationService = ({
  addOnOptions,
  enableYritysWifiInstallationOptions,
  selectedInstallationAddOn,
  installationAddOnChanged,
  numberOfMeshDevices = 0,
  onChange,
  campaignVoucher,
  campaignContextsFromVoucher,
  showRecommendedDisclaimer,
}: FixedBroadbandInstallationServiceProps) => {
  const basicInfo = t.P4CJ('Start using your broadband with ease.');
  const additionalInformation = showRecommendedDisclaimer
    ? `${basicInfo} ${t.V996(
        'Please note that if the connection is ordered elsewhere than home, the installation service is recommended.'
      )}`
    : basicInfo;

  const onAddOnSelected = (addOnCode: string) => {
    installationAddOnChanged(Object.values(addOnOptions).find(a => a.addOnCode === addOnCode));
  };

  const selectedAddOnCode = enableYritysWifiInstallationOptions
    ? (selectedInstallationAddOn?.addOnCode ?? addOnOptions.nettiLiteInstallationServiceAddOn?.addOnCode)
    : addOnOptions.nettiLiteInstallationServiceAddOn?.addOnCode;

  return (
    <div className="of-fixed-bb-installation-service">
      <div className="of-fixed-bb-installation-service__content">
        <ToggleCheckbox
          title={t.LUCJ('Installation service')}
          additionalInformation={additionalInformation}
          onChange={onChange}
          badge={
            campaignVoucher && campaignContextsFromVoucher
              ? {
                  text: campaignVoucher,
                  color: 'warning',
                  type: 'text',
                }
              : undefined
          }
          priceInfo={getPriceInfo(Object.values(addOnOptions), campaignContextsFromVoucher)}
        >
          <div className="ds-text--bold ds-padding-top--3">{t.Y0IR('Select installation service')}</div>
          <div className="of-fixed-bb-installation__price">
            <CL.RadioGroup key={selectedAddOnCode} onChange={onAddOnSelected} value={selectedAddOnCode}>
              {[
                {
                  addOn: addOnOptions.nettiLiteInstallationServiceAddOn,
                  label: t.UXSJ('Internet subscription installation'),
                  subtext: t.TEVT(
                    'The service includes the installation of the modem and connection and functionality testing.'
                  ),
                  visible: true,
                },
                {
                  addOn: addOnOptions.yritysWifiInstallationServiceAddOn,
                  label: t.V7HT('Wireless Mesh network installation'),
                  subtext: t.DS9O(
                    'The service includes the installation of the WIFI Mesh devices ({}) optimally positioned so that the WIFI network covers the entire home/office',
                    numberOfMeshDevices?.toString()
                  ),
                },
                {
                  addOn: addOnOptions.comboInstallationServiceAddOn,
                  label: t.E2HY('Internet subscription and wireless Mesh network installation'),
                  subtext: t.ZGX7(
                    'The service includes the installation of the modem and connection, as well as the installation of the WIFI Mesh devices ({}) optimally positioned so that the WIFI network covers the entire home/office. The service also includes the functionality testing of the network.',
                    numberOfMeshDevices?.toString()
                  ),
                },
              ]
                .filter(option => option.visible || option.addOn)
                .map(({ addOn, label, subtext }) => (
                  <CL.RadioV2 className="of-fixed-bb-installation__radio" key={label} value={addOn?.addOnCode}>
                    <div className="ds-display--flex ds-justify-content--space-between of-fixed-bb-installation__radio-label">
                      <div>
                        <div>{label}</div>
                        <div className="ds-text--xs">{subtext}</div>
                      </div>
                      <div className="ds-padding-left--2 of-fixed-bb-installation__option-price">
                        {formatSum(addOn?.oneTimePrice)}
                      </div>
                    </div>
                  </CL.RadioV2>
                ))}
            </CL.RadioGroup>
          </div>
        </ToggleCheckbox>
      </div>
    </div>
  );
};
