import { OpenFormAction } from './OpenFormReducer.js';
import { OpenFormButton } from './OpenFormComponents/OpenFormButton.js';
import { OpenFormError } from './OpenFormComponents/OpenFormError.js';
import { OpenFormIcon } from './OpenFormComponents/OpenFormIcon.js';
import { OpenFormLoadingSpinner } from './OpenFormComponents/OpenFormLoadingSpinner.js';
import { OpenFormNotifications } from './OpenFormComponents/OpenFormNotifications.js';
import { type ReactNode, useCallback, useMemo } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { paths } from '../../common/constants/pathVariables.js';
import { useOpenFormDispatch, useOpenFormState } from './OpenFormProvider.js';

import './OpenForm.scss';

export const OpenForm = ({
  children,
  critical,
  heading,
  preview,
  clear,
}: {
  children?: ReactNode;
  critical?: string;
  heading?: string;
  preview?: boolean;
  clear?: () => void;
}) => {
  const { confirm, disabled, error, listId, loading, notifications } = useOpenFormState();
  const dispatch = useOpenFormDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const list = useMemo(() => generatePath(listId ? paths.OPENFORM_LIST : paths.OPENFORM, { listId }), [listId]);
  const back = useCallback(() => navigate(list, { replace: true }), [navigate, list]);
  const enabled = useMemo(() => !disabled && !heading, [disabled, heading]);

  return (
    <div className="of-openform">
      <div className="of-openform__bar">
        {notifications.size ? (
          <OpenFormNotifications notifications={notifications} />
        ) : (
          <>
            {confirm ? (
              <OpenFormButton
                className="of-openform__bar__button default-hover"
                icon={<OpenFormIcon icon="close" />}
                color="link"
                size="l"
                disabled={false}
                onClick={() => dispatch(OpenFormAction.confirm(false))}
              />
            ) : (
              <OpenFormButton
                className="of-openform__bar__button default-hover"
                icon={<OpenFormIcon icon="arrow-left" />}
                color="link"
                size="l"
                disabled={!enabled || preview || location.pathname.endsWith(list)}
                onClick={back}
              />
            )}
            <OpenFormLoadingSpinner heading={heading} loading={loading} />
            {confirm ? (
              <OpenFormButton
                className="of-openform__bar__button danger"
                icon={<OpenFormIcon icon="check" />}
                color="link"
                size="l"
                disabled={false}
                onClick={() => clear?.() || dispatch(OpenFormAction.confirm(false))}
              />
            ) : (
              <OpenFormButton
                className="of-openform__bar__button danger-hover"
                icon={<OpenFormIcon icon="delete" type="regular" />}
                color="link"
                size="l"
                disabled={!enabled || !clear}
                onClick={() => dispatch(OpenFormAction.confirm(true))}
              />
            )}
          </>
        )}
      </div>
      {critical ? (
        <OpenFormError dispatch={dispatch} location={location} back={back} error={critical} critical={true} />
      ) : error ? (
        <OpenFormError dispatch={dispatch} location={location} back={back} error={error} critical={false} />
      ) : (
        children
      )}
    </div>
  );
};
