import {
  SHOPPING_BASKET_LOCAL_STORAGE_KEY,
  getShoppingBasketFromLocalStorage,
  setShoppingBasketToLocalStorage,
} from '../../selfservice/common/localStorageUtils.js';
import { isInBrowser } from '../utils/ssrUtils.js';
import { useSyncExternalStore } from 'react';

export function useShoppingBasket() {
  const setShoppingBasket = (newValue: string) => {
    isInBrowser() && setShoppingBasketToLocalStorage(newValue);
    window.dispatchEvent(new StorageEvent('storage', { key: SHOPPING_BASKET_LOCAL_STORAGE_KEY, newValue }));
  };

  const getSnapshot = () => getShoppingBasketFromLocalStorage() ?? '';

  const subscribe = (listener: () => void) => {
    window.addEventListener('storage', listener);
    return () => void window.removeEventListener('storage', listener);
  };

  const shoppingBasket = useSyncExternalStore(subscribe, getSnapshot);

  return { shoppingBasket, setShoppingBasket };
}
