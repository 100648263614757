import { BreadCrumbsWithTitle } from '../BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { CreateBillingAccount } from '../CreateBillingAccount/CreateBillingAccount.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { billingAccountsMsg, createNewBillingAccountMsg, omaElisaForCompaniesMsg, t } from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { onUpsertBillingAccount } from '../../common/utils/dispatcherUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { scrollTo } from '../../common/utils/browserUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import type { State } from '../../selfservice/common/store.js';

export const BillingAccount = () => {
  const dispatch = useDispatch();
  const billingAccounts = useSelector((state: State) => state?.selfservice?.billingAccounts, deepEqual);
  const { authenticatedUser } = useAuth();

  const breadCrumbs = (
    <BreadCrumbsWithTitle
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.ZVMK(billingAccountsMsg), path: paths.BILLING_ACCOUNTS },
        { name: t.W4CZ(createNewBillingAccountMsg) },
      ]}
    />
  );
  return (
    <div className="of-subscriptions-scene">
      <DetailsWrapper
        classes={['of-billing-account-details']}
        detailsTop={breadCrumbs}
        id="billing-account-details"
        heading={t.W4CZ(createNewBillingAccountMsg)}
        heroPicto="billing-account-new"
      >
        <CreateBillingAccount
          commonErrors={(billingAccounts && billingAccounts.errors) || undefined}
          isSaving={(billingAccounts && billingAccounts.saving) || false}
          onUpsertBillingAccount={onUpsertBillingAccount(dispatch)}
          payerName={authenticatedUser?.companyName || ''}
          scrollTo={scrollTo}
        />
      </DetailsWrapper>
    </div>
  );
};
