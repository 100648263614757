import * as CL from '@design-system/component-library';
import { OpenFormButton } from '../OpenFormComponents/OpenFormButton.js';
import { OpenFormGrid } from '../OpenFormComponents/OpenFormGrid.js';
import { OpenFormIcon } from '../OpenFormComponents/OpenFormIcon.js';
import { allMsg, categoryMsg, nameMsg, subscriptionMsg, t, typeMsg } from '../../../common/i18n/index.js';
import { generatePath, useNavigate } from 'react-router-dom';
import { getIndex } from '../OpenFormUtils.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import { useTable } from '../OpenFormHooks/useTable.js';
import classNames from 'classnames';
import type { OpenFormListResponse } from '../../../generated/api/openFormListResponse.js';

export const OpenFormListTable = ({
  list: { forms },
  disabled,
  indexes,
  sizes = [30, 60, 90],
}: {
  list: OpenFormListResponse;
  disabled: boolean;
  indexes: Map<string, number>;
  sizes?: readonly number[];
}) => {
  const async = useOpenFormAsync();
  const navigate = useNavigate();
  const [columns, rows, { end, index, pages, start, total }, refresh, sortDir, setPage] = useTable({
    by: 'name',
    columns: [
      { sortable: true, visible: true, key: 'label', label: t.VGFI(nameMsg), sorted: 'asc' },
      { sortable: true, visible: true, key: 'type', label: t.VGFF(typeMsg) },
      { sortable: true, visible: true, key: 'productCategory', label: t.CV5H(categoryMsg) },
      { sortable: true, visible: true, key: 'subscriptionType', label: t.P674(subscriptionMsg) },
      { sortable: false, visible: true, key: 'icons', label: null, align: 'right' },
      { sortable: false, visible: false, key: 'name', label: null },
      { sortable: false, visible: false, key: 'formId', label: null },
    ],
    pagination: { size: sizes[0] },
    rows: () =>
      forms.map(form => ({
        label: <span className="label">{form.name}</span>,
        type: form.typeDesc ?? '',
        productCategory: form.productCategoryDesc ?? '',
        subscriptionType: form.subscriptionType ?? '',
        icons: (
          <OpenFormButton
            className="of-openform__bar__button danger-hover"
            icon={<OpenFormIcon icon="delete" type="regular" />}
            color="link"
            size="m"
            disabled={!async.storage.hasItem(form.id)}
            onClick={e => {
              e.stopPropagation();
              async.storage.removeItem(form.id);
              async.actions.list({ indexes: Array.from(indexes.set(form.id, getIndex(undefined))) });
              refresh();
            }}
          />
        ),
        name: form.name ?? '',
        formId: form.id,
      })),
  });

  return (
    <OpenFormGrid className="of-openform__list">
      <CL.Table
        className={classNames('of-openform__list__table', { disabled })}
        tableType="bordered"
        hover={true}
        columns={columns}
        onSortClick={value => sortDir(columns.find(({ label }) => label === value))}
        rows={rows}
        rowClicks={rows.map(
          ({ formId }) =>
            () =>
              disabled || navigate(generatePath(paths.OPENFORM_VIEW, { formId, index: getIndex(indexes.get(formId)) }))
        )}
      />
      <div className="of-openform__list__buttons">
        <div className="of-openform__list__buttons__left">
          <CL.Dropdown
            integrated={true}
            onValueChange={el => setPage({ size: Number(el.attributes.getNamedItem('data-value')!.value) })}
            disabled={disabled}
            items={sizes
              .flatMap((size, i) => (sizes[i] < total ? { label: String(size), value: String(size) } : []))
              .concat({ label: t.EY6A(allMsg), value: String(total) })}
          />
          {start} - {end} / {total}
        </div>
        <div className="of-openform__list__buttons__right">
          <OpenFormButton
            className="buttons navigation"
            icon={<OpenFormIcon icon="arrow-left" />}
            color="link"
            size="m"
            disabled={disabled || index <= 1}
            onClick={() => setPage({ index: index - 1 })}
          />
          <OpenFormButton className="buttons navigation index" text={index} color="link" size="m" />
          <OpenFormButton
            className="buttons navigation"
            icon={<OpenFormIcon icon="arrow-right" />}
            color="link"
            size="m"
            disabled={disabled || index >= pages}
            onClick={() => setPage({ index: index + 1 })}
          />
        </div>
      </div>
    </OpenFormGrid>
  );
};
