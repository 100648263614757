import { AuxiliaryEsimOrderPhaseEnum } from '../../../components/AuxiliaryEsimOrderScene/auxiliaryEsimOrderEnums.js';
import { AuxiliaryEsimOrderScene } from '../../../components/AuxiliaryEsimOrderScene/AuxiliaryEsimOrderScene.js';
import { initiateElisaIdV2Login } from '../../common/util/elisaIdLogin';
import { processElisaIdLogin, updateElisaIdSession } from '../../../selfservice/exports.js';
import { setAuxiliaryEsimOrderPhase } from '../../../selfservice/actions/index.js';
import { setMultisimSubscriptionToken } from '../../../selfservice/common/localStorageUtils';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const AuxiliaryEsimOrderPath = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.endsWith('/manage')) {
      dispatch(setAuxiliaryEsimOrderPhase(AuxiliaryEsimOrderPhaseEnum.MANAGE));
    }
  }, [dispatch, location]);

  const onElisaIdLoginSuccess = (token: string) => {
    dispatch(updateElisaIdSession());
    dispatch(processElisaIdLogin(token));
    const subscriptionToken = new URLSearchParams(location.search).get('subscriptionToken') ?? undefined;
    dispatch(setAuxiliaryEsimOrderPhase(AuxiliaryEsimOrderPhaseEnum.VALIDATE, subscriptionToken));
  };

  const startElisaIdV2Login = (locale: string) => {
    const subscriptionToken = new URLSearchParams(location.search).get('subscriptionToken') ?? undefined;
    setMultisimSubscriptionToken(subscriptionToken);
    void initiateElisaIdV2Login(locale);
  };

  return (
    <div id="auxiliary-esim-order">
      <AuxiliaryEsimOrderScene
        onElisaIdLoginSuccess={onElisaIdLoginSuccess}
        startElisaIdV2Login={startElisaIdV2Login}
      />
    </div>
  );
};
