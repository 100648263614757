import { ActionPhase } from '../common/storeUtils.js';
import { TypeKeys } from '../actions/index.js';
import { filterErrors, processConflictedErrors, reduceCrudAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { AnonymousUserState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function anonymousUserReducer(
  state: (AnonymousUserState & ActionsHistory) | undefined | null,
  action: SelfServiceActionTypes
): (AnonymousUserState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }
  switch (action.type) {
    case TypeKeys.RESET_ERRORS:
      return {
        ...state,
        errors: undefined,
      };

    case TypeKeys.PROCESS_ELISAID_LOGIN:
      return {
        ...reduceCrudAction(action, state),
        elisaIdV2LoginProcessing: true,
      };

    case TypeKeys.PROCESS_ELISAID_LOGIN_FULFILLED: {
      return {
        ...state,
        elisaIdLoginProcessed: true,
        actions: updateActionStatePhase(
          TypeKeys.PROCESS_ELISAID_LOGIN,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        ssoSessionValid: true,
        elisaIdV2LoginProcessing: false,
      };
    }

    case TypeKeys.PROCESS_ELISAID_LOGIN_FAILED: {
      return {
        ...state,
        elisaIdLoginProcessed: true,
        actions: updateActionStatePhase(
          TypeKeys.PROCESS_ELISAID_LOGIN,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: filterErrors(processConflictedErrors(action.errors)),
        elisaIdV2LoginProcessing: false,
      };
    }

    case TypeKeys.GET_ELISA_ID_SESSION:
      return reduceCrudAction(action, state);

    case TypeKeys.GET_ELISA_ID_SESSION_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_ELISA_ID_SESSION,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        elisaIdSessionValid: !!action.userName,
      };
    }

    case TypeKeys.GET_ELISA_ID_SESSION_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_ELISA_ID_SESSION,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.GET_ELISA_ID_ACCESS_TOKEN:
      return reduceCrudAction(action, state);

    case TypeKeys.GET_ELISA_ID_ACCESS_TOKEN_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_ELISA_ID_ACCESS_TOKEN,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
      };
    }

    case TypeKeys.GET_ELISA_ID_ACCESS_TOKEN_FAILED: {
      return {
        elisaIdLoginProcessed: true,
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_ELISA_ID_ACCESS_TOKEN,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.ELISA_ID_LOGOUT: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
        elisaIdSessionValid: false,
        ssoSessionValid: false,
      };
    }

    case TypeKeys.ELISA_ID_LOGOUT_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.ELISA_ID_LOGOUT, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        elisaIdSessionValid: false,
        resetLoginForm: true,
        ssoSessionValid: false,
      };
    }

    case TypeKeys.ELISA_ID_LOGOUT_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.ELISA_ID_LOGOUT, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        elisaIdSessionValid: false,
        ssoSessionValid: false,
      };
    }

    case TypeKeys.LOG_OUT:
      return {
        ...state,
        elisaIdSessionValid: false,
        ssoSessionValid: false,
      };

    case TypeKeys.UPDATE_ELISAID_SESSION: {
      return {
        ...state,
        elisaIdSessionValid: true,
      };
    }

    case TypeKeys.ADD_MISSING_MOBILE_NUMBER_AND_LOG_IN:
    case TypeKeys.SELECT_PRIMARY_ACCOUNT_AND_LOG_IN:
    case TypeKeys.RESOLVE_MISSING_PRIMARY_ACCOUNT:
    case TypeKeys.AUTH_VALIDATE:
    case TypeKeys.LOG_IN: {
      return reduceCrudAction(action, state);
    }

    case TypeKeys.LOG_IN_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.LOG_IN, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        ssoSessionValid: true,
        forgotPasswordStepNumber: 0,
        mfaRequired: false,
        mfaInfo: undefined,
      };
    }

    case TypeKeys.LOG_IN_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.LOG_IN, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        errors: action.errors,
        forgotPasswordStepNumber: 0,
      };
    }

    case TypeKeys.MFA_REQUIRED: {
      let actions = updateActionStatePhase(TypeKeys.LOG_IN, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS);
      actions = updateActionStatePhase(
        TypeKeys.AUTH_VALIDATE,
        { actions: actions },
        ActionPhase.IN_PROGRESS,
        ActionPhase.SUCCESS
      );
      return {
        ...state,
        actions,
        ssoSessionValid: false,
        mfaRequired: true,
        mfaInfo: action.mfaInfo,
      };
    }

    case TypeKeys.AUTH_VALIDATE_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.AUTH_VALIDATE, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        ssoSessionValid: true,
        mfaRequired: false,
        mfaInfo: undefined,
      };
    }

    case TypeKeys.AUTH_VALIDATE_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.AUTH_VALIDATE, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        ssoSessionValid: false,
      };
    }

    case TypeKeys.VERIFY_EMAIL: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
      };
    }

    case TypeKeys.VERIFY_EMAIL_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.VERIFY_EMAIL, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
      };
    }

    case TypeKeys.VERIFY_EMAIL_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.VERIFY_EMAIL, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        errors: processConflictedErrors(action.errors),
      };
    }

    case TypeKeys.ADD_ANONYMOUS_LEAD: {
      if (action.errors?.length) {
        return {
          ...state,
          errors: action.errors,
        };
      }
      return {
        ...state,
        ...reduceCrudAction(action, state),
        errors: undefined,
        leadSent: false,
        leadFailed: false,
        leadInProgress: true,
      };
    }

    case TypeKeys.ADD_ANONYMOUS_LEAD_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.ADD_ANONYMOUS_LEAD,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: undefined,
        leadSent: true,
        leadFailed: false,
        leadInProgress: false,
      };
    }

    case TypeKeys.ADD_ANONYMOUS_LEAD_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.ADD_ANONYMOUS_LEAD,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        leadSent: false,
        leadFailed: true,
        leadInProgress: false,
      };
    }

    case TypeKeys.ADD_ANONYMOUS_LEAD_CLEAR: {
      return {
        ...state,
        leadSent: false,
        leadFailed: false,
        leadInProgress: false,
      };
    }

    case TypeKeys.ADD_ANONYMOUS_SUPPORT_CASE: {
      if (action.errors?.length) {
        return {
          ...state,
          errors: action.errors,
        };
      }
      return {
        ...state,
        ...reduceCrudAction(action, state),
        errors: undefined,
      };
    }

    case TypeKeys.ADD_ANONYMOUS_SUPPORT_CASE_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.ADD_ANONYMOUS_SUPPORT_CASE,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: undefined,
        supportCaseSent: true,
        supportCaseFailed: false,
      };
    }

    case TypeKeys.ADD_ANONYMOUS_SUPPORT_CASE_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.ADD_ANONYMOUS_SUPPORT_CASE,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        supportCaseSent: false,
        supportCaseFailed: true,
      };
    }

    case TypeKeys.SET_CASE_SENT_STATUS: {
      return {
        ...state,
        supportCaseSent: action.supportCaseSent,
      };
    }

    case TypeKeys.FORGOT_PASSWORD_STEP_NUMBER: {
      return {
        ...state,
        forgotPasswordStepNumber: action.stepNumber,
      };
    }

    case TypeKeys.SEND_OTP_TO_USER: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
        forgotPasswordErrored: false,
        forgotPasswordInProgress: true,
      };
    }

    case TypeKeys.SEND_OTP_TO_USER_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.SEND_OTP_TO_USER,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        forgotPasswordStepNumber: (state?.forgotPasswordStepNumber || 0) + 1,
        forgotPasswordErrored: false,
        forgotPasswordInProgress: false,
      };
    }

    case TypeKeys.SEND_OTP_TO_USER_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.SEND_OTP_TO_USER, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        forgotPasswordStepNumber: (state?.forgotPasswordStepNumber || 0) + 1,
        forgotPasswordErrored: false,
        forgotPasswordInProgress: false,
      };
    }

    case TypeKeys.VALIDATE_OTP: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
        forgotPasswordErrored: false,
        forgotPasswordInProgress: true,
      };
    }

    case TypeKeys.VALIDATE_OTP_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.VALIDATE_OTP, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        forgotPasswordStepNumber: (state?.forgotPasswordStepNumber || 0) + 1,
        forgotPasswordErrored: false,
        forgotPasswordInProgress: false,
      };
    }

    case TypeKeys.VALIDATE_OTP_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.VALIDATE_OTP, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        forgotPasswordErrored: true,
        forgotPasswordInProgress: false,
      };
    }

    case TypeKeys.RESET_PASSWORD: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
        forgotPasswordErrored: false,
        forgotPasswordInProgress: true,
      };
    }

    case TypeKeys.RESET_PASSWORD_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.RESET_PASSWORD, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        forgotPasswordStepNumber: (state?.forgotPasswordStepNumber || 0) + 1,
        forgotPasswordErrored: false,
        forgotPasswordInProgress: false,
      };
    }

    case TypeKeys.RESET_PASSWORD_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.RESET_PASSWORD, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        forgotPasswordErrored: true,
        forgotPasswordInProgress: false,
      };
    }

    default:
      return state;
  }
}
