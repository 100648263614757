import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { DeliveryAddressDetails } from './DeliveryAddressDetails.js';
import { EppDevicePendingActionsDisclaimer } from './EppDevicePendingActionsDisclaimer.js';
import { EppRedeemTerminateRequestType } from '../../generated/api/models.js';
import { Formik } from 'formik';
import { Loading } from '../Loading/index.js';
import { ProvidedRadio } from '../../common/formik/index.js';
import { contactEmailAddressMsg, contactPhoneNumberMsg, deviceMsg, t } from '../../common/i18n/index.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getFullNameFromAuthenticatedUser } from './eppMaintenanceUtils.js';
import { getSubscriptionStatus } from '../../common/utils/subscriptionUtils.js';
import { postEppRedeemTerminate } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import type { AuthenticatedUserState, CompanyInfoState } from '../../common/types/states.js';
import type { DeliveryAddress, Subscription, SubscriptionAction } from '../../generated/api/models.js';
import type { EmployeeSubscription } from '../../common/types/subscription.js';

import './EppDestroyedOrMissingDevice.scss';

export interface EppDestroyedOrMissingDeviceProps {
  subscription: EmployeeSubscription | Subscription;
  onCancel: () => void;
  companyInfo?: CompanyInfoState | null;
  pendingSubscriptionActions: SubscriptionAction[];
}

export enum EppDestroyedOrMissingType {
  MISSING = 'MISSING',
  DESTROYED = 'DESTROYED',
}

const DescriptionText = ({ destroyedPrice }: { destroyedPrice?: number }) => (
  <div className="of-epp-destroyed-or-missing-device__description_text">
    <p>{t.S0V2('Elisa charges the remaining monthly fees for a destroyed or missing device as a one-time fee.')}</p>
    <p>
      {`${t.EBX4('For this device the amount is ')} `}
      <strong>{formatSum(destroyedPrice)}.</strong>
    </p>
    <p>
      {`${t.WZNT('At the same time, the contract period for Elisa Device is suspended.')} `}
      <strong>{t.ABI6('The replacement device will not be sent automatically.')}</strong>
      <br />
      {t.HIOW(`If necessary, order a new device or instruct the employee to order it from the Employee's OmaElisa.`)}
    </p>
  </div>
);

const LostOrDestroyedSelector = ({
  setRequestType,
}: {
  setRequestType: (requestType: EppDestroyedOrMissingType) => void;
}) => (
  <>
    <p className="of-epp-destroyed-or-missing-device__radio_button_title">{t.OIVZ('Issue')}</p>
    <ProvidedRadio
      name="option"
      radioButtons={[
        {
          label: t.FINQ('Device has been destroyed'),
          value: EppDestroyedOrMissingType.DESTROYED,
          onChange: () => {
            setRequestType(EppDestroyedOrMissingType.DESTROYED);
          },
        },
        {
          label: t.KFGF('Device is lost'),
          value: EppDestroyedOrMissingType.MISSING,
          onChange: () => {
            setRequestType(EppDestroyedOrMissingType.MISSING);
          },
        },
      ]}
      value={EppDestroyedOrMissingType.DESTROYED}
    />
  </>
);

const DeviceDetails = ({
  subscription,
  user,
}: {
  subscription: Subscription;
  user?: AuthenticatedUserState | null;
}) => (
  <>
    <div className="ds-grid__row">
      <div className="ds-grid__col--xs-4 ds-grid__col--l-6 of-epp-destroyed-or-missing-device__device_details-value">
        <strong>{t.TPVQ(deviceMsg)}</strong>
        {subscription.subscriptionName}
      </div>
      <div className="ds-grid__col--xs-4 ds-grid__col--l-6 of-epp-destroyed-or-missing-device__device_details-value">
        <strong>{t.S5PK(contactEmailAddressMsg)}</strong>
        {user?.email || '-'}
      </div>
    </div>
    <div className="ds-grid__row">
      <div className="ds-grid__col--xs-4 ds-grid__col--l-6 of-epp-destroyed-or-missing-device__device_details-value">
        <strong>{t.HUN9(contactPhoneNumberMsg)}</strong>
        {user?.mobile || '-'}
      </div>
    </div>
  </>
);

export const EppDestroyedOrMissingDevice = ({
  subscription,
  onCancel,
  companyInfo,
  pendingSubscriptionActions,
}: EppDestroyedOrMissingDeviceProps) => {
  const [requestType, setRequestType] = useState(EppDestroyedOrMissingType.DESTROYED);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddress>();
  const { authenticatedUser } = useAuth();
  const pendingActions = getSubscriptionStatus(subscription, pendingSubscriptionActions).pendingActions;

  const dispatch = useDispatch();

  const handleSubmit = () => {
    const destroyed =
      requestType === EppDestroyedOrMissingType.DESTROYED
        ? { address: deliveryAddress! } // submit will be disabled unless the deliveryAddress is defined
        : undefined;
    dispatch(postEppRedeemTerminate({ requestType, destroyed }, subscription.subscriptionId));
  };

  if (!companyInfo) {
    return <Loading />;
  } else {
    return (
      <Formik initialValues={{ option: requestType }} onSubmit={handleSubmit}>
        {formik =>
          pendingActions && !formik.isSubmitting ? (
            <EppDevicePendingActionsDisclaimer onClose={onCancel} />
          ) : (
            <div className="of-epp-destroyed-or-missing-device">
              <DescriptionText destroyedPrice={subscription.details?.device?.eppModificationPrices?.destroyed} />
              <LostOrDestroyedSelector setRequestType={setRequestType} />
              <hr />
              <DeviceDetails subscription={subscription} user={authenticatedUser} />
              {requestType === EppRedeemTerminateRequestType.DESTROYED && (
                <DeliveryAddressDetails
                  address={companyInfo.address}
                  companyName={companyInfo.companyName}
                  recipientName={getFullNameFromAuthenticatedUser(authenticatedUser)}
                  phoneNumber={authenticatedUser?.mobile || ''}
                  setDeliveryAddress={setDeliveryAddress}
                  showConfirmAddressCheckbox={true}
                  onAddressReady={setIsSubmitEnabled}
                  isSupportRequest={false}
                />
              )}
              <hr />
              <ButtonGroupForSubmitAndBack
                className="of-epp-destroyed-or-missing-device__buttons"
                onCancel={onCancel}
                submitDisabled={
                  (!isSubmitEnabled || !deliveryAddress) && requestType !== EppDestroyedOrMissingType.MISSING
                }
                onSubmit={formik.submitForm}
                submitting={formik.isSubmitting}
              />
            </div>
          )
        }
      </Formik>
    );
  }
};
