import { useEffect, useState } from 'react';

export const useReload = (): [<T>(target: T | undefined, ...truthy: unknown[]) => target is T, () => void] => {
  const [reload, setReload] = useState(false);

  useEffect(() => {
    reload && setReload(false);
  }, [reload]);

  return [
    <T>(target: T | undefined, ...truthy: unknown[]): target is T => [!reload, target, ...truthy].every(Boolean),
    () => setReload(true),
  ];
};
