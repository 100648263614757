import { SubscriptionsLayout } from '../../../../../components/SubscriptionsLayout/SubscriptionsLayout.js';
import { SubscriptionsService } from '../../../../../components/Subscriptions/SubscriptionsService.js';
import { omaElisaForCompaniesMsg, servicesMsg, t } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';

export const ServicesPath = () => (
  <SubscriptionsLayout
    breadCrumbs={[
      { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
      { name: t.MBG9(servicesMsg) },
    ]}
  >
    <SubscriptionsService />
  </SubscriptionsLayout>
);
