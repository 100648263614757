import { OpenFormAction } from '../OpenFormReducer.js';
import { type OpenFormAnswers } from '../OpenFormAnswers.js';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { getIndex } from '../OpenFormUtils.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useCallback, useEffect, useMemo } from 'react';
import { useOpenFormDispatch } from '../OpenFormProvider.js';
import type { FormId, Index } from '../../../common/constants/pathInterfaces.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';

export const useFormNavigation = (
  { formId, ...params }: Partial<FormId & Index>,
  preview: boolean,
  answers: OpenFormAnswers,
  sections: OpenFormSection[] | undefined
): [boolean, boolean, number, (to: number) => void, () => void, () => void] => {
  const dispatch = useOpenFormDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const completed = useMemo(() => location.pathname.endsWith('completed'), [location.pathname]);
  const summary = useMemo(() => location.pathname.endsWith('summary'), [location.pathname]);
  const max = useMemo(() => (!sections?.length ? 0 : sections.length + 1), [sections?.length]);
  const index = getIndex(Number(params.index), { min: completed || summary ? max : undefined, max });

  const path = useCallback(
    (to: 'completed' | number) => {
      let route: string;
      switch (to) {
        case 'completed': {
          route = preview ? paths.OPENFORM_PREVIEW_COMPLETED : paths.OPENFORM_VIEW_COMPLETED;
          break;
        }
        case max: {
          route = preview ? paths.OPENFORM_PREVIEW_SUMMARY : paths.OPENFORM_VIEW_SUMMARY;
          break;
        }
        default: {
          route = preview ? paths.OPENFORM_PREVIEW : paths.OPENFORM_VIEW;
          break;
        }
      }
      navigate(
        generatePath(route, { formId, index: typeof to === 'number' && max > to ? getIndex(to, { max }) : undefined }),
        { replace: true }
      );
    },
    [formId, preview, navigate, max]
  );

  const step = useCallback(
    (slice: OpenFormSection[] = []) => Math.abs(slice.findIndex((section, i) => i && answers.isActive(section) && i)),
    [answers]
  );

  useEffect(() => {
    if (formId && max) {
      dispatch(OpenFormAction.index({ formId, index }));
      path(completed ? 'completed' : index);
    }
  }, [formId, dispatch, completed, max, index, path]);

  return [
    completed,
    summary,
    index,
    path,
    useCallback(() => path(index + step(sections?.slice(index - 1))), [sections, index, path, step]),
    useCallback(() => path(index - step(sections?.slice(0, index).reverse())), [sections, index, path, step]),
  ];
};
