import * as React from 'react';
import { Field } from 'formik';
import { RadioGroupComponent } from '../FieldComponents/RadioGroup.js';
import type { FormikHelpers, FormikState, FormikValues } from 'formik';

export interface ProvidedRadioGroupProps {
  className?: string;
  id?: string;
  radioButtons: {
    children?: JSX.Element;
    disabled?: boolean;
    label?: string;
    onChange?: (
      e: React.ChangeEvent<HTMLInputElement>,
      form: FormikState<FormikValues> & FormikHelpers<FormikValues>
    ) => void;
    value: string;
  }[];
  // Field uses the name & value-props for itself, so we have to use another name here
  name: string;
  value?: string;
  withError?: boolean;
}

export const ProvidedRadio = (props: ProvidedRadioGroupProps) => {
  return <Field {...props} component={RadioGroupComponent} />;
};
