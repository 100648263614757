import { format, parse } from 'date-fns';
import type { OFPageType } from '../../generated/api/oFPageType.js';
import type { OpenFormQuestion } from '../../generated/api/openFormQuestion.js';
import type { OpenFormSection } from '../../generated/api/openFormSection.js';

type Not<T> = (...args: T extends (...args: infer P) => boolean ? P : never) => boolean;

// Choices are stored in `dd.MM.yyyy` format in storage, but sent to salesforce in `yyyy-MM-dd` format
export const getDate = (date: string | undefined) =>
  date ? format(parse(date, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd') : undefined;

export const getIndex = (index: number | undefined, { min = 1, max = Number.MAX_SAFE_INTEGER } = {}) =>
  Number.isSafeInteger(index) ? Math.min(max, Math.max(min, index!)) : min;

export const getQuestions: {
  (sections: OpenFormSection[], types?: OFPageType[]): OpenFormQuestion[];
  (sections: OpenFormSection[], ...types: OFPageType[]): OpenFormQuestion[];
} = (sections: OpenFormSection[], ...types: unknown[]) =>
  sections.flatMap(s => (!types.length || types.flat().includes(s.pageType) ? s.questions : []));

export const getValue = <T>(input?: T | T[]) => (input ? String(input) : input);

export const isIframe = () => {
  try {
    return !!window.parent.location.href && window.parent !== window.self;
  } catch {
    return true;
  }
};

export const not =
  <T>(func: Not<T>) =>
  (...args: Parameters<Not<T>>) =>
    !func(...args);

export const onBlurPrice = (value: string) => Math.max(0, Math.min(Math.abs(Number(value)), 99999.99)).toFixed(2);

export const onChangePrice = (value: string) => value.replace('-', '').replace(',', '.');

export const scrollIntoView = (target?: OpenFormSection | OpenFormQuestion) =>
  !target?.guid ? undefined : document.getElementById(target.guid)?.scrollIntoView(false);
