import * as CL from '@design-system/component-library';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker } from '../../../common/react-hook-form/components/DatePicker.js';
import { ToggleWithSubComponent } from '../../ToggleWithSubComponent/ToggleWithSubComponent.js';
import {
  dateMustBeGreaterThanTodayAndOutsideWeekendsMsg,
  selectEffectiveDateMsg,
  t,
  willTheChangeTakeEffectImmediatelyMsg,
} from '../../../common/i18n/index.js';
import { isWeekend } from '../../../common/utils/dateUtils.js';
import type { FormEditProps } from './EOperatorChangeRequestForm.js';

const FIELD_NAME = 'effectiveDate';

const validateDate = (value: string) => {
  const [day, month, year] = value.split('.');
  const givenDate = new Date(`${year}-${month}-${day}`);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  givenDate.setHours(0, 0, 0, 0);
  tomorrow.setHours(0, 0, 0, 0);

  return givenDate < tomorrow || isWeekend(givenDate)
    ? t.H5I1(dateMustBeGreaterThanTodayAndOutsideWeekendsMsg)
    : undefined;
};

const FormEditField = () => (
  <DatePicker
    disableAllPastDays={true}
    disableWeekends={true}
    blockedDates={[new Date()]}
    label={t.NDRY(selectEffectiveDateMsg)}
    name={FIELD_NAME}
    required={true}
    validate={validateDate}
  />
);

const FormInfoField = () => {
  const { getValues } = useFormContext();
  return (
    <div className="ds-input">
      <div className="ds-input--labelarea">
        <label className="ds-input--labelarea-label ">{t.NDRY(selectEffectiveDateMsg)}</label>
      </div>
      <div className="ds-input--inputarea">
        <div className="ds-display--flex ds-align-items--center">
          <CL.Icon icon="calendar" />
          <span className="ds-padding-left--2">{getValues(FIELD_NAME)}</span>
        </div>
      </div>
    </div>
  );
};

export const WillChangeTakeEffectImmediately = ({ isEditing = true }: FormEditProps) => {
  const { setValue, clearErrors } = useFormContext();
  const handleToggle = (value: boolean) => {
    if (value) {
      setValue(FIELD_NAME, '');
      clearErrors(FIELD_NAME);
    }
  };
  return (
    <Controller
      name="willChangeTakeEffectImmediately"
      render={({ field: { onChange } }) => (
        <ToggleWithSubComponent
          name="willChangeTakeEffectImmediately"
          title={t.CACT(willTheChangeTakeEffectImmediatelyMsg)}
          infoText={t.VF8X(
            'Yes: the new information will take effect from the first possible invoice. Please note that the change will not affect any invoices that have already been created.\n\n' +
              'No: you can provide your updated invoice details in advance, and the change will take effect on the date you choose.'
          )}
          onToggle={value => {
            onChange(value);
            handleToggle(value);
          }}
          subComponent={isEditing ? <FormEditField /> : <FormInfoField />}
          showSubComponentWhenToggled={false}
          initialValue={true}
          disabled={!isEditing}
        />
      )}
    />
  );
};
