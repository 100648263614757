import { type OpenFormAnswers } from '../OpenFormAnswers.js';
import { fetchOpenFormSummary } from '../../../common/fetch.js';
import { loadingProductInformationMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useMemo, useState } from 'react';
import { useLoadingIndicator } from './useLoadingIndicator.js';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { OFPageType } from '../../../generated/api/oFPageType.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';
import type { OpenFormSummaryResponse } from '../../../generated/api/openFormSummaryResponse.js';

export const useFetchSummary = (
  summary: boolean,
  index: number,
  answers: OpenFormAnswers,
  sections: OpenFormSection[] = [],
  ...pageTypes: OFPageType[]
) => {
  const async = useOpenFormAsync();
  const types = String(pageTypes.sort());
  const choices = useMemo(
    () =>
      sections.slice(index - 1).every(s => !types.includes(s.pageType)) &&
      String(answers.getChoices(sections, ...(types.split(',') as OFPageType[])).sort()),
    [index, answers, sections, types]
  );
  const [data, setData] = useState<OpenFormSummaryResponse | undefined>(undefined);

  useEffect(() => {
    if (!choices) {
      return;
    }
    const { ctrl } = new async(({ signal }) => fetchOpenFormSummary(choices.split(','), { signal }))
      .resolved(setData)
      .rejected(async.actions.error)
      .cache(choices)
      .execute();

    return () => {
      ctrl.abort();
      setData(undefined);
    };
  }, [async, choices]);

  useLoadingIndicator(Boolean(summary && choices && !data), {
    id: 'useFetchSummary',
    text: t.VBS1(loadingProductInformationMsg),
  });

  return data;
};
