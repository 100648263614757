import { FixedBroadbandSetupDate } from '../FixedBroadbandSetupDate.js';
import { prepareBillingAccountEdit } from '../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import type { CheckoutStepProps } from '../FixedBroadbandCheckout.js';
import type { FixedBBCheckoutLoaderData } from '../../../common/loaders.js';
import type { FixedBroadbandInstallationDetails } from '../FixedBroadbandSetupDate.js';

interface StepBroadbandSetupDateProps extends CheckoutStepProps {
  isMobileProduct: boolean;
  setBroadbandInstallationDetails: React.Dispatch<React.SetStateAction<FixedBroadbandInstallationDetails>>;
  fixedBBCheckoutLoaderData: FixedBBCheckoutLoaderData;
}

export const StepBroadbandSetupDate = (props: StepBroadbandSetupDateProps) => {
  const dispatch = useDispatch();

  const { isMobileProduct, setBroadbandInstallationDetails, enterNextStep, fixedBBCheckoutLoaderData } = props;

  return (
    <FixedBroadbandSetupDate
      isMobileProduct={isMobileProduct}
      onNext={values => {
        setBroadbandInstallationDetails(values);
        dispatch(prepareBillingAccountEdit());
        enterNextStep(4);
      }}
      holidays={fixedBBCheckoutLoaderData?.holidays || []}
    />
  );
};
