import { SubscriptionType } from '../../generated/api/subscriptionType.js';
import { fixedLineSubscriptionMsg, mobileSubscriptionsMsg, subscriptionTypeMsg, t } from '../../common/i18n/index.js';
import type { SearchFilterGroup } from '../Table/SearchFilters.js';
import type { VoiceSubscriptionTypeAggregations } from '../../generated/api/voiceSubscriptionTypeAggregations';

export const getVoiceSubscriptionTypeFilterGroup: (
  subscriptionType?: string,
  aggregations?: VoiceSubscriptionTypeAggregations
) => SearchFilterGroup = (subscriptionType = '', aggregations) => {
  const enabledSubTypes = subscriptionType.split('|');
  return {
    label: () => t.QH8P(subscriptionTypeMsg),
    value: 'subscriptionType',
    items: [
      {
        label: () => t.OZCC(mobileSubscriptionsMsg),
        checked: enabledSubTypes.includes(SubscriptionType.MOBILE),
        value: SubscriptionType.MOBILE,
        itemsCount: aggregations?.mobile,
      },
      {
        label: () => t.N04E(fixedLineSubscriptionMsg),
        checked: enabledSubTypes.includes(SubscriptionType.LANDLINE),
        value: SubscriptionType.LANDLINE,
        itemsCount: aggregations?.landline,
      },
    ],
  };
};
