import { CompanyInfo } from '../../../../../components/CompanyInfo/CompanyInfo.js';
import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { useSelector } from 'react-redux';
import type { State } from '../../../../../selfservice/common/store.js';

export const CompanyInfoHomePath = () => {
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  return (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.BASIC}>
      <CompanyInfo companyInfo={companyInfo} />
    </CompanyInfoContainer>
  );
};
