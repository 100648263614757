import { FormProvider, useForm } from 'react-hook-form';
import { OpenFormAction } from '../OpenFormReducer.js';
import { type ReactNode, useCallback, useEffect } from 'react';
import { type UseFormReturn } from 'react-hook-form';
import { useOpenFormDispatch, useOpenFormState } from '../OpenFormProvider.js';

export const useFormProvider = (index: number): [(props: { children: ReactNode }) => JSX.Element, UseFormReturn] => {
  const { answers } = useOpenFormState();
  const dispatch = useOpenFormDispatch();
  const methods = useForm({ resetOptions: { keepErrors: true, keepTouched: true }, values: answers.getRecords() });

  useEffect(() => {
    methods.formState.defaultValues && methods.trigger(Object.keys(methods.formState.defaultValues));
  }, [dispatch, methods, methods.formState.defaultValues]);

  useEffect(() => {
    methods.trigger().then();

    const { unsubscribe } = methods.watch((data, { name, type }) => {
      if (name && type === 'change') {
        dispatch(OpenFormAction.answer({ guid: name, value: data[name] }));
      }
    });

    return () => unsubscribe();
  }, [dispatch, methods, index]);

  return [useCallback(({ children }) => <FormProvider {...methods}>{children}</FormProvider>, [methods]), methods];
};
