import { SubscriptionCategory } from '../../../../common/enums.js';
import { UserDetails } from '../../../../components/UserDetails/UserDetails.js';
import { getEmployeeSubscriptions, loadBillingAccounts } from '../../../../selfservice/actions/index.js';
import { useAuth } from '../../AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const EmployeeOwnInfoPath = () => {
  const { authenticatedUser } = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    if (authenticatedUser?.businessId) {
      dispatch(loadBillingAccounts());
    }
    dispatch(getEmployeeSubscriptions(SubscriptionCategory.SERVICE));
  }, [dispatch, authenticatedUser]);

  return <UserDetails />;
};
