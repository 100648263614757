import { type Context } from '../OpenFormContext.js';
import { OFPageType } from '../../../generated/api/oFPageType.js';
import { OpenFormAction } from '../OpenFormReducer.js';
import { useCallback, useMemo } from 'react';
import { useOpenFormDispatch } from '../OpenFormProvider.js';
import type { OpenFormQuestion } from '../../../generated/api/openFormQuestion.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';

export type SetAnswer = <K extends keyof Context>(value?: string | string[], key?: K, patch?: Context[K]) => void;
export type SetChoice = (value?: string | string[]) => void;
export type SetContext = <K extends keyof Context>(key?: K, patch?: Context[K]) => void;

export type OpenFormDispatcher = {
  setAnswer: ({ guid }: { guid: string }) => SetAnswer;
  setChoice: ({ guid }: { guid: string }) => SetChoice;
  setContext: ({ guid }: { guid: string }) => SetContext;
};

export const useDispatcher = <K extends keyof Context>(section: OpenFormSection) => {
  const dispatch = useOpenFormDispatch();

  const getLinkedValues = useCallback(
    (questions?: OpenFormQuestion[]) => (value?: string | string[]) =>
      questions?.flatMap(q => q.choices.flatMap(c => (value?.includes(c.guid) && c.linkedValue) || [])),
    []
  );

  const setAnswer = useCallback(
    (props: { guid: string }) => (value?: string | string[], key?: K, patch?: Context[K]) => {
      dispatch(OpenFormAction.answer({ guid: props.guid, value }));
      dispatch(OpenFormAction.context({ guid: props.guid, key, patch }));
    },
    [dispatch]
  );

  const setChoice = useCallback(
    (props: { guid: string }) => (value?: string | string[]) => {
      section.pageType === OFPageType.QUESTION_SUBSCRIPTION_TYPE
        ? setAnswer(props)(
            value,
            value?.length ? ('subscriptionTypes' as K) : undefined,
            getLinkedValues(section.questions.filter(question => question.guid === props.guid))(value) as Context[K]
          )
        : dispatch(OpenFormAction.answer({ guid: props.guid, value }));
    },
    [section, dispatch, getLinkedValues, setAnswer]
  );

  const setContext = useCallback(
    (props: { guid: string }) => (key?: K, patch?: Context[K]) => {
      dispatch(OpenFormAction.context({ guid: props.guid, key, patch }));
    },
    [dispatch]
  );

  return useMemo(() => ({ setAnswer, setChoice, setContext }), [setAnswer, setChoice, setContext]);
};
