import * as CL from '@design-system/component-library';
import { DetailsWrapper } from '../DetailsWrapper';
import { EmptyOrError } from '../EmptyOrError';
import { EsimActivation } from '../EsimActivation/EsimActivation.js';
import { Loading } from '../Loading';
import { PhysicalSimActivation } from '../PhysicalSimActivation/PhysicalSimActivation.js';
import { ServiceFeeRequest, SimType } from '../../generated/api/models.js';
import { activateSimCardMsg, returnMsg, simCardMsg, simCardTypeMsg, simChangePendingMsg, t } from '../../common/i18n';
import { getChangeSimServiceFeePayload } from '../../common/utils/simCardUtils.js';
import { getServiceFee } from '../../selfservice/actions/serviceFeeActions.js';
import { showDialog } from '../../selfservice/actions';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import type { CategoryKey } from '../../common/utils/categoryUtils.js';
import type { CompanyInfoState } from '../../common/types/states';
import type { Subscription } from '../../generated/api/models.js';

import './SimActivation.scss';

export interface SimActivationAttrs {
  breadCrumbs: JSX.Element;
  companyInfo: CompanyInfoState;
  category: CategoryKey;
  disallowBillableSimChanges: boolean;
  pendingSimChanges: boolean;
  pendingSubscriptionChanges: boolean;
  subscription?: Subscription;
  simChangeServiceFee?: number;
  isEmployee?: boolean;
}

export interface SimActivationRoutes {
  onClickOrderSim: () => void;
  onClickSubscription: () => void;
}

export type SimActivationProps = SimActivationAttrs & SimActivationRoutes;

export const SimActivationComponent = (props: SimActivationProps) => {
  const dispatch = useDispatch();
  const { subscription, simChangeServiceFee, isEmployee } = props;
  const [simType, setSimType] = useState(subscription?.details?.mobile?.simType || SimType.PHYSICAL);
  const { authenticatedUser } = useAuth();

  useEffect(() => {
    if (!isEmployee && simChangeServiceFee === undefined && simType !== SimType.ESIM) {
      dispatch(
        getServiceFee(
          ServiceFeeRequest.ServiceTypeEnum.SIM,
          subscription?.subscriptionDisplayId || '',
          getChangeSimServiceFeePayload(simType, subscription, props.companyInfo?.address, authenticatedUser)
        )
      );
    }
  }, [props.companyInfo, dispatch, simChangeServiceFee, simType, subscription, authenticatedUser, isEmployee]);

  if (!isEmployee && simChangeServiceFee === undefined && simType !== SimType.ESIM) {
    return <Loading />;
  }

  return (
    <>
      <h3 className="ea-h3 ea-h3--thick ds-padding-bottom--4">{t.OLP0(simCardTypeMsg)}</h3>

      <CL.SelectGroup
        className="of-sim-activation__sim-type ds-padding--0"
        onChange={selectedId => {
          if (selectedId) {
            setSimType(selectedId as SimType);
          }
        }}
        products={[
          {
            id: SimType.ESIM,
            name: 'eSIM',
            selected: simType === SimType.ESIM,
          },
          {
            id: SimType.PHYSICAL,
            name: t.PIZC(simCardMsg),
            selected: simType !== SimType.ESIM,
          },
        ]}
        value={simType}
      />

      {simType === SimType.ESIM ? (
        <EsimActivation {...props} />
      ) : (
        <PhysicalSimActivation
          {...{ ...props, subscription: subscription!, onShowDialog: params => dispatch(showDialog(params)) }}
        />
      )}
    </>
  );
};

export const SimActivation = (props: SimActivationProps) => {
  const { breadCrumbs, pendingSimChanges, subscription, onClickSubscription } = props;

  if (!subscription || !subscription.details || !subscription.details.mobile) {
    return <Loading />;
  }

  if (pendingSimChanges) {
    return (
      <EmptyOrError
        id="pending-sim-changes"
        text={t.O7PT(simChangePendingMsg)}
        onButtonClick={onClickSubscription}
        buttonText={t.VH59(returnMsg)}
      />
    );
  }

  const { subscriptionContactName, subscriptionPurposeOfUse, subscriptionUserFriendlyId } = subscription;

  return (
    <DetailsWrapper
      detailsTop={breadCrumbs}
      id="of-activate-sim"
      heading={t.M1E7(activateSimCardMsg)}
      headingBottom={(subscriptionContactName || subscriptionPurposeOfUse) + ' | ' + subscriptionUserFriendlyId}
      heroPicto="sim-activate"
    >
      <SimActivationComponent {...props} />
    </DetailsWrapper>
  );
};
