import { ActionPhase } from '../common/storeUtils.js';
import { ContactRole, ContactStatus } from '../../generated/api/models.js';
import { TypeKeys } from '../actions/index.js';
import { getErrorsFromUpsertContactDialog } from '../../components/UpsertContactDialog/UpsertContactDialog.js';
import { getPreviousActionState, updateActionStatePhase } from '../common/index.js';
import {
  mergeArrays,
  reduceCrudAction,
  reduceDisplayItemsLoadAction,
  validateLoadActionFulfilledResponseCounts,
} from './reducerUtils.js';
import type { ActionsHistory } from '../common/store.js';
import type { Contact } from '../../generated/api/models.js';
import type { ContactsState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

const sortByFirstNameAsc = (a: Contact, b: Contact) => {
  const sortValueA: string = a.person ? a.person.firstName : a.commonFunction ? a.commonFunction.name : '';
  const sortValueB: string = b.person ? b.person.firstName : b.commonFunction ? b.commonFunction.name : '';
  return sortValueA >= sortValueB ? 1 : -1;
};

export function contactsReducer(
  state: (ContactsState & ActionsHistory) | undefined | null,
  action: SelfServiceActionTypes
): (ContactsState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.LOAD_CONTACTS: {
      const itemsState = reduceDisplayItemsLoadAction<TypeKeys.LOAD_CONTACTS, Contact>(
        action,
        state,
        'contactId',
        true,
        undefined,
        action.forceLoad
      );
      return {
        ...itemsState,
        saving: false,
        loading: itemsState.actions?.[0].phase === ActionPhase.IN_PROGRESS,
      };
    }

    case TypeKeys.LOAD_CONTACTS_FULFILLED: {
      const actionState = getPreviousActionState(TypeKeys.LOAD_CONTACTS, state, ActionPhase.IN_PROGRESS)!;
      const contacts = mergeArrays<Contact>('contactId', 'lastModified', state!.items, action.contacts);
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.LOAD_CONTACTS, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        errors: validateLoadActionFulfilledResponseCounts(
          actionState!.query!,
          action.total,
          action.contacts,
          state!.errors
        ),
        items: contacts.sort(sortByFirstNameAsc),
        total: action.total,
        saving: false,
        loading: false,
      };
    }

    case TypeKeys.LOAD_CONTACTS_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.LOAD_CONTACTS, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        errors: action.errors,
        saving: false,
        loading: false,
      };
    }

    case TypeKeys.UPSERT_CONTACT: {
      const errors = getErrorsFromUpsertContactDialog(action.contact, action.customerType, action.validationErrors);
      if (errors) {
        return {
          ...state,
          errors,
          saving: false,
        };
      } else {
        return {
          ...reduceCrudAction(action, state),
          errors: undefined,
          saving: true,
        };
      }
    }

    case TypeKeys.UPSERT_CONTACT_FULFILLED: {
      let contacts: Contact[] = [...(state?.items ?? [])];

      if (!contacts || contacts.length === 0) {
        return state;
      }

      if (action.contact.contactStatus === ContactStatus.PASSIVE) {
        // Remove from array if upserted contact and passivated
        contacts = contacts.filter(contact => contact.contactId !== action.contact.contactId);
      } else {
        const index = contacts.findIndex(contact => contact.contactId === action.contact.contactId);
        index !== -1
          ? (contacts = contacts.map((contact: Contact) => {
              if (contact.contactId === action.contact.contactId) {
                return { ...contact, ...action.contact };
              } else {
                return contact;
              }
            }))
          : contacts.push(action.contact);
      }

      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.UPSERT_CONTACT, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        errors: undefined,
        items: contacts.sort(sortByFirstNameAsc),
        saving: false,
      };
    }

    case TypeKeys.UPSERT_CONTACT_FAILED: {
      // Errors for this are handled in the dialog
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.UPSERT_CONTACT, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        errors: action.errors,
        saving: false,
      };
    }

    case TypeKeys.ADD_RING_ADMIN: {
      return {
        ...reduceCrudAction(action, state),
        errors: undefined,
        saving: true,
      };
    }

    case TypeKeys.REMOVE_RING_ADMIN: {
      return {
        ...reduceCrudAction(action, state),
        saving: true,
      };
    }

    case TypeKeys.REMOVE_RING_ADMIN_FULFILLED: {
      let contacts: Contact[] = state?.items ?? [];
      contacts = contacts.map((contact: Contact) => {
        if (contact.contactId === action.contactId) {
          return {
            ...contact,
            person: {
              ...contact.person!,
              roles: [
                ...(contact.person!.roles || []).filter((cr: ContactRole) => cr !== ContactRole.MOBILE_PBX_ADMIN),
              ],
            },
          };
        } else {
          return contact;
        }
      });
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.REMOVE_RING_ADMIN,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: undefined,
        items: contacts.sort(sortByFirstNameAsc),
        saving: false,
      };
    }

    case TypeKeys.REMOVE_RING_ADMIN_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.REMOVE_RING_ADMIN,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        saving: false,
      };
    }

    case TypeKeys.ADD_RING_ADMIN_FULFILLED: {
      let contacts: Contact[] = state?.items ?? [];
      contacts.findIndex(contact => contact.contactId === action.contact.contactId) !== -1
        ? (contacts = contacts.map((contact: Contact) => {
            if (contact.contactId === action.contact.contactId) {
              return { ...contact, ...action.contact };
            } else {
              return contact;
            }
          }))
        : contacts.push(action.contact);
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.ADD_RING_ADMIN, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        errors: undefined,
        items: contacts.sort(sortByFirstNameAsc),
        saving: false,
      };
    }

    case TypeKeys.ADD_RING_ADMIN_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.ADD_RING_ADMIN, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        errors: action.errors,
        saving: false,
      };
    }

    case TypeKeys.LOAD_CUSTOMER_ORDER_ADDITIONAL_INFO_FULFILLED: {
      const contacts: Contact[] = state?.items ?? [];
      if (contacts.findIndex(contact => contact.contactId === action.additionalInfo.ordererContact.contactId) !== -1) {
        return state;
      }
      contacts.push(action.additionalInfo.ordererContact);
      return {
        ...state,
        items: contacts,
        total: contacts.length,
        saving: false,
      };
    }

    case TypeKeys.SWITCH_ACCOUNT_FULFILLED:
    case TypeKeys.LOG_OUT:
    case TypeKeys.RESET_CONTACTS: {
      return null;
    }

    case TypeKeys.RESET_ERRORS: {
      return {
        ...state,
        errors: undefined,
        saving: false,
      };
    }

    default:
      return state;
  }
}
