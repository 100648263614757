import { OrderSubscriptionConfig } from '../../../../../../components/OrderSubscription/OrderSubscriptionLayout.js';
import { applyWizardEvent } from '../../../../../../selfservice/actions/index.js';
import { paths } from '../../../../../../common/constants/pathVariables.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLoaderData, useLocation, useNavigate, useRouteLoaderData } from 'react-router-dom';
import type {
  OrderSubscriptionCommonLoaderData,
  OrderSubscriptionConfigLoaderData,
} from '../../../../../../common/loaders.js';

export const OrderBroadbandSubConfigPath = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { companyInfo, onlineModels, contacts } = useRouteLoaderData(
    'newMobileBBRoot'
  ) as OrderSubscriptionCommonLoaderData;
  const { holidays, onlineModel } = useLoaderData() as OrderSubscriptionConfigLoaderData;

  useEffect(() => {
    if (!state?.selectedOffer) {
      navigate(paths.PS_BROADBAND_SUBSCRIPTION_NEW);
    }
  }, [navigate, state?.selectedOffer]);
  useEffect(() => {
    dispatch(applyWizardEvent(2, 'NEW_MOBILE_BROADBAND'));
  }, [dispatch]);

  return (
    <OrderSubscriptionConfig
      contacts={contacts}
      holidays={holidays}
      companyInfo={companyInfo}
      onlineModels={[...onlineModels, onlineModel]}
    />
  );
};
